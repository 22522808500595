import styled from 'styled-components';
import { Row, Button, Col, DatePicker, InputNumber, Modal } from 'antd';
import { ReactComponent as EditIcon } from '../../../assets/icons/Edit.svg';

export const FormContainer = styled(Row)`
  background-color: #fff;
  padding: 25px 30px;
  display: block;
  border-radius: 10px;
  flex-direction: column;
  .first_row_prof {
    padding-bottom: 25px;
  }

  &.profile_setting_data h3 {
    font-size: 18px;
  }
  #dob {
    /* color: #333333; */
  }
  &.form-m2-style h3 {
    margin: 15px 0 25px;
    font-size: 20px;
  }
  &.form-m2-style
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    /* color: #333333; */
    background: #f5f5f5;
  }
  &.form-m2-style .ant-form-item-label label {
    font-size: 16px;
    color: #818181;
  }
  &.form-m2-style .ant-form-item-label label:before {
    display: none;
  }
  &.form-m2-style .ant-form-item-label label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff0003;
    font-size: 18px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    font-weight: 700;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-input-password
    .ant-input {
    padding: 10px;
  }
  &.form-m2-style .ant-form-item-control-input-content .ant-input,
  &.form-m2-style .ant-form-item-control-input-content .ant-select-selector,
  &.form-m2-style .ant-form-item-control-input-content .ant-picker,
  &.form-m2-style .ant-form-item-control-input-content .ant-input-number {
    height: 60px;
    border: 1px solid #d6d6d6;
    border-radius: 5px !important;
    font-size: 18px;
    /* color: #333333; */

    /* background-color: #ffffff; */
  }
  &.form-m2-style .ant-form-item-control-input-content .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    background-color: #ffffff;
  }
  &.appointment_form_style1.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select
    + .ant-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select:hover
    .ant-select-selector {
    border: 1px solid #d6d6d6;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-search-input,
  &.form-m2-style .ant-form-item-control-input-content .ant-input-number-input {
    height: 60px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-placeholder,
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-item {
    line-height: 56px !important;
  }
  &.form-m2-style .ant-form-item-explain div {
    font-size: 12px;
    text-transform: capitalize;
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }
  &.form-m2-style
    .ant-form-item-has-error
    .ant-select:not(.ant-select-borderless)
    .ant-select-selector {
    border-color: #d6d6d6 !important;
  }
  &.form-m2-style .ant-btn-background-ghost.ant-btn-danger {
    padding: 3px 10px;
    height: auto;
    font-weight: 600;
  }
  &.form-m2-style .add-btn {
    padding: 9px 10px;
    height: auto;
    font-weight: 600;
    margin: 12px 0 0;
  }
  &.form-check-tag {
    margin: 5px 0 25px;
  }
  &.form-check-tag span {
    font-size: 18px;
  }
  &.form-check-tag .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .btn-save,
  .btn-cancel {
    min-width: 120px;
    font-size: 18px;
    height: 50px;
    border: 2px solid #26b1ff;
    padding: 0px 10px;
    font-weight: 500;
    border-radius: 6px;
  }

  @media (max-width: 1100px) {
    .form-m2-style .ant-form-item-label label {
      font-size: 14px;
    }
    .form-m2-style .ant-form-item-control-input-content .ant-input,
    .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
    .form-m2-style .ant-form-item-control-input-content .ant-picker,
    &.form-m2-style .ant-form-item-control-input-content .ant-input-number {
      height: 50px;
      font-size: 15px;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-search-input {
      height: 50px !important;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-placeholder,
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-item {
      line-height: 46px !important;
    }
    .form-check-tag span {
      font-size: 15px;
    }
    .form-m2-style h3 {
      margin: 12px 0 24px;
      font-size: 18px;
    }
    h2.h2-base-600 {
      font-size: 20px;
    }
    .form-m2-style .forminput_timepick {
      height: 50px;
      border-radius: 5px;
    }
    .available_time_field .ant-form-item-control-input,
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-input-number-input {
      height: 50px !important;
      border-radius: 5px;
    }
  }

  @media (max-width: 991px) {
    .prof_detail_upload {
      text-align: center;
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .img_port_col {
      width: 150px;
      padding-right: 0;
      margin: 0 auto;
    }
  }

  @media (max-width: 767px) {
    .form-m2-style h3 {
      margin: 5px 0 15px;
      font-size: 16px;
    }
    .form-m2-style {
      max-width: 420px;
      margin: 0 auto;
    }
    .form-m2-style > .ant-row {
      display: block;
    }
    .form-m2-style .ant-col {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .form-m2-style .flex-end-btn .ant-row {
      display: flex;
    }
    .flex-end-btn .ant-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      align-items: center;
    }
    .flex-end-btn .ant-form-item-control {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .btn-save,
    .btn-cancel {
      min-width: 110px;
      font-size: 16px;
      height: 42px;
    }
    .form-m2-style .ant-form-item-explain div {
      font-size: 11px;
    }
    .appointment_form_style1 {
      padding-top: 15px;
    }
    &.form-m2-style h3 {
      font-size: 18px;
    }
  }

  @media (max-width: 600px) {
    padding: 15px 15px;
    .img_port_col {
      margin: 0 auto !important;
    }
    .form-m2-style .ant-form-item-control-input-content .ant-select {
      width: 100%;
    }
  }
`;
export const ChangePasswordCol = styled(Col)`
  @media (max-width: 600px) {
    text-align: center;
  }
`;
export const Heading = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 2px 0;
`;

export const HeadingRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledEditIcon = styled(EditIcon)`
  height: 20px;
  width: 20px;
  & .cls-2 {
    fill: ${(props) => (props.disabled ? '#5d5d5d' : `${props.color}`)};
  }
`;

export const EditButton = styled(Button)`
  border: none !important;
`;

export const PatientIdP = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const ProfileDetailsP = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
`;

export const ProfileDetailsCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  &.prof_detail_wrap p {
    font-weight: 600;
    font-size: 15px;
    /* color: #333333; */
    line-height: 1.4;
    letter-spacing: 0.01em;
  }
  &.prof_detail_wrap p.pat_id {
    font-size: 18px;
  }
  .pwd_btn {
    min-width: 130px;
    height: 40px;
    max-width: 190px;
    font-weight: 600;
  }
  &.align-end {
    align-items: flex-end;
  }
  @media (max-width: 991px) {
    &.col-cnt1 {
      flex: 0 0 40%;
      max-width: 40%;
    }
    &.col-cnt2 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    &.align-end {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .pwd_btn {
      max-width: 170px;
      margin: 20px auto 10px;
      text-align: center;
      align-items: center;
      display: flex;
    }
  }
  @media (max-width: 600px) {
    text-align: center;
    &.col-cnt1 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    &.col-cnt2 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .pwd_btn {
      // margin: 20px 0 10px;
    }
  }
`;

export const ChangePasswordButton = styled(Button)`
  border-radius: 5px;
  width: ${(props) => (props.changeWidth ? '250px' : '80%')};
  min-width: 200px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

export const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
  & .ant-modal-footer {
    border-top: none;
    text-align: center;
  }
`;

export const StyledDivPrint = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
