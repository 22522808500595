import React, { useState, useEffect } from "react";
import {
  SectionHeadContainer,
  SectionHeading,
  StyledDiv,
} from "../styledComponents";
import { NewButton, BodyContainer, StyledBodyTitle } from "./styledComponents";
// import SectionManagementTable from "./SectionManageMentTable";
import { formColumns } from "./tableConfig";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import * as Sentry from "@sentry/react";
import { Row, Col, Button, message, notification } from "antd";
import Icon, { LeftCircleOutlined } from "@ant-design/icons";
import NewFormModal from "./NewFormModal";
import {
  changeSectionOrder,
  fetchObervationForms,
  fetchSubSections,
} from "../../../../apis/healthRecord";
import { useSelector } from "react-redux";
import SectionManagementTable from "../Sections/SectionManageMentTable";
const FormManagement = ({
  setTab,
  totalForms,
  setTotalForms,
  setSelectedForm,
}) => {
  const [loading, setLoading] = useState(false);
  const [newFormModal, setNewFormModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState();
  const [changesOrderStatus, setChangeOrderStatus] = useState(false);
  const [forms, setForms] = useState([]);
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);

  const handleOrderChangeClick = () => {
    if (changesOrderStatus) {
      let newOrder = forms.map((item, index) => ({
        formId: item.id,
        formOrder: index + 1,
      }));
      changeSectionOrder({ formOrderChanges: newOrder })
        .then(({ data, status }) => {
          if (data.status == 1) {
            notification.success({
              message: "New Order saved!",
            });
            setChangeOrderStatus(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    } else {
      setChangeOrderStatus(true);
    }
  };

  const newFormClickHandler = () => {
    setNewFormModal(true);
  };

  const updateFormClickHandler = (record) => {
    setSelectedSection(record);
    setNewFormModal(true);
  };

  const getForms = () => {
    setLoading(true);
    fetchObervationForms()
      .then(({ data, status }) => {
        if (status === 200) {
          console.log("fetchobservation dtaa",data)
          if (data.status === 1) {
            setForms(data.body);
            setTotalForms(data.body.length);
          } else message.error(data.body);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getForms();
  }, []);
  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row>
            <Col span={24}>
              <SectionHeading>Observations</SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <Row gutter={[0, 18]}>
            <Col span={24} style={{ paddingBottom: "5px" }}>
              <Row justify="space-between">
                <Col span={12}>
                  <StyledBodyTitle color={theme["@primary-color"]} level={4}>
                    Manage Forms
                  </StyledBodyTitle>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    onClick={newFormClickHandler}
                    style={{ border: 0, boxShadow: "none" }}
                  >
                    Create New Form
                  </Button>
                  <Button
                    type={"primary"}
                    ghost={!changesOrderStatus}
                    onClick={handleOrderChangeClick}
                    size="large"
                    style={{ border: 0, boxShadow: "none", width: "140px" }}
                  >
                    {changesOrderStatus ? "Save Order" : "Change Order"}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ padding: "0" }}>
              <StyledDiv bgcolor={theme["@primary-color"]}>
                <div>&nbsp;&nbsp;Forms</div>
                <div>&nbsp;&nbsp;</div>
              </StyledDiv>
              <SectionManagementTable
                dataSource={forms}
                columns={formColumns(
                  setTab,
                  setSelectedForm,
                  updateFormClickHandler,
                  theme
                )}
                loading={loading}
                draggable={changesOrderStatus}
                setDataSource={setForms}
              />
            </Col>
          </Row>
        </BodyContainer>
      </Col>
      <NewFormModal
        visible={newFormModal}
        setVisible={(value) => {
          setSelectedSection();
          setNewFormModal(value);
        }}
        totalForms={totalForms}
        selectedForm={selectedSection}
        refetch={() => getForms()}
      />
    </Row>
  );
};

export default FormManagement;
