// export const PROFILE_IMAGE_PREFIX =
//   "http://52.203.100.234:8082/api/v1/auth/download-file?name=";
import { getBaseUrl } from '../config/axiosInstance'
// local
export const PROFILE_IMAGE_PREFIX =
  process.env.REACT_APP_API_BASE_USER_URL + '/auth/download-file?name='
export const DRUG_TEMPLATE = PROFILE_IMAGE_PREFIX + 'drug_default.xlsx'
export const ALLERGY_TEMPLATE = PROFILE_IMAGE_PREFIX + 'dictionar_template.xlsx'
export const CAPTCHA_SERCRET_KEY = '6LeJggccAAAAAEI28blxaGM24KWcymyuRSEcLlp-'
export const IMAGE_PREFIX_URL =
  process.env.REACT_APP_API_BASE_USER_URL + '/auth/download-file?name='
