import { ADD_USER, CLEAR_USER } from "../actions/user";

const initialState = {};
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      return action.payload;

    case CLEAR_USER:
      return {};
    default:
      return state;
  }
};
