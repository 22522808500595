import React from 'react';
import moment from 'moment';
import { Row, Col, Button } from 'antd';

export const SMSListColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  reSendFunction
) => [
  // {
  //   title:
  //     (user.userType === 'RECEPTIONIST' ||
  //       user.userType === 'NURSE' ||
  //       user.userType === 'DOCTOR') &&
  //     'Actions',
  //   dataIndex: ['patient'],
  //   fixed: 'left',
  //   render: (row, patient) => {
  //     return (
  //       (user.userType === 'RECEPTIONIST' ||
  //         user.userType === 'NURSE' ||
  //         user.userType === 'DOCTOR') && (
  //         <>
  //           <HasPrivilege role="RECEPTIONIST">
  //             <Row gutter={12}>
  //               <Col>
  //                 <StyledButton
  //                   color={theme['@primary-color']}
  //                   size="small"
  //                   onClick={(e) => {
  //                     e.stopPropagation();
  //                     history.push(
  //                       `/appointment/${row.id}/${patient.id}/${row.firstName} ${row.lastName}`
  //                     );
  //                   }}
  //                 >
  //                   Reschedule
  //                 </StyledButton>
  //               </Col>
  //               <Col>
  //                 <Dropdown
  //                   overlay={menuActivePatients(
  //                     row,
  //                     patient,
  //                     cancelModalVisible,
  //                     history
  //                   )}
  //                   trigger={['click']}
  //                 >
  //                   <a
  //                     style={{ fontWeight: '600' }}
  //                     onClick={(e) => {
  //                       e.preventDefault();
  //                       e.stopPropagation();
  //                     }}
  //                   >
  //                     More Actions
  //                   </a>
  //                 </Dropdown>
  //               </Col>
  //             </Row>
  //           </HasPrivilege>
  //           <Row gutter={12}>
  //             {console.log(
  //               'date here',
  //               new Date(patient.date).toLocaleDateString('en-US'),
  //               new Date().toLocaleDateString('en-US')
  //             )}
  //             <HasPrivilege role="DOCTOR">
  //               {patient.inTimeDoc === null &&
  //               new Date(patient.date).toLocaleDateString('en-US') ===
  //                 new Date().toLocaleDateString('en-US') ? (
  //                 <Col>
  //                   <StyledButton
  //                     color={theme['@primary-color']}
  //                     size="small"
  //                     onClick={(e) => {
  //                       e.stopPropagation();
  //                       setAppointmentId({
  //                         appointmentId: patient.id,
  //                         patientId: row.id,
  //                       });
  //                       inTimeModalVisible(true);
  //                     }}
  //                   >
  //                     In Time
  //                   </StyledButton>
  //                 </Col>
  //               ) : (
  //                 <Col>
  //                   {' '}
  //                   <StyledButton
  //                     color={theme['@primary-color']}
  //                     size="small"
  //                     onClick={(e) => {
  //                       e.stopPropagation();
  //                       console.log('row id', patient);
  //                       history.push(
  //                         `/health-records/${row.id}/${
  //                           row.firstName + '-' + row.lastName
  //                         }/${patient.id}`
  //                       );
  //                     }}
  //                   >
  //                     Call/View
  //                   </StyledButton>
  //                 </Col>
  //               )}
  //             </HasPrivilege>

  //             <HasPrivilege role="NURSE">
  //               {patient.inTime === null || patient.inTime === undefined ? (
  //                 <Col>
  //                   <StyledButton
  //                     color={theme['@primary-color']}
  //                     size="small"
  //                     onClick={(e) => {
  //                       e.stopPropagation();
  //                       setAppointmentId({
  //                         appointmentId: patient.id,
  //                         patientId: row.id,
  //                       });
  //                       inTimeModalVisible(true);
  //                     }}
  //                   >
  //                     In Time
  //                   </StyledButton>
  //                 </Col>
  //               ) : (
  //                 <Col>
  //                   {' '}
  //                   <StyledButton
  //                     color={theme['@primary-color']}
  //                     size="small"
  //                     onClick={(e) => {
  //                       e.stopPropagation();
  //                       history.push(
  //                         `/health-records/${row.id}/${
  //                           row.firstName + '-' + row.lastName
  //                         }/${patient.id}`
  //                       );
  //                     }}
  //                   >
  //                     Call
  //                   </StyledButton>
  //                 </Col>
  //               )}
  //               <Col>
  //                 <StyledButton
  //                   color={theme['@primary-color']}
  //                   size="small"
  //                   onClick={(e) => {
  //                     e.stopPropagation();
  //                     setNurseUploadReportModalVisible(true);
  //                     setUploadNursePatient(row);
  //                   }}
  //                 >
  //                   Upload
  //                 </StyledButton>
  //               </Col>
  //             </HasPrivilege>
  //           </Row>
  //         </>
  //       )
  //     );
  //   },
  // },
  {
    title: 'Sl. No',
    dataIndex: '',
    key: 'no',
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },

  {
    title: 'Patient Name',
    dataIndex: ['patient'],
    key: 'firstName',
    render: (patient) => {
      if (patient !== null) {
        return (
          <div>
            {patient.firstName} {patient.lastName}
          </div>
        );
      } else return <div>-</div>;
    },
  },
  {
    title: 'Message',
    dataIndex: ['message'],
    key: 'message',
    render: (message) => {
      return <div>{message.substring(0, 50)}</div>;
    },
  },

  {
    title: 'Mobile No',
    dataIndex: ['patient', 'phoneNumbers'],
    key: 'phone',
    render: (value) => {
      if (value !== null) {
        return (
          value &&
          value[0] &&
          value[0].phoneNumber && (
            <div>
              {value[0].countryCode.split(' ')[0]}-{value[0].phoneNumber}
            </div>
          )
        );
      } else return <div>-</div>;
    },
  },
  {
    title: 'Doctor Name',
    dataIndex: ['doctor'],
    key: 'doctor',
    render: (doctor) => {
      if (doctor !== null) {
        return (
          <div>
            Dr. {doctor.firstName} {doctor.lastName}
          </div>
        );
      } else return <div>-</div>;
    },
  },

  {
    title: 'Date',
    dataIndex: 'timeStamp',
    key: 'date',
    render: (date) => {
      if (date !== null) return <div>{moment(date).format('DD-MM-YYYY')}</div>;
      else return <div>-</div>;
    },
  },

  {
    title: 'Status',
    dataIndex: ['status'],
    key: 'status',
    render: (status) => {
      if (status !== null) return <div>{status.toUpperCase()}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: 'Actions',
    dataIndex: ['status'],
    key: 'status',
    render: (status, row) => {
      if (status === 'failed')
        return (
          <div>
            <Button
              onClick={() => {
                // reSendFunction(row.patient.id, row.message, row.recipient);
                reSendFunction(row.id);
              }}
            >
              RESEND
            </Button>
          </div>
        );
      else return <div>-</div>;
    },
  },
  {
    width: 10,
  },
];
