import { Col } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { BlueBgTable } from "../Departments/styledComponents";
import { BodyContainer } from "../Sections/styledComponents";
import { StyledDiv, StyledTable } from "./styledComponents";
import { columns } from "./tableConfig";

const InsuranceProvidersTable = ({
  forms,
  loading,
  setSelected,
  setEditModal,
  setSelectedItem,
  setDeleteModal,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  return (
    <Col span={24}>
      <StyledDiv bgcolor={theme["@primary-color"]}>All Insurance </StyledDiv>
      <StyledTable
        columns={columns(
          setEditModal,
          setDeleteModal,
          setSelectedItem,
          setSelected
        )}
        dataSource={forms}
        loading={loading}
        // scroll={{ x: "max-content" }}
        pagination={false}
      />
    </Col>
  );
};
export default InsuranceProvidersTable;
