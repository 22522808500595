import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import LoginForm from './LoginForm';
import HospitalLogo from './HospitalLogo';
import { ReactComponent as DoctorIcon } from '../../assets/icons/304919.svg';
import {
  FulHeightROw,
  LoginFormContainer,
  StyledContactButton,
  StyledPTag,
  ImageContainer,
  ContactUsContainer,
  RightContainer,
  StyledCol,
} from './styledElements';
import ForgotPasswordModal from '../ForgotPasswordModal';
import PasswordResetAlert from './PasswordResetAlert';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PROFILE_IMAGE_PREFIX } from '../../constants/StringConstants';
import ContactUsModal from './ContactUsModal';
import MeditagLoginImage from '../../assets/images/hospital_pic_1.jpg';

const Login = () => {
  const history = useHistory();
  const [theme, images, user] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
    state.userReducer,
  ]);
  console.log('user in admin', user);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] =
    useState(false);
  const [resetAlertVisible, setResetAlertVisible] = useState(false);
  const [contactUsModalVisible, setContactUsModalVisible] = useState(false);

  useEffect(() => {
    if (history.location.state && history.location.state.showAlert) {
      setResetAlertVisible(true);
    }
  }, []);
  useEffect(() => {
    if (user.token) {
      history.push('/');
    }
  }, [user]);

  return (
    <>
      <FulHeightROw>
        <StyledCol xxl={18} xl={17} lg={14} md={0}>
          <ImageContainer
            // imageURL={
            //   images.cover
            //     ? PROFILE_IMAGE_PREFIX + images.cover
            //     : "https://previews.123rf.com/images/fsstock/fsstock1607/fsstock160700046/60254756-saudi-arab-doctors-working-with-a-tablet-.jpg"
            // }
            imageURL={MeditagLoginImage}
          />
        </StyledCol>
        <StyledCol xxl={6} xl={7} lg={10} md={24}>
          <RightContainer className="main_login_form">
            <LoginFormContainer
              bg={theme['@primary-color']}
              className="form_ui_component"
            >
              <Row align="middle" className="form_inner_data">
                <StyledCol span={24}>
                  <HospitalLogo
                    title="Hospital Logo"
                    icon={<DoctorIcon style={{ height: '84px' }} />}
                    // imageUrl={
                    //   images.logo
                    //     ? PROFILE_IMAGE_PREFIX + images.logo
                    //     : undefined
                    // }
                    imageUrl={
                      'https://api.meditagg.com/api/v1/auth/download-file?name=hospital_pic_1671612999007.png'
                    }
                  />
                </StyledCol>
                <StyledCol span={24}>
                  <LoginForm
                    setForgotPasswordModalVisible={
                      setForgotPasswordModalVisible
                    }
                  />
                </StyledCol>
                {/* <Col span={24} style={{ padding: "25px 0" }}>
                  <StyledPTag size={"18px"}>OR</StyledPTag>
                </Col>
                <StyledCol span={24}>
                  <Button
                    ghost
                    onClick={() => history.push("/self-registration")}
                    className="submit-btn-reg"
                  >
                    Patient Registration
                  </Button>
                </StyledCol> */}
              </Row>
              <ContactUsContainer
                justify="center"
                align="center"
                className="contact_box"
              >
                <StyledCol>
                  <StyledContactButton
                    type="primary"
                    size="large"
                    onClick={() => setContactUsModalVisible(true)}
                  >
                    Contact Us
                  </StyledContactButton>
                </StyledCol>
                <StyledPTag size={'13px'}>{images.contactText}</StyledPTag>
              </ContactUsContainer>
            </LoginFormContainer>
          </RightContainer>
        </StyledCol>
      </FulHeightROw>
      <ForgotPasswordModal
        visible={forgotPasswordModalVisible}
        setVisible={setForgotPasswordModalVisible}
      />
      <PasswordResetAlert
        visible={resetAlertVisible}
        setVisible={setResetAlertVisible}
      />
      <ContactUsModal
        visible={contactUsModalVisible}
        setVisible={setContactUsModalVisible}
      />
    </>
  );
};

export default Login;
