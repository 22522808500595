import React, { useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import { Row, Col, Button, Input, Form, Select, Divider, message } from "antd";
import { useEffect } from "react";
import {
  ModalBodyContainer,
  ModalTitle,
  StyledCheckbox,
  StyledRow,
  StyledTitle,
  StyledDivider,
} from "./styledComponents";
import { useSelector } from "react-redux";
import { NewUserButton } from "./index.jsx";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { deleteInsurancePlanById } from "../../../../apis/Insurance.js";
import VisitTypeContainer from "./VisitTypeContainer.jsx";
import * as Sentry from "@sentry/react";

const { Option } = Select;
const toCamelCase = (str) => {
  var initialArrayList = str.split(" ");

  var finalArrayList = [];
  initialArrayList.map((string) => {
    finalArrayList.push(
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    );
  });

  return finalArrayList.join(" ");
};

const StyledSelectRow = styled(Row)`
  /* padding: 4px 0; */
  cursor: default;
  & .ant-row:hover {
    background-color: #00000016;
  }
`;

const CreateInsuranceProvider = ({
  // visible,
  // setVistible,
  loading,
  createInsuranceProvider,
  edit,
  selectedItem,
  trigger,
  updateInsuranceDetails,
  visitTypeValues,
  setSelected,
}) => {
  const [form] = Form.useForm();
  const [selectedVisitType, setSelectedVisitType] = useState([]);
  const [amountTrigger, setAmountTrigger] = useState(false);
  const theme = useSelector((state) => state.themeReducer);
  const [insurancePlan, setInsurancePlan] = useState([]);
  const [name, setName] = useState();

  const insuranceTypes = ["SELF", "COPAY", "BOTH"];
  let insuranceTypeMap = new Map([
    ["SELF", "Self"],
    ["COPAY", "CoPay"],
    ["BOTH", "Both"],
  ]);
  const deductibleType = ["Percentage", "Fixed Amount"];

  useEffect(() => {
    form.resetFields();
    setSelectedVisitType([]);
    setInsurancePlan([]);
  }, [trigger]);

  useEffect(() => {
    if (edit) {
      setSelectedVisitType(selectedItem.visitTypes);
      form.setFieldsValue({
        providerName: selectedItem.name,
        Hospital: selectedItem.hospitalInsuranceProviderData,
        Laboratory: selectedItem.laboratoryInsuranceProviderData,
        Pharmacy: selectedItem.pharmacyInsuranceProviderData,
        Radiology: selectedItem.radiologyInsuranceProviderData,
        visitTypes: selectedItem.visitTypes,
        insurancePlan: selectedItem.insurancePlans[0]?.value,
      });
      setInsurancePlan(selectedItem.insurancePlans);

      // if (
      //   selectedItem.insuranceType === "COPAY" ||
      //   selectedItem.insuranceType === "BOTH"
      // ) {
      //   setCopayTrigger(true);
      // } else {
      //   setCopayTrigger(false);
      // }
    }
  }, [edit]);

  const submitHandler = (values) => {
    let body = values;
    if (edit) {
      if (body.Laboratory)
        body.Laboratory.id = selectedItem.laboratoryInsuranceProviderData?.id;
      if (body.Radiology)
        body.Radiology.id = selectedItem.radiologyInsuranceProviderData?.id;
      if (body.Pharmacy)
        body.Pharmacy.id = selectedItem.pharmacyInsuranceProviderData?.id;
      if (body.Hospital)
        body.Hospital.id = selectedItem.hospitalInsuranceProviderData?.id;
      body.insurancePlan = insurancePlan.map((item) => {
        if (item.id)
          return {
            id: item.id,
            value: item.value,
          };
        else
          return {
            id: null,
            value: item.value,
          };
      });
      let existingPlansIds = selectedItem.insurancePlans.map((item) => item.id);
      let newPlansIds = body.insurancePlan.map((item) => item.id);
      existingPlansIds.map((item) => {
        if (!newPlansIds.includes(item)) deleteInsurancePlan(item);
      });
      updateInsuranceDetails(body);
    } else {
      body.insurancePlan = insurancePlan.map((item) => {
        if (item.id)
          return {
            id: item.id,
            value: item.value,
          };
        else
          return {
            id: null,
            value: item.value,
          };
      });
      createInsuranceProvider(body);
    }
  };

  const onChange = (changed, all) => {
    if (changed.visitTypes) {
      // visitTypeValues.forEach((element) => {
      //   if (!changed.visitTypes?.includes(element.value)) {
      //     var visitTypeValue = element.value;
      //     form.setFieldsValue({ [visitTypeValue]: undefined });
      //   }
      //   console.log(form.getFieldValue());
      // });
    }
  };

  const addItem = (e) => {
    let value = { value: name };
    setInsurancePlan([...insurancePlan, value]);
    setName();
  };

  useEffect(() => {
    if (insurancePlan.length > 0) {
      form.setFieldsValue({ insurancePlan: "Insurance Plans" });
    } else {
      form.setFieldsValue({ insurancePlan: null });
    }
  }, [insurancePlan]);

  const deleteInsurancePlan = (id) => {
    deleteInsurancePlanById(id)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 1) {
            console.log(res.data.body);
          } else {
            message.error(res.data.body);
          }
        } else {
          message.error(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const deleteItem = (e) => {
    console.log(e);
    insurancePlan.map((item) => {
      if (item.value == e)
        if (item.id) {
          console.log(item);
          setInsurancePlan(insurancePlan.filter((plan) => plan.value !== e));
        } else {
          setInsurancePlan(insurancePlan.filter((plan) => plan.value !== e));
        }
    });
  };

  return (
    // <CustomModal
    //   visible={visible}
    //   antdMOdalProps={{ centered: true }}
    //   style={{ height: "240px" }}
    // >
    <ModalBodyContainer>
      <Row>
        <Col span={24}>
          <ModalTitle color={theme["@primary-color"]}>
            {edit ? "Edit Insurance" : "Add New Insurance"}
          </ModalTitle>
        </Col>
      </Row>
      <Form
        layout="vertical"
        onFinish={(values) => submitHandler(values)}
        onValuesChange={onChange}
        form={form}
        scrollToFirstError={true}
        style={{ width: "100%" }}
      >
        <Row span={24} justify="space-between">
          <Col span={11}>
            <Form.Item
              label="Provider Name"
              name="providerName"
              rules={[
                { required: true, message: "Provider Name cannot be empty" },
              ]}
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Insurance Plan"
              name="insurancePlan"
              rules={[
                { required: true, message: "Insurance Plan cannot be empty" },
              ]}
            >
              {/* <Input size="large" placeholder="Plan" /> */}
              <Select
                size="large"
                placeholder="Plan"
                dropdownRender={(menu) => (
                  <div>
                    {menu.props.flattenOptions?.map((item) => (
                      <StyledSelectRow>
                        <Row
                          style={{
                            width: "100%",
                            padding: "4px",
                            textTransform: "capitalize",
                          }}
                        >
                          <Col span={22}>&nbsp;&nbsp;&nbsp;{item.key}</Col>
                          <Col
                            span={2}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteItem(item.key)}
                          >
                            <CloseCircleOutlined style={{ color: "red" }} />
                          </Col>
                        </Row>
                      </StyledSelectRow>
                    ))}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                      }}
                    >
                      <Input
                        style={{ flex: "auto" }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <a
                        style={{
                          flex: "none",
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={() => name && addItem()}
                      >
                        <PlusOutlined /> Add Item
                      </a>
                    </div>
                  </div>
                )}
              >
                {insurancePlan?.map((item) => (
                  <Option key={item?.value}>{item?.value}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Visit Types"
              name="visitTypes"
              rules={[{ required: true, message: "Select a Visit Type" }]}
            >
              <StyledCheckbox.Group
                size="large"
                onChange={(value) => setSelectedVisitType(value)}
              >
                <Row style={{ gap: "10px" }}>
                  {visitTypeValues &&
                    visitTypeValues.map((type) => (
                      <StyledCheckbox value={type.value} size="large">
                        {type.value}
                      </StyledCheckbox>
                    ))}
                </Row>
              </StyledCheckbox.Group>
            </Form.Item>
          </Col>
          {selectedVisitType.map((item) => (
            <>
              <VisitTypeContainer
                type={item}
                form={form}
                deductibleType={deductibleType}
                setAmountTrigger={setAmountTrigger}
                insuranceTypes={insuranceTypes}
                insuranceTypeMap={insuranceTypeMap}
              />
            </>
          ))}
        </Row>
        <Form.Item>
          <StyledRow gutter={12} justify="end">
            <Col>
              <NewUserButton
                ghost={true}
                type="primary"
                onClick={() => {
                  // setVistible(false);
                  form.resetFields();
                  setSelectedVisitType([]);
                  setSelected(false);
                  setInsurancePlan([]);
                }}
              >
                Cancel
              </NewUserButton>
            </Col>
            <Col>
              <NewUserButton type="primary" loading={loading} htmlType="submit">
                {edit ? "Update" : "Create"}
              </NewUserButton>
            </Col>
          </StyledRow>
        </Form.Item>
      </Form>
    </ModalBodyContainer>
    // </CustomModal>
  );
};

export default CreateInsuranceProvider;
