import axiosInstance from "../config/axiosInstance";
const FORM_PREFIX = "/consent-form";

export const viewConsentForms = (name) => {
  return axiosInstance.get(`${FORM_PREFIX}?name=${name}`);
};

export const addConsentForm = (departmentId, name, data) => {
  const formData = new FormData();
  formData.append("form", data);
  return axiosInstance.post(
    `${FORM_PREFIX}?departmentId=${departmentId}&name=${name}`,
    formData,
    { "content-type": "multipart/form-data" }
  );
};

export const editConsentForm = (formId, departmentId,name, data) => {
  const formData = new FormData();
  formData.append("form", data);
  return axiosInstance.put(
    `${FORM_PREFIX}?departmentId=${departmentId}&id=${formId}&name=${name}`,
    formData,
    { "content-type": "multipart/form-data" }
  );
};

export const deleteConsentForm = (consentFormId) => {
  return axiosInstance.delete(`${FORM_PREFIX}?consentFormId=${consentFormId}`);
};
