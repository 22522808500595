import { ReactComponent as UserRole } from "../../../assets/icons/user-roles.svg";
import { ReactComponent as UserManagement } from "../../../assets/icons/user-manage.svg";
import { ReactComponent as Departments } from "../../../assets/icons/department.svg";
import { ReactComponent as Encounters } from "../../../assets/icons/encounters.svg";
import { ReactComponent as Drug } from "../../../assets/icons/drug.svg";
import { ReactComponent as ManageSections } from "../../../assets/icons/sections.svg";
import { ReactComponent as ConsentForms } from "../../../assets/icons/consent-manage.svg";
import { ReactComponent as Insurance } from "../../../assets/icons/insurance.svg";
import { ReactComponent as HL7Status } from "../../../assets/icons/HL7Status.svg";
import { ReactComponent as HL7 } from "../../../assets/icons/serverIcon.svg";
import { ReactComponent as GeneralSettings } from "../../../assets/icons/SettingsIcon.svg";
import { ReactComponent as Laboratory } from "../../../assets/icons/Laboratory.svg";
import { ReactComponent as Radiology } from "../../../assets/icons/Radiology.svg";
import { ReactComponent as ChiefComplaints } from "../../../assets/icons/ChiefComplaints.svg";
import { ReactComponent as Email } from "../../../assets/icons/Email.svg";
import { ReactComponent as Allergy } from "../../../assets/icons/allergyIcon.svg";
export const sidebarConfig = [
  {
    name: "Dashboard",
    path: "dashboard",
    icon: ManageSections,
    privilege: "MANAGE_SECTIONS",
  },
  {
    name: "User Roles",
    path: "user-roles",
    icon: UserRole,
    privilege: "USER_ROLE_SECTION",
  },
  {
    name: "User Management",
    path: "user-management",
    icon: UserManagement,
    privilege: "USER_MANAGEMENT_SECTION",
  },
  {
    name: "Departments",
    path: "departments",
    icon: Departments,
    privilege: "DEPARTMENTS_SECTION",
  },
  {
    name: "Ward Category",
    path: "ward-category",
    icon: Drug,
    privilege: "VIEW_WARD_CATEGORY",
  },
  {
    name: "Wards",
    path: "ward",
    icon: Departments,
    privilege: "VIEW_WARD",
  },
  {
    name: "Beds",
    path: "bed",
    icon: Departments,
    privilege: "VIEW_BED",
  },
  {
    name: "Observations",
    path: "observations",
    icon: Encounters,
    privilege: "OBSERVATIONS_SECTION",
  },
  { name: "Drugs", path: "drug", icon: Drug, privilege: "DRUG_SECTION" },
  {
    name: "Dictionary",
    path: "dictionary",
    icon: Allergy,
    privilege: "DRUG_SECTION",
  },
  {
    name: "Manage Sections",
    path: "manage-sections",
    icon: ManageSections,
    privilege: "MANAGE_SECTIONS",
  },
  {
    name: "Laboratory",
    path: "lab-tests",
    icon: Laboratory,
    privilege: "LIST_LABORATORY_TESTS",
  },
  {
    name: "Radiology",
    path: "radiology",
    icon: Radiology,
    privilege: "LIST_RADIOLOGY_TESTS",
  },
  {
    name: "Chief Complaints",
    path: "chief-complaints",
    icon: ChiefComplaints,
    privilege: "LIST_CHIEF_COMPLAINTS",
  },
  {
    name: "Manage Consent Forms",
    path: "manage-consent-forms",
    icon: ConsentForms,
    privilege: "MANEGE_CONSENT_FORMS_SECTION",
  },
  {
    name: "Manage Insurance",
    path: "manage-insurance",
    icon: Insurance,
    privilege: "MANAGE_INSURANCE_SECTION",
  },
  // { name: "Dictionary", path: "dictionary", icon: Dictionory },
  {
    name: "Email Templates",
    path: "email-template",
    icon: Email,
    privilege: "EMAIL_TEMPLATE_SECTION",
  },
  {
    name: "HL7 Status",
    path: "hl7-status",
    icon: HL7Status,
    privilege: "HL7_STATUS",
  },
  {
    name: "HL7 Listeners",
    path: "hl7-listeners",
    icon: HL7,
    privilege: "FETCH_HL7_LISTENERS",
  },
  {
    name: "General Settings",
    path: "general-settings",
    icon: GeneralSettings,
    privilege: "GENERAL_SETTINGS_SECTION",
  },
];
