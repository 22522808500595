export const nationality = () => [
  {
    text: "Afghan",
    code: "AFG",
  },
  {
    text: "Ålandish",
    code: "ALA",
  },
  {
    text: "Albanian",
    code: "ALB",
  },
  {
    text: "Algerian",
    code: "DZA",
  },
  {
    text: "American Samoan",
    code: "ASM",
  },
  {
    text: "Andorran",
    code: "AND",
  },
  {
    text: "Angolan",
    code: "AGO",
  },
  {
    text: "Anguillian",
    code: "AIA",
  },
  {
    text: "",
    code: "ATA",
  },
  {
    text: "Antiguan, Barbudan",
    code: "ATG",
  },
  {
    text: "Argentinean",
    code: "ARG",
  },
  {
    text: "Armenian",
    code: "ARM",
  },
  {
    text: "Aruban",
    code: "ABW",
  },
  {
    text: "Australian",
    code: "AUS",
  },
  {
    text: "Austrian",
    code: "AUT",
  },
  {
    text: "Azerbaijani",
    code: "AZE",
  },
  {
    text: "Bahamian",
    code: "BHS",
  },
  {
    text: "Bahraini",
    code: "BHR",
  },
  {
    text: "Bangladeshi",
    code: "BGD",
  },
  {
    text: "Barbadian",
    code: "BRB",
  },
  {
    text: "Belarusian",
    code: "BLR",
  },
  {
    text: "Belgian",
    code: "BEL",
  },
  {
    text: "Belizean",
    code: "BLZ",
  },
  {
    text: "Beninese",
    code: "BEN",
  },
  {
    text: "Bermudian",
    code: "BMU",
  },
  {
    text: "Bhutanese",
    code: "BTN",
  },
  {
    text: "Bolivian",
    code: "BOL",
  },
  {
    text: "Dutch",
    code: "BES",
  },
  {
    text: "Bosnian, Herzegovinian",
    code: "BIH",
  },
  {
    text: "Motswana",
    code: "BWA",
  },
  {
    text: "",
    code: "BVT",
  },
  {
    text: "Brazilian",
    code: "BRA",
  },
  {
    text: "Indian",
    code: "IOT",
  },
  {
    text: "American",
    code: "UMI",
  },
  {
    text: "Virgin Islander",
    code: "VGB",
  },
  {
    text: "Virgin Islander",
    code: "VIR",
  },
  {
    text: "Bruneian",
    code: "BRN",
  },
  {
    text: "Bulgarian",
    code: "BGR",
  },
  {
    text: "Burkinabe",
    code: "BFA",
  },
  {
    text: "Burundian",
    code: "BDI",
  },
  {
    text: "Cambodian",
    code: "KHM",
  },
  {
    text: "Cameroonian",
    code: "CMR",
  },
  {
    text: "Canadian",
    code: "CAN",
  },
  {
    text: "Cape Verdian",
    code: "CPV",
  },
  {
    text: "Caymanian",
    code: "CYM",
  },
  {
    text: "Central African",
    code: "CAF",
  },
  {
    text: "Chadian",
    code: "TCD",
  },
  {
    text: "Chilean",
    code: "CHL",
  },
  {
    text: "Chinese",
    code: "CHN",
  },
  {
    text: "Christmas Island",
    code: "CXR",
  },
  {
    text: "Cocos Islander",
    code: "CCK",
  },
  {
    text: "Colombian",
    code: "COL",
  },
  {
    text: "Comoran",
    code: "COM",
  },
  {
    text: "Congolese",
    code: "COG",
  },
  {
    text: "Congolese",
    code: "COD",
  },
  {
    text: "Cook Islander",
    code: "COK",
  },
  {
    text: "Costa Rican",
    code: "CRI",
  },
  {
    text: "Croatian",
    code: "HRV",
  },
  {
    text: "Cuban",
    code: "CUB",
  },
  {
    text: "Dutch",
    code: "CUW",
  },
  {
    text: "Cypriot",
    code: "CYP",
  },
  {
    text: "Czech",
    code: "CZE",
  },
  {
    text: "Danish",
    code: "DNK",
  },
  {
    text: "Djibouti",
    code: "DJI",
  },
  {
    text: "Dominican",
    code: "DMA",
  },
  {
    text: "Dominican",
    code: "DOM",
  },
  {
    text: "Ecuadorean",
    code: "ECU",
  },
  {
    text: "Egyptian",
    code: "EGY",
  },
  {
    text: "Salvadoran",
    code: "SLV",
  },
  {
    text: "Equatorial Guinean",
    code: "GNQ",
  },
  {
    text: "Eritrean",
    code: "ERI",
  },
  {
    text: "Estonian",
    code: "EST",
  },
  {
    text: "Ethiopian",
    code: "ETH",
  },
  {
    text: "Falkland Islander",
    code: "FLK",
  },
  {
    text: "Faroese",
    code: "FRO",
  },
  {
    text: "Fijian",
    code: "FJI",
  },
  {
    text: "Finnish",
    code: "FIN",
  },
  {
    text: "French",
    code: "FRA",
  },
  {
    text: "",
    code: "GUF",
  },
  {
    text: "French Polynesian",
    code: "PYF",
  },
  {
    text: "French",
    code: "ATF",
  },
  {
    text: "Gabonese",
    code: "GAB",
  },
  {
    text: "Gambian",
    code: "GMB",
  },
  {
    text: "Georgian",
    code: "GEO",
  },
  {
    text: "German",
    code: "DEU",
  },
  {
    text: "Ghanaian",
    code: "GHA",
  },
  {
    text: "Gibraltar",
    code: "GIB",
  },
  {
    text: "Greek",
    code: "GRC",
  },
  {
    text: "Greenlandic",
    code: "GRL",
  },
  {
    text: "Grenadian",
    code: "GRD",
  },
  {
    text: "Guadeloupian",
    code: "GLP",
  },
  {
    text: "Guamanian",
    code: "GUM",
  },
  {
    text: "Guatemalan",
    code: "GTM",
  },
  {
    text: "Channel Islander",
    code: "GGY",
  },
  {
    text: "Guinean",
    code: "GIN",
  },
  {
    text: "Guinea-Bissauan",
    code: "GNB",
  },
  {
    text: "Guyanese",
    code: "GUY",
  },
  {
    text: "Haitian",
    code: "HTI",
  },
  {
    text: "Heard and McDonald Islander",
    code: "HMD",
  },
  {
    text: "",
    code: "VAT",
  },
  {
    text: "Honduran",
    code: "HND",
  },
  {
    text: "Chinese",
    code: "HKG",
  },
  {
    text: "Hungarian",
    code: "HUN",
  },
  {
    text: "Icelander",
    code: "ISL",
  },
  {
    text: "Indian",
    code: "IND",
  },
  {
    text: "Indonesian",
    code: "IDN",
  },
  {
    text: "Ivorian",
    code: "CIV",
  },
  {
    text: "Iranian",
    code: "IRN",
  },
  {
    text: "Iraqi",
    code: "IRQ",
  },
  {
    text: "Irish",
    code: "IRL",
  },
  {
    text: "Manx",
    code: "IMN",
  },
  {
    text: "Israeli",
    code: "ISR",
  },
  {
    text: "Italian",
    code: "ITA",
  },
  {
    text: "Jamaican",
    code: "JAM",
  },
  {
    text: "Japanese",
    code: "JPN",
  },
  {
    text: "Channel Islander",
    code: "JEY",
  },
  {
    text: "Jordanian",
    code: "JOR",
  },
  {
    text: "Kazakhstani",
    code: "KAZ",
  },
  {
    text: "Kenyan",
    code: "KEN",
  },
  {
    text: "I-Kiribati",
    code: "KIR",
  },
  {
    text: "Kuwaiti",
    code: "KWT",
  },
  {
    text: "Kirghiz",
    code: "KGZ",
  },
  {
    text: "Laotian",
    code: "LAO",
  },
  {
    text: "Latvian",
    code: "LVA",
  },
  {
    text: "Lebanese",
    code: "LBN",
  },
  {
    text: "Mosotho",
    code: "LSO",
  },
  {
    text: "Liberian",
    code: "LBR",
  },
  {
    text: "Libyan",
    code: "LBY",
  },
  {
    text: "Liechtensteiner",
    code: "LIE",
  },
  {
    text: "Lithuanian",
    code: "LTU",
  },
  {
    text: "Luxembourger",
    code: "LUX",
  },
  {
    text: "Chinese",
    code: "MAC",
  },
  {
    text: "Macedonian",
    code: "MKD",
  },
  {
    text: "Malagasy",
    code: "MDG",
  },
  {
    text: "Malawian",
    code: "MWI",
  },
  {
    text: "Malaysian",
    code: "MYS",
  },
  {
    text: "Maldivan",
    code: "MDV",
  },
  {
    text: "Malian",
    code: "MLI",
  },
  {
    text: "Maltese",
    code: "MLT",
  },
  {
    text: "Marshallese",
    code: "MHL",
  },
  {
    text: "French",
    code: "MTQ",
  },
  {
    text: "Mauritanian",
    code: "MRT",
  },
  {
    text: "Mauritian",
    code: "MUS",
  },
  {
    text: "French",
    code: "MYT",
  },
  {
    text: "Mexican",
    code: "MEX",
  },
  {
    text: "Micronesian",
    code: "FSM",
  },
  {
    text: "Moldovan",
    code: "MDA",
  },
  {
    text: "Monegasque",
    code: "MCO",
  },
  {
    text: "Mongolian",
    code: "MNG",
  },
  {
    text: "Montenegrin",
    code: "MNE",
  },
  {
    text: "Montserratian",
    code: "MSR",
  },
  {
    text: "Moroccan",
    code: "MAR",
  },
  {
    text: "Mozambican",
    code: "MOZ",
  },
  {
    text: "Burmese",
    code: "MMR",
  },
  {
    text: "Namibian",
    code: "NAM",
  },
  {
    text: "Nauruan",
    code: "NRU",
  },
  {
    text: "Nepalese",
    code: "NPL",
  },
  {
    text: "Dutch",
    code: "NLD",
  },
  {
    text: "New Caledonian",
    code: "NCL",
  },
  {
    text: "New Zealander",
    code: "NZL",
  },
  {
    text: "Nicaraguan",
    code: "NIC",
  },
  {
    text: "Nigerien",
    code: "NER",
  },
  {
    text: "Nigerian",
    code: "NGA",
  },
  {
    text: "Niuean",
    code: "NIU",
  },
  {
    text: "Norfolk Islander",
    code: "NFK",
  },
  {
    text: "North Korean",
    code: "PRK",
  },
  {
    text: "American",
    code: "MNP",
  },
  {
    text: "Norwegian",
    code: "NOR",
  },
  {
    text: "Omani",
    code: "OMN",
  },
  {
    text: "Pakistani",
    code: "PAK",
  },
  {
    text: "Palauan",
    code: "PLW",
  },
  {
    text: "Palestinian",
    code: "PSE",
  },
  {
    text: "Panamanian",
    code: "PAN",
  },
  {
    text: "Papua New Guinean",
    code: "PNG",
  },
  {
    text: "Paraguayan",
    code: "PRY",
  },
  {
    text: "Peruvian",
    code: "PER",
  },
  {
    text: "Filipino",
    code: "PHL",
  },
  {
    text: "Pitcairn Islander",
    code: "PCN",
  },
  {
    text: "Polish",
    code: "POL",
  },
  {
    text: "Portuguese",
    code: "PRT",
  },
  {
    text: "Puerto Rican",
    code: "PRI",
  },
  {
    text: "Qatari",
    code: "QAT",
  },
  {
    text: "Kosovar",
    code: "KOS",
  },
  {
    text: "French",
    code: "REU",
  },
  {
    text: "Romanian",
    code: "ROU",
  },
  {
    text: "Russian",
    code: "RUS",
  },
  {
    text: "Rwandan",
    code: "RWA",
  },
  {
    text: "Saint Barthélemy Islander",
    code: "BLM",
  },
  {
    text: "Saint Helenian",
    code: "SHN",
  },
  {
    text: "Kittian and Nevisian",
    code: "KNA",
  },
  {
    text: "Saint Lucian",
    code: "LCA",
  },
  {
    text: "Saint Martin Islander",
    code: "MAF",
  },
  {
    text: "French",
    code: "SPM",
  },
  {
    text: "Saint Vincentian",
    code: "VCT",
  },
  {
    text: "Samoan",
    code: "WSM",
  },
  {
    text: "Sammarinese",
    code: "SMR",
  },
  {
    text: "Sao Tomean",
    code: "STP",
  },
  {
    text: "Saudi Arabian",
    code: "SAU",
  },
  {
    text: "Senegalese",
    code: "SEN",
  },
  {
    text: "Serbian",
    code: "SRB",
  },
  {
    text: "Seychellois",
    code: "SYC",
  },
  {
    text: "Sierra Leonean",
    code: "SLE",
  },
  {
    text: "Singaporean",
    code: "SGP",
  },
  {
    text: "Dutch",
    code: "SXM",
  },
  {
    text: "Slovak",
    code: "SVK",
  },
  {
    text: "Slovene",
    code: "SVN",
  },
  {
    text: "Solomon Islander",
    code: "SLB",
  },
  {
    text: "Somali",
    code: "SOM",
  },
  {
    text: "South African",
    code: "ZAF",
  },
  {
    text: "South Georgia and the South Sandwich Islander",
    code: "SGS",
  },
  {
    text: "South Korean",
    code: "KOR",
  },
  {
    text: "South Sudanese",
    code: "SSD",
  },
  {
    text: "Spanish",
    code: "ESP",
  },
  {
    text: "Sri Lankan",
    code: "LKA",
  },
  {
    text: "Sudanese",
    code: "SDN",
  },
  {
    text: "Surinamer",
    code: "SUR",
  },
  {
    text: "Norwegian",
    code: "SJM",
  },
  {
    text: "Swazi",
    code: "SWZ",
  },
  {
    text: "Swedish",
    code: "SWE",
  },
  {
    text: "Swiss",
    code: "CHE",
  },
  {
    text: "Syrian",
    code: "SYR",
  },
  {
    text: "Taiwanese",
    code: "TWN",
  },
  {
    text: "Tadzhik",
    code: "TJK",
  },
  {
    text: "Tanzanian",
    code: "TZA",
  },
  {
    text: "Thai",
    code: "THA",
  },
  {
    text: "East Timorese",
    code: "TLS",
  },
  {
    text: "Togolese",
    code: "TGO",
  },
  {
    text: "Tokelauan",
    code: "TKL",
  },
  {
    text: "Tongan",
    code: "TON",
  },
  {
    text: "Trinidadian",
    code: "TTO",
  },
  {
    text: "Tunisian",
    code: "TUN",
  },
  {
    text: "Turkish",
    code: "TUR",
  },
  {
    text: "Turkmen",
    code: "TKM",
  },
  {
    text: "Turks and Caicos Islander",
    code: "TCA",
  },
  {
    text: "Tuvaluan",
    code: "TUV",
  },
  {
    text: "Ugandan",
    code: "UGA",
  },
  {
    text: "Ukrainian",
    code: "UKR",
  },
  {
    text: "Emirati",
    code: "ARE",
  },
  {
    text: "British",
    code: "GBR",
  },
  {
    text: "American",
    code: "USA",
  },
  {
    text: "Uruguayan",
    code: "URY",
  },
  {
    text: "Uzbekistani",
    code: "UZB",
  },
  {
    text: "Ni-Vanuatu",
    code: "VUT",
  },
  {
    text: "Venezuelan",
    code: "VEN",
  },
  {
    text: "Vietnamese",
    code: "VNM",
  },
  {
    text: "Wallis and Futuna Islander",
    code: "WLF",
  },
  {
    text: "Sahrawi",
    code: "ESH",
  },
  {
    text: "Yemeni",
    code: "YEM",
  },
  {
    text: "Zambian",
    code: "ZMB",
  },
  {
    text: "Zimbabwean",
    code: "ZWE",
  },
];
