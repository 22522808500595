import { SET_SETTINGS } from "../actions/settings";

const initialState = {};

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
