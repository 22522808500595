export const SET_SETTINGS = "SET_SETTINGS";
export const setSettings = (data) => {
  return {
    type: SET_SETTINGS,
    payload: {
      vat: data.vat,
      currency: data.currency,
      hospital: data.hospital,
    }
  };
};
