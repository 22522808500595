import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import {
  Tabs,
  Button,
  message,
  Col,
  Row,
  Dropdown,
  Menu,
  Space,
  Modal,
  Radio,
  Divider,
  InputNumber,
  Table
} from "antd";
import type { RadioChangeEvent } from "antd";
import Icon, {
  DownloadOutlined,
  MailOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import PatientSearch from "./PatientSearch";
import { useHistory, useParams } from "react-router-dom";
import PatientsTable from "./PatientsTable";
import * as Sentry from "@sentry/react";
import CreateNewPatientManual from "./createNewPatientManualEntry";
import { getUserRoles } from "../../utils/LocalStorage";
import {
  activePatientColumns,
  allPatientColumns,
  appointmentRequestsColumns,
  reportsColumns,
  reportWaitingColumns,
} from "./columns";
import {
  getAllPatients,
  getActivetPatients,
  getAppointmentRequests,
  getDoctors,
  getReports,
  exportReport,
  getReportWaiting,
  exportAll,
  getPatientsByPhone,
} from "../../../apis/patients";
import moment from "moment-timezone";
import HasPrivilege from "../../utils/privilegeHandlers/HasPrivilege.jsx";
import Privileges from "../../../constants/privileges";
import { useSelector } from "react-redux";
import StatusModal from "../Appoinment/StatusModal";
import MessageAllModal from "../Appoinment/MessageModal";
import {
  deleteAppointment,
  fetchMyAppointments,
  fetchMyMedicalHistory,
  acceptOrDeclineAppointment,
} from "../../../apis/Appointment";
import IntimeModal from "../InTimeModal";
import { medicalHistoryColumns, myAppointmentsColumns } from "./patientColumns";
import InsuranceDashboard from "../InsuranceDashboard";
import { hasPrivilege } from "../../utils/privilegeHandlers/hasPrivilege.js";
import { ReactComponent as UploadReport } from "../../../assets/icons/uploadreport.svg";
import UploadReportModal from "./uploadReportModal";
import PreviewOCRFiles from "./previewOCRFiles";
import { pingOCR } from "../../../apis/createPatientOCR";
import { getSettings } from "../../../apis/settings";
import LaboratoryDashboard from "../LaboratoryDashboard";
import RadiologyDashboard from "../RadiologyDashboard";
import PharmacyDashboard from "../PharmacyDashboard";
import { setActiveCount } from "../../../redux/actions/activeCount";
import { useQuery } from "../../utils/UseQuery";

const { TabPane } = Tabs;
const StyledTabs = styled(Tabs)`
  &.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    /* padding: 16px 25px; */
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid #d6d6d6;
    width: 200px;
    /* text-align: center; */
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
  }
  & .ant-tabs-tab-btn {
    margin: auto;
  }
  &.tab_sec_portal .ant-tabs-tab div {
    font-size: 20px;
    color: #808080;
  }
  &.tab_sec_portal .ant-tabs-tab {
    width: auto !important;
    padding: 14px 18px;
  }
  &.tab_sec_portal .ant-tabs-tab.ant-tabs-tab-active div {
    color: ${(props) => props.color};
  }

  .table_col_style1 {
    padding-bottom: 60px;
  }

  @media (max-width: 1359px) {
    &.tab_sec_portal .ant-tabs-tab {
      width: auto !important;
      padding: 12px 18px;
    }
    &.tab_sec_portal .ant-tabs-tab div {
      font-size: 18px;
    }
  }

  .table_col_style1 .field-title {
    font-size: 16px;
    font-weight: 500;
  }
  .table_col_style1 .field-typed .ant-picker {
    height: 50px;
    border-radius: 5px;
  }
  .table_col_style1 .book_btn_bl {
    font-size: 17px;
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    padding: 0px 20px;
    border-radius: 5px;
  }
  .table_col_style1 table thead tr th {
    font-size: 16px;
    border-bottom: 0px;
  }
  .table_col_style1 table tbody tr td {
    border-top: 1px solid #cdcdcd;
    border-bottom: 0px;
  }
  .table_col_style1 table tbody tr td {
    font-size: 17px;
    padding: 12px 16px;
  }
  @media (max-width: 1359px) {
    .table_col_style1 table tbody tr td {
      font-size: 15px;
    }
  }

  .table_col_style1 table tbody tr td .ant-btn {
    padding: 5px 12px;
    height: auto;
    font-size: 16px;
    border-radius: 5px;
  }
  .table_col_style1 table tbody tr td .ant-btn-sm:hover {
    background-color: ${(props) => props.color} !important;

    color: #ffffff;
  }
  .table_col_style1
    table
    tbody
    tr
    td
    .ant-btn-background-ghost.ant-btn-primary:hover {
    background-color: ${(props) => props.color} !important;
    color: #ffffff;
  }
  @media (max-width: 1199px) {
    .table_col_style1 .field-typed .ant-picker,
    .table_col_style1 .book_btn_bl {
      height: 42px;
    }
    .table_col_style1 table tbody tr td .ant-btn {
      font-size: 14px !important;
    }
    .table_col_style1 table thead tr th {
      font-size: 15px;
    }
    .mt-input-search {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .mt-input-search-btn {
      flex: 0 0 12.5%;
    }
  }
  @media (max-width: 991px) {
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 640px;
      flex: 0 0 640px;
      max-width: 640px;
    }
    .table_col_style1 .btn_right_stripe {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 18px;
    }
    .mt-input-search {
      flex: 0 0 47%;
      max-width: 47%;
    }
    .mt-input-search-btn {
      flex: 0 0 12.5%;
    }
  }
  @media (max-width: 767px) {
    &.tab_sec_portal .ant-tabs-tab {
      padding: 9px 15px;
    }
    &.tab_sec_portal .ant-tabs-tab div {
      font-size: 16px;
    }
    .mt-input-search,
    .mt-input-search-btn {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 640px) {
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 480px;
      flex: 0 0 480px;
      max-width: 480px;
    }
    .data_field_picker .ant-row {
      margin: 0px -3px !important;
    }
    .data_field_picker .ant-col {
      padding: 0px 6px !important;
    }
    .data_field_picker .ant-col:last-child {
      padding-left: 0px !important;
    }
  }
  @media (max-width: 480px) {
    &.ant-tabs > .ant-tabs-nav {
      flex-direction: column;
      gap: 10px;
    }
    .ant-tabs-extra-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    &.tab_sec_portal .ant-tabs-nav-wrap {
      width: 100%;
      overflow: inherit;
    }
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .data_field_picker .ant-row {
      margin: 0px 0px !important;
    }
    .data_field_picker .ant-col {
      padding: 0px 0px 5px !important;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .data_field_picker .ant-col.field-title {
      padding: 0px 0px !important;
    }
    .mx_clear_data {
      text-align: right;
      color: #dc2e18;
    }
  }
`;

export const Heading = styled.h3`
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 2px 0;
  @media (max-width: 480px) {
    display: none;
  }
`;
const ExtraTabButton = styled.div`
  display: none;
  /* 
  @media (max-width: 480px) {
    display: block;
    text-align: right;
    margin: 0px 0px 16px 0px;
  } */
`;

export const InQueueCountDiv = styled.div`
  cursor: pointer;
  display: flex;
  gap: 5px;
  text-align: center;
  background: white;
  padding: 3px 30px;
  border-radius: 15px;
  border: 1px solid ${(props) => props.color};
  color: rgb(125, 125, 125);
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    background: ${(props) => props.color};
    color: white;
  }
`;

const StyledIcon = styled(Icon)`
  & .cls-1 {
    fill: ${(props) => props.color};
  }
`;

const searchByItem = ["all", "name", "id", "mobileNo"];

const PatientsList = () => {
  let query = useQuery();
  const tab = query.get("tab");

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [currentTab, setCurrentTab] = useState(tab ?? 1);
  const [filterParams, setFilterParams] = useState({});
  const [searchParam, setSearchParam] = useState("all");
  const [searchString, setSearchString] = useState("");
  const [activeLoading, setActiveLoading] = useState(false);
  const [appointmentReqLoading, setAppointmentReqLoading] = useState(false);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [reportWaitingLoading, setReportWaitingLoading] = useState(false);
  const [myAppointmentsLoading, setMyAppointmentsLoading] = useState(false);
  const [medicalHistoryLoading, setMedicalHistoryLoading] = useState(false);
  const [allLoading, setAllLoading] = useState(false);
  const [allPatients, setAllPatients] = useState([]);
  const [activePatients, setActivePatients] = useState([]);
  const [allPatientTotal, setAllPatientTotal] = useState(0);
  const [activePatientTotal, setActivePatientTotal] = useState(0);
  const [reportsPatients, setReportsPatients] = useState([]);
  const [reportWaitingPatients, setReportWaitingPatients] = useState([]);
  const [reportsTotal, setReportsTotal] = useState(0);
  const [reportWaitingTotal, setReportWaitingTotal] = useState(0);
  const [downloadReport, setDownloadReport] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [messageModalVisible, setMessageModalVisible] = useState(false);

  const [cancelLoading, setCancelLoading] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [inTimeModalVisible, setIntimeModalVisible] = useState(false);
  const [selectedAppoinementId, setSelectedAppointmentId] = useState();
  const [myAppointments, setMyAppointments] = useState([]);
  const [myAppointmentsTotal, setMyAppointmentsTotal] = useState(0);
  const [myMedicalHistory, setMyMedicalHistory] = useState([]);
  const [myMedicalHistoryTotal, setMyMedicalHistoryTotal] = useState(0);
  const [defaultActiveReportsParam, setDefaultActiveReportsParam] = useState();
  const [defaultPendingAllParam, setDefaultPendingAllParam] = useState();
  const [defaultPatientParam, setDefaultPatientParam] = useState();
  const [docFilterParam, setDocFilterParam] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState({});
  const [timeFilter, setTimeFilter] = useState({});
  const [uploadReportModalVisible, setUploadReportModalVisible] = useState(
    false
  );
  const [
    patientUploadReportModalVisible,
    setPatientUploadReportModalVisible,
  ] = useState(false);
  const [createPatientVisible, setCreatePatientVisible] = useState(false);
  const [previewOCRFilesVisible, setPreviewOCRFilesVisible] = useState(false);
  const [ocrData, setOcrData] = useState({});
  const [appointmentRequests, setAppointmentRequests] = useState([]);
  const [appointmentRequestsTotal, setAppointmentRequestsTotal] = useState(0);
  const [doctors, setDoctors] = useState([]);
  const [cancelled, setCancelled] = useState(false);
  const [row, setRow] = useState();
  const [uploadNursePatient, setUploadNursePatient] = useState();
  const [
    nurseUploadReportModalVisible,
    setNurseUploadReportModalVisible,
  ] = useState(false);
  const [currency, setCurrency] = useState();
  const [newPatientModalOpen, setNewPatientModalOpen] = useState(false);
  const [patientType, setPatientType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newPatientIPD, setNewPatientIPD] = useState(false);
  const [patientListByPhone, setPatientListByPhone] = useState([]);

  const history = useHistory();

  const user = useSelector((state) => state.userReducer);

  const theme = useSelector((state) => state.themeReducer);
  const patient = useSelector((state) => state.patientReducer);
  const activeCount = useSelector((state) => state.activeCountReducer);
  const dispatch = useDispatch();
  const newPatientHandler = () => {
    history.push(`/new-patient${newPatientIPD ? "?type=ipd" : ""}`);
  };
  const onTabChange = (activeTabKey) => {
    setCurrentTab(activeTabKey);
    searchQuery();
    if (
      searchString ||
      docFilterParam.length !== 0 ||
      filterParams.filterString ||
      (dateRangeFilter.startDate && dateRangeFilter.endDate) ||
      (timeFilter.startTime && timeFilter.endTime)
    )
      onClear();
  };
  const searchQuery = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("tab");
    const newUrl = `${window.location.pathname}${searchParams.toString()}`;
    window.history.replaceState(null, "", newUrl);
  };

  const onPatientTypeSelect = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setPatientType(e.target.value);
  };

  const onClear = () => {
    setPageNo(0);
    setDocFilterParam([]);
    setFilterParams({});
    setSearchParam("all");
    setSearchString("");
    setDateRangeFilter({});
    setTimeFilter({});
    if (currentTab == 1) {
      setActiveLoading(true);
      fetchActivePatients(defaultActiveReportsParam);
    } else if (currentTab == 2) {
      setAppointmentReqLoading(true);
      fetchAppointmentRequests(defaultPendingAllParam);
    } else if (currentTab == 3) {
      setReportWaitingLoading(true);
      fetchReportWaiting(defaultActiveReportsParam);
    } else if (currentTab == 4) {
      setAllLoading(true);
      fetchAllPatients(defaultPendingAllParam);
    } else if (currentTab == 5) {
      setReportsLoading(true);
      fetchReports(defaultActiveReportsParam);
    } else if (currentTab == 6) {
      setMyAppointmentsLoading(true);
      getMyAppointments(defaultPatientParam);
    } else if (currentTab == 7) {
      setReportWaitingLoading(true);
      fetchReportWaiting(defaultActiveReportsParam);
    } else if (currentTab == 8) {
      setMedicalHistoryLoading(true);
      getMyMedicalHistory(defaultPatientParam);
    }
  };
  const onInputChange = (event) => {
    setSearchString(event.target.value);
  };
  const onSelectChange = (value) => {
    setSearchParam(value);
  };

  const onDocFilterSubmit = (filter) => {
    setDocFilterParam(filter);
  };

  const onFilterSubmit = (filter) => {
    let filterString;
    Object.entries(filter).forEach(([key, value]) => {
      if (value.length > 0) {
        filterString = `${filterString ? filterString : ""}${
          filterString ? "&" : ""
        }${key}=${value}`;
      } else
        filterString = `${filterString ? filterString : ""}${
          filterString ? "&" : ""
        }`;
    });
    setFilterParams({ filterString, filter });
  };

  const onActiveSearch = (value) => {
    let searchQuery;
    if (value == true) {
      searchQuery = `pageNo=0&size=${pageSize}&timeZone=${moment.tz.guess()}`;
      setPageNo(0);
    } else
      searchQuery = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
    if (searchString)
      searchQuery = `${searchQuery}&${searchParam}=${searchString}`;
    if (docFilterParam.length !== 0)
      searchQuery = `${searchQuery}&doctorName=${docFilterParam}`;
    if (filterParams.filterString)
      searchQuery = `${searchQuery}&${filterParams.filterString}`;
    if (timeFilter.startTime && timeFilter.endTime) {
      searchQuery = `${searchQuery}&startTime=${timeFilter.startTime.format(
        "x"
      )}&endTime=${timeFilter.endTime.format("x")}`;
    }
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${searchQuery}&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    setActiveLoading(true);
    fetchActivePatients(searchQuery);
  };
  const onAllSearch = (value) => {
    let searchQuery;
    if (downloadReport == false) {
      if (value == true) {
        searchQuery = `pageNo=0&size=${pageSize}`;
        setPageNo(0);
      } else searchQuery = `pageNo=${pageNo}&size=${pageSize}`;
    }
    if (searchString) {
      searchQuery = `${
        searchQuery ? searchQuery : ``
      }&${searchParam}=${searchString}`;
    }
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${
        searchQuery ? searchQuery : ``
      }&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    if (downloadReport == false) {
      setAllLoading(true);
      fetchAllPatients(searchQuery);
    } else exportAllOnClick(searchQuery ? searchQuery : ``);
  };

  const onRequestsSearch = (value) => {
    let searchQuery;
    if (value == true) {
      searchQuery = `pageNo=0&size=${pageSize}`;
      setPageNo(0);
    } else searchQuery = `pageNo=${pageNo}&size=${pageSize}`;
    if (searchString)
      searchQuery = `${searchQuery}&${searchParam}=${searchString}`;
    if (docFilterParam.length !== 0)
      searchQuery = `${searchQuery}&doctorName=${docFilterParam}`;
    if (filterParams.filterString)
      searchQuery = `${searchQuery}&${filterParams.filterString}`;
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${searchQuery}&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    setAppointmentReqLoading(true);
    fetchAppointmentRequests(searchQuery);
  };

  const onReportsSearch = (value) => {
    let searchQuery;
    if (downloadReport == false) {
      if (value == true) {
        searchQuery = `pageNo=0&size=${pageSize}&timeZone=${moment.tz.guess()}`;
        setPageNo(0);
      } else
        searchQuery = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
    } else searchQuery = `timeZone=${moment.tz.guess()}`;
    if (searchString)
      searchQuery = `${searchQuery}&${searchParam}=${searchString}`;
    if (docFilterParam.length !== 0)
      searchQuery = `${searchQuery}&doctorName=${docFilterParam}`;
    if (filterParams.filterString)
      searchQuery = `${searchQuery}&${filterParams.filterString}`;
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${searchQuery}&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    if (downloadReport == false) {
      setReportsLoading(true);
      fetchReports(searchQuery);
    } else exportReportOnClick(searchQuery);
  };
  const onReportWaitingSearch = (value) => {
    let searchQuery;
    if (value == true) {
      searchQuery = `pageNo=0&size=${pageSize}&timeZone=${moment.tz.guess()}`;
      setPageNo(0);
    } else
      searchQuery = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
    if (user.userType == "PATIENT")
      searchQuery = `${searchQuery}&id=${user.id}`;
    if (searchString)
      searchQuery = `${searchQuery}&${searchParam}=${searchString}`;
    if (docFilterParam.length !== 0)
      searchQuery = `${searchQuery}&doctorName=${docFilterParam}`;
    if (filterParams.filterString)
      searchQuery = `${searchQuery}&${filterParams.filterString}`;
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${searchQuery}&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    if (timeFilter.startTime && timeFilter.endTime) {
      searchQuery = `${searchQuery}&startTime=${timeFilter.startTime.format(
        "x"
      )}&endTime=${timeFilter.endTime.format("x")}`;
    }
    setReportWaitingLoading(true);
    fetchReportWaiting(searchQuery);
  };
  const onMyAppointmentsSearch = (value) => {
    let searchQuery;
    if (value == true) {
      searchQuery = `pageNo=0&size=${pageSize}&patientId=${
        user.id
      }&timeZone=${moment.tz.guess()}`;
      setPageNo(0);
    } else
      searchQuery = `pageNo=${pageNo}&size=${pageSize}&patientId=${
        user.id
      }&timeZone=${moment.tz.guess()}`;
    if (docFilterParam.length !== 0)
      searchQuery = `${searchQuery}&doctorName=${docFilterParam}`;
    if (filterParams.filterString)
      searchQuery = `${searchQuery}&${filterParams.filterString}`;
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${searchQuery}&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    setMyAppointmentsLoading(true);
    getMyAppointments(searchQuery);
  };
  const onMedicalHistorySearch = (value) => {
    let searchQuery;
    if (value == true) {
      searchQuery = `pageNo=0&size=${pageSize}&patientId=${
        user.id
      }&timeZone=${moment.tz.guess()}`;
      setPageNo(0);
    } else
      searchQuery = `pageNo=${pageNo}&size=${pageSize}&patientId=${
        user.id
      }&timeZone=${moment.tz.guess()}`;
    if (docFilterParam.length !== 0)
      searchQuery = `${searchQuery}&doctorName=${docFilterParam}`;
    if (filterParams.filterString)
      searchQuery = `${searchQuery}&${filterParams.filterString}`;
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${searchQuery}&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    setMedicalHistoryLoading(true);
    getMyMedicalHistory(searchQuery);
  };

  // const onActiveRowClick = (record, rowIndex) => {
  //   if (
  //     user.userType !== "RECEPTIONIST" &&
  //     user.userType !== "DOCTOR" &&
  //     user.userType !== "NURSE" &&
  //     hasPrivilege("VIEW_SECTION")
  //   ) {
  //     history.push(
  //       `/health-records/${record.patient.id}/${record.patient.firstName}-${record.patient.lastName}/${record.id}`
  //     );
  //   }
  //   if (user.userType === "RECEPTIONIST" && hasPrivilege("UPDATE_PATIENT")) {
  //     history.push(
  //       `/update-patient/${record.patient.id}/${record.patient.firstName}`
  //     );
  //   }
  // };

  const fetchAllPatients = (searchQuery) => {
    getAllPatients(searchQuery)
      .then((res) => {
        console.log("all patient", res);
        if (res.status == 200) {
          setAllPatients(res.data.body);
          setAllPatientTotal(res.data.total);
        }
        setAllLoading(false);
      })
      .catch((err) => {
        setAllLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };
  const fetchActivePatients = (searchQuery) => {
    getActivetPatients(searchQuery)
      .then((res) => {
        console.log("active patient is", res);
        if (res.status == 200) {
          setActivePatients(res.data.body);
          setActivePatientTotal(res.data.total);
          dispatch(setActiveCount({ activePatientCount: res.data.total }));
        }
        setActiveLoading(false);
      })
      .catch((err) => {
        setActiveLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  // const fetchActivePatientCount = (searchQuery) => {
  //   if (
  //     user.userType == "RECEPTIONIST" ||
  //     user.userType == "DOCTOR" ||
  //     user.userType == "NURSE"
  //   ) {
  //     if (searchQuery) {
  //       getActivetPatients(searchQuery)
  //         .then((res) => {
  //           if (res.status == 200) {
  //             dispatch(setActiveCount({ activePatientCount: res.data.total }));
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   }
  // };

  const fetchAppointmentRequests = (searchQuery) => {
    getAppointmentRequests(searchQuery)
      .then((res) => {
        console.log("fetch appoinment is", res);
        if (res.status == 200) {
          setAppointmentRequests(res.data.body);
          setAppointmentRequestsTotal(res.data.total);
        }
        setAppointmentReqLoading(false);
      })
      .catch((err) => {
        setAppointmentReqLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const fetchReports = (searchQuery) => {
    getReports(searchQuery)
      .then((res) => {
        if (res.status == 200) {
          setReportsPatients(res.data.body);
          setReportsTotal(res.data.total);
        }
        setReportsLoading(false);
      })
      .catch((err) => {
        setReportsLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const fetchReportWaiting = (searchQuery) => {
    getReportWaiting(searchQuery)
      .then((res) => {
        console.log("fetch report", res);
        if (res.status == 200) {
          setReportWaitingPatients(res.data.body);
          setReportWaitingTotal(res.data.total);
          dispatch(setActiveCount({ inQueueCount: res.data.total }));
        }
        setReportWaitingLoading(false);
      })
      .catch((err) => {
        setReportWaitingLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const exportAllOnClick = (searchQuery) => {
    const today = new Date()
      .toLocaleDateString("en-GB", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");
    exportAll(searchQuery ? searchQuery : ``)
      .then((res) => {
        console.log("res in file", res);

        setDownloadReport(false);
        var fileDownload = require("js-file-download");
        fileDownload(res.data, `Patients-${today}.xlsx`);
      })
      .catch();
  };

  const exportReportOnClick = (searchQuery) => {
    const today = new Date()
      .toLocaleDateString("en-GB", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");
    exportReport(searchQuery)
      .then((res) => {
        setDownloadReport(false);
        var fileDownload = require("js-file-download");
        fileDownload(res.data, `Reports-${today}.xlsx`);
      })
      .catch();
  };

  const fetchDoctors = () => {
    getDoctors()
      .then((res) => {
        if (res.data.status === 1) {
          const doctorData = res.data.body.map((eachDoc) => ({
            label: eachDoc.firstName + " " + eachDoc.lastName,
            value: eachDoc.firstName + " " + eachDoc.lastName,
          }));
          setDoctors(doctorData);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const getMyAppointments = (queryString) => {
    fetchMyAppointments(queryString)
      .then((res) => {
        if (res.status == 200) {
          setMyAppointments(res.data.body);
          setMyAppointmentsTotal(res.data.total);
        }
        setMyAppointmentsLoading(false);
      })
      .catch((err) => {
        setMyAppointmentsLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };
  const getMyMedicalHistory = (queryString) => {
    fetchMyMedicalHistory(queryString)
      .then((res) => {
        if (res.status == 200) {
          setMyMedicalHistory(res.data.body);
          setMyMedicalHistoryTotal(res.data.total);
        }
        setMedicalHistoryLoading(false);
      })
      .catch((err) => {
        setMedicalHistoryLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const onTimeFilter = (time, timeString) => {
    const startTime = moment(timeString[0], "hh:mm a");
    const endTime = moment(timeString[1], "hh:mm a");
    setTimeFilter({ startTime, endTime });
  };

  const onDateRangeFilter = (date, dateString) => {
    const startDate = moment(dateString[0], "DD-MM-YYYY");
    const endDate = moment(dateString[1], "DD-MM-YYYY");
    setDateRangeFilter({ startDate, endDate });
  };

  useEffect(() => {
    searchQuery();
    const value = window.localStorage.getItem("tabKey");
    if (value) {
      setCurrentTab(3);
      window.localStorage.removeItem("tabKey");
    }
    if (user.userType !== "PATIENT")
      setDefaultActiveReportsParam(
        `pageNo=${0}&size=${pageSize}&timeZone=${moment.tz.guess()}`
      );
    else
      setDefaultActiveReportsParam(
        `pageNo=${0}&size=${pageSize}&timeZone=${moment.tz.guess()}&id=${
          user.id
        }`
      );
    setDefaultPendingAllParam(`pageNo=${0}&size=${pageSize}`);
    setDefaultPatientParam(
      `pageNo=${pageNo}&size=${pageSize}&patientId=${
        user.id
      }&timeZone=${moment.tz.guess()}`
    );
    fetchDoctors();
    // if (hasPrivilege(undefined, ["DOCTOR", "RECEPTIONIST", "NURSE"]))
    //   fetchActivePatientCount(
    //     `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`
    //   );
    let searchAllPendingQuery = `pageNo=${pageNo}&size=${pageSize}`;
    let searchActiveReportsQuery = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
    let myAppointmentsQuery = `pageNo=${pageNo}&size=${pageSize}&patientId=${
      user.id
    }&timeZone=${moment.tz.guess()}`;
    let myMedicalHistoryQuery = `pageNo=${pageNo}&size=${pageSize}&patientId=${user.id}`;
    if (
      searchString ||
      docFilterParam.length !== 0 ||
      filterParams.filterString ||
      (dateRangeFilter.startDate && dateRangeFilter.endDate) ||
      (timeFilter.startTime && timeFilter.endTime)
    ) {
      if (currentTab == 1) onActiveSearch();
      else if (currentTab == 2) onRequestsSearch();
      else if (currentTab == 3) onReportWaitingSearch();
      else if (currentTab == 4) onAllSearch();
      else if (currentTab == 5) onReportsSearch();
      else if (currentTab == 6) onMyAppointmentsSearch();
      else if (currentTab == 7) onReportWaitingSearch();
      else if (currentTab == 8) onMedicalHistorySearch();
    } else {
      if (pageNo == 0) {
        if (hasPrivilege("VIEW_PENDING_APPOINTMENT")) {
          setAppointmentReqLoading(true);
          fetchAppointmentRequests(searchAllPendingQuery);
        }
        if (user.userType == "PATIENT") {
          getSettings().then(({ data, status }) => {
            if (status === 200) {
              if (data.status == 1) {
                setCurrency(data.body.currency);
              }
            }
          });
          setCurrentTab(6);

          // fetch patient details
          if (hasPrivilege("VIEW_APPOINTMENT_DETAILS")) {
            setMyAppointmentsLoading(true);
            setMedicalHistoryLoading(true);
            getMyAppointments(myAppointmentsQuery);
            getMyMedicalHistory(myMedicalHistoryQuery);
          }
          if (hasPrivilege("ACTIVE_PATIENT")) {
            setReportWaitingLoading(true);
            searchActiveReportsQuery = `${searchActiveReportsQuery}&id=${user.id}`;
            fetchReportWaiting(searchActiveReportsQuery);
          }
        }
        if (hasPrivilege("ACTIVE_PATIENT")) {
          setActiveLoading(true);
          fetchActivePatients(searchActiveReportsQuery);
          if (user.userType == "NURSE") {
            setReportWaitingLoading(true);
            fetchReportWaiting(searchActiveReportsQuery);
          }
        }
        if (hasPrivilege("VIEW_PATIENT")) {
          setAllLoading(true);
          fetchAllPatients(searchAllPendingQuery);
          if (user.userType == "RECEPTIONIST") {
            setReportsLoading(true);
            fetchReports(searchActiveReportsQuery);
          }
          if (user.userType == "DOCTOR") {
            setReportWaitingLoading(true);
            fetchReportWaiting(searchActiveReportsQuery);
          }
        }
      } else {
        if (currentTab == 1) {
          setActiveLoading(true);
          fetchActivePatients(searchActiveReportsQuery);
        } else if (currentTab == 2) {
          setAppointmentReqLoading(true);
          fetchAppointmentRequests(searchAllPendingQuery);
        } else if (currentTab == 3) {
          setReportWaitingLoading(true);
          fetchReportWaiting(searchActiveReportsQuery);
        } else if (currentTab == 4) {
          setAllLoading(true);
          fetchAllPatients(searchAllPendingQuery);
        } else if (currentTab == 5) {
          setReportsLoading(true);
          fetchReports(searchActiveReportsQuery);
        } else if (currentTab == 6) {
          setMyAppointmentsLoading(true);
          getMyAppointments(myAppointmentsQuery);
        } else if (currentTab == 7) {
          setReportWaitingLoading(true);
          fetchReportWaiting(searchActiveReportsQuery);
        } else if (currentTab == 8) {
          setMedicalHistoryLoading(true);
          getMyMedicalHistory(myMedicalHistoryQuery);
        }
      }
    }
  }, [pageNo, user]);

  useEffect(() => {
    if (
      filterParams.filterString ||
      filterParams.filterString == "" ||
      docFilterParam.length !== 0
    ) {
      if (currentTab == 1) onActiveSearch(true);
      else if (currentTab == 2) onRequestsSearch(true);
      else if (currentTab == 3) onReportWaitingSearch(true);
      else if (currentTab == 4) onAllSearch(true);
      else if (currentTab == 5) onReportsSearch(true);
      else if (currentTab == 6) onMyAppointmentsSearch(true);
      else if (currentTab == 7) onReportWaitingSearch(true);
      else if (currentTab == 8) onMedicalHistorySearch(true);
    }
  }, [filterParams, docFilterParam]);

  useEffect(() => {
    if (downloadReport == true) {
      if (currentTab == 5) onReportsSearch();
      else if (currentTab == 4) onAllSearch();
    }
  }, [downloadReport]);

  useEffect(() => {
    if (cancelLoading == false && cancelled == true) {
      setMyAppointmentsLoading(true);
      if (user.userType == "PATIENT") getMyAppointments(defaultPatientParam);
    }
  }, [cancelLoading]);

  const cancelModalVisible = (id) => {
    setAppointmentId(id);
    setStatusModalVisible(true);
  };
  const cancelAppointment = () => {
    setCancelLoading(true);
    setCancelled(true);
    deleteAppointment(appointmentId)
      .then((response) => {
        if (response.data.status === 1) {
          setCancelLoading(false);
          setCancelled(false);
          setStatusModalVisible(false);
          message.success("Appointment Cancelled Successfully");
          let searchActiveQuery = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
          if (hasPrivilege("ACTIVE_PATIENT")) {
            fetchActivePatients(searchActiveQuery);
          } else {
            //setStatusModalVisible
            let myAppointmentsQuery = `pageNo=${pageNo}&size=${pageSize}&patientId=${
              user.id
            }&timeZone=${moment.tz.guess()}`;
            getMyAppointments(myAppointmentsQuery);
          }
        } else {
          setCancelLoading(false);
          setStatusModalVisible(false);
          setCancelled(false);
          message.error("Could not delete appointment,Try again");
        }
      })
      .catch((err) => {
        setCancelLoading(false);
        setStatusModalVisible(false);
        setCancelled(false);
        message.error("Could not delete appointment,Try again");
      });
  };

  const bookAppointmentHandler = () => {
    history.push(`/appointment/${user.id}/${user.fullName}`);
  };

  const acceptAppointment = (id) => {
    acceptOrDeclineAppointment(id, "Confirmed")
      .then((res) => {
        if (res.data.status === 1) {
          message.success("Appointment Accepted Successfully.");
          let searchQuery = `${searchParam}=${searchString}&pageNo=${pageNo}&size=${pageSize}`;
          fetchAppointmentRequests(searchQuery);
          let searchActiveQuery = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
          fetchActivePatients(searchActiveQuery);
        } else {
          message.error("Could not Accept appointment, Try again.");
        }
      })
      .catch((err) => {
        message.error("Could not Accept appointment, Try again.");
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const declineAppointment = (id) => {
    acceptOrDeclineAppointment(id, "Declined")
      .then((res) => {
        if (res.data.status === 1) {
          message.success("Appointment Declined.");
          let searchQuery = `${searchParam}=${searchString}&pageNo=${pageNo}&size=${pageSize}`;
          fetchAppointmentRequests(searchQuery);
        } else {
          message.error("Could not Decline appointment, Try again.");
        }
      })
      .catch((err) => {
        message.error("Could not Decline appointment, Try again.");
        console.log(err);
        Sentry.captureException(err);
      });
  };
  const createNewPatient = () => {
    return (
      <Menu
        onClick={(props) => {
          props.key == "manual" && newPatientHandler();
          props.key == "scan" && setCreatePatientVisible(true);
        }}
      >
        <Menu.Item key="manual">Manual Entry</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="scan">Upload ID</Menu.Item>
      </Menu>
    );
  };

  const ExtraTabContent = () => {
    if (user.userType === "PATIENT") {
      return (
        <>
          <Button
            type="primary"
            size="large"
            style={{ borderRadius: "5px" }}
            ghost={true}
            onClick={() => setPatientUploadReportModalVisible(true)}
          >
            <StyledIcon
              component={UploadReport}
              color={theme["@primary-color"]}
            />
            Upload Report
          </Button>
          &nbsp; &nbsp;
          <Button
            type="primary"
            size="large"
            style={{ borderRadius: "5px" }}
            onClick={bookAppointmentHandler}
          >
            Book Visit
          </Button>
        </>
      );
    } else if (hasPrivilege(undefined, ["DOCTOR", "NURSE"])) {
      return (
        <>
          <Space wrap>
            {hasPrivilege(undefined, ["DOCTOR"]) &&
            getUserRoles().includes("ADD_PATIENT") ? (
              <div>
                <Button
                  type="primary"
                  ghost
                  size="large"
                  icon={<UserAddOutlined />}
                  style={{ fontWeight: "600" }}
                  onClick={(e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                    setNewPatientModalOpen(true);
                  }}
                >
                  New Patient
                </Button>
                {/* <Dropdown
                  overlay={createNewPatient}
                  trigger={['click']}
                  icon={<UserAddOutlined />}
                  ghost
                  size="large"
                >
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    icon={<UserAddOutlined />}
                    style={{ fontWeight: '600' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    Create Patient
                  </Button>
                </Dropdown> */}
                <CreateNewPatientManual
                  createPatientVisible={createPatientVisible}
                  setCreatePatientVisible={setCreatePatientVisible}
                  setOcrData={setOcrData}
                  setPreviewOCRFilesVisible={setPreviewOCRFilesVisible}
                />{" "}
              </div>
            ) : null}
            {/* <Button
              type="primary"
              ghost
              size="large"
              icon={<MailOutlined />}
              style={{ fontWeight: '600' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMessageModalVisible(true);
              }}
            >
              Message All
            </Button> */}
            <InQueueCountDiv
              color={theme["@primary-color"]}
              onClick={() => {
                if (user.userType == "NURSE") setCurrentTab(7);
                else setCurrentTab(3);
              }}
            >
              <div>In Queue: </div>
              <div style={{ color: theme["@primary-color"] }}>
                {/* {reportWaitingTotal}- */}
                {activeCount?.inQueueCount}
              </div>
            </InQueueCountDiv>
          </Space>
        </>
      );
    } else {
      return (
        <HasPrivilege privilege={Privileges.ADD_PATIENT}>
          <Dropdown
            overlay={createNewPatient}
            trigger={["click"]}
            icon={<UserAddOutlined />}
            ghost
            size="large"
          >
            <Button
              type="primary"
              ghost
              size="large"
              icon={<UserAddOutlined />}
              style={{ fontWeight: "600", marginRight: "10px" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Create Patient
            </Button>
          </Dropdown>
          {/* <Button
            type="primary"
            ghost
            size="large"
            icon={<MailOutlined />}
            style={{ fontWeight: '600' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setMessageModalVisible(true);
            }}
          >
            Message All
          </Button> */}
          <CreateNewPatientManual
            createPatientVisible={createPatientVisible}
            setCreatePatientVisible={setCreatePatientVisible}
            setOcrData={setOcrData}
            setPreviewOCRFilesVisible={setPreviewOCRFilesVisible}
          />
          <PreviewOCRFiles
            data={ocrData}
            visible={previewOCRFilesVisible}
            setVisible={setPreviewOCRFilesVisible}
          />
        </HasPrivilege>
      );
    }
  };
  const CreatePatient = () => {
    return (
      <ExtraTabButton>
        <HasPrivilege privilege={Privileges.ADD_PATIENT}>
          <Button
            type="primary"
            ghost
            size="large"
            icon={<UserAddOutlined />}
            onClick={newPatientHandler}
            // className="bold-border-button"
          >
            Create Patient
          </Button>
        </HasPrivilege>
      </ExtraTabButton>
    );
  };

  useEffect(() => {
    if (activeCount)
      if (docFilterParam?.length > 0)
        if (searchString == "")
          if (searchParam == "all") {
            onReportWaitingSearch(true);
          }
  }, [activeCount?.inQueueCount]);

  useEffect(() => {
    if (activeCount)
      if (defaultActiveReportsParam)
        if (docFilterParam?.length > 0)
          if (searchString == "")
            if (searchParam == "all") {
              fetchActivePatients(defaultActiveReportsParam);
            }
  }, [activeCount?.activeReceptionCount, activeCount?.activeDoctorCount]);

  // Serach Patient by number
  const searchByPhone = () => {
    let data = {
      phoneNumbers: [
        {
          countryCode: "+91 India",
          phoneNumber: phoneNumber.toString(),
        },
      ],
    };

    getPatientsByPhone(data)
      .then((res) => {
        if (res.data.status === 1) {
          let newData = res.data.body.map(row=>{
            row.key=row.id;
            row.name = row.userName;
            row.phone = row.phoneNumbers[0].phoneNumber
            return row;
          })
          setPatientListByPhone(newData);
          setNewPatientIPD(false);
        } else {
          setNewPatientIPD(true);
          message.error("Could not find patient, Create New Patient.");
        }
      })
      .catch((err) => {
        message.error("Could not find patient, Try again.");
        console.log(err);
        Sentry.captureException(err);
      });
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a href={`/update-patient/${record.id}/${record.firstName}?type=ipd`}>Edit</a>
        </Space>
      ),
    },
  ];
  const showNewPatientButton = () => {
    return (
      <>
        <Divider />
        <Dropdown
          overlay={createNewPatient}
          trigger={["click"]}
          icon={<UserAddOutlined />}
          ghost
          size="large"
        >
          <Button
            type="primary"
            ghost
            size="large"
            icon={<UserAddOutlined />}
            style={{ fontWeight: "600" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Create Patient
          </Button>
        </Dropdown>
      </>
    );
  };

  if (Object.keys(dateRangeFilter).length == 0) {
    console.log("if condition ");
  }
  // console.log('active pat', activePatients);
  // console.log('all patient', allPatients);
  return (
    <>
      {hasPrivilege(undefined, [
        "DOCTOR",
        "NURSE",
        "RECEPTIONIST",
        "PATIENT",
      ]) && (
        <StyledTabs
          className="tab_sec_portal"
          color={theme["@primary-color"]}
          // defaultActiveKey="1"
          activeKey={`${currentTab}`}
          onTabClick={(key) => setCurrentTab(key)}
          size="large"
          animated
          tabBarGutter={0}
          onChange={onTabChange}
          tabBarExtraContent={<ExtraTabContent />}
          tabBarStyle={{ width: "100%" }}
        >
          {hasPrivilege("ACTIVE_PATIENT") &&
            hasPrivilege(undefined, ["DOCTOR", "NURSE", "RECEPTIONIST"]) && (
              <TabPane tab="Active Patients" key="1">
                <CreatePatient />
                {user.userType == "DOCTOR" && (
                  <PatientSearch
                    searchSelect={searchParam}
                    onSearch={onActiveSearch}
                    filters={["Visit Type", "Payment Method"]}
                    tabType={"docActive"}
                    {...{
                      searchByItem,
                      onInputChange,
                      onSelectChange,
                      searchString,
                      onClear,
                      onFilterSubmit,
                      onTimeFilter,
                      timeFilter,
                      filterParams,
                      docFilterParam,
                      dateRangeFilter,
                      onDateRangeFilter,
                    }}
                  />
                )}
                {user.userType == "RECEPTIONIST" && (
                  <PatientSearch
                    docFilterItems={doctors}
                    searchSelect={searchParam}
                    onSearch={onActiveSearch}
                    filters={[
                      "Visit Type",
                      "Payment Method",
                      "Appointment Status",
                    ]}
                    tabType={"active"}
                    {...{
                      searchByItem,
                      onInputChange,
                      onSelectChange,
                      onDocFilterSubmit,
                      searchString,
                      onClear,
                      onFilterSubmit,
                      onTimeFilter,
                      timeFilter,
                      filterParams,
                      docFilterParam,
                      dateRangeFilter,
                      onDateRangeFilter,
                    }}
                  />
                )}
                {user.userType == "NURSE" && (
                  <PatientSearch
                    docFilterItems={doctors}
                    searchSelect={searchParam}
                    onSearch={onActiveSearch}
                    filters={["Payment Method"]}
                    tabType={"active"}
                    {...{
                      searchByItem,
                      onInputChange,
                      onSelectChange,
                      onDocFilterSubmit,
                      searchString,
                      onClear,
                      onFilterSubmit,
                      onTimeFilter,
                      timeFilter,
                      filterParams,
                      docFilterParam,
                    }}
                  />
                )}
                <PatientsTable
                  columns={activePatientColumns(
                    history,
                    cancelModalVisible,
                    user,
                    setIntimeModalVisible,
                    setSelectedAppointmentId,
                    pageNo,
                    pageSize,
                    StyledButton,
                    theme,
                    setNurseUploadReportModalVisible,
                    setUploadNursePatient
                  )}
                  loading={activeLoading}
                  dataSource={activePatients}
                  pageSize={pageSize}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  total={activePatientTotal}
                  // onRow={(record, rowIndex) => {
                  //   return {
                  //     onClick: (event) => {
                  //       onActiveRowClick(record, rowIndex);
                  //     }, // click row
                  //   };
                  // }}
                />
              </TabPane>
            )}
          {hasPrivilege("VIEW_PENDING_APPOINTMENT") && (
            <TabPane tab="Appointment Requests" key="2">
              <CreatePatient />
              <PatientSearch
                docFilterItems={doctors}
                searchSelect={searchParam}
                onSearch={onRequestsSearch}
                filters={["Visit Type", "Payment Method", "Insurance Status"]}
                tabType={"pending"}
                {...{
                  searchByItem,
                  onInputChange,
                  onSelectChange,
                  onDocFilterSubmit,
                  searchString,
                  onClear,
                  onFilterSubmit,
                  onDateRangeFilter,
                  dateRangeFilter,
                  filterParams,
                  docFilterParam,
                }}
              />
              <PatientsTable
                columns={appointmentRequestsColumns(
                  history,
                  acceptAppointment,
                  declineAppointment,
                  pageNo,
                  pageSize,
                  StyledButton,
                  theme
                )}
                loading={appointmentReqLoading}
                dataSource={appointmentRequests}
                pageSize={15}
                setPageNo={setPageNo}
                pageNo={pageNo}
                total={appointmentRequestsTotal}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: (event) => {
                //       onActiveRowClick(record, rowIndex);
                //     }, // click row
                //   };
                // }}
              />
            </TabPane>
          )}
          {user.userType == "DOCTOR" && hasPrivilege("ACTIVE_PATIENT") && (
            <TabPane tab="Report Waiting" key="3">
              <PatientSearch
                docFilterItems={doctors}
                searchSelect={searchParam}
                onSearch={onReportWaitingSearch}
                filters={["Payment Method"]}
                tabType={"docReportWaiting"}
                {...{
                  searchByItem,
                  onInputChange,
                  onSelectChange,
                  searchString,
                  onClear,
                  onFilterSubmit,
                  onDateRangeFilter,
                  dateRangeFilter,
                  filterParams,
                }}
              />
              <PatientsTable
                columns={reportWaitingColumns(
                  history,
                  user,
                  pageNo,
                  pageSize,
                  StyledButton,
                  theme,
                  setUploadNursePatient,
                  setNurseUploadReportModalVisible
                )}
                dataSource={reportWaitingPatients}
                loading={reportWaitingLoading}
                pageSize={pageSize}
                setPageNo={setPageNo}
                pageNo={pageNo}
                total={reportWaitingTotal}
              />
            </TabPane>
          )}
          {hasPrivilege("VIEW_PATIENT") && (
            <TabPane tab="All Patients" key="4">
              <CreatePatient />
              <PatientSearch
                searchSelect={searchParam}
                onSearch={onAllSearch}
                tabType={"all"}
                {...{
                  searchByItem,
                  onInputChange,
                  onSelectChange,
                  searchString,
                  onDateRangeFilter,
                  dateRangeFilter,
                  onClear,
                  setDownloadReport,
                }}
              />
              <PatientsTable
                columns={allPatientColumns(
                  history,
                  user,
                  pageNo,
                  pageSize,
                  StyledButton,
                  theme,
                  uploadReportModalVisible,
                  setUploadReportModalVisible,
                  setRow,
                  setMessageModalVisible,
                  messageModalVisible,
                  cancelLoading
                )}
                dataSource={allPatients}
                loading={allLoading}
                pageSize={pageSize}
                setPageNo={setPageNo}
                pageNo={pageNo}
                total={allPatientTotal}
              />
            </TabPane>
          )}

          {user.userType == "RECEPTIONIST" && hasPrivilege("VIEW_PATIENT") && (
            <TabPane tab="Reports" key="5">
              <CreatePatient />
              <PatientSearch
                docFilterItems={doctors}
                searchSelect={searchParam}
                onSearch={onReportsSearch}
                filters={[
                  "Payment Method",
                  "Appointment Status",
                  "Insurance Status",
                ]}
                tabType={"reports"}
                {...{
                  searchByItem,
                  onInputChange,
                  onSelectChange,
                  onDocFilterSubmit,
                  searchString,
                  onClear,
                  onFilterSubmit,
                  onDateRangeFilter,
                  dateRangeFilter,
                  filterParams,
                  docFilterParam,
                  setDownloadReport,
                }}
              />
              <PatientsTable
                columns={reportsColumns(history, pageNo, pageSize)}
                dataSource={reportsPatients}
                loading={reportsLoading}
                pageSize={pageSize}
                setPageNo={setPageNo}
                pageNo={pageNo}
                total={reportsTotal}
              />
            </TabPane>
          )}
          {user.userType == "PATIENT" &&
            hasPrivilege("VIEW_APPOINTMENT_DETAILS") && (
              <TabPane tab="My Appointments" key="6">
                <PatientSearch
                  docFilterItems={doctors}
                  filters={[
                    "Visit Type",
                    "Payment Method",
                    "Appointment Status",
                  ]}
                  onSearch={onMyAppointmentsSearch}
                  tabType={"myAppointments"}
                  {...{
                    onDocFilterSubmit,
                    onClear,
                    onFilterSubmit,
                    onDateRangeFilter,
                    dateRangeFilter,
                    filterParams,
                    docFilterParam,
                  }}
                />
                <PatientsTable
                  columns={myAppointmentsColumns(
                    history,
                    user,
                    cancelModalVisible,
                    pageNo,
                    pageSize,
                    StyledButton,
                    theme,
                    currency
                  )}
                  dataSource={myAppointments}
                  loading={myAppointmentsLoading}
                  pageSize={15}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  total={myAppointmentsTotal}
                />
              </TabPane>
            )}
          {hasPrivilege(undefined, ["NURSE", "PATIENT"]) &&
            hasPrivilege("ACTIVE_PATIENT") && (
              <TabPane tab="Report Waiting" key="7">
                <PatientSearch
                  docFilterItems={doctors}
                  searchSelect={searchParam}
                  onSearch={onReportWaitingSearch}
                  filters={["Payment Method"]}
                  tabType={"nurseReportWaiting"}
                  {...{
                    searchByItem,
                    onInputChange,
                    onSelectChange,
                    onDocFilterSubmit,
                    searchString,
                    onClear,
                    onFilterSubmit,
                    onDateRangeFilter,
                    dateRangeFilter,
                    onTimeFilter,
                    timeFilter,
                    filterParams,
                    docFilterParam,
                  }}
                />
                <PatientsTable
                  columns={reportWaitingColumns(
                    history,
                    user,
                    pageNo,
                    pageSize,
                    StyledButton,
                    theme,
                    setUploadNursePatient,
                    setNurseUploadReportModalVisible
                  )}
                  dataSource={reportWaitingPatients}
                  loading={reportWaitingLoading}
                  pageSize={pageSize}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  total={reportWaitingTotal}
                />
              </TabPane>
            )}
          {user.userType == "PATIENT" &&
            hasPrivilege("VIEW_APPOINTMENT_DETAILS") && (
              <TabPane tab="Medical History" key="8">
                <PatientSearch
                  docFilterItems={doctors}
                  filters={[
                    "Visit Type",
                    "Payment Method",
                    "Appointment Status",
                  ]}
                  onSearch={onMedicalHistorySearch}
                  tabType={"myMedicalHistory"}
                  {...{
                    onDocFilterSubmit,
                    onClear,
                    onFilterSubmit,
                    onDateRangeFilter,
                    dateRangeFilter,
                    filterParams,
                    docFilterParam,
                  }}
                />
                <PatientsTable
                  columns={medicalHistoryColumns(
                    history,
                    user,
                    pageNo,
                    pageSize,
                    StyledButton,
                    theme,
                    currency
                  )}
                  dataSource={myMedicalHistory}
                  loading={medicalHistoryLoading}
                  pageSize={pageSize}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  total={myMedicalHistoryTotal}
                />
              </TabPane>
            )}
        </StyledTabs>
      )}
      {user.userType == "INSURANCE" && <InsuranceDashboard />}
      {user.userType == "LABORATORY" && <LaboratoryDashboard />}
      {user.userType == "RADIOLOGY" && <RadiologyDashboard />}
      {user.userType == "PHARMACY" && <PharmacyDashboard />}
      <StatusModal
        cancel={true}
        cancelAppointment={cancelAppointment}
        visible={statusModalVisible}
        closeModal={() => setStatusModalVisible(false)}
        cancelLoading={cancelLoading}
      />
      <MessageAllModal
        visible={messageModalVisible}
        closeModal={() => setMessageModalVisible(false)}
        cancelLoading={cancelLoading}
        row={row}
      />
      <IntimeModal
        visible={inTimeModalVisible}
        setVisible={setIntimeModalVisible}
        id={selectedAppoinementId}
        refetch={() => {
          fetchActivePatients(
            `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`
          );
        }}
      />
      <UploadReportModal
        visible={patientUploadReportModalVisible}
        setVisible={setPatientUploadReportModalVisible}
        row={user}
      />
      <UploadReportModal
        visible={uploadReportModalVisible}
        setVisible={setUploadReportModalVisible}
        row={row}
      />
      <UploadReportModal
        visible={nurseUploadReportModalVisible}
        setVisible={setNurseUploadReportModalVisible}
        row={uploadNursePatient}
      />
      <Modal
        title="Select Patient Type"
        centered
        onCancel={() => {
          setNewPatientModalOpen(false);
          setPatientType("");
        }}
        visible={newPatientModalOpen}
        cancelText={"Close"}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Row>
          <Radio.Group onChange={onPatientTypeSelect} value={patientType}>
            <Radio value={"ipd"}>IPD</Radio>
            <Radio value={"opd"}>OPD</Radio>
          </Radio.Group>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            {patientType === "opd" && showNewPatientButton()}
            {patientType === "ipd" && (
              <>
                <Divider />
                <Row gutter={16} justify="end">
                  <Col span={20}>
                    <InputNumber
                      placeholder="Serach for User using his Phone Number"
                      onChange={(value) => setPhoneNumber(value)}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={4}>
                    <Button onClick={() => searchByPhone()}>Search</Button>
                  </Col>
                </Row>
                {patientListByPhone.length > 0
                  ? 
                  <Table rowKey="id" columns={columns} dataSource={patientListByPhone} pagination={false} />
                  : ""}

                {newPatientIPD && showNewPatientButton()}
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PatientsList;
