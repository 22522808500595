import { ADD_PATIENT } from '../actions/newPatient';

const initialState = {};
export const newPatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PATIENT:
      return action.payload;

    default:
      return state;
  }
};
