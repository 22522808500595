import React, { useEffect } from "react";
import { Row, Col, message, Button } from "antd";
import { SectionHeading, SectionHeadContainer } from "../styledComponents";
import { BodyContainer } from "../Sections/styledComponents";
import { useState } from "react";
import { LeftCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import {
  fetchInsuranceProviders,
  deleteInsuranceProvider,
  addInsuranceProvider,
  editInsuranceProvider,
} from "../../../../apis/Insurance";
import { fetchBloodGroup } from "../../../../apis/Auth";
import ConfirmationModal from "./ConfirmationModal";
import InsuranceProvidersTable from "./InsuranceProvidersTable";
import CreateInsuranceProvider from "./CreateInsuranceProvider";
import * as Sentry from "@sentry/react";

export const NewUserButton = styled(Button)`
  height: 40px;
  width: 110px;
  font-size: 15px;
  border-radius: 5px !important;
`;

const InsuranceManagement = () => {
  const [selected, setSelected] = useState(false);
  const [createConsentFormModal, setCreateConsentFormModal] = useState(false);
  const [trigger, setTrigger] = useState(true);

  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [forms, setForms] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [visitTypeValues, setVisitTypeValues] = useState([]);

  useEffect(() => {
    fetchInsuranceProviders()
      .then(({ data }) => {
        if (data.status === 1) {
          let forms = data.body;
          if (forms.length > 0) {
            forms.forEach((form) => {
              form.createdDate = moment(form.createdDate).format("DD-MM-YYYY");
            });
          }
          setForms(forms);
          setLoading(false);
        } else {
          setLoading(false);
          message.error("Could not fetch forms, Try again");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });

    fetchBloodGroup("VISIT_TYPE")
      .then((res) => {
        if (res.data.status === 1) {
          setVisitTypeValues(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, [trigger]);

  const createInsuranceProvider = (values) => {
    const payload = {
      hospitalInsuranceProviderData: values.Hospital ? values.Hospital : null,
      laboratoryInsuranceProviderData: values.Laboratory
        ? values.Laboratory
        : null,
      pharmacyInsuranceProviderData: values.Pharmacy ? values.Pharmacy : null,
      radiologyInsuranceProviderData: values.Radiology
        ? values.Radiology
        : null,
      insuranceName: values.providerName,
      planRequests: values.insurancePlan,
    };
    setSubmitLoading(true);
    addInsuranceProvider(payload)
      .then((resp) => {
        if (resp.data.status === 1) {
          setTrigger(!trigger);
          setSelected(false);
          message.success("Successfully added Insurance Provider");
          setSubmitLoading(false);
          setCreateConsentFormModal(false);
        } else {
          message.error("Could not add Insurance Provider, Try Again");
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        message.error("Could not add Insurance Provider, Try Again");
        console.log(err);
        Sentry.captureException(err);
        setSubmitLoading(false);
      });
  };

  const updateInsuranceDetails = (values) => {
    const payload = {
      hospitalInsuranceProviderData: values.Hospital ? values.Hospital : null,
      laboratoryInsuranceProviderData: values.Laboratory
        ? values.Laboratory
        : null,
      pharmacyInsuranceProviderData: values.Pharmacy ? values.Pharmacy : null,
      radiologyInsuranceProviderData: values.Radiology
        ? values.Radiology
        : null,
      insuranceName: values.providerName,
      planRequests: values.insurancePlan,
    };
    setSubmitLoading(true);
    editInsuranceProvider(selectedItem.id, payload)
      .then((resp) => {
        if (resp.data.status === 1) {
          setTrigger(!trigger);
          setSelected(false);
          setSelectedItem({});
          message.success("Successfully Updated Insurance Provider");
          setSubmitLoading(false);
          setEditModal(false);
          setCreateConsentFormModal(false);
        } else {
          message.error("Could not Update Insurance Provider, Try Again");
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        message.error("Could not Update Insurance Provider, Try Again");
        console.log(err);
        Sentry.captureException(err);
        setSubmitLoading(false);
      });
  };

  const deleteInsuranceProviderHandler = () => {
    setDeleteLoading(true);
    deleteInsuranceProvider(selectedItem.id)
      .then((resp) => {
        if (resp.data.status === 1) {
          setTrigger(!trigger);
          setDeleteLoading(false);
          message.success("Successfully Deleted Insurance");
          setDeleteModal(false);
        } else {
          message.error("Could not delete Insurance, Try Again");
          setDeleteLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error("Could not delete Insurance, Try Again");
        setDeleteLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between" style={{ alignItems: "center" }}>
              <Col>
                <SectionHeading>
                  Manage Insurance
                  {selected && (
                    <>
                      <LeftCircleOutlined
                        onClick={() => {
                          setSelected(false);
                          setTrigger(!trigger);
                        }}
                        style={{ margin: "0 10px" }}
                      />
                      {editModal ? "Edit" : "Add new"}
                    </>
                  )}
                </SectionHeading>
              </Col>
              <Col>
                {selected == false && (
                  <NewUserButton
                    type="primary"
                    onClick={() => {
                      setSelected(true);
                      setTrigger(!trigger);
                      setEditModal(false);
                    }}
                  >
                    Add New
                  </NewUserButton>
                )}
              </Col>
            </Row>
          </SectionHeadContainer>
        </Col>
        <Col span={24}>
          <BodyContainer>
            {selected == false ? (
              <InsuranceProvidersTable
                loading={loading}
                setSelected={setSelected}
                setEditModal={setEditModal}
                setDeleteModal={setDeleteModal}
                setSelectedItem={setSelectedItem}
                forms={forms}
              />
            ) : (
              <CreateInsuranceProvider
                // visible={editModal ? editModal : createConsentFormModal}
                // setVistible={editModal ? setEditModal : setCreateConsentFormModal}
                trigger={trigger}
                setTrigger={setTrigger}
                createInsuranceProvider={createInsuranceProvider}
                updateInsuranceDetails={updateInsuranceDetails}
                loading={submitLoading}
                setEditModal={setEditModal}
                edit={editModal}
                selectedItem={selectedItem}
                setSelected={setSelected}
                visitTypeValues={visitTypeValues}
              />
            )}
          </BodyContainer>
        </Col>
      </Row>

      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        deleteForm={deleteInsuranceProviderHandler}
        loading={deleteLoading}
      />
    </>
  );
};

export default InsuranceManagement;
