import styled from "styled-components";
import { Row } from "antd";

export const ModalBodyContainer = styled.div`
  width: 500px;
`;

export const StyledRow = styled(Row)`
  margin-top: 24px;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  color: ${(props) => props.color};
  text-align: center;
`;

export const UploadTitle = styled.h3`
  font-size: 16px;
  color: ${(props) => props.color};
  text-align: left;
`;

export const DocumentDetailsDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
