import React from "react";
import { Modal } from "antd";
import Camera from "../../utils/Camera";

const CameraModal = ({ visible, setVisible, setProfilePic }) => {
  return (
    <Modal
      visible={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
        setProfilePic(undefined);
      }}
      destroyOnClose={true}
    >
      <Camera
        onCapture={(blob) => setProfilePic(blob)}
        onClear={() => setProfilePic(undefined)}
      />
    </Modal>
  );
};
export default CameraModal;
