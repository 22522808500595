import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, message, Row, Col, Select } from 'antd';
import { Option } from "antd/lib/mentions";
import { ReactComponent as SuccessIcon } from '../../../assets/icons/Successful-icon.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/CancelIcon.svg';
import { useHistory } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { getHospitalId } from '../../utils/LocalStorage/index';
import { getDepartments, getDoctors } from '../../../apis/Appointment';


const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

const ModalContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledCancelIcon = styled(MailOutlined)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledP = styled.p`
  font-size: 15px;
`;

const StyledPTitle = styled.p`
  font-size: 28px;
  font-family: bold;
  color: #56aef8;
`;

const StyledRow = styled(Row)`
  padding: 24px;
`;

const AdmitPatientModal = ({
  visible,
  closeModal,
  phoneNumber,
  firstName,
  patientId,
}) => {
  const history = useHistory();
  
  const [departments, setDepartments] = useState([]);
  const [doctorsLoading, setDoctorsLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);

  const [form] = Form.useForm();
  useEffect(() => {
    getDepartments(getHospitalId())
      .then((response) => {
        if (response.data.status === 1) {
          setDepartments(response.data.body);
        } else {
          message.error('Could not fetch departments, Try Again');
        }
      })
      .catch((err) => {
        message.error('Could not fetch departments, Try Again');
      });
  }, []);


  const onSubmit = (values) => {
    
  };

  const departmentChange = (departmentId) => {
    getDoctors(departmentId)
        .then((response) => {
          if (response.data.status === 1) {
            setDoctorsLoading(false);
            if (response.data.body) setDoctors(response.data.body);
          } else {
            setDoctorsLoading(false);
            message.error('Could not fetch Doctors List, Try Again');
          }
        })
        .catch((err) => {
          setDoctorsLoading(false);
          console.log(err);
          message.error('Could not fetch Doctors List, Try Again');
        });
  }

  return (
    <StyledModal
      visible={visible}
      closable={false}
      centered
      maskClosable={true}
      footer={null}
    >
      <ModalContentDiv>
        {/* <MailOutlined style={{ width: '60px' }} /> */}
        <StyledPTitle>Admit Patient</StyledPTitle>
        <Form
          layout="vertical"
          onFinish={(values) => onSubmit(values)}
          form={form}
          style={{ width: '100%' }}
          scrollToFirstError={true}
        >
            <Form.Item
                label="Department"
                name="department"
                rules={[
                  { required: true, message: "Department cannot be empty" },
                ]}
                style={{ width: "100%" }}
              >
                <Select
                  size="large"
                  placeholder="Select Department"
                  //   mode="multiple"
                  onChange={(value)=>departmentChange(value)}
                >
                  {departments &&
                    departments.map((department) => {
                      return (
                        <Option value={department.id} key={department.id}>
                          {department.departmentName}
                        </Option>
                      );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                  name="doctor"
                  label="Doctor"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    loading={doctorsLoading}
                    placeholder="Select Doctor"
                    onSelect={(value) => {
                      console.log('value of doc', value);
                    //   setDocId(value);
                    }}
                    mode="multiple"
                  >
                    {doctors &&
                      doctors.map((doctor) => {
                        return (
                          <Option value={doctor.id}>
                            {doctor.firstName + ' ' + doctor.lastName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
            <Form.Item label="Assign Nurse">
                <Select mode="multiple">
                    <Select.Option value="demo">Demo</Select.Option>
                </Select>
            </Form.Item>
          <>
            <StyledRow gutter={12} justify="end">
              <Button
                key="ok"
                type="primary"
                ghost
                onClick={() => closeModal()}
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>

              <Button key="ok" type="primary" htmlType="submit">
                Submit
              </Button>
            </StyledRow>
          </>
        </Form>
      </ModalContentDiv>
    </StyledModal>
  );
};

export default AdmitPatientModal;
