import React, { useEffect, useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import {
  ModalBodyContainer,
  StyledTitle,
  StyledForm,
  StyledIcon,
} from "./styledComponents";
import { Row, Col, Input, Form, Radio, Button, message } from "antd";
import { sidebarIcons } from "../../../../constants/sideBarIcons.js";
import { addNewSection } from "../../../../apis/healthRecord.js";

const ModalBody = ({
  setVisible,
  selectedSection,
  totalSections,
  refetch,
  setSelectedSection,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const addNew = (request) => {
    setLoading(true);
    addNewSection(request)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) {
            message.success(selectedSection ? "Updated" : "Created");
            if (selectedSection) {
              setSelectedSection({
                ...selectedSection,
                formName: request.name,
                status: request.status,
              });
            }

            setVisible(false);
            refetch();
          } else {
            message.error(data.body);
          }
          //   setSelectedItem(undefined);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedSection)
      form.setFieldsValue({
        ...selectedSection,
        name: selectedSection.formName,
        sectionIcon: selectedSection.icon,
      });
  }, [selectedSection]);

  const onFinish = (values) => {
    values.editable = true;
    if (!values.order) values.order = totalSections + 1;
    if (selectedSection) {
      values.sectionId = selectedSection.id;
    }
    addNew(values);
  };
  return (
    <ModalBodyContainer>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <StyledTitle level={3}>
            {selectedSection ? "Update Section" : "Create New Section"}
          </StyledTitle>
        </Col>
        <Col span={24}>
          <StyledForm
            name="admin-login-form"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            scrollToFirstError={true}
            layout="vertical"
            size="large"
            wrapperCol={{ gutter: 36 }}
            form={form}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Section name is Required !",
                },
              ]}
            >
              <Input
                size="large"
                disabled={selectedSection ? !selectedSection.editable : false}
              />
            </Form.Item>
            <Form.Item
              label="Choose Icon"
              name="sectionIcon"
              rules={[
                {
                  required: true,
                  message: "Choose a Icon !",
                },
              ]}
            >
              <Radio.Group
                defaultValue="a"
                size="large"
                disabled={selectedSection ? !selectedSection.editable : false}
              >
                <Row gutter={[8, 8]}>
                  {Object.entries(sidebarIcons).map(([key, value]) => (
                    <Col>
                      <Radio.Button value={key}>
                        <StyledIcon component={value} />
                      </Radio.Button>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "STatus required !",
                },
              ]}
            >
              <Radio.Group defaultValue="a" size="large">
                <Radio value="ACTIVE">Active</Radio>
                <Radio value="INACTIVE">Inactive</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Row gutter={12} justify="end">
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    ghost
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    htmlType="submit"
                  >
                    {selectedSection ? "Update" : "Create"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </StyledForm>
        </Col>
      </Row>
    </ModalBodyContainer>
  );
};

const NewSectionModal = ({
  visible,
  setVisible,
  selectedSection,
  totalSections,
  setSelectedSection,
  refetch,
}) => {
  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBody
        setVisible={setVisible}
        selectedSection={selectedSection}
        totalSections={totalSections}
        refetch={refetch}
        setSelectedSection={setSelectedSection}
      />
    </CustomModal>
  );
};
export default NewSectionModal;
