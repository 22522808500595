import React from 'react';
import {
  Layout as AntdLayout,
  Row,
  Col,
  Menu,
  Dropdown,
  Avatar,
  Button,
  Tooltip,
  Badge,
  message as AlertMessage,
} from 'antd';
import styled from 'styled-components';
import Icon, {
  CaretDownOutlined,
  MessageFilled,
  UserOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import Logo from '../../../assets/images/logo.png';
import { ReactComponent as UserAvatar } from '../../../assets/icons/avatar.svg';
import { ReactComponent as PatientAvatar } from '../../../assets/icons/PatientAvatar.svg';
import { ReactComponent as NurseAvatar } from '../../../assets/icons/NurseAvatar.svg';
import { ReactComponent as DoctorAvatar } from '../../../assets/icons/DoctorAvatar.svg';
import { ReactComponent as InsuranceAvatar } from '../../../assets/icons/InsuranceAvatar.svg';
import { ReactComponent as AdminAvatar } from '../../../assets/icons/HospitalAdminAvatar.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/ProfileIcon.svg';
import { ReactComponent as SignOutIcon } from '../../../assets/icons/SignoutIcon.svg';
import { useHistory } from 'react-router-dom';
import { clearUser } from '../../../redux/actions/user';
import { useEffect, useState } from 'react';
import { PROFILE_IMAGE_PREFIX } from '../../../constants/StringConstants';
import moment from 'moment-timezone';
import { hasPrivilege } from '../../utils/privilegeHandlers/hasPrivilege';
import { getActivetPatients } from '../../../apis/patients';
import { ActiveCount } from '../../../socket/socket';
import MeditagLogo from '../../../assets/images/hospital_pic_1671612999007.png';
import * as Sentry from '@sentry/react';
import { MessageOutlined } from '@ant-design/icons';

const { Header, Content } = AntdLayout;

const StyledHeader = styled(Header)`
  && {
    background-color: ${(props) => props.bg};
  }
  /* padding: 5px; */
  height: 70px;
  color: '#fff';
  position: fixed;
  width: 100%;
  z-index: 1000;
  &.main_header_layout {
    padding: 0px 15px;
  }
  .header_main_logo {
    max-width: 150px;
    height: 70px;
  }
  .header_user_data {
    display: flex;
    width: auto;
  }
  .usr_name {
    margin: 0px 5px;
  }
  .usr_name + div {
    top: -9px !important;
  }
  .usr_name + div .loged_dropdown {
    left: auto !important;
    right: -15px !important;
  }
  .usr_name + div .loged_dropdown li {
    font-size: 16px;
    font-weight: 600;
  }
  .usr_name + div .loged_dropdown li svg {
    margin-right: 10px;
  }
  .header_user_data .dm-user .cls-2 {
    fill: #ffffff;
  }
  .header_user_data svg .cls-3 {
    fill: #3e3e3e;
  }
  @media (max-width: 1439px) {
    .usr_name + div .loged_dropdown li {
      font-size: 15px;
    }
  }
  @media (max-width: 767px) {
    .usr_name + div .loged_dropdown {
      min-width: 160px !important;
      right: 0px !important;
    }
    &.main_header_layout {
      height: 60px;
    }
    .header_main_logo {
      height: 60px;
      display: flex;
    }
    .header_main_logo img {
      align-self: center;
    }
    .dm-user {
      font-size: 42px !important;
    }
    .header_user_data {
      height: 60px;
    }
    .header_row_path {
      width: 100% !important;
    }
  }
`;
const StyledRow = styled(Row)`
  width: 90%;
  margin: auto;
`;
const HeaderRightWrapper = styled(Row)`
  display: flex;
  gap: 25px;
`;
const StyledContent = styled(Content)`
  width: 85%;
  margin: auto;
  min-height: calc(100vh - 90px);
  margin-top: 90px;
  @media (max-width: 1359px) {
    width: 92%;
  }
  @media (max-width: 640px) {
    width: 100%;
    padding: 0px 15px;
  }
`;
const Username = styled.div`
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  @media (max-width: 1280px) {
    display: none;
  }
  @media (max-width: 1480px) {
    font-size: 14px;
  }
`;

const ActivePatientCountWrapper = styled(Row)`
  gap: 10px;
  align-items: center;
`;

const ActivePatientsText = styled(Col)`
  color: white;
  @media (max-width: 480px) {
    display: none;
  }
`;

const ActivePatientCountValue = styled(Col)`
  background-color: white;
  color: ${(props) => props.color};
  border-radius: 15px;
  height: 16px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: smaller;
  box-shadow: 2px 4px 3px 1px rgba(0, 0, 0, 0.2);
`;
const DropdownContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const StyledProfileIcon = styled(ProfileIcon)`
  height: 25px;
  width: 25px;
  margin-right: 20px;
`;

const StyledSignOutIcon = styled(SignOutIcon)`
  height: 25px;
  width: 25px;
  margin-right: 20px;
`;

const StlyedMenu = styled(Menu)`
  min-width: 170px;
`;

const StlyedMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  padding: 10px;
  min-width: 170px;
`;

const StyledCol = styled(Col)`
  div {
    left: -20px !important;
  }
  @media (max-width: 1280px) {
    div {
      left: -60px !important;
    }
  }
`;

const iconsMap = new Map([
  ['PATIENT', PatientAvatar],
  ['RECEPTIONIST', UserAvatar],
  ['NURSE', NurseAvatar],
  ['DOCTOR', DoctorAvatar],
  ['INSURANCE', InsuranceAvatar],
  ['LABORATORY', PatientAvatar],
  ['RADIOLOGY', PatientAvatar],
  ['PHARMACY', PatientAvatar],
  ['ADMIN', AdminAvatar],
]);

//User Drop down menu
const menu = (onDrpDownClick) => (
  <StlyedMenu onClick={onDrpDownClick}>
    <StlyedMenuItem key="profile" icon={<StyledProfileIcon />}>
      My Profile
    </StlyedMenuItem>
    <StlyedMenu.Divider />
    <StlyedMenuItem key="logout" icon={<StyledSignOutIcon />}>
      Sign Out
    </StlyedMenuItem>
  </StlyedMenu>
);

//User avatar section
const ProfileDropdown = ({ user, profilePic }) => {
  return (
    <DropdownContainer>
      <Row align={'middle'} justify="end">
        <Col>
          {profilePic ? (
            <Avatar size={50} src={profilePic} />
          ) : (
            <Icon
              className="dm-user"
              component={iconsMap.get(user.userType)}
              style={{ fontSize: '50px', transform: 'translateY(10px)' }}
            />
          )}
        </Col>
        <StyledCol>
          <Username className="usr_name">{user.fullName}</Username>
        </StyledCol>
        <Col>
          <CaretDownOutlined style={{ color: '#fff' }} />
        </Col>
      </Row>
    </DropdownContainer>
  );
};

//Overall Layout
const Layout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const activeCount = useSelector((state) => state.activeCountReducer);
  const [profilePic, setProfilePic] = useState(null);
  const [count, setCount] = useState(0);
  const [theme, images, patientActiveCount] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
    state.patientReducer,
  ]);

  const clickEvents = {
    profile: () => {
      history.push('/user-profile');
    },
    logout: () => {
      let redirectionUrl = '';
      if (user.backend) redirectionUrl = '/admin-login';
      else redirectionUrl = '/login';
      // localStorage.clear();
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      dispatch(clearUser());
      history.push(redirectionUrl);
    },
  };
  const onDrpDownClick = ({ key }) => {
    clickEvents[key]();
  };

  const fetchActivePatientCount = (searchQuery) => {
    if (
      user.userType == 'RECEPTIONIST' ||
      user.userType == 'DOCTOR' ||
      user.userType == 'NURSE'
    ) {
      getActivetPatients(searchQuery)
        .then((res) => {
          if (res.status == 200) {
            setCount(res.data.total);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    }
  };

  useEffect(() => {
    if (user.profilePic) {
      setProfilePic(PROFILE_IMAGE_PREFIX + user.profilePic);
    }
    let searchQuery = `pageNo=${0}&size=${15}&timeZone=${moment.tz.guess()}`;
    fetchActivePatientCount(searchQuery);
  }, [user]);

  return (
    <AntdLayout>
      <StyledHeader bg={theme['@primary-color']} className="main_header_layout">
        <StyledRow justify="space-between" className="header_row_path">
          <Col className="header_main_logo">
            <img
              onClick={() => {
                history.push('/');
              }}
              // src={images.logo ? PROFILE_IMAGE_PREFIX + images.logo : Logo}
              src={MeditagLogo}
              style={{ width: '100%', cursor: 'pointer' }}
            />
          </Col>
          <Col className="header_user_data">
            <HeaderRightWrapper>
              {hasPrivilege(undefined, [
                'DOCTOR',
                'RECEPTIONIST',
                'NURSE',
                'LABORATORY',
                'RADIOLOGY',
                'PHARMACY',
                'INSURANCE',
              ]) && (
                <>
                  <Col>
                    <Tooltip title="Messages">
                      <Button
                        type="primary"
                        shape="circle"
                        // icon={
                        //   <Badge count={totalSMS} size="small">
                        //     <MessageFilled />
                        //   </Badge>
                        // }
                        icon={<MessageFilled />}
                        size="large"
                        onClick={() => history.push(`/sms-list`)}
                      />
                    </Tooltip>
                  </Col>

                  <ActivePatientCountWrapper>
                    <ActivePatientsText>
                      {user.userType == 'INSURANCE'
                        ? 'Claim Pending'
                        : 'Active Patients'}
                    </ActivePatientsText>
                    <ActivePatientCountValue color={theme['@primary-color']}>
                      {/* {JSON.stringify(patientActiveCount.activePatientCount)} */}
                      {/* {count} */}
                      {console.log('active Count', activeCount)}
                      {hasPrivilege(undefined, [
                        'DOCTOR',
                        'LABORATORY',
                        'RADIOLOGY',
                        'PHARMACY',
                        'INSURANCE',
                      ]) ? (
                        <>
                          {/* need to check from where Doctor and reception count is coming */}
                          {user.userType == 'DOCTOR' &&
                            activeCount?.activePatientCount}
                          {user.userType == 'LABORATORY' &&
                            activeCount?.activeLaboratoryCount}
                          {user.userType == 'RADIOLOGY' &&
                            activeCount?.activeRadiologyCount}
                          {user.userType == 'PHARMACY' &&
                            activeCount?.activePharmacyCount}
                          {user.userType == 'INSURANCE' &&
                            activeCount?.activeInsuranceCount}
                        </>
                      ) : (
                        activeCount?.activePatientCount
                      )}

                      {ActiveCount()}
                    </ActivePatientCountValue>
                  </ActivePatientCountWrapper>
                </>
              )}
              <Col>
                <Dropdown
                  // overlayClassName="loged_dropdown"
                  overlay={menu(onDrpDownClick)}
                  placement="bottomCenter"
                  trigger="click"
                  overlayStyle={{ width: '170px' }}
                  getPopupContainer={(node) => {
                    return node.childNodes[0].childNodes[0].childNodes[1];
                  }}
                >
                  <a style={{ height: '70px' }}>
                    <ProfileDropdown user={user} profilePic={profilePic} />
                  </a>
                </Dropdown>
              </Col>
            </HeaderRightWrapper>
          </Col>
        </StyledRow>
      </StyledHeader>
      <StyledContent id="main_content_layout">{children}</StyledContent>
    </AntdLayout>
  );
};

export default Layout;
