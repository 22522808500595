import React, { useState } from 'react';
import { Row, Col, Form, message, Select } from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { addUser, editUser } from '../../../../apis/User';
import { BodyContainer } from '../Sections/styledComponents';
import { SectionHeading, SectionHeadContainer } from '../styledComponents';
import * as Sentry from '@sentry/react';
import PatientDetailsForm from './PatientDetailsForm.jsx';
import UserDetailsForm from './UserDetailsForm.jsx';
import { SelectRoleMessage, StyledContent } from './styledComponents';
import { fetchBloodGroup } from '../../../../apis/Auth';

const { Option } = Select;

const CreateUser = ({
  departments,
  setVisible,
  loading,
  edit,
  setEdit,
  trigger,
  roles,
  selectedItem,
  setSelectedItem,
  getUsers,
}) => {
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [bloodGroup, setBloodGroup] = useState();
  const [disabled, setDisabled] = useState(true);
  const [availability, setAvailability] = useState([]);
  useEffect(() => {
    if (edit) {
      const role = roles.find((item) => item.name === selectedItem.role.name);
      setSelectedRole(role);
    } else {
    }
  }, [edit]);

  useEffect(() => {
    form.resetFields();
  }, [trigger]);

  useEffect(() => {
    fetchBloodGroup('BLOOD_GROUP')
      .then((res) => {
        if (res.data.status === 1) {
          setBloodGroup(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, []);

  const submitHandler = (values) => {
    // setSubmitLoading(true);

    var payload = [];
    if (selectedRole.name.toUpperCase() === 'PATIENT') {
      payload = {
        additionalInfo: {
          alternateContactDetails: values.alternateContactDetails,
          phoneType: values.phoneType,
          citizenship: values.citizenship,
          emailType: values.emailType,
          id: 0,
          maritalStatus: values.maritalStatus,
          nationality: values.nationality,
          primaryLanguage: values.primaryLanguage,
          race: values.race,
          religion: values.religion,
          deathStatus: values.deathStatus === 'Yes' ? true : false,
          deathDate: values.deathDate,
          // vip: values.vip,
        },
        addresses: values.addresses && values.addresses,
        bloodGroupId: values.bloodGroup,
        phoneNumbers:
          values.phoneNumbers &&
          values.phoneNumbers.map((number) => {
            return {
              phoneType: number.phoneType,
              ...{
                countryCode: number.phoneNumber.countryCode,
                phoneNumber: number.phoneNumber.phoneNumber,
              },
            };
          }),
        dob: values.dob,
        identityDocuments: values.identityDocuments && values.identityDocuments,
        email: values.email,
        firstName: values.firstName,
        gender: values.gender,
        lastName: values.lastName,
        mobileNo: values.mobileNo,
        nationality: values.nationality,
        captcha: values.captcha,
        roleType: values.roleType,
        status: values.status,
        userType: values.userType,
        userTypeId: values.idType,
        userTypeId: selectedRole.id,
        password: 'abcd',
      };
    } else {
      if (availability.length === 0) {
        message.error('Please select time');
        return;
      }
      payload = {
        additionalInfo: {
          alternateContactDetails: [
            {
              phoneNumber: values.alternateContactNo,
            },
          ],

          phoneType: values.phoneType,
          citizenship: values.citizenship,
          emailType: values.emailType,
          id: 0,
          maritalStatus: values.maritalStatus,
          nationality: values.nationality,
          primaryLanguage: values.primaryLanguage,
          race: values.race,
          religion: values.religion,
          deathStatus: values.deathStatus === 'Yes' ? true : false,
          deathDate: values.deathDate,
          // vip: values.vip,
        },
        alternateContactNo: values.alternateContactNo,
        departmentIds: form.getFieldValue('departments'),
        privilegesId: values.privilegesId,

        addresses: values.addresses && values.addresses,
        phoneNumbers:
          values.phoneNumbers &&
          values.phoneNumbers.map((number) => {
            return {
              phoneType: number.phoneType,
              ...{
                countryCode: number.phoneNumber.countryCode,
                phoneNumber: number.phoneNumber.phoneNumber,
              },
            };
          }),
        availability: availability,
        dob: values.dob,
        identityDocuments: values.identityDocuments && values.identityDocuments,
        email: values.email,
        firstName: values.firstName,
        gender: values.gender,
        // startTime: new Date(values.startTime).getTime(),
        // endTime: new Date(values.endTime).getTime(),
        fee: values.fee,
        consultationTime: values.consultationTime,
        lastName: values.lastName,
        mobileNo: values.mobileNo,
        nationality: values.nationality,
        captcha: values.captcha,
        roleType: values.roleType,
        status: values.status,
        userType: values.userType,
        receptionistAddress: values.receptionistAddress,
        userTypeId: values.idType,
        userTypeId: selectedRole.id,
        password: 'abcd',
      };
    }
    if (
      (selectedRole.name.toUpperCase() === 'NURSE' ||
        selectedRole.name.toUpperCase() === 'DOCTOR') &&
      !form.getFieldValue('departments')
    ) {
      message.error('Please select Department');
      setSubmitLoading(false);
    } else {
      if (edit) {
        setSubmitLoading(false);
        console.log('payload s', payload);
        editUser(selectedItem.id, payload)
          .then((resp) => {
            if (resp.data.status === 1) {
              message.success('User Updated Successfully.');
              setSubmitLoading(false);
              getUsers();
            } else {
              message.error('Could not update User, Try Again');
              setSubmitLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
            message.error('Could not update User, Try Again');
            setSubmitLoading(false);
          })
          .finally(() => {
            setVisible(false);
            setEdit(false);
          });
      } else {
        addUser(payload)
          .then((resp) => {
            if (resp.data.status === 1) {
              message.success('User Created Successfully.');
              setSubmitLoading(false);
              setVisible(false);
              getUsers();
            } else {
              message.error('User already exist');
              setSubmitLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
            message.error('Could not create User, Try Again');
            setSubmitLoading(false);
          })
          .finally(() => {
            setVisible(false);
            setEdit(false);
          });
      }
    }
  };

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        role: selectedRole.id,
        departments:
          selectedItem.departments &&
          selectedItem.departments.map((item) => item.id),
      });
    }
  }, [selectedRole]);

  const valuesChangeHandler = (changed, all) => {
    if (changed.role) form.setFieldsValue({ department: null });
    if (changed.departments) setDisabled(false);
  };

  return (
    <>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading>
                <LeftCircleOutlined
                  onClick={() => {
                    setVisible(false);
                    setEdit(false);
                  }}
                  style={{ marginRight: '10px' }}
                />
                Create User
              </SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <BodyContainer>
        <StyledContent>
          <Form
            layout="vertical"
            form={form}
            style={{ width: '100%' }}
            onValuesChange={valuesChangeHandler}
            className="form-m2-style"
            scrollToFirstError={true}
          >
            <Row justify="left" gutter={[36, 24]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="role"
                  label="User Type"
                  rules={[{ required: true }]}
                >
                  <Select
                    size="large"
                    placeholder="Select Role"
                    style={{ width: '100%' }}
                    onSelect={(value) => {
                      setSelectedRole(roles.find((item) => item.id === value));
                    }}
                    disabled={edit ? true : false}
                  >
                    {roles.map((role) => {
                      return (
                        <Option value={role.id} key={role.id}>
                          {role.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {selectedRole && selectedRole.departmentRelated && (
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="departments"
                    label="Department"
                    rules={[{ required: true }]}
                  >
                    <Select
                      mode="multiple"
                      size="large"
                      placeholder="Select Department"
                      style={{ width: '100%' }}
                    >
                      {departments.map((department) => {
                        return (
                          <Select.Option
                            value={department.id}
                            key={department.id}
                          >
                            {department.departmentName}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
          <Row>
            {selectedRole &&
            roles.find((item) => item.id === selectedRole.id) ? (
              roles
                .find((item) => item.id === selectedRole.id)
                .name.toUpperCase() === 'PATIENT' ? (
                <>
                  <PatientDetailsForm
                    submitLoading={submitLoading}
                    selectedItem={selectedItem}
                    submitHandler={submitHandler}
                    setVisible={setVisible}
                    setSelectedItem={setSelectedItem}
                    message={'from admin user'}
                  />
                </>
              ) : (
                <UserDetailsForm
                  departments={departments}
                  submitLoading={submitLoading}
                  submitHandler={(values) => {
                    submitHandler(values);
                  }}
                  setAvailability={setAvailability}
                  setVisible={setVisible}
                  selectedId={selectedRole.id}
                  edit={edit}
                  selectedItem={selectedItem}
                  availability={availability}
                  role={
                    roles.find((item) => item.id === selectedRole.id) &&
                    roles
                      .find((item) => item.id === selectedRole.id)
                      .name.toUpperCase()
                  }
                  disabled={disabled}
                  setDisabled={setDisabled}
                />
              )
            ) : (
              <SelectRoleMessage>
                Select a User Type to continue
              </SelectRoleMessage>
            )}
          </Row>
        </StyledContent>
      </BodyContainer>
    </>
  );
};

export default CreateUser;
