import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { Row, Col, Button, message, Form, Popconfirm, Divider } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as Avatar } from "../../../../assets/icons/image-upload-avatar.svg";
import Spinner from "../../../utils/Spinner";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";
import { getOneByID } from "../../../../apis/patients";
import { getAppointmentDetails } from "../../../../apis/Appointment";
import { genderList } from "../../../Datasets/basicDetails";
import * as Sentry from "@sentry/react";
import { nationality } from "../../../Datasets/nationality";

// import ViewDetailsModal from "./ViewDetailsModal";

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;
export const ImageContainer = styled.div`
  width: 90px;
  height: 90px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
const StyledIcon = styled(Icon)``;

const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
  margin-top: 20px;
`;

const PatientDetailsCol = styled(Col)`
  margin-top: 5px;
`;

const ProfileDetailsContainer = styled.div`
  width: 200px;
`;

const Patient = () => {
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const [loading, setLoading] = useState(true);
  const [patientData, setPatientData] = useState();
  const [profilePic, setProfilePic] = useState();

  const [visible, setVisible] = useState(false);
  const theme = useSelector((state) => state.themeReducer);

  const submitFormData = () => {};
  const getGender = (code) => {
    return genderList().find((item) => {
      if (item.code === code) {
        return item;
      }
    });
  };
  const getCountry = (code) => {
    return nationality().find((item) => {
      if (item.code === code) {
        return item;
      }
    });
  };
  useEffect(() => {
    if (patient) {
      setLoading(false);
      if (patient.appointmentId) {
        getAppointmentDetails(patient.appointmentId)
          .then((response) => {
            if (response.data.status === 1) setPatientData(response.data.body);
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
            message.error("Could not fetch appointment details,Try again");
          });
      }
    }
  }, [patient]);

  const formatUserName = (pName) => {
    return pName
      .split("-")
      .map((item) => {
        return item.charAt(0)?.toUpperCase() + item.slice(1);
      })
      .join(" ");
  };

  return !loading ? (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <FormContainer>
          <Row justify="left">
            <Col span={3}>
              <ImageContainer
                bgImage={
                  patient.profilePic &&
                  PROFILE_IMAGE_PREFIX + patient.profilePic
                }
                color={theme["@primary-color"]}
              >
                {!patient.profilePic && (
                  <StyledIcon
                    component={Avatar}
                    style={{ fontSize: "70px", margin: "auto" }}
                  />
                )}
              </ImageContainer>
            </Col>
          </Row>
          <StyledH3 color={theme["@primary-color"]}>
            General Informations
          </StyledH3>
          <Row>
            <PatientDetailsCol span={9}>First Name</PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>{patient.firstName}</PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>Last Name:</PatientDetailsCol>

            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>{patient.lastName}</PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>Gender:</PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>
              {getGender(patient.gender)?.text}
            </PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>Date of birth:</PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>
              {moment(patient.dob).format("DD-MM-YYYY")}
            </PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>Age</PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>
              {moment().diff(moment(patient.dob), "years", false)}
            </PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>Nationality</PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>
              {getCountry(patient.nationality)?.text}
            </PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>Mobile No</PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>
              {patient.phoneNumbers[0]?.countryCode?.split(" ")[0] +
                "-" +
                patient.phoneNumbers[0]?.phoneNumber}
            </PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>
              Alternative Contact Number
            </PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>
              {patient.additionalInfo?.alternateContactDetails[0]?.phoneNumber?.countryCode?.split(
                " "
              )[0] +
                "-" +
                patient.additionalInfo?.alternateContactDetails[0]?.phoneNumber
                  ?.phoneNumber}
            </PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>Email</PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>{patient.email}</PatientDetailsCol>
          </Row>
          <Row>
            <PatientDetailsCol span={9}>Next Of Kin</PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>
              {patient.additionalInfo?.alternateContactDetails[0]?.fullName}
            </PatientDetailsCol>
          </Row>
          {/* <Row>
            <PatientDetailsCol span={9}>
              Father/Husband/Spouse Name
            </PatientDetailsCol>
            <PatientDetailsCol span={1}>:</PatientDetailsCol>
            <PatientDetailsCol span={10}>
              {patient.guardianPhoneNo.countryCode.split(" ")[0] +
                "-" +
                patient.guardianPhoneNo.phoneNumber[0]}
            </PatientDetailsCol>
          </Row> */}
          <Divider />
          <StyledH3 color={theme["@primary-color"]}>Address Details</StyledH3>
          {patient.addresses && (
            <>
              <Row>
                <PatientDetailsCol span={9}>House/Flat No </PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol span={10}>
                  {patient.addresses[0]?.houseFlatNo}
                </PatientDetailsCol>
              </Row>

              <Row>
                <PatientDetailsCol span={9}>
                  Nearest Landmark/Plot
                </PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol span={10}>
                  {patient.addresses[0]?.landmark}
                </PatientDetailsCol>
              </Row>

              <Row>
                <PatientDetailsCol span={9}>City</PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol span={10}>
                  {patient.addresses[0]?.city}
                </PatientDetailsCol>
              </Row>

              <Row>
                <PatientDetailsCol span={9}>Post Box Number</PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol span={10}>
                  {patient.addresses[0]?.postBox}
                </PatientDetailsCol>
              </Row>

              <Row>
                <PatientDetailsCol span={9}>Country</PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol span={10}>
                  {
                    getCountry(
                      patient.addresses && patient.addresses[0]?.country
                    )?.text
                  }
                </PatientDetailsCol>
              </Row>
            </>
          )}
          <Divider />
          <StyledH3 color={theme["@primary-color"]}>Insurance Details</StyledH3>

          {patientData && patientData && (
            <>
              <Row>
                <PatientDetailsCol span={9}>Provider Name </PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol span={10}>
                  {patientData.insuranceData?.insuranceProvider?.name}
                </PatientDetailsCol>
              </Row>

              <Row>
                <PatientDetailsCol span={9}>Insurance Number</PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol span={10}>
                  {patientData.insuranceData?.number}
                </PatientDetailsCol>
              </Row>

              <Row>
                <PatientDetailsCol span={9}>Expiry Date</PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol span={10}>
                  {moment(patientData.insuranceData?.expireDate).format(
                    "DD-MM-YYYY"
                  )}
                </PatientDetailsCol>
              </Row>

              <Row>
                <PatientDetailsCol span={9}>Insurance Type</PatientDetailsCol>
                <PatientDetailsCol span={1}>:</PatientDetailsCol>
                <PatientDetailsCol
                  span={10}
                  style={{ textTransform: "capitalize" }}
                >
                  {patientData?.insuranceData?.insuranceProvider?.hospitalInsuranceProviderData?.insuranceType?.toLowerCase()}
                </PatientDetailsCol>
              </Row>
            </>
          )}
        </FormContainer>
      </Col>
    </Row>
  ) : (
    <Spinner />
  );
};
export default Patient;
