import React, { useState, useEffect } from 'react';
import {
  SectionHeadContainer,
  SectionHeading,
  StyledDiv,
} from '../styledComponents';
import { NewButton, BodyContainer, StyledBodyTitle } from './styledComponents';
// import SectionManagementTable from "./SectionManageMentTable";
import { formColumns, fieldsColumn } from './tableConfig';
import { ReactComponent as EditIcon } from '../../../../assets/icons/Edit.svg';
import * as Sentry from '@sentry/react';
import { Row, Col, Button, message, notification } from 'antd';
import Icon, { LeftCircleOutlined } from '@ant-design/icons';
import NewFormModal from './NewFormModal';
import NewFieldModal from './NewFieldModal';
import {
  changeSectionOrder,
  fetchSubSectionFields,
} from '../../../../apis/healthRecord';
import ConfirmationModal from './ConfirmationModal';
import { useSelector } from 'react-redux';
import PreviewModal from '../Sections/PreviewModal';
import SectionManagementTable from '../Sections/SectionManageMentTable';
const FieldManamgement = ({ setTab, selectedForm, selectedSection }) => {
  const [loading, setLoading] = useState(false);
  const [newFormModal, setNewFormModal] = useState(false);
  const [newFieldModal, setNewFieldModal] = useState(false);
  const [changesOrderStatus, setChangeOrderStatus] = useState(false);
  const [fields, selectedFields] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [total, setTotals] = useState(0);
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);

  const handleOrderChangeClick = () => {
    if (changesOrderStatus) {
      let newOrder = fields.map((item, index) => ({
        formId: item.id,
        formOrder: index + 1,
      }));
      changeSectionOrder({ formOrderChanges: newOrder })
        .then(({ data, status }) => {
          if (data.status == 1) {
            notification.success({
              message: 'New Order saved!',
            });
            setChangeOrderStatus(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    } else {
      setChangeOrderStatus(true);
    }
  };

  const newFieldClickHandler = () => {
    setNewFieldModal(true);
  };
  const getFormFields = () => {
    setLoading(true);
    fetchSubSectionFields(selectedForm.id)
      .then(({ data, status }) => {
        console.log('fetch form -2', data);

        if (status === 200) {
          if (data.status === 1) {
            selectedFields(data.body);
            setTotals(data.body.length);
          } else message.error(data.body);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getFormFields();
  }, []);

  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row>
            <Col span={24}>
              <SectionHeading>
                <span style={{ color: 'gray' }}>Observations</span>
                <LeftCircleOutlined
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                  onClick={() => {
                    setTab('forms');
                  }}
                />
                Manage Fields
              </SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <Row gutter={[0, 18]}>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <Row justify="space-between">
                <Col span={12}>
                  <StyledBodyTitle level={4} color={theme['@primary-color']}>
                    {selectedForm.formName}
                  </StyledBodyTitle>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    onClick={newFieldClickHandler}
                    style={{ border: 0, boxShadow: 'none' }}
                  >
                    Create New Field
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    style={{ border: 0, boxShadow: 'none' }}
                    onClick={() => {
                      if (fields?.length > 0) setPreviewModal(true);
                    }}
                  >
                    Preview
                  </Button>
                  <Button
                    type={'primary'}
                    ghost={!changesOrderStatus}
                    onClick={handleOrderChangeClick}
                    size="large"
                    style={{ border: 0, boxShadow: 'none', width: '140px' }}
                  >
                    {changesOrderStatus ? 'Save Order' : 'Change Order'}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ padding: 0 }}>
              <StyledDiv bgcolor={theme['@primary-color']}>
                <div>&nbsp;&nbsp;Fields</div>
                <div>&nbsp;&nbsp;</div>
              </StyledDiv>
              <SectionManagementTable
                dataSource={fields}
                columns={fieldsColumn(
                  setSelectedItem,
                  setConfirmationModal,
                  setNewFieldModal
                )}
                loading={loading}
                setSelectedItem={setSelectedItem}
                draggable={changesOrderStatus}
                setDataSource={selectedFields}
              />
            </Col>
          </Row>
        </BodyContainer>
      </Col>
      <NewFormModal
        visible={newFormModal}
        setVisible={setNewFormModal}
        selectedSection={selectedSection}
        selectedForm={selectedForm}
      />
      <NewFieldModal
        visible={newFieldModal}
        setVisible={setNewFieldModal}
        selectedForm={selectedForm}
        refetch={() => getFormFields()}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        allFields={fields}
        total={total}
      />
      <ConfirmationModal
        visible={confirmationModal}
        setVisible={setConfirmationModal}
        selectedItem={selectedItem}
        refetch={() => getFormFields()}
      />
      <PreviewModal
        visible={previewModal}
        setVisible={setPreviewModal}
        formName={selectedForm?.formName}
        fields={fields}
      />
    </Row>
  );
};

export default FieldManamgement;
