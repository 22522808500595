import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";
import { CloseCircleOutlined } from "@ant-design/icons";

const ModalBodyContainer = styled.div`
  width: 500px;
  @media (max-width: 640px) {
    width: 250px;
  } ;
`;
const ConfirmationModal = ({ visible, setVisible, onConfirm }) => {
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <Row justify="end">
        <CloseCircleOutlined
          style={{
            fontSize: "20px",
            color: "red",
            right: "-7px",
            top: "-7px",
            cursor: "pointer"
          }}
          onClick={() => {
            setVisible(false);
          }}
        />
      </Row>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="success"
              title="Confirm Submit ?"
              description="By submitting this health record will be added permanently."
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVisible(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                onConfirm();
                setVisible(false);
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default ConfirmationModal;
