import { Button, Col, Row, Form, notification, Space, message } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  fetchHL7Listeners,
  createListener,
  deleteListener,
  updateListener,
} from "../../../../apis/Hl7";
import { BodyContainer } from "../Sections/styledComponents";
import {
  SectionHeadContainer,
  SectionHeading,
  StyledDiv,
} from "../styledComponents";
import ConfirmationModal from "./ConfirmationModal";
import Hl7Table from "./Hl7TableForm";
import ListenerForm from "./ListenerForm";
import { NewUserButton } from "./styledComponent";

const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
`;
const Hl7 = () => {
  const [listeners, setListeners] = useState();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newListenerModal, setNewListenerModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const theme = useSelector((state) => state.themeReducer);

  const getHl7Listeners = () => {
    fetchHL7Listeners()
      .then(({ data, status }) => {
        if (data.status === 1) {
          setListeners(data.body);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };
  const addListenerForm = (data) => {
    createListener(data).then((data) => {
      if (data.data.status === 1) {
        message.success(data.data.body);
        setNewListenerModal(false);
        setLoading(true);
        getHl7Listeners();
      } else {
        message.error("Failed to add the listener");
      }
    });
  };
  const updateListenerForm = (payload) => {
    updateListener(payload).then((data) => {
      if (data.data.status === 1) {
        setLoading(true);
        getHl7Listeners();
        setEdit(!edit);
      }
    });
  };
  const deleteHl7Listener = () => {
    deleteListener(selectedItem.id)
      .then((data) => {
        message.success("successfully deleted the listener");
        setDeleteModal(false);
        setLoading(true);
        getHl7Listeners();
      })
      .catch((error) => {
        message.error("Failed to delete the listener");
      });
  };
  useEffect(() => {
    setLoading(true);
    getHl7Listeners();
  }, []);
  const handleEdit = () => {
    setEdit(false);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between" align="bottom">
              <Col>
                <SectionHeading>HL7 Listeners </SectionHeading>
              </Col>
              <Col>
                <NewUserButton
                  type="primary"
                  // ghost
                  size="large"
                  onClick={() => {
                    setNewListenerModal(true);
                  }}
                >
                  New Listener
                </NewUserButton>
              </Col>
            </Row>
          </SectionHeadContainer>
        </Col>
        <Col span={24}>
          <BodyContainer>
            <StyledDiv bgcolor={theme["@primary-color"]}>
              <div>&nbsp;&nbsp;HL7 Listeners</div>
              <div>&nbsp;&nbsp;</div>
            </StyledDiv>
            <Hl7Table
              forms={listeners}
              loading={loading}
              setSelectedItem={setSelectedItem}
              setDeleteModal={setDeleteModal}
              setVisible={setEdit}
              handleEdit={setEdit}
              edit={edit}
            />
          </BodyContainer>
        </Col>
      </Row>

      <ListenerForm
        visible={edit ? edit : newListenerModal}
        edit={edit}
        addListenerForm={addListenerForm}
        updateListenerForm={updateListenerForm}
        setVisible={setNewListenerModal}
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        selectedItem={selectedItem}
        handleEdit={setEdit}
      />
      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        deleteForm={deleteListener}
        deleteHl7Listener={deleteHl7Listener}
      />
    </>
  );
};

export default Hl7;
