import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, message } from 'antd';
import { loginAPI } from '../../apis/Auth';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUser } from '../../redux/actions/user';
import * as Sentry from '@sentry/react';

const StyledForm = styled(Form)`
  text-align: left;
  &.ant-form-vertical .ant-form-item-label > label {
    height: auto;
  }
  & .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }
`;
const SubmitButton = styled(Button)`
  width: 100%;
  font-weight: 700;
  height: 50px;
  border-radius: 5px;
`;

const StyledInput = styled(Input)`
  border-radius: 5px;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
`;
const StyledPasswordInput = styled(Input.Password)`
  border-radius: 4px;
  font-size: 13px;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 25px;
`;
const AdminLoginForm = ({ setForgotPasswordModalVisible }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    loginAPI(values)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 1) {
            if (res.data.body.userType === 'ADMIN') {
              localStorage.setItem('user', JSON.stringify(res.data.body));
              localStorage.setItem('token', res.data.body.token);
              dispatch(addUser(res.data.body));
              history.push('/');
            } else if (res.data.body.userType === 'ORG_ADMIN') {
              localStorage.setItem('user', JSON.stringify(res.data.body));
              localStorage.setItem('token', res.data.body.token);
              dispatch(addUser(res.data.body));
              history.push('/org-dashboard');
            }
          } else {
            message.error(res.data.body);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error('Invalid username or Password');
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onForgotPassword = () => {
    setForgotPasswordModalVisible(true);
  };

  return (
    <StyledForm
      name="admin-login-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      size="large"
      scrollToFirstError={true}
      wrapperCol={{ gutter: 36 }}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Enter valid email !',
            type: 'email',
          },
        ]}
      >
        <StyledInput placeholder="abc@example.com" size="large" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        style={{ marginBottom: '0px' }}
      >
        <StyledPasswordInput placeholder="*******" size="large" />
      </Form.Item>
      <Form.Item>
        <Button
          size="small"
          type="link"
          style={{ float: 'right', marginTop: '10px', color: '#000' }}
          onClick={onForgotPassword}
        >
          Forgot Password ?
        </Button>
      </Form.Item>
      <Form.Item>
        <SubmitButton
          htmlType="submit"
          loading={loading}
          size="large"
          type="primary"
          className="btn-save"
        >
          Login
        </SubmitButton>
      </Form.Item>
    </StyledForm>
  );
};

export default AdminLoginForm;
