import React, { useState, useEffect } from "react";
import { Col, Row, Form, Input, Select } from "antd";
import {
  RightButton,
  StyledDatePicker,
  StyledH3,
  StyledInputNumber,
  FormContainer,
} from "./styledComponents";
import { CaretDownOutlined } from "@ant-design/icons";
import { getCountries } from "../../../apis/publicApis";

const Option = Select.Option;

function PatientProfileForm({
  formInitialValues,
  onSubmit,
  disabled,
  setDisabled,
  userType,
  submitLoading,
  logOut,
}) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries().then((resp) => {
      setCountries(
        resp.data.map((country) => ({
          code: country.callingCodes[0],
          name: country.name,
        }))
      );
    });
  }, []);

  const capitalize = (camelCaseText) => {
    return (
      camelCaseText.charAt(0).toUpperCase() +
      camelCaseText
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(" ")
    );
  };

  return (
    <FormContainer className="form-m2-style">
      {" "}
      <Form
        className="form-m2-style"
        layout="vertical"
        onFinish={(values) => onSubmit(values)}
        initialValues={formInitialValues}
        key={Object.keys(formInitialValues).length !== 0}
        scrollToFirstError={true}
      >
        <Row gutter={[36, 0]}>
          <Col span="12">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(
                    /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
                  ),
                  message: "Please enter alphabets only.",
                },
              ]}
            >
              <Input size="large" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(
                    /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
                  ),
                  message: "Please enter alphabets only.",
                },
              ]}
            >
              <Input size="large" disabled={disabled} />
            </Form.Item>
          </Col>
          <>
            <Col span="12">
              <Form.Item label="Primary Contact Number">
                <Input.Group compact size="large">
                  <Form.Item
                    name={["mobileNo", "countryCode"]}
                    rules={[
                      { required: true, message: "Country Code is required" },
                    ]}
                    noStyle
                  >
                    <Select
                      size="large"
                      suffixIcon={<CaretDownOutlined showSearch />}
                      placeholder="Select Country code"
                      style={{ width: "30%" }}
                      showSearch
                      disabled={disabled}
                      // optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      //   || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {countries.map((item) => (
                        <Select.Option
                          value={"+" + item.code + " " + item.name}
                          key={item.name}
                        >
                          {"+" + item.code + " " + capitalize(item.name)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={["mobileNo", "phoneNumber"]}
                    noStyle
                    rules={[
                      { required: true, message: "Phone number is required" },
                    ]}
                  >
                    <Input
                      style={{ width: "65%", marginLeft: "2%" }}
                      placeholder="Primary Contact Number"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Secondary Contact Number">
                <Input.Group compact size="large">
                  <Form.Item
                    name={["alternateContactNo", "countryCode"]}
                    rules={[
                      { required: true, message: "Country Code is required" },
                    ]}
                    noStyle
                  >
                    <Select
                      size="large"
                      suffixIcon={<CaretDownOutlined showSearch />}
                      placeholder="Select Country code"
                      style={{ width: "30%" }}
                      showSearch
                      disabled={disabled}

                      // optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      //   || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {countries.map((item) => (
                        <Select.Option
                          value={"+" + item.code + " " + item.name}
                          key={item.name}
                        >
                          {"+" + item.code + " " + capitalize(item.name)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={["alternateContactNo", "phoneNumber"]}
                    noStyle
                    rules={[
                      { required: true, message: "Phone number is required" },
                    ]}
                  >
                    <Input
                      style={{ width: "65%", marginLeft: "2%" }}
                      placeholder="Secondary Contact Number"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </>
          {userType === "RECEPTIONIST" && (
            <Col span="24">
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea disabled={disabled} />
              </Form.Item>
            </Col>
          )}
          <Col span="24">
            <Form.Item>
              <RightButton
                htmlType="submit"
                type="primary"
                loading={submitLoading}
                disabled={disabled}
                className="btn-save"
              >
                Submit
              </RightButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
}

export default PatientProfileForm;
