import React, { useState } from "react";
import LabTypes from "./LabTypes";
import LabTestTypes from "./LabTestTypes";
import LabTests from "./LabTests";
import TestFieldManagement from "./LabTestsFields";
const Lab = () => {
  const [tab, setTab] = useState("lab");
  const [selectedSection, setSelectedSection] = useState("section");
  const [selectedField, setSelectedField] = useState();
  const [totalForms, setTotalForms] = useState(0);
  const [selectedForm, setSelectedForm] = useState();

  switch (tab) {
    case "lab":
      return (
        <LabTypes
          setTab={setTab}
          setSelectedSection={setSelectedSection}
          selectedSection={selectedSection}
        />
      );
    case "lab_test_type":
      return (
        <LabTestTypes
          setTab={setTab}
          selectedSection={selectedSection}
          selectedLabTestTypes={selectedForm}
          setSelectedLabTestTypes={setSelectedForm}
        />
      );
    case "lab_test":
      return (
        <LabTests
          setTab={setTab}
          selectedForm={selectedForm}
          selectedSection={selectedSection}
          totalForms={totalForms}
          setSelectedField={setSelectedField}
        />
      );
    case "lab_test_fields":
      return (
        <TestFieldManagement
          setTab={setTab}
          selectedForm={selectedForm}
          selectedSection={selectedSection}
          totalForms={totalForms}
          selectedField={selectedField}
        />
      );
    default:
      return <LabTypes />;
  }
};

export default Lab;
