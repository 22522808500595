import React from "react";
import { Button, Table, Row } from "antd";
import styled from "styled-components";

export const NewButton = styled(Button)`
  height: 50px;
  width: 250px;
  font-size: 20px;
  border-radius: 7px;
`;

export const StyledHeading = styled.h2`
  font-weight: 600;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 30px;
`;

export const BlueBgTable = styled(Table)`
  & .ant-table-thead > tr > th {
    color: #fff;
    background: ${(props) => props.headBg};
    font-size: 14px;
    font-weight: 600;
  }

  & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  }
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #9e9292;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #9e9292;
  }

  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    position: relative;
    padding: 8px 16px;
    overflow-wrap: break-word;
  }
`;

export const EmptyMessageContainer = styled.div`
  height: 200px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background : white;
`;
