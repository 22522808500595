import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Login from './components/Login';
import Content from './components/Content';
import ResetPassword from './components/ResetPassword';
import { addUser } from './redux/actions/user';
import AdminLogin from './components/AdminLogin';
import { getSettings } from './apis/settings';
import { applyTheme } from './redux/actions/theme';
import { addImages } from './redux/actions/appImages';
import Spinner from './components/utils/Spinner';
import SelfRegistration from './components/SelfRegistration';
import { setSettings } from './redux/actions/settings';
import NotFoundPage from './components/utils/NotFound/Notfound';
import * as Sentry from '@sentry/react';
import NewPatientForm from './components/NewPatientForm';
import BookVisitPage from './components/NewPatientForm/BookVisitPage';
import AppointmentSuccessPage from './components/NewPatientForm/AppointmentSuccessPage';
import PhoneNumberLogin from './components/PhoneNumberLogin';
import VerifyOTPForm from './components/PhoneNumberLogin/VerifyOtpForm';

function App() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userReducer);
  const theme = useSelector((state) => state.themeReducer);

  const getAppSettings = () => {
    getSettings().then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          if (data.body.theme)
            dispatch(applyTheme({ '@primary-color': data.body.theme }));
          dispatch(
            addImages({
              logo: data.body.hospitalLogo,
              cover: data.body.coverPhoto,
              contactText: data.body.contactUsMessage,
              settings: data.body,
            })
          );
          dispatch(setSettings(data.body));
        }
      }
    });
  };

  useEffect(() => {
    if (theme['@primary-color'])
      window.less
        .modifyVars(theme)
        .then((res) => {
          console.log('sucess', res);
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
  }, [theme]);
  useEffect(() => {
    getAppSettings();
    let user = localStorage.getItem('user');
    if (user && Object.entries(JSON.parse(user)).length) {
      dispatch(addUser(JSON.parse(user)));
    }
  }, []);
  return theme['@primary-color'] ? (
    <Switch>
      <Route path="/:hId/:id?/new-patient-form" component={PhoneNumberLogin} />
      <Route path="/:hId/:id?/patient-form" component={NewPatientForm} />

      <Route
        path="/appointment-success-message"
        component={AppointmentSuccessPage}
      />

      <Route path="/login" component={Login} />
      <Route path="/reset-password/:token" component={ResetPassword} />
      <Route path="/admin-login" component={AdminLogin} />
      {/* <Route path="/:hId/:id?/login-with-phone" component={PhoneNumberLogin} /> */}
      {/* <Route path="/verify-otp-form" component={VerifyOTPForm} /> */}
      <Route
        path="/book-visit-new-patient/:patientId/:patientName"
        component={BookVisitPage}
      />
      <Route path="/self-registration" component={SelfRegistration} />
      <Route path="/" render={() => <Content />} />

      <Route component={NotFoundPage} />
      {/* <Redirect to="/login" /> */}
    </Switch>
  ) : (
    <Spinner height="100vh" />
  );
}

export default App;
