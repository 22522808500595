import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import AdminLoginForm from "./AdminLoginForm";
import ForgotPasswordModal from "../ForgotPasswordModal";
import HospitalLogo from "../Login/HospitalLogo";
import { ReactComponent as DoctorIcon } from "../../assets/icons/304919.svg";
import { useSelector } from "react-redux";
import { PROFILE_IMAGE_PREFIX } from "../../constants/StringConstants";
import PasswordResetAlert from "./PasswordResetAlert";
import { useHistory } from "react-router-dom";

const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f1f5f8;
`;
const LoginFormContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 70px;
`;

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const AdminLogin = () => {
  const history = useHistory();
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(
    false
  );
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);
  const [resetAlertVisible, setResetAlertVisible] = useState(false);
  useEffect(() => {
    if (history.location.state && history.location.state.showAlert) {
      setResetAlertVisible(true);
    }
  }, []);
  return (
    
    <LoginContainer>
      <FullHeightRow justify="center" align="middle">
        <Col xl={7} lg={12} md={12} sm={18} xs={23}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row justify="space-around">
                <Col span={24} style={{ textAlign: "center" }}>
                  <HospitalLogo
                    title="Hospital Logo"
                    color={theme["@primary-color"]}
                    icon={
                      <DoctorIcon style={{ width: "100%", color: "red" }} />
                    }
                    // imageUrl={
                    //   images.logo
                    //     ? PROFILE_IMAGE_PREFIX + images.logo
                    //     : undefined
                    // }
                    imageUrl={"https://api.meditagg.com/api/v1/auth/download-file?name=hospital_pic_1671612999007.png"}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <LoginFormContainer>
                <AdminLoginForm
                  setForgotPasswordModalVisible={setForgotPasswordModalVisible}
                />
              </LoginFormContainer>
            </Col>
          </Row>
        </Col>
      </FullHeightRow>
      <ForgotPasswordModal
        visible={forgotPasswordModalVisible}
        setVisible={setForgotPasswordModalVisible}
      />
      <PasswordResetAlert
        visible={resetAlertVisible}
        setVisible={setResetAlertVisible}
      />
    </LoginContainer>
  );
};

export default AdminLogin;
