import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  getHealthRecordForms,
  getPreviousRecords,
  addHealthRecord,
  getSectionData,
} from '../../../../apis/healthRecord';
import * as Sentry from '@sentry/react';
import { tableColumns as LabTableColumns } from '../Laboratory/columns';
import { tableColumns as RadiologyTableColumns } from '../Radiology/columns';
import { Row, Col, Button, message, Form } from 'antd';
import TableContainer from '../TableContainer';
import {
  generateFormFields,
  deserialize,
} from '../../../utils/FormBuilder/valuesSerialisation';
import FormBuilder from '../../../utils/FormBuilder';
import ViewDetailsModal from '../ViewDetailsModal';
import {
  fetchFormFieldMeta,
  previousRadiologyRequest,
  previousLabTestRequest,
} from '../../../../apis/forms';
import { useCallback } from 'react';
import Spinner from '../../../utils/Spinner';
import { generatePreviousInfoTableColumns, softValidate } from '../helper';
import ReportView from './ReportView';
import NoRecords from '../../../utils/NoRecord';
import ObservationView from './ObservationView';
import { tableColumns as prescriptionsColumns } from '../MedicalDetails/columns';

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

const columns = (setSelected, setVisible) => [
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'id',
    width: '150px',
    render: (value) => moment(value).format('DD-MM-YYYY'),
  },
  {
    title: 'Vital Informations',
    dataIndex: 'vitalsInformation',
    key: 'vital',
    render: (values) => (
      <Button
        size="small"
        type="primary"
        ghost
        onClick={() => {
          setSelected(values);
          setVisible(true);
        }}
      >
        View Details
      </Button>
    ),
  },
];

const CollapsBody = ({ sectionId, sectionName, renderType }) => {
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedVitalInformation, setSelectedVitalInformation] = useState({});
  const patient = useSelector((state) => state.patientReducer);
  const user = useSelector((state) => state.userReducer);
  const section = useSelector((state) => state.sectionReducer);
  console.log('patient in collaps', patient);
  console.log('section in collaps', section);
  console.log('user in collaps', user);

  const [visible, setVisible] = useState(false);
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [tableFetchLoading, setTableFetchLoading] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [validationStatus, setValidationStatus] = useState({});
  const [previousDataColumns, setPreviousDataColumns] = useState([]);

  const [validationCriterias, setValidationCriterias] = useState([
    { fieldId: 17, validationCrieteria: { type: 'GREATER_THAN', value: 30 } },
  ]);
  const location = useLocation();
  const [form] = Form.useForm();

  const getPreviousData = () => {
    getSectionData(
      sectionId,
      user.useType == 'PATIENT' ? user.id : patient.pId,
      location?.state?.id ? location.state.id : patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status == 200) {
          if (data.status == 1) {
            let initialValues = {};
            data.body.forEach((item) => {
              item.fields.forEach((item) => {
                initialValues[item.fieldId] = initialValues[item.fieldId]
                  ? Array.isArray(initialValues[item.fieldId])
                    ? [...initialValues[item.fieldId], item.value]
                    : [initialValues[item.fieldId], item.value]
                  : item.value;
              });
            });
            setFormInitialValues(initialValues);
          }
        }
      })
      .catch(console.err);
  };
  const getForms = useCallback(
    (sectionId, patientId) => {
      var isData = false;
      getHealthRecordForms(sectionId, patientId ? patientId : null)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.status === 1) {
              const tempFormIds = {};
              var tempMetaDataIds = [];
              isData = data.body[0].date ? true : false;
              data.body.forEach((item) => {
                //getting formIds and corresponding field IDs for submit request Generation
                tempFormIds[item.id] = item.fields.map((field) => field.id);

                //finding fields which needs to call external API for fetching values eg:dropdown
                tempMetaDataIds = [
                  ...tempMetaDataIds,
                  ...item.fields.filter(
                    (field) =>
                      field.dropDownType !== null &&
                      field.dropDownType !== undefined &&
                      Object.keys(field.dropDownType).length !== 0
                  ),
                ];
              });

              //converting forms into formBuilder readable format
              let FormsTemp = data.body.map((item) => {
                return {
                  formName: item.formName,
                  fields: generateFormFields(item.fields),
                };
              });
              setFormIds(tempFormIds);
              setMetaDataFieldIds(tempMetaDataIds);
              setForms(FormsTemp);
              setFormFetchLoading(false);
            } else {
              message.error(data.body);
              setFormFetchLoading(false);
            }
          }
        })
        .catch((err) => {
          setFormFetchLoading(false);
          console.log(err);
          Sentry.captureException(err);
        });
    },
    [sectionId]
  );
  const getTableData = () => {
    switch (renderType) {
      case 'radiology':
        previousRadiologyRequest(
          location?.state?.id ? location.state.id : patient.appointmentId,
          patient.pId,
          undefined,
          undefined,
          true
        ).then(({ data, status }) => {
          if (status == 200) {
            if (data.status == 1) {
              if (data.body.length) {
                let tempColumns = RadiologyTableColumns({
                  isMain: false,
                  user: user,
                });
                tempColumns = tempColumns.filter(
                  (item) => item.title !== 'Actions' && item.width !== 0
                );
                setPreviousDataColumns(tempColumns);
              }
              setTableData(data.body);
              setTableFetchLoading(false);
            }
          }
        });
        break;
      case 'laboratory':
        previousLabTestRequest(
          location?.state?.id ? location.state.id : patient.appointmentId,
          patient.pId,
          undefined,
          undefined,
          true
        )
          .then(({ data, status }) => {
            if (status == 200) {
              if (data.status == 1) {
                console.log('location', location);
                if (data.body.length) {
                  let tempColumns = LabTableColumns({
                    isMain: false,
                    user: user,
                  });
                  tempColumns = tempColumns.filter(
                    (item) => item.title !== 'Actions' && item.width !== 0
                  );
                  setPreviousDataColumns(tempColumns);
                }
                setTableData(data.body);
                setTableFetchLoading(false);
              }
            }
          })
          .catch();
        break;
      case 'prescriptions':
        getPreviousRecords(
          sectionId,
          patient.pId,
          location?.state?.id ? location.state.id : patient.appointmentId,
          undefined,
          undefined,
          true
        )
          .then(({ data, status }) => {
            if (status === 200) {
              if (data.status === 1) {
                // if (data.body.length) {
                getHealthRecordForms(sectionId, patient.pId)
                  .then((res) => {
                    console.log('res is', res);
                    if (res.data.status == 1) {
                      var columnsData = res.data.body;
                      if (columnsData[0]) {
                        if (columnsData[0].fields) {
                          var tempColumns = generatePreviousInfoTableColumns(
                            columnsData[0].fields
                          );
                          setPreviousDataColumns(tempColumns);
                        }
                      }
                    }
                  })
                  .catch((err) => console.log(err));
                setTableData(data.body);
                setTableFetchLoading(false);
              } else {
                // message.error("No Record");
                setTableFetchLoading(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
            setTableFetchLoading(false);
          });
        break;
      default:
        getPreviousRecords(
          sectionId,
          patient.pId,
          location?.state?.id ? location.state.id : patient.appointmentId,
          undefined,
          undefined,
          true
        )
          .then(({ data, status }) => {
            if (status === 200) {
              if (data.status === 1) {
                getHealthRecordForms(sectionId, patient.pId)
                  .then((res) => {
                    console.log('resq,', res);
                    if (res.data.status == 1) {
                      var columnsData = res.data.body;
                      if (columnsData[0]) {
                        if (columnsData[0].fields) {
                          var tempColumns = generatePreviousInfoTableColumns(
                            columnsData[0].fields
                          );
                          setPreviousDataColumns(tempColumns);
                        }
                      }
                    }
                  })
                  .catch((err) => console.log(err));
                setTableData(data.body);
                setTableFetchLoading(false);
              } else {
                // message.error("No Record");
                setTableFetchLoading(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
            setTableFetchLoading(false);
          });
    }
  };

  const appendSlNo = (columns) => {
    var slNo = [
      { width: '2vw' },
      {
        title: 'Sl. No',
        render: (value, record, index) => index + 1,
        width: '4vw',
      },
    ];
    return [...slNo, ...columns];
  };

  const submitFormData = (dynamicFormData) => {
    setSubmitLoading(true);
    addHealthRecord(
      sectionId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            message.success('Health Record added');
            getTableData();
            form.resetFields();
          } else {
            message.error("couldn't add Vital Informations!");
          }
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };
  useEffect(() => {
    if (renderType == 'report') {
      setFormFetchLoading(true);
      getForms(sectionId, patient.pId);
      getPreviousData();
    } else if (renderType == 'observation') {
      setFormFetchLoading(true);
      getForms(sectionId);
      getPreviousData();
    } else {
      setTableFetchLoading(true);
      getTableData();
    }
  }, []);

  useEffect(() => {}, [previousDataColumns]);

  const onFormChangeHandler = (changed) => {};

  useEffect(() => {
    onFormChangeHandler(formInitialValues);
  }, [formInitialValues]);

  return !formFetchLoading && !tableFetchLoading ? (
    <Row gutter={[24, 12]}>
      {tableData.length > 0
        ? renderType !== 'report' &&
          renderType !== 'observation' && (
            <Col span={24} className="consultation">
              <TableContainer
                title={''}
                tableProps={{
                  columns: previousDataColumns,
                  dataSource: tableData,
                  scroll: { x: 'max-content' },
                }}
                tableTitle={sectionName}
              />
            </Col>
          )
        : renderType !== 'report' &&
          renderType !== 'observation' && <NoRecords height={'50px'} />}
      {renderType === 'observation' &&
      Object.keys(formInitialValues).length > 0 ? (
        <Col span={24}>
          <ReportView formFields={forms} fieldData={formInitialValues} />
        </Col>
      ) : (
        renderType === 'observation' && <NoRecords height={'50px'} />
      )}
      {renderType === 'report' && Object.keys(formInitialValues).length > 0 ? (
        <Col span={24}>
          <ReportView formFields={forms} fieldData={formInitialValues} />
        </Col>
      ) : (
        renderType == 'report' && <NoRecords height={'30px'} />
      )}
    </Row>
  ) : (
    <Spinner />
  );
};
export default CollapsBody;
