import {
  genderList,
  maritalStatus,
  raceList,
  addressType,
  phoneType,
  emailType,
  vipType,
  addressStateType,
  contactRoleType,
  documentTypes,
  idType,
} from '../../Datasets/basicDetails';
import { religions } from '../../Datasets/Religion';
import { relationship } from '../../Datasets/relationship';
import { nationality } from '../../Datasets/nationality';
import { primaryLanguages } from '../../Datasets/primaryLanguage';
import { citizenship } from '../../Datasets/citizenship';

export const validation = (documentList, value) => {
  console.log('id **', documentList, 'Value**', value);
  var pattern = /^[0-9]{7}$/;
  console.log('match', pattern.test(value));
  var status = true;
  for (var i = 0; i < documentList.length; i++) {
    console.log('doc item', documentList[i]);
    switch (documentList[i].type) {
      case '1':
        // status = false;
        break;
      case '2':
        // status = false;
        break;
      case '3':
        // status = false;
        break;
      case '4':
        // status = false;
        break;
      case '5':
        var pattern = /^[0-9]{7}$/;
        if (pattern.test(value)) {
          status = true;

          console.log('doc no validation', status);
        } else {
          status = false;
          console.log('doc no validation', status);
        }
        break;
      case '6':
        // status = false;
        break;
    }
    // if (status === false) {
    //   console.log("doc no validation status final", status);

    //   break;
    // }
  }

  return status;
};
export const config = [
  {
    inputType: 'input',
    rules: [
      {
        required: true,
        pattern: new RegExp(
          /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
        ),
        message: 'Please enter alphabets only.',
      },
    ],
    name: 'firstName',
    label: 'First Name',
  },
  {
    inputType: 'input',
    rules: [
      {
        required: true,
        pattern: new RegExp(
          /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
        ),

        message: 'Please enter alphabets only.',
      },
    ],
    name: 'lastName',
    label: 'Last Name',
  },
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Gender is required' }],
    name: 'gender',
    label: 'Gender',

    meta: genderList(),
  },
  {
    inputType: 'date',
    rules: [{ required: true, message: 'Date of Birth is required' }],
    name: 'dob',
    label: 'Date of Birth',
    past: true,
    placeholder: 'DD-MM-YYYY',
  },
];
export const optionalDetailsFields = [
  {
    inputType: 'input',
    rules: [
      {
        // required: true,
        pattern: new RegExp(/[0-9]/),
        // message: "Age is required",
      },
    ],
    name: 'age',
    label: 'Age',
    disabled: true,
  },
  {
    inputType: 'select',
    // rules: [
    //   {
    //     required: true,
    //   },
    // ],
    name: 'bloodGroup',
    label: 'Blood Group',

    meta: [],
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Marital Status is required" }],
    name: 'maritalStatus',
    label: 'Marital Status',

    meta: maritalStatus(),
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Race is required" }],
    name: 'race',
    label: 'Race',

    meta: raceList(),
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Religion is required" }],
    name: 'religion',
    label: 'Religion',

    meta: religions(),
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Nationality is required" }],
    name: 'nationality',
    label: 'Nationality',
    meta: nationality(),
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Citizenship is required" }],
    name: 'citizenship',
    label: 'Citizenship',
    meta: citizenship(),
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Primary Language is required" }],
    name: 'primaryLanguage',
    label: 'Primary Language',
    meta: primaryLanguages,
  },
  // {
  //   inputType: "select",
  //   rules: [{ required: true, message: "Phone type is required" }],
  //   name: "phoneType",
  //   label: "Phone Type",
  //   meta: phoneType(),
  // },

  // {
  //   inputType: "phoneNumber",
  //   rules: [
  //     {
  //       required: true,
  //       message: "Mobile Number is required",
  //     },
  //   ],
  //   name: "mobileNo",
  //   label: "Mobile Number",

  //   meta: [],
  // },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Email Type is required" }],
    name: 'emailType',
    label: 'Email Type',
    meta: emailType(),
  },
  {
    inputType: 'input',
    type: 'email',
    // rules: [{ required: true, type: "email", message: "Email is required" }],
    name: 'email',
    label: 'Email',
  },
];

export const addresses = [
  // {
  //   inputType: "heading",
  //   label: "Address Details",
  // },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Address type is required" }],
    name: 'addressType',
    label: 'Address Type',
    meta: addressType(),
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Address State is required" }],
    name: 'addressState',
    label: 'Address State',
    meta: addressStateType(),
  },
  {
    inputType: 'input',
    // rules: [{ required: true, message: "House/Flat No is required" }],
    name: 'houseFlatNo',
    label: 'House/Flat No',
  },
  {
    inputType: 'input',
    // rules: [{ required: true, message: "Nearest Landmark/Plot is required" }],
    name: 'landmark',
    label: 'Nearest Landmark/Plot',
  },
  {
    inputType: 'input',
    // rules: [{ required: true, message: "City is required" }],
    name: 'city',
    label: 'City',
    meta: ['New York', 'Washinton', 'USA'],
  },
  {
    inputType: 'input',
    // rules: [
    //   {
    //     required: true,
    //     pattern: new RegExp(/[0-9a-zA-Z]+/),
    //     message: "Enter a Valid Post Box Number",
    //   },
    // ],
    name: 'postBox',
    label: 'Post Box Number',
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Country is required" }],
    name: 'country',
    label: 'Country',
    meta: citizenship(),
  },
];
export const nextOfKinType = [
  {
    inputType: 'heading',
    label: 'Next Of Kin / Emergency',
  },

  {
    inputType: 'select',
    // rules: [{ required: true, message: "Contact Role is required" }],
    name: 'contactRole',
    label: 'Contact Role',
    meta: contactRoleType(),
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Relationship is required" }],
    name: 'relationship',
    label: 'Relationship',
    meta: relationship(),
  },
  {
    inputType: 'input',
    // rules: [
    //   {
    //     required: true,
    //     pattern: new RegExp(
    //       /^(([A-Za-z]{1,})([\.,] |[-']| )*)+[A-Za-z]+\.?\s*$/
    //     ),
    //     message: "Please enter alphabets only.",
    //   },
    // ],
    name: 'fullName',
    label: 'Full Name',
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Phone type is required" }],
    name: 'phoneType',
    label: 'Phone Type',
    meta: phoneType(),
  },

  {
    inputType: 'phoneNumber',
    // rules: [
    //   // { required: true, message: "Phone Number is required" },
    //   // ({ getFieldValue }) => ({
    //   //   validator(rule, value) {
    //   //     if (value && value.phoneNumber && value.countryCode) {
    //   //       return Promise.resolve();
    //   //     } else {
    //   //       return Promise.reject("Phone Number and Country code required");
    //   //     }
    //   //   },
    //   // }),
    //   // ({ getFieldValue }) => ({
    //   //   validator(rule, value) {
    //   //     if (value) {
    //   //       var pattern = /[0-9]+/;
    //   //       console.log("match", pattern.test(value.phoneNumber));
    //   //       if (pattern.test(value.phoneNumber)) {
    //   //         return Promise.resolve();
    //   //       } else {
    //   //         return Promise.reject("Enter a valid mobile Number");
    //   //       }
    //   //     }
    //   //   },
    //   // }),
    // ],
    name: 'phoneNumber',
    label: 'Phone Number',

    meta: [],
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Address type is required" }],
    name: ['address', 'addressType'],
    label: 'Address Type',
    meta: addressType(),
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Address State is required" }],
    name: ['address', 'addressState'],
    label: 'Address State',
    meta: addressStateType(),
  },
  {
    inputType: 'input',
    // rules: [{ required: true, message: "House/Flat No is required" }],
    name: ['address', 'houseFlatNo'],
    label: 'House/Flat No',
  },
  {
    inputType: 'input',
    // rules: [{ required: true, message: "Nearest Landmark/Plot is required" }],
    name: ['address', 'landmark'],
    label: 'Nearest Landmark/Plot',
  },
  {
    inputType: 'input',
    // rules: [{ required: true, message: "City is required" }],
    name: ['address', 'city'],
    label: 'City',
    meta: ['New York', 'Washinton', 'USA'],
  },

  {
    inputType: 'input',
    // rules: [
    //   {
    //     required: true,
    //     pattern: new RegExp(/^[0-9a-zA-Z]+$/),
    //     message: "Enter  a Valid Post Box Number",
    //   },
    // ],
    name: ['address', 'postBox'],
    label: 'Post Box Number',
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Country is required" }],
    name: ['address', 'country'],
    label: 'Country',
    meta: citizenship(),
  },
];
export const documenType = [
  {
    inputType: 'heading',
    label: 'Document Type',
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Type is required" }],
    name: 'type',
    label: 'Type',
  },
];
export const phoneNumberType = [
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Phone type is required' }],
    name: 'phoneType',
    label: 'Phone Type',
    meta: phoneType(),
  },

  {
    inputType: 'phoneNumber',
    rules: [
      { required: true, message: 'Phone type is required' },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (value && value.phoneNumber && value.countryCode) {
            return Promise.resolve();
          } else {
            return Promise.reject('Phone Number and Country code required');
          }
        },
      }),

      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (value) {
            var pattern = /^[0-9]{10}$/;
            console.log('match', pattern.test(value.phoneNumber));
            if (pattern.test(value.phoneNumber)) {
              return Promise.resolve();
            } else {
              return Promise.reject('Enter a valid mobile Number');
            }
          }
        },
      }),
    ],
    name: 'phoneNumber',
    label: 'Phone Number',

    meta: [],
  },
];
export const staticDocumentType = [
  {
    inputType: 'heading',
    label: 'Document Type',
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Type is required" }, {}],
    name: 'documentType',
    label: 'Type',
    meta: idType(),
  },
  {
    inputType: 'input',

    // rules: [
    //   {
    //     required: true,
    //     // pattern: new RegExp(/[0-9]/),
    //     message: "Document Number is required",
    //   },
    // ],
    name: 'number',
    label: 'Document Number',
  },
  {
    inputType: 'date',
    // rules: [{ required: true, message: "Document Expiry Date is required" }],
    name: 'expiryDate',
    label: 'Document  Expiry Date',
    past: false,
  },
  // {
  //   inputType: "input",
  //   rules: [{ required: true, message: "Document Expiry Date is required" }],
  //   name: "documentUrl",
  //   label: "Dcouemnt Url",
  // },
];
export const paymentType = [];
export const insuranceFields = [
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Insurance type is required' }],
    name: ['insuranceData', 'type'],
    label: 'Insurance',
    meta: [],
  },
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Insurance plan is required' }],
    name: 'insuranceDataPlan',
    label: 'Insurance Plan',
    meta: [],
  },
  {
    inputType: 'input',
    rules: [
      {
        required: true,
        pattern: new RegExp('^[a-zA-Z0-9]+$'),
        message: 'Please enter alphabets and digits only.',
      },
    ],
    name: ['insuranceData', 'number'],
    label: 'Insurance Number',
  },
  {
    inputType: 'date',
    rules: [{ required: true, message: 'Expiry Date is required' }],
    name: ['insuranceData', 'expiry'],
    label: 'Expiry Date',
    past: false,
  },
];
export const copayOrSelf = [
  {
    inputType: 'heading',
    label: 'Insurance deductable',
  },
  {
    inputType: 'radio',
    rules: [{ required: true, message: 'Insurance deductable is required' }],
    name: 'insuranceDeductibleType',
    meta: ['own', 'copay'],
    layout: { span: 24 },
  },
];

export const deathStatus = [
  {
    inputType: 'heading',
    label: 'Death status',
  },
  {
    inputType: 'radio',
    rules: [{ required: true, message: 'Death Status is required' }],
    name: 'deathStatus',
    meta: ['No', 'Yes'],
    layout: { span: 24 },
    // label: "Is Dead ?",
  },

  {
    inputType: 'date',
    rules: [{ required: false }],
    name: 'deathDate',
    label: 'Date',
  },
];
