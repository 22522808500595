import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, message, Tooltip, Button } from 'antd';
import CameraModal from '../PatientForm/CameraModal';
import { uploadImage } from '../../../apis/uploadImages';
import { getProfile, editProfile } from '../../../apis/User';
import { LeftCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import ImageUpload from './ImageUpload';
import Spinner from '../../utils/Spinner/index';
import UserProfileForm from './UserProfileForm';
import * as Sentry from '@sentry/react';
import QRcode from 'react-qr-code';
import { PrinterOutlined } from '@ant-design/icons';

import {
  ChangePasswordButton,
  EditButton,
  FormContainer,
  Heading,
  HeadingRow,
  PatientIdP,
  ProfileDetailsCol,
  ProfileDetailsP,
  StyledEditIcon,
  ChangePasswordCol,
  StyledDivPrint,
} from './styledComponents';
import ResetPassword from './ResetPassword';
import { PROFILE_IMAGE_PREFIX } from '../../../constants/StringConstants';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, addUser } from '../../../redux/actions/user';
import { deleteUploadedFile } from '../../../apis/uploadPdfOrImage';
import { useReactToPrint } from 'react-to-print';
import {
  getHospitalId,
  getDoctorId,
  getHospitalName,
} from '../../utils/LocalStorage/index';
import moment from 'moment';

const UserProfile = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer);

  const componentRef = useRef();
  const [profilePic, setProfilePic] = useState();
  const [profilePicURL, setProfilePicURL] = useState();
  const [patientId, setPatientId] = useState();
  const [cameraModalVisible, setCameraModalVisible] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [passwordReset, setPasswordReset] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [qrcode, setqrCode] = useState(
    `https://app.meditagg.com/${getHospitalId()}/${getDoctorId()}/new-patient-form`
  );
  useEffect(() => {
    if (user.id) {
      fetchProfileDetails();
    }
  }, [user.id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data',
    // onAfterPrint: () => alert('print success'),
  });

  const fetchProfileDetails = () => {
    setLoading(true);
    getProfile(user.id)
      .then((response) => {
        if (response.data.status === 1) {
          console.log('data in', response.data.body);
          let body = response.data.body;
          setFormInitialValues(body);

          if (body.profilePic) {
            const url = PROFILE_IMAGE_PREFIX + body.profilePic;
            setProfilePicURL(url);
          }
          setLoading(false);
        } else {
          message.error('Could not load profile,Try again');
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setLoading(false);
        message.error('Could not load profile,Try again');
      });
  };

  const onSubmit = async (values) => {
    let body = values;
    setSubmitLoading(true);
    setDisabled(!disabled);
    if (profilePic) {
      let image = await uploadImage(null, profilePic);
      body.profilePic = image.data.body;
    } else if (profilePicURL) {
      body.profilePic = formInitialValues.profilePic;
    } else if (formInitialValues.profilePic) {
      deleteUploadedFile(formInitialValues.profilePic)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
              console.log(res.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
      body.profilePic = undefined;
    }
    editProfile(body)
      .then((response) => {
        if (response.data.status === 1) {
          history.go(0);
          let userNew = user;
          userNew.profilePic = response.data.body.profilePic;
          userNew.fullName =
            response.data.body.firstName + ' ' + response.data.body.lastName;
          dispatch(addUser({ ...userNew }));
          localStorage.setItem('user', JSON.stringify(userNew));
          message.success('Profile updated successfully.');
          setSubmitLoading(false);
          fetchProfileDetails();
        } else {
          message.error("Couldn't update profile,Try agian");
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        message.error("Couldn't update profile,Try agian");
        setSubmitLoading(false);
      });
  };
  console.log('user is ----', user);
  const logOut = () => {
    localStorage.clear();
    dispatch(clearUser());
    history.push('/login');
  };

  return (
    <>
      <h2>
        <LeftCircleOutlined
          onClick={() => {
            history.goBack();
          }}
        />
        {'  '}
        Back To Dashboard
      </h2>

      <FormContainer>
        <HeadingRow>
          <Heading
            color={theme['@primary-color']}
            // style={{ fontSize: "20px", marginBottom: "5px", marginTop: "0px" }}
          >
            My Profile &nbsp;
            {!passwordReset && (
              <Tooltip title="Edit Profile">
                <EditButton
                  shape="circle"
                  icon={
                    <StyledEditIcon
                      disabled={disabled}
                      color={theme['@primary-color']}
                    />
                  }
                  onClick={() => setDisabled(!disabled)}
                />
              </Tooltip>
            )}
          </Heading>
          {user.userType === 'DOCTOR' ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    height: 'auto',
                    // margin: "0 auto",
                    maxWidth: 80,
                    width: '100%',
                  }}
                >
                  <QRcode
                    size={350}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={qrcode}
                    viewBox={`0 0 350 350`}
                  />
                </div>

                <Tooltip title="Print QRCode" placement="bottom">
                  <Button type="primary" onClick={handlePrint}>
                    {' '}
                    Print
                    {/* <PrinterOutlined
                      style={{
                        fontSize: "20px",
                        color: theme["@primary-color"],
                      }}
                      onClick={handlePrint}
                    /> */}
                  </Button>
                </Tooltip>
              </div>
            </>
          ) : null}
        </HeadingRow>

        {loading ? (
          <Spinner />
        ) : (
          <>
            <Row justify="left" gutter={[12, 12]}>
              <Col
                lg={4}
                md={6}
                xs={24}
                sm={24}
                style={{ textAlign: 'center' }}
              >
                <ImageUpload
                  disabled={disabled}
                  profilePic={profilePic}
                  setProfilePic={setProfilePic}
                  setCameraModalVisible={setCameraModalVisible}
                  profilePicURL={profilePicURL}
                  setProfilePicURL={setProfilePicURL}
                  setDisabled={setDisabled}
                />
              </Col>

              <ProfileDetailsCol lg={20} md={18} xs={24} sm={24}>
                <PatientIdP>
                  Username: {formInitialValues.firstName}&nbsp;
                  {formInitialValues.lastName}
                </PatientIdP>
                <ProfileDetailsP>{formInitialValues.email}</ProfileDetailsP>
                {!passwordReset && (
                  <ChangePasswordButton
                    changeWidth={true}
                    type="primary"
                    className="pwd_btn"
                    onClick={() => setPasswordReset(true)}
                  >
                    Change Password
                  </ChangePasswordButton>
                )}
              </ProfileDetailsCol>
            </Row>

            {passwordReset ? (
              <ResetPassword id={user.id} setPasswordReset={setPasswordReset} />
            ) : (
              <UserProfileForm
                formInitialValues={formInitialValues}
                disabled={disabled}
                setDisabled={setDisabled}
                onSubmit={(values) => onSubmit(values)}
                userType={user.userType}
                submitLoading={submitLoading}
                logOut={logOut}
              />
            )}
          </>
        )}
      </FormContainer>
      <CameraModal
        visible={cameraModalVisible}
        setVisible={setCameraModalVisible}
        setProfilePic={setProfilePic}
      />
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <StyledDivPrint>
            <h1 style={{ marginTop: '30px' }}>{getHospitalName()}</h1>
            <h4>Scan this QRCode to book an Appointment</h4>
            <span>
              Consultation Time :{' '}
              <span style={{ fontWeight: 'bold' }}>
                {moment(formInitialValues.startTime).format('hh:mm A')} -{' '}
                {moment(formInitialValues.endTime).format('hh:mm A')}
              </span>
            </span>
            <div
              style={{
                height: 'auto',
                margin: '15% 0 0 0',
              }}
            >
              <QRcode value={qrcode} />
            </div>
          </StyledDivPrint>
        </div>
      </div>
    </>
  );
};
export default UserProfile;
