export const SET_ACTIVE_COUNT = "SET_ACTIVE_COUNT";
export const SET_INQUEUE_COUNT = "SET_INQUEUE_COUNT";

export const setActiveCount = (data) => {
  return {
    type: SET_ACTIVE_COUNT,
    payload: data,
  };
};

export const setInQueueCount = (data) => {
  return {
    type: SET_INQUEUE_COUNT,
    payload: data,
  };
};
