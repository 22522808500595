import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import styled from "styled-components";
import { Row, Col, Input, Button, Typography, message } from "antd";
import { addNewDepartment } from "../../../../apis/departments.js";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getHospitalId } from "../../../utils/LocalStorage/index.js";
const ModalBodyContainer = styled.div`
  width: 450px;
  padding: 10px 30px;
`;
const { Title } = Typography;

const StyledTitle = styled(Title)`
  &.ant-typography,
  &.ant-typography h3 {
    margin-bottom: 0;
    color: ${(props) => props.color};
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
  }
`;

const NewDocumentModal = ({
  visible,
  setVisible,
  refetch,
  selectedItem,
  setSelectedItem,
}) => {
  const [departmentName, setDepartmentName] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.themeReducer);

  const addDepartment = () => {
    setLoading(true);
    let request = { departmentName: departmentName, hospitalId: getHospitalId() };
    if (selectedItem) request.departmentId = selectedItem.id;
    addNewDepartment(request)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) {
            message.success("Created");
            refetch();
            setDepartmentName("");
            setSelectedItem(undefined);
            setVisible(false);
          } else {
            message.error("Could n't create department");
          }
          //   setSelectedItem(undefined);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (selectedItem) setDepartmentName(selectedItem.departmentName);
  }, [selectedItem]);
  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBodyContainer>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            {" "}
            <StyledTitle level={3} color={theme["@primary-color"]}>
              {!selectedItem ? "Create New Department" : "Update Department"}
            </StyledTitle>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Name</Col>
              <Col span={24}>
                <Input
                  value={departmentName}
                  size="large"
                  onChange={(event) => {
                    setDepartmentName(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={12} justify="end">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => {
                    setDepartmentName("");
                    setSelectedItem(undefined);
                    setVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => {
                    addDepartment();
                  }}
                >
                  {!selectedItem ? "Create" : "Update"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default NewDocumentModal;
