import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, Input, message, Row, Col } from 'antd';

import { ReactComponent as SuccessIcon } from '../../../assets/icons/Successful-icon.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/CancelIcon.svg';
import { useHistory } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { createSMSToSinglePerson } from '../../../apis/Message';
import { getHospitalId, getDoctorId } from '../../utils/LocalStorage/index';
const { TextArea } = Input;

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

const ModalContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledCancelIcon = styled(MailOutlined)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledP = styled.p`
  font-size: 15px;
`;

const StyledPTitle = styled.p`
  font-size: 28px;
  font-family: bold;
  color: #56aef8;
`;

const StyledRow = styled(Row)`
  padding: 24px;
`;

const MessageSinglePersonModal = ({
  visible,
  closeModal,
  phoneNumber,
  firstName,
  patientId,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  useEffect(() => {}, []);
  let searchQuery = `patientId=${patientId}&doctorId=${getDoctorId()}`;
  const onSubmit = (values) => {
    const payload = {
      phoneNumber,
      message: values.message,
      hospitalId: getHospitalId(),
    };

    createSMSToSinglePerson(payload, searchQuery)
      .then((response) => {
        if (response.status == 200) {
          closeModal();
          message.success('Message send');

          form.resetFields();
        } else {
          message.error('Unable to send SMS');
        }
      })
      .catch((err) => {
        message.error('Unable to send SMS!', err);
      });
  };

  return (
    <StyledModal
      visible={visible}
      closable={false}
      centered
      maskClosable={true}
      footer={null}
    >
      <ModalContentDiv>
        {/* <MailOutlined style={{ width: '60px' }} /> */}
        <StyledPTitle>Message</StyledPTitle>
        <StyledP>
          {' '}
          Send Message to {firstName} in {phoneNumber}
        </StyledP>
        <Form
          layout="vertical"
          onFinish={(values) => onSubmit(values)}
          form={form}
          style={{ width: '100%' }}
          scrollToFirstError={true}
        >
          <Form.Item name="message" style={{ width: '100%' }}>
            <TextArea rows={8} placeholder="Enter message" />
          </Form.Item>{' '}
          <>
            <StyledRow gutter={12} justify="end">
              <Button
                key="ok"
                type="primary"
                ghost
                onClick={() => closeModal()}
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>

              <Button key="ok" type="primary" htmlType="submit">
                Send Message
              </Button>
            </StyledRow>
          </>
        </Form>
      </ModalContentDiv>
    </StyledModal>
  );
};

export default MessageSinglePersonModal;
