import React from 'react';

import { Button, Row, Col } from 'antd';
import Icon from '@ant-design/icons';
import { sidebarIcons } from '../../../../constants/sideBarIcons';
import { ReactComponent as EditIcon } from '../../../../assets/icons/Edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/DeleteIcon.svg';
// import styledComponents from "styled-components";

// const StyledIcon = styledComponents(Icon)`
// .cls-2{
//   fill: ${props => props.theme["@primary-color"]};
// }
// `

export const sectionColumns = (setTab, setSelectedSection) => [
  {
    title: 'Sections',
    dataIndex: 'formName',
    render: (value, record) => {
      return (
        <Row gutter={24}>
          <Col>
            <Icon
              style={{ fontSize: '25px' }}
              component={
                sidebarIcons[record.icon] || sidebarIcons['consent-form']
              }
            />
          </Col>
          {value}
          <Col></Col>
        </Row>
      );
    },
  },
  {
    title: 'Actions',
    width: '150px',
    render: (record) => (
      // record.editable &&
      <Button
        type="primary"
        //   size="small"
        ghost
        onClick={() => {
          setSelectedSection(record);
          setTab('forms');
        }}
      >
        Manage Forms
      </Button>
    ),
  },
];

export const formColumns = (
  setTab,
  setSelectedForm,
  newFormClickHandler,
  theme
) => [
  {
    width: '1vw',
  },
  {
    title: 'Sl. No',
    render: (value, item, index) => {
      return index + 1;
    },
    width: '4vw',
  },
  { title: 'Form Name', width: '30vw', dataIndex: 'formName' },
  {
    title: 'Actions',
    width: '8vw',
    render: (record) => (
      <>
        {record.editable !== false && (
          <Icon
            style={{ fontSize: '20px', marginRight: '20px' }}
            component={EditIcon}
            onClick={() => newFormClickHandler(record)}
            theme={theme}
          />
        )}
        <Button
          type="primary"
          //   size="small"
          style={{ borderRadius: '5px' }}
          ghost
          onClick={() => {
            setSelectedForm(record);
            setTab('form_fields');
          }}
        >
          Manage Fields
        </Button>
      </>
    ),
  },
];
export const fieldsColumn = (
  setSelectedItem,
  setConfirmationModal,
  setNewFieldModal
) => [
  { title: 'Fields', dataIndex: 'formName' },
  { title: 'Type', dataIndex: 'fieldType' },
  {
    title: 'Mandatory Field',
    dataIndex: 'required',
    render: (val) => (val ? 'Yes' : 'No'),
  },
  { title: 'Input Box', dataIndex: 'width' },
  {
    title: 'Actions',
    width: '150px',
    render: (record) => (
      <Row gutter={12}>
        <Col>
          <Icon
            style={{ fontSize: '22px' }}
            component={DeleteIcon}
            onClick={() => {
              setSelectedItem(record);
              setConfirmationModal(true);
            }}
          />
        </Col>
        {record.editable && (
          <Col>
            {' '}
            <Icon
              style={{ fontSize: '20px' }}
              component={EditIcon}
              onClick={() => {
                setSelectedItem(record);
                setNewFieldModal(true);
              }}
            />
          </Col>
        )}
      </Row>
    ),
  },
];
