import styled from "styled-components";
import { Button, Row } from "antd";
export const NewUserButton = styled(Button)`
  height: 40px;
  width: 150px;
  font-size: 17px;
  border-radius: 5px !important;
`;

export const ModalBodyContainer = styled.div`
  width: 500px;
`;

export const StyledRow = styled(Row)`
  margin-top: 24px;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  color: ${(props) => props.color};
  text-align: center;
`;
