import React from "react";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  DatePicker,
  Upload,
} from "antd";
import styled from "styled-components";
import Icon, { CloudUploadOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { useSelector } from "react-redux";
export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;
export const StyledIcon = styled(Icon)`
  && {
    font-size: 25px;
    margin-top: 5px;
  }
`;
const buildHelper = (item, theme) => {
  console.log("preview build helper", item);
  switch (item.fieldType) {
    case "TEXT":
      return <Input size="large" />;
    case "TEXT_AREA":
      return <Input size="large" />;
    case "DROPDOWN":
      return (
        <Select size="large">
          {item?.dropDownValues?.map((element) => (
            <Select.Option value={element.id}>{element.value}</Select.Option>
          ))}
        </Select>
      );
    case "MULTI_SELECT_DROP_DOWN":
      return (
        <Select mode="multiple" size="large">
          {item?.dropDownValues?.map((element) => (
            <Select.Option value={element.id}>{element.value}</Select.Option>
          ))}
        </Select>
      );
    case "SEARCHABLE_DROPDOWN":
      return <Select size="large"></Select>;
    case "DATE":
      return (
        <StyledDatePicker size="large" format="DD-MM-YYYY" allowClear={false} />
      );
    case "BOOLEAN_RADIO_BUTTON":
      return (
        <Radio.Group size="large">
          <Radio value="a">A</Radio>
          <Radio value="b">B</Radio>
        </Radio.Group>
      );
    case "TAG_SELECT":
      return "  a, b";
    case "RADIO":
      return (
        <Radio.Group size="large">
          {item?.dropDownValues?.map((element) => (
            <Radio value={element.id}>{element.value}</Radio>
          ))}
        </Radio.Group>
      );
    case "NUMBER":
      return <InputNumber size="large" />;
    case "SINGLE_UPLOAD":
      return (
        <Upload.Dragger size="large">
          <p
            className="ant-upload-hint"
            style={{ fontSize: "75px", color: "rgba(0, 0, 0, 0.25)" }}
          >
            <CloudUploadOutlined />
          </p>
          <Text
            className="ant-upload-text"
            style={{ color: "rgba(0, 0, 0, 0.25)", fontWeight: "bold" }}
          >
            Drag and Drop or
            <Text
              style={{
                color: theme["@primary-color"],
                fontWeight: "bold",
              }}
            >
              &nbsp;Browse&nbsp;
            </Text>
            File
          </Text>
          <br />
          <Text
            className="ant-upload-text"
            style={{ color: theme["@primary-color"], fontWeight: "bold" }}
          >
            Upload any one file of{" "}
            {item.validFormats?.map((format, i, arr) =>
              i == 0 ? format.value?.toUpperCase() : "/" + format.value?.toUpperCase()
            )}
          </Text>
        </Upload.Dragger>
      );
    case "MULTIPLE_UPLOAD":
      return (
        <Upload.Dragger size="large">
          <p
            className="ant-upload-hint"
            style={{ fontSize: "75px", color: "rgba(0, 0, 0, 0.25)" }}
          >
            <CloudUploadOutlined />
          </p>
          <Text
            className="ant-upload-text"
            style={{ color: "rgba(0, 0, 0, 0.25)", fontWeight: "bold" }}
          >
            Drag and Drop or
            <Text
              style={{
                color: theme["@primary-color"],
                fontWeight: "bold",
              }}
            >
              &nbsp;Browse&nbsp;
            </Text>
            File
          </Text>
          <br />
          <Text
            className="ant-upload-text"
            style={{ color: theme["@primary-color"], fontWeight: "bold" }}
          >
            Upload any files of{" "}
            {item.validFormats?.map((format, i, arr) =>
              i == 0 ? format.value?.toUpperCase() : "/" + format.value?.toUpperCase()
            )}
          </Text>
        </Upload.Dragger>
      );
  }
};
const PreviewBuilder = ({ formName, fields }) => {
  const theme = useSelector((state) => state.themeReducer);
  return (
    <Form layout="vertical">
      <Row gutter={12}>
        {fields.map((item) => (
          <Col span={item.width === "HALF" ? 12 : 24}>
            {console.log("field item**", item)}
            <Form.Item label={item.formName} required={item.required}>
              {buildHelper(item, theme)}
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default PreviewBuilder;
