import React from "react";
import Icon from "@ant-design/icons";
import styled from "styled-components";
import { Row, Col } from "antd";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/Successful-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIcon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning.svg";

const alertTypes = {
  success: {
    icon: <Icon component={SuccessIcon} style={{ fontSize: "150px" }} />,
    color: "#47b2fe",
  },
  delete: {
    icon: <Icon component={DeleteIcon} style={{ fontSize: "50px" }} />,
    color: "#47b2fe",
  },
  warning: {
    icon: <Icon component={WarningIcon} style={{ fontSize: "80px" }} />,
    color: "#47b2fe",
  },
};

const AlertContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 40px;
`;
const StyledPTag = styled.p`
  font-size: 12px;
`;
const CustomAlerts = ({ type, title, description }) => {
  return (
    <AlertContainer>
      <Row gutter={[24, 5]}>
        <Col span={24}>{alertTypes[type].icon}</Col>
        <Col span={24}>
          <h2>{title}</h2>
        </Col>
        <Col span={24}>
          <StyledPTag>{description}</StyledPTag>
        </Col>
      </Row>
    </AlertContainer>
  );
};
export default CustomAlerts;
