import { CheckOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import {
  ColorBox,
  Colorcontainer,
  ThemeContainer,
  ColorNameContainer,
  SelectedColorBox,
} from "./styledComponents";

const colors = [
  "#76d3a1",
  "#70d4d3",
  "#e5646b",
  "#f8c957",
  "#e69bf3",
  "#6752cd",
  "#26b1ff",
  "#f5cd79",
  "#cf6a87",
  "#778beb",
  "#e98685",
  "#e3587b",
  "#3b3b98",
  "#eba3c2",
];

const ThemeSelector = ({ primaryColor, setPrimaryColor }) => {
  return (
    <ThemeContainer>
      <Row gutter={24}>
        <Col>
          <SelectedColorBox primaryColor={primaryColor}></SelectedColorBox>
        </Col>
        <Col>
          <ColorNameContainer>{primaryColor.toUpperCase()}</ColorNameContainer>
          <Colorcontainer>
            {colors.map((item) => (
              <ColorBox
                primaryColor={item}
                isActive={item === primaryColor}
                onClick={() => {
                  setPrimaryColor(item);
                }}
              >
                {/* {item === primaryColor && <CheckOutlined />} */}
              </ColorBox>
            ))}
          </Colorcontainer>
        </Col>
      </Row>
    </ThemeContainer>
  );
};

export default ThemeSelector;
