import React, { useEffect, useCallback } from "react";
import { Button, Col, Row, message } from "antd";
import { BodyContainer } from "../Sections/styledComponents";
import { SectionHeadContainer, SectionHeading } from "../styledComponents";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/Import.svg";
import {
  DownloadOutlined,
  SearchOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import DrugsTable from "./DrugsTable";
import { useState } from "react";
import ImportModal from "./ImportModal";
import * as Sentry from "@sentry/react";
import {
  importDrugs,
  getDrugsList,
  removeDrug,
  addOrModifyDrug,
  exportDrugs,
} from "../../../../apis/Admin";
import { StyledSearch, SearchContainer, Container } from "./styledComponents";
import { useDebounce } from "../../HealthRecords/ConcentForm/helper";
import DrugDetailsModal from "./DrugDetailsModal";
import DeleteModal from "./DeleteModal";
import Spinner from "../../../utils/Spinner";

const Drugs = () => {
  const [dataSource, setDataSource] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [total, setTotal] = useState();
  const [searchString, setSearchString] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newMedicineModal, setNewMedicineModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getDrugs = (page, size, name) => {
    setLoading(true);
    getDrugsList(page, size, name)
      .then((resp) => {
        if (resp.status === 200) {
          setDataSource(resp.data.body);
          setTotal(resp.data.total);
          setLoading(false);
        } else {
          message.error(resp.data.body);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    getDrugs(pageNo, 15, searchString);
  }, [searchString, pageNo]);

  const onChangeHandler = (value) => {
    setSearchString(value);
  };

  const uploadFile = (data) => {
    setLoading(true);
    importDrugs(data)
      .then((resp) => {
        if (resp.data.status === 1) {
          message.success(resp.data.body);
          setModalVisible(false);
          getDrugs(pageNo, 15, searchString);
        } else {
          setLoading(false);
          message.error(resp.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const debounceOnChange = useCallback(useDebounce(onChangeHandler, 1000), []);

  const deleteDrug = () => {
    removeDrug(selectedItem.id)
      .then((resp) => {
        if (resp.data.status === 1) {
          message.success("Removed Drug");
          setDeleteModal(false);
          setSelectedItem(null);
          if (total % 15 == 1 && pageNo !== 0) {
            getDrugs(pageNo - 1, 15, searchString);
            setPageNo(pageNo - 1);
          } else getDrugs(pageNo, 15, searchString);
        } else {
          message.error("Could not remove drug, Try Again");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const submitHandler = (values) => {
    console.log("entered into submit fn in index");
    let payload = values;
    if (editModal) {
      payload.id = selectedItem.id;
    }
    addOrModifyDrug(payload)
      .then((resp) => {
        if (resp.data.status === 1) {
          message.success(
            `Successfully ${editModal ? "modified" : "added"} Drug`
          );
          setEditModal(false);
          setNewMedicineModal(false);
          setSelectedItem(null);
          getDrugs(pageNo, 15, searchString);
        } else {
          message.error(
            `Could not ${editModal ? "modify" : "add"} Drug, Try Again`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const exportData = () => {
    setExportLoading(true);
    exportDrugs()
      .then((res) => {
        var fileDownload = require("js-file-download");
        fileDownload(res.data, "drugs.xlsx");
      })
      .catch()
      .finally(() => setExportLoading(false));
  };

  // return loading ? (
  //   <Spinner />
  // ) : (
  return (
    <Container>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between" align="middle">
              <Col>
                <SectionHeading>Drugs</SectionHeading>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => setModalVisible(true)}
                  icon={<DownloadOutlined />}
                  style={{ marginRight: "10px", borderRadius: "5px" }}
                >
                  Import
                </Button>

                <Button
                  type="primary"
                  size="large"
                  style={{ borderRadius: "5px" }}
                  onClick={() => exportData()}
                  icon={<ExportOutlined />}
                  loading={exportLoading}
                >
                  Export
                </Button>
              </Col>
            </Row>
          </SectionHeadContainer>
        </Col>
        <Col span={24}>
          <BodyContainer>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <SearchContainer>
                      <StyledSearch
                        className="box-shadow-remove"
                        prefix={
                          <SearchOutlined style={{ marginRight: "5px" }} />
                        }
                        size="large"
                        placeholder="Search"
                        onChange={(e) => {
                          debounceOnChange(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter")
                            getDrugs(pageNo, 15, searchString);
                        }}
                      />
                    </SearchContainer>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      ghost
                      onClick={() => {
                        setNewMedicineModal(true);
                      }}
                      style={{ border: "none", boxShadow: "none" }}
                    >
                      Add New
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                {!loading ? (
                  <DrugsTable
                    loading={loading}
                    dataSource={dataSource}
                    pageSize={15}
                    setPageNo={setPageNo}
                    pageNo={pageNo}
                    total={total}
                    setEditModal={setEditModal}
                    setDeleteModal={setDeleteModal}
                    setSelectedItem={setSelectedItem}
                  />
                ) : (
                  <Spinner />
                )}
              </Col>
            </Row>
          </BodyContainer>
        </Col>
      </Row>
      <ImportModal
        visible={modalVisible}
        setVisible={setModalVisible}
        uploadFile={uploadFile}
        loading={loading}
      />
      <DrugDetailsModal
        visible={editModal ? editModal : newMedicineModal}
        setVisible={editModal ? setEditModal : setNewMedicineModal}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        edit={editModal}
        submitHandler={submitHandler}
      />

      <DeleteModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        deleteDrug={deleteDrug}
        loading={deleteLoading}
      />
    </Container>
  );
};

export default Drugs;
