import React, { useEffect, useState } from "react";
import { LeftCircleOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { DeleteButton, FormContainer, StyledContent } from "./styledComponents";
import styled from "styled-components";
import { getOneByID } from "../../../apis/patients";
import { message, Col, Form, Row, Input, Spin, Button, Modal } from "antd";
import { resetPatientPassword, deletePatient } from "../../../apis/patients";
import CustomAlerts from "../../utils/CustomAlerts/index.jsx";
import HasPrivilege from "../../utils/privilegeHandlers/HasPrivilege.jsx";
import Spinner from "../../utils/Spinner";
import * as Sentry from "@sentry/react";

const PatientInfo = styled.h3`
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const ResetPasswordFormContainer = styled.div`
  background-color: #fff;
  margin: 40px 0px;
  padding: 30px 60px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 480px) {
    padding: 20px 20px;
  }
`;

const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
  & .ant-modal-footer {
    border-top: none;
    text-align: center;
  }
`;

const StyledRow = styled(Row)`
  .ant-form-item-children-icon {
    right: 35px !important;
  }
`;

function ResetPassword() {
  const { pId: patientId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [patientDetails, setPatientDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    getOneByID(patientId)
      .then((response) => {
        if (response.data.status === 1) {
          setPatientDetails(response.data.body);
          setLoading(false);
        } else {
          message.error("Could not fetch patient details,Try again");
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error("Could not fetch patient details,Try again");
        setLoading(false);
      });
  }, []);

  const onFinish = (values) => {
    setButtonLoading(true);
    resetPatientPassword(patientId, values.newPassword)
      .then((res) => {
        if (res.status == 200) {
          form.resetFields();
          setResetSuccess(true);
          setVisible(true);
        } else {
          message.error("Service temporarily unavailable");
        }
        setButtonLoading(false);
      })
      .catch((err) => {
        message.error("Service temporarily unavailable");
        console.log(err);
        Sentry.captureException(err);
        setButtonLoading(false);
      });
  };

  const confirmDeletePatient = () => {
    setDeleteLoading(true);
    deletePatient(patientId)
      .then((response) => {
        if (response.data.status === 1) {
          message.success("Patient deleted Succesfully.");
          history.push("/dashboard");
          setDeleteLoading(false);
        } else {
          message.error("Something went wrong, Try Again");
          setDeleteLoading(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong, Try Again");
        setDeleteLoading(false);
      });
  };

  const closeModal = () => {
    setVisible(false);
    if (resetSuccess) {
      setResetSuccess(false);
    }
  };

  return (
    <>
      <h2>
        <LeftCircleOutlined
          onClick={() => {
            history.goBack();
          }}
        />
        {"  "}
        Reset Password
      </h2>
      <ResetPasswordFormContainer>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Row gutter={[36, 0]}>
              <Col lg={12} md={24} xs={24}>
                <PatientInfo>Patient ID: {patientDetails.id}</PatientInfo>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <PatientInfo>
                  Mobile No:
                  {patientDetails.phoneNumbers[0].countryCode.split(" ")[0] +
                    "-" +
                    patientDetails.phoneNumbers[0].phoneNumber}
                </PatientInfo>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <PatientInfo>
                  Patient Name: {patientDetails.firstName}{" "}
                  {patientDetails.lastName}
                </PatientInfo>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <PatientInfo>Email: {patientDetails.email}</PatientInfo>
              </Col>
            </Row>
            <StyledContent>
              <Form
                form={form}
                className="form-m2-style"
                layout="vertical"
                onFinish={(values) => onFinish(values)}
                scrollToFirstError={true}
              >
                <StyledRow gutter={[36, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="newPassword"
                      label="Enter New Password"
                      rules={[
                        {
                          required: true,
                          message: "Password is Required!",
                        },
                      ]}
                    >
                      <Input.Password size="large" className="form-col" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="confirmPassword"
                      label="Confirm New Password"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The two passwords that you entered do not match!"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password size="large" className="form-col" />
                    </Form.Item>
                  </Col>
                  <Col span="24" className="form-col">
                    <Form.Item>
                      <Row gutter={[12, 12]}>
                        <Col xl={17} lg={15} md={24} xs={24} justify="end">
                          <HasPrivilege role="RECEPTIONIST">
                            <DeleteButton
                              // type="primary"
                              // ghost
                              onClick={() => setVisible(true)}
                              style={{
                                backgroundColor: "#f2f2f2",
                                color: "#6d6d6d",
                                border: "none",
                              }}
                            >
                              Delete Patient
                            </DeleteButton>
                          </HasPrivilege>
                        </Col>
                        <Col xl={7} lg={9} md={24} xs={24}>
                          <Row gutter={[12, 12]}>
                            <Col xl={10} lg={10} md={16} xs={9}>
                              {" "}
                              <RightButton
                                type="primary"
                                ghost
                                onClick={() => history.push("/dashboard")}
                              >
                                Cancel
                              </RightButton>
                            </Col>
                            <Col xl={14} lg={14} md={8} xs={15}>
                              <RightButton
                                htmlType="submit"
                                type="primary"
                                loading={buttonLoading}
                              >
                                Change Password
                              </RightButton>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </StyledRow>
              </Form>
            </StyledContent>
          </>
        )}
      </ResetPasswordFormContainer>
      <StyledModal
        visible={visible}
        centered={true}
        onCancel={closeModal}
        footer={
          resetSuccess
            ? [
                <Button
                  key="ok"
                  type="primary"
                  onClick={() => {
                    setVisible(false);
                    setTimeout(() => {
                      setResetSuccess(false);
                    }, 1000);
                  }}
                >
                  Done
                </Button>,
              ]
            : [
                <Button
                  key="ok"
                  type="primary"
                  ghost
                  onClick={() => setVisible(false)}
                >
                  Cancel
                </Button>,
                <Button
                  key="ok"
                  type="primary"
                  onClick={() => confirmDeletePatient()}
                  loading={deleteLoading}
                >
                  Confirm
                </Button>,
              ]
        }
      >
        <CustomAlerts
          type={resetSuccess ? "success" : "delete"}
          title={resetSuccess ? "Successfully Password Reset!" : "Delete"}
          description={
            resetSuccess
              ? "You can now use your new password to login in to your account"
              : "Please click confirm if you want to delete the patient"
          }
        />
      </StyledModal>
    </>
  );
}

export default ResetPassword;
