import React from "react";
import styled from "styled-components";
import { Modal, Button, Row } from "antd";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/CancelIcon.svg";
import { useHistory } from "react-router-dom";
import Spinner from "../../utils/Spinner";

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

const ModalContentDiv = styled.div`
  height: 30vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledWarningIcon = styled(WarningIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledCancelIcon = styled(CancelIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledP = styled.p`
  font-size: 15px;
`;

const StyledPTitle = styled.p`
  font-size: 18px;
`;

const StatusModal = ({
  visible,
  closeModal,
  cancel,
  cancelAppointment,
  cancelLoading,
  loading,
  patientRegistration
}) => {
  return (
    <StyledModal
      visible={visible}
      closable={false}
      centered
      maskClosable={true}
      footer={false}
    >{loading ? <Spinner height={"30vh"} /> :
      <ModalContentDiv>
        <StyledWarningIcon />
        <StyledPTitle>{patientRegistration ? "Upload ID" : "Upload Document"}</StyledPTitle>
        <StyledP>
          Please ensure that all uploaded documents are correct.
        </StyledP>
        <Row justify="end" style={{ width: "100%", marginTop: "20px" }}>
          <Button
            key="ok"
            type="primary"
            ghost
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <RightButton
            key="ok"
            type="primary"
            onClick={() => cancelAppointment()}
          >
            Confirm
          </RightButton>
        </Row>
      </ModalContentDiv>}
    </StyledModal>
  );
};

export default StatusModal;