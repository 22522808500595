import { axiosInstanceOCR } from "../config/axiosInstance";
import axios from "axios";

export const createPatientOCR = (data) => {
    return axiosInstanceOCR.post(
        `${process.env.REACT_APP_OCR}/ocr_process`, data
    );
};

export const pingOCR = () => {
    return axiosInstanceOCR.get(
        `${process.env.REACT_APP_OCR}/ocr_process`
    );
};