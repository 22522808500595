import axiosInstance from '../config/axiosInstance';
import axios from 'axios';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;
// export const baseUrl = process.env.REACT_APP_STAGING_API_BASE_URL;
export const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return baseUrl;
    default:
      return process.env.REACT_APP_STAGING_API_BASE_URL;
  }
};

const PUBLIC_PREFIX = '/public';
export const contactUsRequest = (data) => {
  return axiosInstance.post(PUBLIC_PREFIX + '/contact-us', data);
};

export const createPatient = (data) => {
  return axiosInstance.post(`${PUBLIC_PREFIX}/create-patient`, data);
};

export const createAppointment = (data) => {
  return axiosInstance.post(`${PUBLIC_PREFIX}/create-appointment`, data);
};

export const getHospitalDetail = (doctorId, hospitalId) => {
  return axiosInstance.get(
    `${PUBLIC_PREFIX}/doctor/${doctorId}/hospital/${hospitalId}`
  );
};

export const getHospitalDetailWithHospitalId = (hospitalId, doctorId) => {
  console.log('docid in api', doctorId);
  return axiosInstance.get(
    `${PUBLIC_PREFIX}/info?${
      doctorId ? '&doctor=' + doctorId : ''
    }&hospital=${hospitalId}`
  );
};

export const getAvailableTimes = (queryString) => {
  return axiosInstance.get(`${PUBLIC_PREFIX}/time-slots/${queryString}`);
};

export const searchAppointmentUnderGivenDate = (data) => {
  console.log('data in api', data);
  return axiosInstance.post(
    `${PUBLIC_PREFIX}/search-previous-appointment`,
    data
  );
};

export const getDoctors = (departmentId, hospitalId) => {
  console.log(
    'depart id',
    `${PUBLIC_PREFIX}/info/doctors?department=${departmentId}&hospital=${hospitalId}`
  );
  return axiosInstance.get(
    `${PUBLIC_PREFIX}/info/doctors?department=${departmentId}&hospital=${hospitalId}`
  );
};

export const getSMSList = (queryString) => {
  console.log('querystring', queryString);
  //once jwt implemented (jwt in BE) comment 68 and uncomment 69
  // return axiosInstance.get(`sms/listMessages?${queryString}`);
  return axiosInstance.get(`/sms/listMessages?${queryString}`);
};
