import React, { useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import { Row, Col, Button, message } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";
import { deleteDepartment } from "../../../../apis/departments.js";
import { deleteField, deleteSection } from "../../../../apis/healthRecord.js";

const ModalBodyContainer = styled.div`
  width: 500px;
`;
const SessionDeleteConfirmationModal = ({
  visible,
  setVisible,
  selectedItem,
  setSelectedItem,
  refetch,
  setDeleteErrorModal,
  setErrorMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const onConfirmHandler = () => {
    setLoading(true);
    deleteSection(selectedItem.id)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) {
            message.success("Deleted!");
            refetch();
            setVisible(false);
          } else {
            message.error("Unable to delete!");
          }
          setSelectedItem(undefined);
          setLoading(false);
        } else {
          console.log(data);
          if (data.status === 0) {
            setErrorMessage(data.body);
            setDeleteErrorModal(true);
            setVisible(false);
          }
        }
      })
      .catch(({ response }) => {
        setLoading(false);
      });
  };
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="delete"
              title="Delete Form Field ?"
              description={`This action will permanently delete the Section ${
                selectedItem ? selectedItem.formName : ""
              }.`}
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVisible(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default SessionDeleteConfirmationModal;
