import React from "react";
import styled from "styled-components";

const NoDataContainer = styled.div`
  height: ${(prps) => prps.height || "calc(100vh - 300px)"};
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  background: ${(prps) => prps.bgColor};
  align-items: center;
  border-radius: 10px;
`;

const StyledMessage = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #acacac;
`;
const NoRecords = ({ message, height, bgColor }) => {
  return (
    <NoDataContainer height={height} bgColor={bgColor}>
      <StyledMessage>{message || "No Records"}</StyledMessage>
    </NoDataContainer>
  );
};

export default NoRecords;
