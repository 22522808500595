import Icon, {
  DownloadOutlined,
  LeftCircleOutlined,
  PrinterOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, message, Row, Form, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import { sidebarIcons } from "../../../constants/sideBarIcons";
import Spinner from "../../utils/Spinner";
import * as Sentry from "@sentry/react";
import {
  BlueBgTable,
  StyledDiv,
  StyledIcon,
} from "../AdminDashboard/styledComponents";
import {
  SectionContent,
  SidebarCol,
  StyledHead,
  StyledRouterContainer,
} from "../HealthRecords";
import {
  HealthRecordMenu,
  HealthRecordMenuItem,
} from "../HealthRecords/styledComponents";
import moment from "moment";
import { getAppointmentDetails } from "../../../apis/Appointment";
import { UserProfileHead } from "../Appoinment/userProfileHead";
import FormBuilder from "../../utils/FormBuilder";
import { copayOrSelf, insuranceFields } from "../PatientForm/formConfig";
import { PatientInfoContainer } from "../Appoinment";
import {
  calculatePayment,
  getAppointmentOrders,
  submitAppointment,
} from "../../../apis/pharmacy";
import { paymentColumns, StyledDivInput } from "./pharmacyColumns";
import { useReactToPrint } from "react-to-print";
import { getSettings } from "../../../apis/settings";
import { RightButton } from "../HealthRecords/Laboratory";
import { fetchInsuranceProviders } from "../../../apis/Insurance";
import Moment from "moment-timezone";
import { PROFILE_IMAGE_PREFIX } from "../../../constants/StringConstants";
import { genderList } from "../../Datasets/basicDetails";

const { Title } = Typography;
const { TextArea } = Input;

const StyledRowProfile = styled(Row)`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;

const StyledPayment = styled.p`
  color: gray;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0;
`;

const StyledRow = styled(Row)`
  font-weight: 600;
  color: rgb(93, 93, 93);
`;

const StyledCol = styled(Col)`
  background: #fff;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0 0 15px 0;
  border-radius: 10px;
`;

const SummaryContainer = styled.div`
  background: #fff;
  width: 100%;
  padding-bottom: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding: 15px 0 15px 20px;
  }
`;

const StyledP = styled.p`
  display: contents;
  font-size: 12px;
  font-weight: normal;
`;

const StyledTextArea = styled(TextArea)`
  & .ant-input {
    /* border: 1px solid black; */
    border-radius: 5px;
  }
`;

const StyledPreviewIcon = styled(Icon)`
  & .cls-1 {
    fill: rgba(0, 0, 0, 0.25);
  }
`;

const PharmacyDetails = () => {
  const { pId, pName, appointmentId, pharmacyRequestId } = useParams();
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);
  const images = useSelector((state) => state.imageReducer);
  const history = useHistory();
  const theme = useSelector((state) => state.themeReducer);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [mobileMenuVisible, setmobileMenuVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState(["0"]);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [appointmentOrdersTable, setAppointmentOrdersTable] = useState([]);
  const [appointmentOrders, setAppointmentOrders] = useState([]);
  const [appointmentOrdersBill, setAppointmentOrdersBill] = useState([]);
  const [editTestResults, setEditTestResults] = useState(false);
  const [insuranceTypes, setIncuranceTypes] = useState(insuranceFields);
  const [insuranceProviders, setInsuranceProviders] = useState([]);
  const [changedTabledata, setChangedTabledata] = useState(false);
  const [columns, setColumns] = useState([]);
  const [isInsurance, setIsInsurance] = useState();
  const [payInCashList, setPayInCashList] = useState([]);
  const [paymentSummary, setPaymentSummary] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
  const [paymentTypes, setPaymentMethods] = useState([]);
  const [hospitalLogo, setHospitalLogo] = useState();
  const [calculated, setCalculated] = useState(false);
  const [settings, setSettings] = useState();
  const componentRef = useRef();
  const [form] = Form.useForm();

  const menuItems = [{ id: "0", formName: "Pharmacy", icon: "pharmacy" }];

  const [selectedSection, setSelectedSection] = useState(menuItems[0]);

  const getPayment = () => {
    var cashOrders = [];
    var insuranceOrders = [];
    appointmentOrdersTable.forEach((item) => {
      if (payInCashList.includes(item.subSectionDataId))
        cashOrders.push({
          additionalInfoId: item.additionalInfo?.id,
          dataId: item.subSectionDataId,
          dispenseQty: item.dispense,
          drugCode: item.medicalCode,
        });
      else if (insuranceList.includes(item.subSectionDataId))
        insuranceOrders.push({
          additionalInfoId: item.additionalInfo?.id,
          dataId: item.subSectionDataId,
          dispenseQty: item.dispense,
          drugCode: item.medicalCode,
        });
    });
    var insurer = insuranceProviders?.find((item) => {
      return item.name === form.getFieldValue(["insuranceData", "type"]);
    });
    let data = {
      appointmentId: appointmentId,
      insuranceProviderId: insurer?.id,
      insuranceType: form.getFieldValue("insuranceDeductibleType"),
      paymentType: form.getFieldValue("paymentType")?.toUpperCase(),
      cashOrders: cashOrders,
      insuranceOrders: insuranceOrders,
    };
    calculatePayment(data)
      .then((response) => {
        if (response.data.status == 1) {
          message.destroy();
          message.success("Payment Calculated");
          setPaymentSummary(response.data.body);
          setCalculated(true);
        } else message.error(response.data.body);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      })
      .finally(() => {});
  };

  const handlemobileMenu = (value) => {
    setmobileMenuVisible(!mobileMenuVisible);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const menuChangeHandler = (e) => {
    console.log(e);
    setSelectedKey(e.selectedKeys);
  };

  const submitForm = () => {
    var values = form.getFieldsValue();
    var cashOrders = [];
    var insuranceOrders = [];
    appointmentOrdersTable.forEach((item) => {
      if (payInCashList.includes(item.subSectionDataId))
        cashOrders.push({
          additionalInfoId: item.additionalInfo?.id,
          dataId: item.subSectionDataId,
          dispenseQty: item.dispense,
          drugCode: item.medicalCode,
        });
      else if (insuranceList.includes(item.subSectionDataId))
        insuranceOrders.push({
          additionalInfoId: item.additionalInfo?.id,
          dataId: item.subSectionDataId,
          dispenseQty: item.dispense,
          drugCode: item.medicalCode,
        });
    });
    var insurer = insuranceProviders?.find((item) => {
      return item.name === form.getFieldValue(["insuranceData", "type"]);
    });
    var payload = {
      appointmentId: appointmentId,
      cashOrders: cashOrders,
      insuranceOrders: insuranceOrders,
      insuranceProviderId: insurer?.id,
      insuranceType: form.getFieldValue("insuranceDeductibleType"),
      patientId: patient?.pId ? patient.pId : pId,
      paymentType: form.getFieldValue("paymentType")?.toUpperCase(),
      pharmacyRequestId: pharmacyRequestId,
      timeZone: Moment.tz.guess(),
    };
    var id;
    if (values.insuranceData)
      id = insuranceProviders.find(
        (item) => item.name === values.insuranceData.type
      );
    if (values.insuranceData) {
      payload = {
        ...payload,
        insuranceData: {
          expireDate: values?.insuranceData?.expiry?.format("x"),
          number: values.insuranceData?.number,
          insuranceProvider: {
            id: id.id,
            insurancePlans: id.insurancePlans,
          },
          planName: values.insuranceDataPlan,
        },
        insuranceDeductibleType: values.insuranceDeductibleType?.toUpperCase(),
      };
    }
    submitAppointment(payload).then((response) => {
      if (response.data.status == 1) {
        message.destroy();
        message.success(response.data.body);
        history.push("/dashboard");
      } else message.error(response.data.body);
    });
  };

  const findInsuranceData = (data, changed) => {
    insuranceTypes[0].meta = data?.map((item) => item.name);
    setInsuranceProviders(data);
    var insurance = data?.filter(
      (item) => item.name == form.getFieldValue(["insuranceData", "type"])
    )[0];
    if (insurance)
      insuranceTypes[1].meta = insurance?.insurancePlans.map(
        (item) => item.value
      );
    setIncuranceTypes(insuranceFields);
    if (form.getFieldValue(["insuranceData", "type"]))
      setPaymentMethods([...insuranceFields, ...copayOrSelf]);
    else setPaymentMethods(insuranceFields);
    var insurer = data?.find((item) => {
      return item.name === changed.insuranceData.type;
    });
    if (insurer?.["hospitalInsuranceProviderData"]?.insuranceType === "BOTH") {
      copayOrSelf.find((item) => item.name === "insuranceDeductibleType").meta =
        ["own", "copay"];

      setPaymentMethods([...insuranceFields, ...copayOrSelf]);
    } else if (
      insurer?.["hospitalInsuranceProviderData"]?.insuranceType === "SELF"
    ) {
      copayOrSelf.find((item) => item.name === "insuranceDeductibleType").meta =
        ["own"];
      setPaymentMethods([...insuranceFields, ...copayOrSelf]);
    } else if (
      insurer?.["hospitalInsuranceProviderData"]?.insuranceType === "COPAY"
    ) {
      copayOrSelf.find((item) => item.name === "insuranceDeductibleType").meta =
        ["copay"];
      setPaymentMethods([...insuranceFields, ...copayOrSelf]);
    }
  };

  const onFormValueChange = (changed, all) => {
    setCalculated(false);
    if (changed.paymentType == "self") setIsInsurance(false);
    if (changed.paymentType == "insurance") setIsInsurance(true);
    if (changed.insuranceData) {
      if (!insuranceProviders.length)
        fetchInsuranceProviders("Pharmacy").then((resp) => {
          findInsuranceData(resp.data.body, changed);
        });
      else findInsuranceData(insuranceProviders, changed);
    }
  };

  useEffect(() => {
    if (!isInsurance) {
      setPayInCashList(
        appointmentOrdersTable?.map((item) => item.subSectionDataId)
      );
      setInsuranceList([]);
    } else {
      setPayInCashList([]);
      setInsuranceList([]);
    }
  }, [isInsurance]);

  useEffect(() => {
    setLoading(true);
    setTableLoading(true);
    fetchInsuranceProviders("Pharmacy").then((resp) => {
      insuranceTypes[0].meta = resp.data.body?.map((item) => item.name);
      setInsuranceProviders(resp.data.body);
      var insurance = resp.data.body?.filter(
        (item) => item.name == form.getFieldValue(["insuranceData", "type"])
      )[0];
      if (insurance)
        insuranceTypes[1].meta = insurance?.insurancePlans.map(
          (item) => item.value
        );
      setIncuranceTypes(insuranceFields);
      if (form.getFieldValue(["insuranceData", "type"]))
        setPaymentMethods([...insuranceFields, ...copayOrSelf]);
      else setPaymentMethods(insuranceFields);
    });
    getSettings().then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setSettings(data.body);
        }
      }
    });
    getAppointmentDetails(appointmentId)
      .then((response) => {
        if (response.data.status == 1) {
          setAppointmentDetails(response.data.body);
        } else {
          message.error(response.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      })
      .finally(() => {
        setLoading(false);
        // setTableLoading(false);
      });
    getAppointmentOrders(appointmentId, pharmacyRequestId)
      .then((response) => {
        if (response.data.status == 1) {
          setAppointmentOrders(response.data.body);
          let tableData = response.data.body?.table?.map((item) => {
            if (item.additionalInfo.dispenseQuantity)
              item.dispense = item.additionalInfo.dispenseQuantity;
            else item.dispense = 0;
            return item;
          });
          setAppointmentOrdersTable(tableData);
          setChangedTabledata(!changedTabledata);
          if (response.data.body?.bill)
            setPaymentSummary(response.data.body.bill);
          setAppointmentOrdersBill(response.data.body?.bill);
        } else {
          message.error(response.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, []);

  useEffect(() => {
    const objectURL = PROFILE_IMAGE_PREFIX + images.logo;
    // preloadImage(objectURL);
    try {
      fetch(objectURL, {
        method: "GET",
        headers: { "Content-Type": "application/octet-stream" },
        responseType: "blob",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          console.log(blob);
          const url = window.URL.createObjectURL(new Blob([blob]));
          setHospitalLogo(url);
        });
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  }, [images]);

  const changeDataForDispense = (type, data) => {
    setCalculated(false);
    setAppointmentOrdersTable((prev) => {
      prev.forEach((item) => {
        if (item.subSectionDataId == data.subSectionDataId) {
          if (type) item.dispense = item.dispense + 1;
          else item.dispense = item.dispense - 1;
        }
      });
      return prev;
    });
    setChangedTabledata(!changedTabledata);
    setColumns(columns);
  };

  return loading ? (
    <Spinner height="calc(100vh - 200px)" />
  ) : (
    <Row>
      <SidebarCol
        span={5}
        className={
          mobileMenuVisible
            ? "fixed_sidebar_panel mob-menu-open" //case when the menu is triggered
            : "fixed_sidebar_panel" // case when the menu is closed
        }
      >
        <Col
          className="sidebar_menu_tag"
          onClick={() => {
            handlemobileMenu(false);
          }}
        >
          <div className="menu_bar">
            <span></span>
          </div>
        </Col>
        <HealthRecordMenu
          className="sidepanel_menu_list"
          mode="vertical"
          selectedKeys={selectedKey}
          onSelect={menuChangeHandler}
        >
          {menuItems.map((item, i) => (
            <HealthRecordMenuItem
              key={i}
              icon={
                sidebarIcons[item.icon] ? (
                  <StyledIcon
                    color={theme["@primary-color"]}
                    component={sidebarIcons[item.icon]}
                    isActive={item.id === selectedSection.id}
                  />
                ) : (
                  <ReconciliationOutlined style={{ fontSize: "25px" }} />
                )
              }
            >
              {item.formName}
            </HealthRecordMenuItem>
          ))}
        </HealthRecordMenu>
      </SidebarCol>
      <SectionContent span={19} className="scroll_right_panel">
        <Col style={{ padding: "0px 0px 20px", marginTop: "15px" }}>
          <Row className="panel_row">
            <Col>
              <StyledHead>
                {/* {consultationHistoryButtonVisible == true && ( */}
                <LeftCircleOutlined
                  onClick={() =>
                    editTestResults
                      ? setEditTestResults(false)
                      : history.goBack()
                  }
                />
                &nbsp;&nbsp;
                {history.location.state ? "History" : "Request Details"}
              </StyledHead>
            </Col>
            <Col span={24}>
              <StyledRowProfile justify="center">
                <UserProfileHead
                  // user={user}
                  patientId={patient?.pId ? patient.pId : pId}
                  patientName={patient?.pName ? patient.pName : pName}
                />
              </StyledRowProfile>
            </Col>
          </Row>
        </Col>
        <StyledRouterContainer className="form-m2-style">
          <Row>
            <SummaryContainer>
              <Row>
                <Col span={1}></Col>
                <h3 style={{ color: theme["@primary-color"] }}>
                  Request Summary
                </h3>
              </Row>
              <Row>
                <Col lg={1} md={1} sm={1} xs={1}></Col>
                <Col lg={5} md={11} sm={11} xs={23}>
                  <StyledRow>
                    Date:&nbsp;&nbsp;
                    <StyledP>
                      {moment(appointmentDetails?.date).format("DD-MM-YYYY")}
                    </StyledP>
                  </StyledRow>
                </Col>
                <Col lg={0} md={0} sm={0} xs={1}></Col>
                <Col lg={6} md={12} sm={12} xs={23}>
                  <StyledRow>
                    Doctor:&nbsp;&nbsp;
                    <StyledP>
                      Dr. {appointmentDetails?.doctor?.userName}
                    </StyledP>
                  </StyledRow>
                  <Row></Row>
                </Col>
                <Col lg={0} md={1} sm={1} xs={1}></Col>
                <Col lg={6} md={11} sm={11} xs={23}>
                  <StyledRow>
                    Time:&nbsp;&nbsp;
                    <StyledP>
                      {moment(appointmentDetails?.startTime).format("hh:mm a")}
                      &nbsp;-&nbsp;
                      {moment(appointmentDetails?.endTime).format("hh:mm a")}
                    </StyledP>
                  </StyledRow>
                </Col>
                <Col lg={0} md={0} sm={0} xs={1}></Col>
                <Col lg={6} md={12} sm={12} xs={23}>
                  <StyledRow>
                    Request Type:&nbsp;&nbsp;
                    <StyledP style={{ textTransform: "capitalize" }}>
                      {appointmentOrders?.pharmacyRequest?.requestType.toLowerCase()}
                    </StyledP>
                  </StyledRow>
                </Col>
              </Row>
            </SummaryContainer>
          </Row>
        </StyledRouterContainer>
        {!history.location.state && (
          <StyledCol style={{ padding: "10px 0 0 35px" }} className="px-6">
            <PatientInfoContainer>
              <Form
                layout="vertical"
                // onFinish={onFinish}
                onValuesChange={onFormValueChange}
                form={form}
                scrollToFirstError={true}
                className="form-m2-style"
              >
                <Row gutter={[36, 0]}>
                  <Col>
                    <FormBuilder
                      formConfig={[
                        {
                          inputType: "heading",
                          label: "Payment",
                        },
                        {
                          inputType: "radio",
                          rules: [
                            {
                              required: true,
                              message: "Payment Type is required",
                            },
                          ],
                          name: "paymentType",
                          meta: ["self", "insurance"],
                          layout: { span: 24 },
                        },
                      ]}
                    />
                    {isInsurance && <FormBuilder formConfig={paymentTypes} />}
                  </Col>
                </Row>
              </Form>
            </PatientInfoContainer>
          </StyledCol>
        )}
        <StyledCol>
          <Row>
            <Col style={{ width: "100%", padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 15px",
                }}
              >
                <Title
                  style={{
                    color: theme["@primary-color"],
                  }}
                  level={4}
                >
                  Prescriptions
                </Title>
                <Button
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  ghost
                  style={{
                    border: "none",
                    color: theme["@primary-color"],
                    boxShadow: "none",
                    fontSize: "17px",
                  }}
                >
                  Print
                </Button>
              </div>
              <Col span={24} style={{ padding: "10px 20px" }}>
                <StyledDiv bgcolor={theme["@primary-color"]}>
                  <div>&nbsp;&nbsp;Tests</div>
                  <div>&nbsp;&nbsp;</div>
                </StyledDiv>
                <BlueBgTable
                  scroll={{ x: "max-content" }}
                  dataSource={appointmentOrdersTable}
                  columns={paymentColumns(
                    changeDataForDispense,
                    payInCashList,
                    setPayInCashList,
                    insuranceList,
                    setInsuranceList,
                    isInsurance,
                    history,
                    true
                  )}
                  pagination={false}
                />
                {!history.location.state && (
                  <div style={{ margin: "20px 0" }}>
                    <RightButton
                      htmlType="submit"
                      type="primary"
                      style={{
                        padding: "10px 20px",
                        height: "auto",
                        borderRadius: "5px",
                      }}
                      onClick={() =>
                        form
                          .validateFields()
                          .then((response) => {
                            if (
                              payInCashList.length + insuranceList.length ==
                              appointmentOrdersTable.length
                            )
                              getPayment();
                            else {
                              message.destroy();
                              message.error(
                                "Select the payment type for each order!"
                              );
                            }
                          })
                          .catch((error) => {
                            console.log("error", error);
                          })
                      }
                    >
                      Calculate Payment
                    </RightButton>
                  </div>
                )}
              </Col>
            </Col>
          </Row>
        </StyledCol>
        <StyledCol style={{ padding: "25px 35px" }}>
          <Row>
            <Col style={{ width: "100%" }}>
              <FormBuilder
                formConfig={[
                  {
                    inputType: "heading",
                    label: "Payment Summary",
                  },
                ]}
              />
              <Row gutter={[36, 24]}>
                <Col lg={8} md={10} sm={12} xs={24}>
                  <StyledPayment>&nbsp;Total Amount: </StyledPayment>
                </Col>
                <Col lg={16} md={14} sm={12} xs={24}>
                  <StyledPayment>
                    <b>
                      {settings?.currency}&nbsp;
                      {parseFloat(
                        paymentSummary?.totalAmount
                          ? paymentSummary.totalAmount
                          : 0
                      ).toFixed(2)}
                    </b>
                  </StyledPayment>
                </Col>
                <Col xl={8} lg={8} md={10} sm={12} xs={24}>
                  <StyledPayment>&nbsp;Insurance Claim Amount: </StyledPayment>
                </Col>
                <Col xl={16} lg={16} md={14} sm={12} xs={24}>
                  <StyledPayment>
                    <b>
                      {settings?.currency}&nbsp;
                      {parseFloat(
                        paymentSummary?.totalAmountOnApprovedOrder
                          ? paymentSummary.totalAmountOnApprovedOrder
                          : 0
                      ).toFixed(2)}
                    </b>
                  </StyledPayment>
                </Col>
                <Col lg={8} md={10} sm={12} xs={24}>
                  <StyledPayment>&nbsp;VAT: </StyledPayment>
                </Col>
                <Col lg={16} md={14} sm={12} xs={24}>
                  <StyledPayment>
                    <b>
                      {settings?.currency}&nbsp;
                      {parseFloat(
                        paymentSummary?.vat ? paymentSummary.vat : 0
                      ).toFixed(2)}
                    </b>
                  </StyledPayment>
                </Col>
                <Col lg={8} md={10} sm={12} xs={24}>
                  <StyledPayment>&nbsp;Payable: </StyledPayment>
                </Col>
                <Col lg={16} md={14} sm={12} xs={24}>
                  <StyledPayment>
                    <b>
                      {settings?.currency}&nbsp;
                      {parseFloat(
                        paymentSummary?.payable ? paymentSummary.payable : 0
                      ).toFixed(2)}
                    </b>
                  </StyledPayment>
                </Col>
                {isInsurance && (
                  <>
                    <Col lg={8} md={10} sm={12} xs={24}>
                      <StyledPayment>&nbsp;Claimable Amount: </StyledPayment>
                    </Col>
                    <Col lg={16} md={14} sm={12} xs={24}>
                      <StyledPayment>
                        <b>
                          {settings?.currency}&nbsp;
                          {parseFloat(
                            paymentSummary?.claimableAmount
                              ? paymentSummary.claimableAmount
                              : 0
                          ).toFixed(2)}
                        </b>
                      </StyledPayment>
                    </Col>
                  </>
                )}
              </Row>
              {!history.location.state && (
                <Row justify="end" style={{ paddingTop: "15px", gap: "10px" }}>
                  <Button
                    className="btn-cancel"
                    type="primary"
                    ghost
                    onClick={() => history.push("/dashboard")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="btn-save"
                    onClick={() =>
                      form
                        .validateFields()
                        .then(() => {
                          if (calculated) submitForm();
                          else {
                            message.destroy();
                            message.error("Please update the calculates!");
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          Sentry.captureException(err);
                        })
                    }
                  >
                    Submit
                  </Button>
                </Row>
              )}
              <div style={{ display: "none" }}>
                <div ref={componentRef}>
                  <div style={{ padding: "30px", marginTop: "20px" }}>
                    <Row justify="space-between">
                      <img src={hospitalLogo} style={{ width: "20%" }} />
                      <div style={{ alignItems: "center", display: "flex" }}>
                        Date: {moment().format("DD-MM-YYYY")}
                      </div>
                    </Row>
                    <Row
                      style={{
                        borderTop: `2px solid ${theme["@primary-color"]}`,
                        height: "20px",
                        width: "100%",
                      }}
                    ></Row>
                    <Row
                      justify="space-between"
                      style={{ padding: "20px 20px 0 20px" }}
                    >
                      <Col>
                        <p>
                          Patient ID :{" "}
                          {
                            appointmentOrders?.pharmacyRequest?.appointment
                              ?.patient?.id
                          }
                        </p>
                        <p style={{ textTransform: "capitalize" }}>
                          Patient Name :{" "}
                          {
                            appointmentOrders?.pharmacyRequest?.appointment
                              ?.patient?.userName
                          }
                        </p>
                        <p>
                          Age :{" "}
                          {moment().diff(
                            appointmentOrders?.pharmacyRequest?.appointment
                              ?.patient?.dob,
                            "years",
                            false
                          )}
                        </p>
                        <p>
                          Gender :{" "}
                          {
                            genderList().find(
                              (item) =>
                                item.code ==
                                appointmentOrders?.pharmacyRequest?.appointment
                                  ?.patient?.gender
                            )?.text
                          }
                        </p>
                      </Col>
                      <Col>
                        <p>Pharmacy Assistant: {user?.fullName}</p>
                        <p>
                          Doctor:{" "}
                          {
                            appointmentOrders?.pharmacyRequest?.appointment
                              ?.doctor?.userName
                          }
                        </p>
                        <p>
                          Department:{" "}
                          {
                            appointmentOrders?.pharmacyRequest?.appointment
                              ?.department?.departmentName
                          }
                        </p>
                        <p>
                          Date :{" "}
                          {moment(
                            appointmentOrders?.pharmacyRequest?.appointment
                              ?.date
                          ).format("DD-MM-YYYY")}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        borderTop: `1px solid #f0f0f0`,
                        height: "2px",
                        width: "100%",
                        marginBottom: "20px",
                        marginTop: "20px",
                      }}
                    ></Row>
                    <BlueBgTable
                      dataSource={appointmentOrdersTable}
                      columns={paymentColumns(
                        changeDataForDispense,
                        payInCashList,
                        setPayInCashList,
                        insuranceList,
                        setInsuranceList,
                        isInsurance,
                        { location: { state: true } }
                      )}
                      pagination={false}
                    />
                    <Row
                      style={{
                        borderTop: `1px solid #f0f0f0`,
                        height: "2px",
                        width: "100%",
                        marginBottom: "30px",
                        marginTop: "30px",
                      }}
                    ></Row>
                    {appointmentOrders.pharmacyRequest?.paymentType ? (
                      <Col>
                        <p style={{ textTransform: "capitalize" }}>
                          Payment Type :{" "}
                          {appointmentOrders.pharmacyRequest?.paymentType?.toLowerCase()}
                        </p>
                      </Col>
                    ) : (
                      <></>
                    )}
                    <table
                      style={{
                        width: "100%",
                        border: "1px solid #f0f0f0",
                        borderCollapse: "collapse",
                      }}
                    >
                      {appointmentOrders?.bill &&
                        Object.keys(appointmentOrders?.bill) &&
                        Object.keys(appointmentOrders?.bill).map((item) => (
                          <tr
                            style={{
                              border: "1px solid #f0f0f0",
                              borderCollapse: "collapse",
                            }}
                          >
                            <td
                              style={{
                                border: "1px solid #f0f0f0",
                                borderCollapse: "collapse",
                              }}
                            >
                              <div style={{ padding: "5px 0 5px 20px" }}>
                                {item
                                  .replace(/([A-Z])/g, " $1")
                                  .charAt(0)
                                  .toUpperCase() +
                                  item.replace(/([A-Z])/g, " $1").slice(1)}
                              </div>
                            </td>
                            <td
                              style={{
                                border: "1px solid #f0f0f0",
                                borderCollapse: "collapse",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "600",
                                }}
                              >
                                {settings?.currency}{" "}
                                {parseFloat(
                                  appointmentOrders?.bill[item]
                                    ? appointmentOrders?.bill[item]
                                    : 0
                                ).toFixed(2)}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </StyledCol>
      </SectionContent>
    </Row>
  );
};
export default PharmacyDetails;
