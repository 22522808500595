import {
  genderList,
  maritalStatus,
  raceList,
  addressType,
  phoneType,
  emailType,
  vipType,
  addressStateType,
  contactRoleType,
  documentTypes,
  idType,
} from '../../components/Datasets/basicDetails';
import { religions } from '../../components/Datasets/Religion';
import { relationship } from '../../components/Datasets/relationship';
import { nationality } from '../../components/Datasets/nationality';
import { primaryLanguages } from '../../components/Datasets/primaryLanguage';
import { citizenship } from '../../components/Datasets/citizenship';
export const validation = (documentList, value) => {
  var pattern = /^[0-9]{7}$/;
  var status = true;
  for (var i = 0; i < documentList.length; i++) {
    switch (documentList[i].type) {
      case '1':
        // status = false;
        break;
      case '2':
        // status = false;
        break;
      case '3':
        // status = false;
        break;
      case '4':
        // status = false;
        break;
      case '5':
        var pattern = /^[0-9]{7}$/;
        if (pattern.test(value)) {
          status = true;
        } else {
          status = false;
        }
        break;
      case '6':
        // status = false;
        break;
    }
    // if (status === false) {
    //   console.log("doc no validation status final", status);

    //   break;
    // }
  }

  return status;
};
export const config = [
  //   {
  //     inputType: "heading",
  //     label: "Patient Registration",
  //   },
  {
    inputType: 'input',
    rules: [
      {
        required: true,
        pattern: new RegExp(
          /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
        ),
        message: 'Please enter alphabets only.',
      },
    ],
    name: 'firstName',
    label: 'First Name',
  },
  {
    inputType: 'input',
    rules: [
      {
        required: true,
        pattern: new RegExp(
          /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
        ),

        message: 'Please enter alphabets only.',
      },
    ],
    name: 'lastName',
    label: 'Last Name',
  },
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Gender is required' }],
    name: 'gender',
    label: 'Gender',

    meta: genderList(),
  },
  {
    inputType: 'date',
    rules: [{ required: true, message: 'Date of Birth is required' }],
    name: 'dob',
    label: 'Date of Birth',
    past: true,
    placeholder: 'DD-MM-YYYY',
  },
  {
    inputType: 'input',
    rules: [
      {
        // required: true,
        pattern: new RegExp(/[0-9]/),
        // message: "Age is required",
      },
    ],
    name: 'age',
    label: 'Age',
    disabled: true,
  },
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Phone type is required' }],
    name: 'phoneType',
    label: 'Phone Type',
    meta: phoneType(),
  },
];

export const documenType = [
  {
    inputType: 'heading',
    label: 'Document Type',
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Type is required" }],
    name: 'type',
    label: 'Type',
  },
];
export const phoneNumberType = [
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Phone type is required' }],
    name: 'phoneType',
    label: 'Phone Type',
    meta: phoneType(),
  },

  // {
  //   inputType: "phoneNumber",
  //   rules: [
  //     { required: true, message: "Phone type is required" },
  //     ({ getFieldValue }) => ({
  //       validator(rule, value) {
  //         if (value && value.phoneNumber && value.countryCode) {
  //           return Promise.resolve();
  //         } else {
  //           return Promise.reject("Phone Number and Country code required");
  //         }
  //       },
  //     }),
  //     ({ getFieldValue }) => ({
  //       validator(rule, value) {
  //         if (value) {
  //           var pattern = /[0-9]+/;
  //           console.log("match", pattern.test(value.phoneNumber));
  //           if (pattern.test(value.phoneNumber)) {
  //             return Promise.resolve();
  //           } else {
  //             return Promise.reject("Enter a valid mobile Number");
  //           }
  //         }
  //       },
  //     }),
  //   ],
  //   name: "phoneNumber",
  //   label: "Phone Number",

  //   meta: [],
  // },
];
export const staticDocumentType = [
  {
    inputType: 'heading',
    label: 'Document Type',
  },
  {
    inputType: 'select',
    // rules: [{ required: true, message: "Type is required" }, {}],
    name: 'documentType',
    label: 'Type',
    meta: idType(),
  },
  {
    inputType: 'input',

    // rules: [
    //   {
    //     required: true,
    //     // pattern: new RegExp(/[0-9]/),
    //     message: "Document Number is required",
    //   },
    // ],
    name: 'number',
    label: 'Document Number',
  },
  {
    inputType: 'date',
    // rules: [{ required: true, message: "Document Expiry Date is required" }],
    name: 'expiryDate',
    label: 'Document  Expiry Date',
    past: false,
  },
  // {
  //   inputType: "input",
  //   rules: [{ required: true, message: "Document Expiry Date is required" }],
  //   name: "documentUrl",
  //   label: "Dcouemnt Url",
  // },
];
export const paymentType = [];
export const insuranceFields = [
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Insurance type is required' }],
    name: ['insuranceData', 'type'],
    label: 'Insurance',
    meta: [],
  },
  {
    inputType: 'select',
    rules: [{ required: true, message: 'Insurance plan is required' }],
    name: 'insuranceDataPlan',
    label: 'Insurance Plan',
    meta: [],
  },
  {
    inputType: 'input',
    rules: [
      {
        required: true,
        pattern: new RegExp('^[a-zA-Z0-9]+$'),
        message: 'Please enter alphabets and digits only.',
      },
    ],
    name: ['insuranceData', 'number'],
    label: 'Insurance Number',
  },
  {
    inputType: 'date',
    rules: [{ required: true, message: 'Expiry Date is required' }],
    name: ['insuranceData', 'expiry'],
    label: 'Expiry Date',
    past: false,
  },
];
export const copayOrSelf = [
  {
    inputType: 'heading',
    label: 'Insurance deductable',
  },
  {
    inputType: 'radio',
    rules: [{ required: true, message: 'Insurance deductable is required' }],
    name: 'insuranceDeductibleType',
    meta: ['own', 'copay'],
    layout: { span: 24 },
  },
];

export const deathStatus = [
  {
    inputType: 'heading',
    label: 'Death status',
  },
  {
    inputType: 'radio',
    rules: [{ required: true, message: 'Death Status is required' }],
    name: 'deathStatus',
    meta: ['No', 'Yes'],
    layout: { span: 24 },
    // label: "Is Dead ?",
  },

  {
    inputType: 'date',
    rules: [{ required: false }],
    name: 'deathDate',
    label: 'Date',
  },
];
