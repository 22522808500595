import React from "react";
import CustomModal from "../utils/CustomModal.jsx/index.jsx";
import ForgotPasswordBody from "./ForgotPasswordBody.jsx";
const ForgotPasswordModal = ({ visible, setVisible }) => {
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ForgotPasswordBody setVisible={setVisible} />
    </CustomModal>
  );
};

export default ForgotPasswordModal;
