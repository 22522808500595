import React, { useCallback, useEffect, useState } from 'react';

import ApexCharts from 'apexcharts';
import { getRevenueCount } from '../../../../apis/DashboardAnalytics';
import { getHospitalId } from '../../../utils/LocalStorage/index';
import { Card, DatePicker, message, Typography, Row, Col } from 'antd';
import styled from 'styled-components'; // Import styled-components
const { Title } = Typography;

const StyledDatePicker = styled(DatePicker)`
  margin: 2px;
`;
const StyledPara = styled.p`
  font-size: 18px;
  margin-top: 10px;
`;
export const SectionHeading = styled(Typography)`
  font-size: 25px !important;
  color: #606060 !important;
  background-color: 'grey';
`;

const Analytics = () => {
  const [startDate, setStartDate] = useState(''); // Selected start date
  const [endDate, setEndDate] = useState(''); // Selected end date
  const [loading, setLoading] = useState(false);
  const [revenueCount, setRevenueCount] = useState({});

  const RevenueCountCalculation = () => {
    const formatStartDate = startDate?.format('x');
    const formatEndDate = endDate?.format('x');

    getRevenueCount(getHospitalId(), formatStartDate, formatEndDate)
      .then((resp) => {
        if (resp.data) {
          setRevenueCount(resp.data); // Update the revenue count state
        } else {
          message.error('Could not fetch total revenue-1, Try again');
        }
      })
      .catch((err) => {
        message.error('Could not fetch total revenue, Try again');
        console.log('error is', err);
      });
  };

  // Use useEffect to automatically fetch revenue count when dates change
  useEffect(() => {
    if (startDate && endDate) {
      setLoading(true);
      RevenueCountCalculation();
      setLoading(false);
    }
  }, [startDate, endDate]);

  return (
    <div>
      <Card
        title={<Title level={3}>Revenue & Appointments</Title>}
        headStyle={{ backgroundColor: '#FAFAFA' }}
      >
        <Row justify="center" gutter={16}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <StyledDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              format="DD-MM-YYYY"
              placeholder="Select start date"
              size="large"
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <StyledDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              format="DD-MM-YYYY"
              placeholder="Select End date"
              size="large"
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        {/* <button onClick={RevenueCountCalculation}>Search</button> */}
        {loading ? (
          <p>Loading...</p>
        ) : (
          revenueCount !== null && (
            <StyledPara>
              {revenueCount.totalConsultationRevenue &&
                revenueCount.totalAppointments && (
                  <p>
                    Revenue is :{' '}
                    <b>{revenueCount.totalConsultationRevenue} INR</b>
                    <br />
                    Total appointment is :{' '}
                    <b>{revenueCount.totalAppointments}</b>
                  </p>
                )}
            </StyledPara>
          )
        )}
      </Card>
    </div>
  );
};

export default Analytics;
