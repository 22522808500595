import axios from 'axios'
import { message } from 'antd'

// export const baseUrl = "http://10.10.1.45:8082/api/v1";
export const baseUrl = process.env.REACT_APP_API_BASE_URL
// export const baseUrl = process.env.REACT_APP_STAGING_API_BASE_URL;
export const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return baseUrl
    default:
      return process.env.REACT_APP_STAGING_API_BASE_URL
  }
}

export const getSubdomain = (url) => {
  const hostname = window.location.hostname
  const subdomain = hostname.replace('.meditagg.com', '')

  return subdomain
}

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: { subdomain: getSubdomain() },
  validateStatus: function (status) {
    return status >= 200 && status < 500 // default
  },
})

export const axiosInstanceOCR = axios.create({
  baseURL: '',
})

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
    ? false
    : true
}

const validateRequest = (request) => {
  const userApiPath = ['admin', 'auth', 'patient', 'sms', 'user']
  const userDataApiPath = [
    'appointment',
    'bill',
    'consent-form',
    'dischargesummary',
    'dropdown',
    'insurance',
    'ipd',
    'OTBooking',
    'public',
    'sample-form',
    'stomp',
  ]

  const hospitalApiPath = [
    'bed',
    'beds',
    'departments',
    'chief-complaints',
    'hl7',
    'hl7-status',
    'hospital',
    'laboratory',
    'org',
    'pharmacy',
    'radiology',
    'settings',
    'ward',
    'category',
  ]

  const isDepartmentPattern = /\/\d+\/departments/.test(request.url)
  const isUserApi = userApiPath.some((e) =>
    `${request.baseURL}${request.url}`.includes(`v1/${e}`),
  )

  const isUserDataApi = userDataApiPath.some((e) =>
    `${request.baseURL}${request.url}`.includes(`v1/${e}`),
  )

  const isHospitalApi =
    hospitalApiPath.some((e) =>
      `${request.baseURL}${request.url}`.includes(`v1/${e}`),
    ) || isDepartmentPattern

  if (isUserApi) {
    request.baseURL = process.env.REACT_APP_API_BASE_USER_URL
  }

  if (isUserDataApi) {
    request.baseURL = process.env.REACT_APP_USER_DATA_API_BASE_URL
  }

  if (isHospitalApi) {
    request.baseURL = process.env.REACT_APP_API_BASE_HOSPITAL_URL
  }

  return request
}

//request handler
const requestHandler = (request) => {
  request = validateRequest(request)
  if (isHandlerEnabled(request)) {
    // inject access token here
    const token = localStorage.getItem('token')
    if (token)
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      }
  }
  return request
}

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // Handle errors
  }
  console.log('axios error', error)
  if (!error.response) {
    message.destroy()
    message.error('Unable to connect server')
  } else if (error.response.status == 500) {
    message.destroy()
    message.error('Something went wrong at server side!')
  } else if (error.response.status == 403) {
    message.destroy()
    message.error('Permisson denied')
  } else if (error.response.status == 401) {
    window.location.href = '/login'
    localStorage.clear()
    message.destroy()
    message.error('Token Expired')
  }
  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
  }
  return response
}

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
)
axiosInstance.interceptors.request.use((request) => requestHandler(request))
export default axiosInstance
