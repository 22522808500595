import React, { useState } from "react";
import styled from "styled-components";
import { Input, Row, Col, List, Checkbox, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getHealthRecordForms } from "../../../../../apis/healthRecord";
import { useEffect } from "react";

const PopoverContentContainer = styled.div`
  width: 250px;
  /* min-height: 400px; */
`;
const StyledChcekBox = styled(Checkbox)`
  /* margin-left: 20px; */
  & .ant-checkbox-inner {
    border-color: ${(props) => props.color};
  }
`;
const StyledSearch = styled(Input)`
  /* && {
    border-radius: 10px;
    background: #f2f2f2;
  } */
  & .ant-input-group-addon {
    border-radius: 0px;
    background: #f2f2f2;
    padding-left: 31px;
    color: #aeaeae;
    font-size: 15px;
  }

  & .ant-input {
    border-radius: 0px;
    background: #f2f2f2;
    border-left: 0;
  }
`;
const ObservationsPopOver = ({
  selected,
  setSelected,
  loading,
  forms,
  setSearchParam,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  return (
    <PopoverContentContainer>
      <Row>
        <Col span={24}>
          <StyledSearch
            addonBefore={<SearchOutlined />}
            placeholder="Search"
            onChange={(e) => {
              // debounceOnChange(e.target.value);
              setSearchParam(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter");
            }}
          />
        </Col>
        <Col span={24} style={{ overflowY: "scroll"}}>
          <List
            loading={loading}
            dataSource={forms}
            bordered
            renderItem={(item) => {
              return listItem(
                item,
                selected,
                setSelected,
                selected.some((form) => form.id == item.id),
                theme
              );
            }}
          ></List>
        </Col>
      </Row>
    </PopoverContentContainer>
  );
};

const listItem = (item, selected, setSelected, checked, theme) => {
  return (
    <List.Item>
      <StyledChcekBox
        size="large"
        checked={checked}
        onChange={(e) => {
          if (e.target.checked) setSelected([...selected, item]);
          else
            setSelected(
              selected.filter((selectedItem) => selectedItem.id !== item.id)
            );
        }}
        key={item.id}
        color={theme["@primary-color"]}
      >
        {item.formName}
      </StyledChcekBox>
    </List.Item>
  );
};

export default ObservationsPopOver;
