import React from "react";
import CustomModal from "../CustomModal.jsx";
import CustomAlerts from "../CustomAlerts/index.jsx";
import { Row, Col, Button } from "antd";
const SuccessModal = ({ message, onOk, visible, okText, setVisible, type }) => {
  return (
    <CustomModal
      visible={visible}
      isClosable={true}
      setVisible={setVisible}
      okText
      type={type}
      antdMOdalProps={{ centered: true, maskClosable: true, onCancel: onOk }}
    >
      <CustomAlerts type="success" title={message} />
    </CustomModal>
  );
};

export default SuccessModal;
