import React from "react";
import { Row, Col } from "antd";
import Icon, { DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/DeleteIcon.svg";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";

export const columns = (setEditModal, setDeleteModal, setselectedItem) => [
  {
    width: "1vw",
  },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
  },
  { title: "Form Name", dataIndex: "name" },
  {
    title: "Departments",
    dataIndex: ["departments"],
    render: (value) => {
      return value.map((item) => item.departmentName).join(", ");
    },
  },
  { title: "Created Date", dataIndex: "date" },
  {
    title: "Actions",
    render: (record) => (
      <Row gutter={12}>
        <Col>
          <a href={`${PROFILE_IMAGE_PREFIX}${record.formUrl}`}>
            <DownloadOutlined style={{ fontSize: "20px" }} />
          </a>
        </Col>
        <Col>
          {" "}
          <Icon
            style={{ fontSize: "20px" }}
            component={EditIcon}
            onClick={() => {
              setselectedItem(record);
              setEditModal(true);
            }}
          />
        </Col>
        <Col>
          <Icon
            style={{ fontSize: "22px" }}
            component={DeleteIcon}
            onClick={() => {
              setselectedItem(record);
              setDeleteModal(true);
            }}
          />
        </Col>
      </Row>
    ),
  },
];
