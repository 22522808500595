import React from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
export const withPrivilege = (OriginalComponent, privilege) => {
  return (props) => {
    const history = useHistory();
    const user = useSelector((state) => state.userReducer);
    if (user.roles && user.roles.includes(privilege))
      return <OriginalComponent {...props} />;
    else
      return (
        <div>
          <div>Permission denied: {privilege}</div>
          <Button type="link" onClick={() => history.goBack()}>
            Go Back
          </Button>
        </div>
      );
  };
};
