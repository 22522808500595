import React from 'react';
import { BlueBgTable } from './styledComponents';
import { columns } from './tableConfig';

const HospitalListTable = ({
  hospitals,
  loading,
  setEditModal,
  setSelectedItem,
  setDeleteModal,
  history,
}) => {
  return (
    <BlueBgTable
      headBg={'#26b1ff'}
      columns={columns(setEditModal, setDeleteModal, setSelectedItem, history)}
      dataSource={hospitals}
      //   loading={loading}
    />
  );
};
export default HospitalListTable;
