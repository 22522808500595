import React from "react";
import styled from "styled-components";
import {
  Select,
  Button,
  Input,
  Table,
  Row,
  Checkbox,
  Col,
  DatePicker,
  InputNumber,
  TimePicker,
} from "antd";
export const UserTypeSelect = styled(Select)`
  width: 100%;
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 40px;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #929292;
    border-radius: 5px;
  }

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector::after,
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 40px;
    font-size: 17px;
    padding-left: 17px;
  }
`;

export const NewUserButton = styled(Button)`
  height: 40px;
  width: 200px;
  font-size: 17px;
  border-radius: 5px !important;
`;
export const StyledContent = styled.div`
  // width: 85%;
  margin: auto;
  min-height: calc(100vh - 90px);
  &.container {
    width: 100%;
    max-width: 1560px;
  }

  h2.h2-base-600 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.02em;
    color: #504c4c;
    margin-bottom: 25px;
  }
  h2.h2-base-600 span {
    margin-right: 10px;
  }
  .form-m2-style h3 {
    margin: 15px 0 35px;
    font-size: 20px;
  }
  .form-m2-style .ant-form-item-label label {
    font-size: 16px;
    color: #818181;
  }
  .form-m2-style .ant-form-item-label label:before {
    display: none;
  }
  .form-m2-style .ant-form-item-label label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff0003;
    font-size: 18px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    font-weight: 700;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-input-number-input {
  height: 60px;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-input,
  .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
  .form-m2-style .ant-form-item-control-input-content .ant-picker,
  .form-m2-style .ant-form-item-control-input-content .ant-input-number {
    height: 60px;
    border: 1px solid #d6d6d6;
    border-radius: 5px !important;
    font-size: 18px;
    /* color: #333333; */
  }
  .form-m2-style .ant-form-item-control-input-content .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-select + .ant-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select:hover
    .ant-select-selector {
    border: 1px solid #d6d6d6;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-search-input {
    height: 60px !important;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-placeholder,
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-item {
    line-height: 56px !important;
  }

  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-multiple
    .ant-select-selection-item {
    line-height: 30px !important;
  }

  .form-m2-style .ant-form-item-explain div {
    font-size: 12px;
    text-transform: capitalize;
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }
  .form-m2-style
    .ant-form-item-has-error
    .ant-select:not(.ant-select-borderless)
    .ant-select-selector {
    border-color: #d6d6d6 !important;
  }
  .form-m2-style .ant-btn-background-ghost.ant-btn-danger {
    padding: 3px 10px;
    height: auto;
    font-weight: 600;
  }
  .form-m2-style .add-btn {
    padding: 9px 10px;
    height: auto;
    font-weight: 600;
    margin: 12px 0 0;
  }
  .form-check-tag {
    margin: 5px 0 25px;
  }
  .form-check-tag span {
    font-size: 18px;
  }
  .form-check-tag .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .btn-save,
  .btn-cancel {
    min-width: 120px;
    font-size: 18px;
    height: 50px;
    border: 2px solid #26b1ff;
    padding: 0px 10px;
    font-weight: 500;
    border-radius: 6px;
  }
  @media (max-width: 1520px) {
    &.container {
      max-width: 1310px;
    }
    .px-6 {
      padding: 45px 60px;
    }
  }
  @media (max-width: 1439px) {
    &.container {
      max-width: 1240px;
    }
    .px-6 {
      padding: 45px 60px;
    }
  }
  @media (max-width: 1359px) {
    &.container {
      max-width: 1170px;
    }
    .px-6 {
      padding: 45px 40px;
    }
    h2.h2-base-600 {
      padding: 0px 15px;
    }
  }
  @media (max-width: 1100px) {
    .form-m2-style .ant-form-item-label label {
      font-size: 14px;
    }
    .form-m2-style .ant-form-item-control-input-content .ant-input,
    .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
    .form-m2-style .ant-form-item-control-input-content .ant-picker,
    .form-m2-style .ant-form-item-control-input-content .ant-input-number {
      height: 50px;
      font-size: 15px;
    }
    .form-m2-style .ant-form-item-control-input-content .ant-input-number-input {
  height: 50px;
  }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-search-input {
      height: 50px !important;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-placeholder,
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-item {
      line-height: 46px !important;
    }
    .form-check-tag span {
      font-size: 15px;
    }
    .form-m2-style h3 {
      margin: 12px 0 24px;
      font-size: 18px;
    }
    h2.h2-base-600 {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    .form-m2-style {
      max-width: 420px;
      margin: 0 auto;
    }
    .form-m2-style > .ant-row {
      display: block;
    }
    .form-m2-style .ant-col {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .form-m2-style .flex-end-btn .ant-row {
      display: flex;
    }
    .flex-end-btn .ant-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      align-items: center;
    }
    .flex-end-btn .ant-form-item-control {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .btn-save,
    .btn-cancel {
      min-width: 110px;
      font-size: 16px;
      height: 42px;
    }
    .form-m2-style .ant-form-item-explain div {
      font-size: 11px;
    }
  }
  @media (max-width: 600px) {
    .px-6 {
      padding: 45px 15px;
    }
  }
  @media (max-width: 480px) {
    h2.h2-base-600 {
      font-size: 17px;
    }
  }
`;

export const BorderLessInput = styled(Input)`
  border: none;
  border-radius: 5px !important;
`;
export const BorderLessSelect = styled(Select)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px !important;
  }
  width: 100%;
  border-radius: 5px !important;
`;
export const ClearButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  :hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

export const TableCotainer = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
`;
export const StyledTable = styled(Table)`
  & .ant-table-thead > tr > th {
    color: ${(props) => props.headColor};
    font-weight: 600;
    text-align: left;
    background: #ffffff;
    border-bottom: 2px solid #dedede;
  }
  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  & .ant-table-tbody > tr > td {
    border-bottom: 2px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
    border-top-right-radius: 10px !important;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
    border-top-left-radius: 10px !important;
  }

  /* & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  } */
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  }
`;

export const ModalBodyContainer = styled.div`
  width: 500px;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  color: ${(props) => props.color};
  text-align: center;
`;

export const StyledRow = styled(Row)`
  margin-top: 24px;
`;

export const SectionTtile = styled.h3`
  font-size: 20px;
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    display: flex;
  }
  & .ant-checkbox-inner {
    height: 22px;
    width: 22px;
  }

  & .ant-checkbox-inner::after {
    left: 25%;
    width: 7px;
    height: 12px;
  }
  & .ant-checkbox {
    margin-right: 15px;
  }
`;
export const DocumentImages = styled(Row)`
  height: 200px;
`;
export const DocumentImage = styled(Col)`
  height: 200px;
`;
export const StyledCol = styled(Col)``;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const StyledTimePicker = styled(TimePicker)`
  width: 100%;
`;

export const SelectRoleMessage = styled.div`
  margin-top: 30px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;
