import { Row, Col, message, Input, Empty, Button } from "antd";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import { fetchTableDataForTest } from "../../../apis/laboratory.js";
import { fetchTableDataForTestRadiology } from "../../../apis/radiology.js";
import { PROFILE_IMAGE_PREFIX } from "../../../constants/StringConstants.js";
import CustomModal from "../../utils/CustomModal.jsx";
import Spinner from "../../utils/Spinner/index.jsx";
import { EmptyMessageContainer } from "../AdminDashboard/styledComponents.js";
import { BlueBgTable } from "./EditTestResults.jsx";
import * as Sentry from "@sentry/react";

const { TextArea } = Input;

const ModalContainer = styled(Row)`
  width: 50vw;
  padding: 20px;
  @media (max-width: 480px) {
    width: unset;
  }
`;

const StyledRowTextArea = styled(Row)`
  .ant-input[disabled] {
    color: black !important;
  }
`;

const ViewDetailsModal = ({
  visible,
  closeModal,
  data,
  isLabUser,
  recordsType,
  parentAppointmentId,
  appointmentOrder,
}) => {
  const history = useHistory();
  const user = useSelector((state) => state.userReducer);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [technicalNotes, setTechnicalNotes] = useState();
  const [files, setFiles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [columns, setColumns] = useState([]);
  const [hospitalLogo, setHospitalLogo] = useState();
  const theme = useSelector((state) => state.themeReducer);
  const images = useSelector((state) => state.imageReducer);
  const componentRef = useRef();

  const fetchImageAndPrint = () => {
    const objectURL = PROFILE_IMAGE_PREFIX + images.logo;
    fetch(objectURL, {
      method: "GET",
      headers: { "Content-Type": "application/octet-stream" },
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        setHospitalLogo(url);
        console.log("url");
        console.log(url);
      })
      .catch(console.log)
      .finally(() => {
        handlePrint();
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchTableData = () => {
    if (data && parentAppointmentId) {
      setTableLoading(true);
      var tableDataForTest;
      if (recordsType == "Laboratory")
        tableDataForTest = fetchTableDataForTest(
          parentAppointmentId,
          data.testDataId ? data.testDataId : data.TestId
        );
      else if (recordsType == "Radiology")
        tableDataForTest = fetchTableDataForTestRadiology(
          parentAppointmentId,
          data.testDataId ? data.testDataId : data.TestId
        );
      if (tableDataForTest)
        tableDataForTest
          .then((response) => {
            if (response.data.status == 1) {
              setTableData(response.data.body.data);
              setTechnicalNotes(response.data.body.technicalNotes);
              var column = Object.keys(response.data.body.data[0]).map(
                (item) => {
                  if (item == "fieldDataId")
                    return {
                      title: "Sl. No",
                      render: (value, row, index) => index + 1,
                    };
                  else return { dataIndex: item, title: item };
                }
              );
              setColumns(column);
              if (response.data.body.documents?.length > 0) {
                setDocuments(response.data.body.documents);
                setFiles(response.data.body.documents);
              }
            } else message.error(response.data.body);
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
          })
          .finally(() => {
            setTableLoading(false);
          });
      else {
        message.error("Sorry! something went wrong");
        history.push("/dashboard");
      }
    }
  };

  useEffect(() => {
    if (visible) fetchTableData();
  }, [data, parentAppointmentId, visible]);

  useEffect(() => {
    fetchTableData();
  }, []);

  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true }}
      isClosable={true}
      setVisible={() => {
        setTableData([]);
        setTechnicalNotes();
        setColumns([]);
        closeModal();
      }}
    >
      <ModalContainer>
        <Col span={24}>
          <Row>
            <b>Test Details</b>
          </Row>
          <Row style={{ padding: "10px 0" }}>
            <Col>
              <b style={{ fontWeight: "600", marginRight: "40px" }}>
                {data ? data["Test Name"] : ""}
              </b>
            </Col>
            <Col>
              <Row>
                Ordered Date :&nbsp;&nbsp;&nbsp;
                <p style={{ fontWeight: "600", marginRight: "40px" }}>
                  {data
                    ? isLabUser
                      ? data["Request Date"]
                        ? moment(data["Request Date"]).format("DD-MM-YYYY")
                        : ""
                      : data["Request Date"]
                    : ""}
                </p>
              </Row>
            </Col>
            <Col>
              <Row>
                Result Date :&nbsp;&nbsp;&nbsp;
                <p style={{ fontWeight: "600" }}>
                  {data
                    ? isLabUser
                      ? data["Last Updated"]
                        ? moment(data["Last Updated"]).format("DD-MM-YYYY")
                        : ""
                      : data["Last Updated"]
                    : ""}
                </p>
              </Row>
            </Col>
          </Row>
          <Row justify="center">
            {tableLoading ? (
              <Spinner height="calc(100vh - 410px)" />
            ) : (
              <BlueBgTable
                style={{ width: "100%" }}
                dataSource={tableData}
                columns={columns}
                pagination={false}
                scroll={{ x: "max-content" }}
                color={theme["@primary-color"]}
                locale={{
                  emptyText: (
                    <EmptyMessageContainer>
                      <Empty />
                    </EmptyMessageContainer>
                  ),
                }}
              />
            )}
          </Row>
          {(user.userType == "PATIENT" && technicalNotes?.visibleToPatient) ||
          user.userType !== "PATIENT" ? (
            <StyledRowTextArea style={{ padding: "20px 0" }}>
              <h3 style={{ fontWeight: "600" }}> Technician Notes</h3>
              <TextArea
                rows={4}
                disabled={true}
                value={technicalNotes?.notes}
                defaultValue={technicalNotes?.notes}
              />
            </StyledRowTextArea>
          ) : (
            <Row style={{ minHeight: "10px" }}></Row>
          )}
          <Row justify="end">
            <Button type="primary" onClick={fetchImageAndPrint}>
              Print
            </Button>
          </Row>
        </Col>
        <div style={{ display: "none" }}>
          <Row
            style={{ width: "100%", padding: "40px 20px 20px 20px" }}
            ref={componentRef}
          >
            <Col span={24}>
              <Row justify="space-between">
                <img src={hospitalLogo} style={{ width: "20%" }} />
                <h2 style={{ alignItems: "center", display: "flex" }}></h2>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                    justifyContent: "end",
                  }}
                ></p>
              </Row>
              <Row>
                <b>Test Details</b>
              </Row>
              <Row style={{ padding: "10px 0" }}>
                <Col span={5}>
                  <b style={{ fontWeight: "600" }}>
                    {data && data["Test Name"]}
                  </b>
                </Col>
                <Col span={8}>
                  <Row>
                    Ordered Date :&nbsp;&nbsp;&nbsp;
                    <p style={{ fontWeight: "600" }}>
                      {data && data["Request Date"]}
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    Result Date :&nbsp;&nbsp;&nbsp;
                    <p style={{ fontWeight: "600" }}>
                      {data && data["Last Updated"]}
                    </p>
                  </Row>
                </Col>
              </Row>
              {tableData.length > 0 ? (
                <Row style={{ width: "100%" }}>
                  <BlueBgTable
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    color={theme["@primary-color"]}
                    scroll={{ x: "max-content" }}
                  />
                </Row>
              ) : (
                <Empty />
              )}
              <Row style={{ padding: "20px 0" }}>
                <Col>
                  <h3 style={{ fontWeight: "600" }}> Technician Notes</h3>
                  <br />
                  <p>{technicalNotes?.notes}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </ModalContainer>
    </CustomModal>
  );
};

export default ViewDetailsModal;
