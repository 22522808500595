//incase of formitem with array
import React, { useEffect, useRef } from 'react';
import {
  Checkbox,
  Card,
  TimePicker,
  Row,
  Col,
  Space,
  Button,
  Form,
  Divider,
} from 'antd';
import {
  CopySelectedTimesToAll,
  handleChangeAvailDays,
  handleUpdateTime,
} from './OnClickEvents';
import moment from 'moment';

const ServiceSelectedDays = (props) => {
  const {
    selectedDays,
    setSelectedDays,
    selectedDaysError,
    onClosePanel,
    togglePanel,
    availability,
    setAvailability,
    setDisabled,
    ...other
  } = props;
  const [form] = Form.useForm();
  // const formRef = useRef(null);

  const valuesChangeHandler = (changed, all) => {
    console.log('changed and all', changed, all);
    setDisabled(false);
    let arr = [];
    let weekDay = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ];

    for (let i = 0; i < all.days.length; i++) {
      arr.push({
        ...all.days[i],
        dayOfWeek: weekDay[i],
      });
    }

    const convertedArray = arr.map((item) => ({
      ...item,
      startTime: item.startTime ? moment(item.startTime).format('HH:mm') : '',
      endTime: item.endTime ? moment(item.endTime).format('HH:mm') : '',
      breakStartTime: item.breakStartTime
        ? moment(item.breakStartTime).format('HH:mm')
        : '',
      breakEndTime: item.breakEndTime
        ? moment(item.breakEndTime).format('HH:mm')
        : '',
    }));
    setAvailability(convertedArray);
  };

  return (
    <Card elevation={16}>
      <Form
        layout="vertical"
        onFinish={(values) => {
          //   submitHandler(values);
          // setDisabled(!disabled);
        }}
        initialValues={{
          days: selectedDays,
        }}
        form={form}
        className="form-m2-style"
        style={{ width: '100%' }}
        onValuesChange={valuesChangeHandler}
        scrollToFirstError={true}
        // ref={formRef}
      >
        {selectedDays.map((days, index) => {
          // {Object.keys(selectedDays).map(day)}

          return (
            <div key={days?.dayOfWeek}>
              {/* //key={day} */}
              <Row style={{ marginBottom: '10px' }}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name={['days', index, 'isAvailable']}
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={(e) => {
                        handleChangeAvailDays(
                          e,
                          index,
                          selectedDays,
                          setSelectedDays,
                          availability,
                          setAvailability
                        );
                      }}
                      // checked={days.isAvailable}
                    >
                      {days?.dayOfWeek}
                      {/* {day} */}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                {/* <Space wrap> */}
                <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
                  <Form.Item
                    name={['days', index, 'startTime']}
                    label={<p style={{ fontSize: '14px' }}>Start Time</p>}
                  >
                    <TimePicker
                      size="large"
                      // value={days.startTime !== '' ? days.startTime : null}
                      format="HH:mm "
                      onChange={(newValue) => {
                        handleUpdateTime(
                          index,
                          'startTime',
                          newValue,
                          selectedDays,
                          setSelectedDays
                        );
                      }}
                      style={{ width: '100%' }}
                      allowClear={false}
                      disabled={!days?.isAvailable ? true : false}
                      onSelect={(newValue) => {
                        form.setFieldsValue(
                          ['days', index, 'startTime'],
                          newValue
                        );
                      }}
                      // disabled={formRef.current?.getFieldValue(['days',index,'isAvailable'])?true:false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
                  <Form.Item
                    name={['days', index, 'endTime']}
                    label={<p style={{ fontSize: '14px' }}>End Time</p>}
                    style={{ maxWidth: '100%' }}
                  >
                    <TimePicker
                      size="large"
                      // value={days.endTime !== '' ? days.endTime : null}
                      style={{ width: '100%' }}
                      format="HH:mm "
                      onChange={(newValue) => {
                        handleUpdateTime(
                          index,
                          'endTime',
                          newValue,
                          selectedDays,
                          setSelectedDays
                        );
                      }}
                      disabled={!days?.isAvailable ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
                  <Form.Item
                    name={['days', index, 'breakStartTime']}
                    label={<p style={{ fontSize: '14px' }}>BreakStartTime</p>}
                  >
                    <TimePicker
                      size="large"
                      // value={days.startTime !== '' ? days.startTime : null}
                      format="HH:mm "
                      onChange={(newValue) => {
                        handleUpdateTime(
                          index,
                          'breakStartTime',
                          newValue,
                          selectedDays,
                          setSelectedDays
                        );
                      }}
                      disabled={!days.isAvailable ? true : false}
                      style={{ width: '100%' }}
                      // onSelect={getstartTime}
                      // disabled={formRef.current?.getFieldValue(['days',index,'isAvailable'])?true:false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
                  <Form.Item
                    name={['days', index, 'breakEndTime']}
                    label={<p style={{ fontSize: '14px' }}>BreakEndTime</p>}
                  >
                    <TimePicker
                      size="large"
                      // value={days.endTime !== '' ? days.endTime : null}
                      style={{ width: '100%' }}
                      format="HH:mm "
                      onChange={(newValue) => {
                        handleUpdateTime(
                          index,
                          'breakEndTime',
                          newValue,
                          selectedDays,
                          setSelectedDays
                        );
                      }}
                      disabled={!days.isAvailable ? true : false}
                      // onSelect={getEndTime}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={4}
                  xl={4}
                  xxl={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    sx={{
                      color: '#F76857',
                      width: '100%',
                    }}
                    id="copyAllBtn"
                    disabled={!days.isAvailable ? true : false}
                    onClick={(e) => {
                      e.preventDefault();
                      setDisabled(false);
                      CopySelectedTimesToAll(
                        index,
                        selectedDays,
                        setSelectedDays,
                        form,
                        setAvailability
                      );
                    }}
                  >
                    Copy To All
                  </Button>
                </Col>
                {/* </Space> */}
                {index !== selectedDays.length - 1 ? (
                  <Divider sx={{ my: 2 }} />
                ) : null}
              </Row>
            </div>
          );
        })}
      </Form>
    </Card>
  );
};

export default ServiceSelectedDays;
