import React from "react";
import styled from "styled-components";
import { Modal, Button } from "antd";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/CancelIcon.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";


const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

const ModalContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledWarningIcon = styled(WarningIcon)`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  & .cls-warning-1{
      fill: ${(props) => props.color};
  }
`;

const StyledCancelIcon = styled(CancelIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledP = styled.p`
  font-size: 15px;
`;

const StyledPTitle = styled.p`
  font-size: 18px;
`;

const ImmediateBookingConfirm = ({
    visible,
    closeModal,
    immediateBooking,
    setImmediateBooking,
    isInsurance
}) => {

    const theme = useSelector((state) => state.themeReducer);


    return (
        <StyledModal
            visible={visible}
            closable={true}
            centered
            maskClosable={true}
            footer={false}
            closeIcon={<CloseCircleOutlined/>}
            onCancel={() => closeModal()}
        >
            <ModalContentDiv>
                <StyledWarningIcon color={`${theme["@primary-color"]}`} />
                <StyledP>
                    Do you want to initiate Immediate Booking
                </StyledP>
                <StyledP>
                    Payment Type selected is {isInsurance ? "Insurance" : "Self"}
                </StyledP>
                <div>
                    <Button
                        key="ok"
                        type="primary"
                        ghost
                        onClick={() => {
                            // setImmediateBooking(immediateBooking)
                            closeModal()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        key="ok"
                        type="primary"
                        onClick={() => {
                            setImmediateBooking(!immediateBooking)
                            closeModal()
                        }}
                        style={{ marginLeft: "15px" }}
                    >
                        Confirm
                    </Button>
                </div>
            </ModalContentDiv>
        </StyledModal >
    );
};

export default ImmediateBookingConfirm;