import moment from 'moment';
import React from 'react';
import ReadMoreReact from 'read-more-react';

export const generatePreSubmitFormFields = (fields) => {
  return fields.map((field) => {
    return field.fieldType == 'TEXT_AREA' ||
      field.fieldType == 'SEARCHABLE_DROPDOWN'
      ? {
          title: field.formName,
          dataIndex: field.id,
          key: 'uuid',
          render: (value) => (
            <p style={{ maxWidth: '10vw', margin: 0 }}>
              <ReadMoreReact
                text={
                  Array.isArray(value)
                    ? value.join(', ')
                    : typeof value === 'object'
                    ? value?.text
                    : value
                    ? value
                    : ''
                }
                min={35}
                readMoreText={<a>...</a>}
              />
            </p>
          ),
        }
      : {
          title: field.formName,
          dataIndex: field.id,
          key: 'uuid',
          render: (value) => (
            <p style={{ margin: 0 }}>
              {Array.isArray(value)
                ? value.join(', ')
                : typeof value === 'object'
                ? value.text
                : value
                ? value
                : ''}
            </p>
          ),
        };
  });
};

export const generatePreviousInfoTableColumns = (fields) => {
  return fields.map((field) => {
    console.log('field in previous', field);
    return field.fieldType == 'TEXT_AREA' ||
      field.fieldType == 'SEARCHABLE_DROPDOWN'
      ? {
          title: field.formName,
          dataIndex: field.formName,
          align: 'center',
          render: (value) => (
            <p style={{ maxWidth: '10vw', margin: 0 }}>
              {value ? (
                <ReadMoreReact
                  text={value}
                  min={35}
                  readMoreText={<a>...</a>}
                />
              ) : (
                value
              )}
            </p>
          ),
        }
      : { title: field.formName, dataIndex: field.formName, align: 'center' };
  });
};

export const migrateIntoTableFormat = (data) => {
  // let tableData = [];
  // data.forEach((item) => {
  //   Object.keys(item).map((key) => {
  //     //   data.push({ ...item[key], date: moment(date).format("DD-MM-YYYY") });
  //     tableData.push(item[key]);
  //   });
  // });
  // return tableData;
  return data;
};

export const softValidate = (validationList, value) => {
  var status = false;
  validationList.validationCrieteria.every((item) => {
    switch (item.type) {
      case 'GREATER_THAN':
        if (item.value > parseInt(value)) {
          status = true;
          break;
        } else break;
      case 'LESS_THAN':
        if (item.value < parseInt(value)) {
          status = true;
          break;
        } else break;
      default:
        status = false;
        break;
    }
    if (status === true) return false;
    else return true;
  });

  return status;
};

export const bmiCalculator = (height, weight) => {
  let bmi, status;
  bmi = (weight / (height / 100) ** 2).toFixed(2);
  if (bmi < 18.5) status = 'Underweight';
  else if (bmi >= 18.5 && bmi <= 24.9) status = 'Normal';
  else if (bmi >= 25 && bmi <= 29.9) status = 'Overweight';
  else status = 'Obese';
  return [bmi, status];
};
export const totalDispenseCalculator = (days, frequency) => {
  let totatDispense = days * frequency;
  return totatDispense;
};
