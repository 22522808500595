import React from "react";
import { Menu, Typography, Table, Button } from "antd";
import styled from "styled-components";
import Icon from "@ant-design/icons";
const { Title } = Typography;

export const HospitalAdminMenu = styled(Menu)`
  border-radius: 10px;
  margin-top: 80px;

  && {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow: scroll;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }
`;
export const HospitalAdminMenuItem = styled(HospitalAdminMenu.Item)`
  && {
    height: 70px;
    line-height: 60px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
    overflow: scroll;
    @media (max-width: 1100px) {
      && {
        font-size: 11px;
      }
    }
  }
`;
export const StyledIcon = styled(Icon)`
  && {
    font-size: 25px;
    margin-right: 8px;
    @media (max-width: 767px) {
      && {
        font-size: 20px;
        margin-right: 8px;
      }
    }
    @media (max-width: 1100px) {
      && {
        margin-right: 8px;
      }
    }
  }
  ${(props) =>
    props.isactive &&
    `.cls-1 {
      fill: ${props.iconColor} !important; 
  }`}
`;

export const SectionHeading = styled(Title)`
  font-size: 25px !important;
  padding-top: 25px;
  color: #606060 !important;
`;

export const StyledDiv = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.bgcolor};
  font-size: 16px !important;
  align-items: center;
  width: 100%;
  padding: 15px;
  /* margin-top: 20px; */
  font-weight: 600;
  height: 50px;
  border-radius: 10px 10px 0 0;
`;

export const FooterButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 17px;
  border-radius: 5px !important;
`;

export const BlueBgTable = styled(Table)`
  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    /* position: relative; */
    padding: 8px;
    overflow-wrap: break-word;
    margin: 0px;
  }
  & .ant-table-thead > tr > th {
    /* padding: 8px 0 !important; */
    color: #606060;
    font-weight: 600;
  }
  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
  }

  /* & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  } */
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  }
`;

export const SectionHeadContainer = styled.div`
  height: 80px;
`;

export const EmptyMessageContainer = styled.div`
  height: calc(100vh - 450px);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
`;
