import { Button, Col, Row, Form, notification, Space } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { StyledIcon } from "../styledComponents";
import { fetchEmailTemplate, saveEmailTemplate } from "../../../../apis/Admin";
import { BodyContainer } from "../Sections/styledComponents";
import { SectionHeadContainer, SectionHeading } from "../styledComponents";
import EmailFormFields from "./EmailFormFields";
import EmailPreview from "./TemplatePreview";
import Icon from "@ant-design/icons";
import { ReactComponent as PreviewIcon } from "../../../../assets/icons/previewIcon.svg";
import TemplatePreview from "./TemplatePreview";
import Spinner from "../../../utils/Spinner";

const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
`;
const EmailTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [containerLoading, setContainerLoading] = useState(false);
  const [previous, setPrevious] = useState();
  const [emailPreview, setEmailPreview] = useState(false);
  const [form] = Form.useForm();
  const theme = useSelector((state) => state.themeReducer);
  const [primaryColor, setPrimaryColor] = useState("");
  const [templateValues, setTemplateValues] = useState();

  const fetchTemplates = () => {
    setContainerLoading(true);
    fetchEmailTemplate()
      .then(({ data, status }) => {
        if (data.status === 1) {
          form.setFieldsValue({
            newUserTemplate: data.body.NewUserTemplate,
            resetPasswordTemplate: data.body.ResetPasswordTemplate,
            statusEmailTemplate: data.body.StatusEmailTemplate,
          });
          setPrevious({
            newUserTemplate: data.body.NewUserTemplate,
            resetPasswordTemplate: data.body.ResetPasswordTemplate,
            statusEmailTemplate: data.body.StatusEmailTemplate,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setContainerLoading(false);
      });
  };
  const saveTemplates = (data) => {
    setLoading(true);
    saveEmailTemplate(data)
      .then(({ data, status }) => {
        if (data.status === 1) {
          notification.success({
            message: "Email Template Updated",
            // description: "Logo resolution should be in the ratio of 1:3",
            // onClick: () => {
            //   console.log("Notification Clicked!");
            // },
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {}, [templateValues]);
  useEffect(() => {
    fetchTemplates();
  }, []);
  useEffect(() => {
    setPrimaryColor(theme["@primary-color"]);
  }, [theme]);

  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading>Email Template </SectionHeading>
            </Col>
            <Col></Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        {containerLoading ? (
          <Spinner />
        ) : (
          <BodyContainer>
            <Form
              layout="vertical"
              onFinish={(values) => {
                if (previous) {
                  values.newUserTemplate.id = previous.newUserTemplate?.id;
                  values.resetPasswordTemplate.id =
                    previous.resetPasswordTemplate?.id;
                  values.statusEmailTemplate.id =
                    previous.statusEmailTemplate?.id;
                }
                saveTemplates(values);
              }}
              form={form}
              scrollToFirstError={true}
            >
              <Row gutter={24}>
                <Col span={24}>
                  <StyledH3 color={theme["@primary-color"]}>
                    <Space>
                      New User
                      <Icon
                        style={{
                          fontSize: "20px",
                          marginLeft: "10px",
                        }}
                        component={PreviewIcon}
                        onClick={() => {
                          setTemplateValues(
                            form.getFieldsValue(["newUserTemplate"])
                              .newUserTemplate
                          );
                          setEmailPreview(!emailPreview);
                        }}
                      />
                    </Space>
                  </StyledH3>
                </Col>
                <EmailFormFields type="newUserTemplate" />
                <Col span={24}>
                  <StyledH3 color={theme["@primary-color"]}>
                    <Space>
                      Reset Password
                      <Icon
                        style={{
                          fontSize: "20px",
                          marginLeft: "10px",
                        }}
                        component={PreviewIcon}
                        onClick={() => {
                          setTemplateValues(
                            form.getFieldsValue(["resetPasswordTemplate"])
                              .resetPasswordTemplate
                          );
                          setEmailPreview(!emailPreview);
                        }}
                      />
                    </Space>
                  </StyledH3>
                </Col>
                <EmailFormFields type="resetPasswordTemplate" />
                <Col span={24}>
                  <StyledH3 color={theme["@primary-color"]}>
                    <Space>
                      Status Email
                      <Icon
                        style={{
                          fontSize: "20px",
                          marginLeft: "10px",
                        }}
                        component={PreviewIcon}
                        onClick={() => {
                          setTemplateValues(
                            form.getFieldsValue(["statusEmailTemplate"])
                              .statusEmailTemplate
                          );
                          setEmailPreview(!emailPreview);
                        }}
                      />
                    </Space>
                  </StyledH3>
                </Col>
                <EmailFormFields type="statusEmailTemplate" />
              </Row>
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ float: "right" }}
                  loading={loading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </BodyContainer>
        )}
      </Col>
      <TemplatePreview
        visible={emailPreview}
        setVisible={setEmailPreview}
        templateValues={templateValues}
      />
    </Row>
  );
};

export default EmailTemplate;
