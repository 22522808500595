import React, { useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button, Input, Form, Select } from "antd";

import { useEffect } from "react";
import {
  DocumentDetailsDiv,
  ModalBodyContainer,
  ModalTitle,
  StyledRow,
  UploadTitle,
} from "./styledComponent";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants.js";
import { useSelector } from "react-redux";

const { Option } = Select;

const ListenerForm = ({
  departments,
  visible,
  setVisible,
  loading,
  addListenerForm,
  edit,
  selectedItem,
  trigger,
  handleEdit,
  updateListenerForm,
}) => {
  const [form] = Form.useForm();

  const [selectedFile, setSelectedFile] = useState(null);
  const [formInitialValues, setFormInitialValues] = useState({});
  const theme = useSelector((state) => state.themeReducer);

  const submitHandler = (values) => {
    const payload = {
      application: values.applicationName,
      facilityName: values.facilityName,
      port: values.port,
      ipAddress: values.ipAddress,
      receiverNameSpaceId: values.receiverNameSpaceId,
      receiverUniversalId: values.receiverUniversalId,
    };
    if (edit) {
      payload["id"] = selectedItem.id;
      updateListenerForm(payload);
    } else {
      addListenerForm(payload);
      form.resetFields();
    }
  };
  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        facilityName: selectedItem.facilityName,
        applicationName: selectedItem.application,
        ipAddress: selectedItem.ipAddress,
        port: selectedItem.port,
        receiverNameSpaceId: selectedItem.receiverNameSpaceId,
        receiverUniversalId: selectedItem.receiverUniversalId,
      });
    }
  }, [edit]);
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <ModalTitle color={theme["@primary-color"]}>
              Add New Listener
            </ModalTitle>
          </Col>
        </Row>
        <Form
          layout="vertical"
          onFinish={(values) => submitHandler(values)}
          form={form}
        >
          <Row justify="center">
            <Col span={24}>
              <Form.Item
                label="Facility Name"
                name="facilityName"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^(([A-Za-z]{0,})([\.,] |[-']| )*)+[A-Za-z]+\.?\s*$/
                    ),
                    message: "Please enter alphabets only.",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input size="large" placeholder="Enter Facility Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Application Name"
                name="applicationName"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^(([A-Za-z]{0,})([\.,] |[-']| )*)+[A-Za-z]+\.?\s*$/
                    ),
                    message: "Please enter alphabets only.",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input size="large" placeholder="Enter Application Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Ip Address"
                name="ipAddress"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/
                    ),
                    message: "Invalid Ip Address.",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input size="large" placeholder="Enter Ip Address" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Port"
                name="port"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^(\d{4,5})$/),
                    message: "Invalid port.",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input size="large" placeholder="Enter Port" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label=" Receiver  Namespace Id"
                name="receiverNameSpaceId"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^(([A-Za-z]{0,})([\.,] |[-']| )*)+[A-Za-z]+\.?\s*$/
                    ),
                    message: "Please enter alphabets only.",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input
                  size="large"
                  placeholder="Enter Receiver  Namespace Id"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Receiver Universal Id"
                name="receiverUniversalId"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^(([A-Za-z]{0,})([\.,] |[-']| )*)+[A-Za-z]+\.?\s*$/
                    ),
                    message: "Please enter alphabets only.",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input size="large" placeholder="Enter Receiver Universal Id" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <StyledRow gutter={12} justify="end">
              <Col>
                <Button
                  onClick={() => {
                    setVisible(false);

                    setSelectedFile(null);
                    handleEdit(false);
                    form.resetFields();
                  }}
                  danger
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" loading={loading} htmlType="submit">
                  Save
                </Button>
              </Col>
            </StyledRow>
          </Form.Item>
        </Form>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default ListenerForm;
