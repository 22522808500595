import React from "react";
import moment from "moment";
import styled from "styled-components";

const AppointmentStatus = styled.div`
  color: ${(props) => statusColour[props.status]};
`;
const statusColour = {
  Success: "green",
  Failed: "red",
};

export const statusColumns = (
  pageNo,
  pageSize,
  StyledButton,
  theme,
  setResendIds
) => [
  { width: "1vw" },
  {
    title: "Sl. No",
    dataIndex: "",
    key: "no",
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: "P ID",
    dataIndex: "patientID",
    key: "id",
  },
  {
    title: "Patient Name",
    dataIndex: "patientName",
    key: "patientName",
  },
  {
    title: "Visit Type",
    dataIndex: "visitType",
    key: "visitType",
    render: (visitType) => {
      if (visitType !== null) return <div>{visitType}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Date",
    dataIndex: "createTime",
    key: "date",
    render: (date) => {
      if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Doctor Name",
    dataIndex: "doctorName",
    key: "doctor",
    render: (doctor) => {
      if (doctor !== null) return <div>Dr. {doctor}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Triggered Date",
    dataIndex: "lastTriggeredTime",
    key: "triggeredDate",
    render: (triggeredDate) => {
      if (triggeredDate !== null)
        return <div>{moment(triggeredDate).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Triggered Time",
    dataIndex: "lastTriggeredTime",
    key: "triggeredTime",
    render: (triggeredTime) => {
      if (triggeredTime !== null)
        return <div>{moment(triggeredTime).format("hh:mm A")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      if (status !== null)
        return <AppointmentStatus status={status}>{status}</AppointmentStatus>;
      else return <div>-</div>;
    },
  },
  {
    title: "Actions",
    render: (text, record) => {
      return (
        <StyledButton
          color={theme["@primary-color"]}
          size="small"
          onClick={() => {
            setResendIds(text.id);
          }}
          disabled={text.status == "Success" ? true : false}
        >
          Resend
        </StyledButton>
      );
    },
  },
];
