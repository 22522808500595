import React, { useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  message,
  Select,
  Upload,
  Tooltip,
} from "antd";
import { UploadOutlined, FileOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import {
  DocumentDetailsDiv,
  ModalBodyContainer,
  ModalTitle,
  StyledRow,
  UploadTitle,
} from "./styledComponents";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants.js";
import { useSelector } from "react-redux";

const { Option } = Select;

const CreateConsentForm = ({
  departments,
  visible,
  setVistible,
  loading,
  addConsentForm,
  edit,
  selectedItem,
  trigger,
  updateConsentForm,
}) => {
  const [form] = Form.useForm();
  const [singleFile, setSingleFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formInitialValues, setFormInitialValues] = useState({});
  const theme = useSelector((state) => state.themeReducer);

  const prop = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: (file, fileList) => {
      if (file.type === "application/pdf") {
        setSingleFile(true);
        setSelectedFile(file);
      } else {
        message.error("you can only upload PDF files.");
        setSingleFile(false);
      }
    },
  };

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        department:
          selectedItem && selectedItem.departments.map((item) => item.id),
        formName: selectedItem && selectedItem.name,
      });
      fetch(PROFILE_IMAGE_PREFIX + selectedItem.formUrl)
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          let data = blob;
          data.name = selectedItem.name;
          setSelectedFile(data);
        })
        .catch();
    }
  }, [edit]);

  useEffect(() => {
    form.resetFields();
    setSelectedFile(null);
  }, [trigger]);

  const clearDoc = (e) => {
    e.stopPropagation();
    setSelectedFile(null);
    setSingleFile(false);
  };

  const submitHandler = (values) => {
    if (selectedFile) {
      const payload = {
        departmentId: values.department,
        name: values.formName,
        file: selectedFile.type === "application/pdf" && selectedFile,
      };
      if (edit) {
        updateConsentForm(payload);
      } else {
        addConsentForm(payload);
      }
    } else {
      message.error("Please select a file to upload.");
    }
  };

  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <ModalTitle color={theme["@primary-color"]}>
              Create Consent Form
            </ModalTitle>
          </Col>
        </Row>
        <Form
          layout="vertical"
          onFinish={(values) => submitHandler(values)}
          form={form}
          scrollToFirstError={true}
        >
          <Row justify="center">
            <Col span={24}>
              <Form.Item
                label="Form Name"
                name="formName"
                rules={[
                  { required: true, message: "Form name cannot be empty" },
                ]}
                style={{ width: "100%" }}
              >
                <Input size="large" placeholder="Enter Form Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Form.Item
              label="Department"
              name="department"
              rules={[
                { required: true, message: "Department cannot be empty" },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                size="large"
                placeholder="Select Department"
                mode="multiple"
              >
                {departments &&
                  departments.map((department) => {
                    return (
                      <Option value={department.id} key={department.id}>
                        {department.departmentName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Row>
          <Row>
            <UploadTitle color={theme["@primary-color"]}>
              Upload Consent Form
            </UploadTitle>
            <Col span={24}>
              <Upload.Dragger {...prop} customRequest={() => {}}>
                <p className="ant-upload-drag-icon">
                  {selectedFile ? <FileOutlined /> : <UploadOutlined />}
                </p>
                {selectedFile ? (
                  <DocumentDetailsDiv>
                    {selectedFile && selectedFile.name}
                    <Tooltip title="Delete document">
                      <Button
                        shape="circle"
                        type="link"
                        danger
                        icon={<CloseOutlined />}
                        onClick={(e) => clearDoc(e)}
                      />
                    </Tooltip>
                  </DocumentDetailsDiv>
                ) : (
                  <p className="ant-upload-text">
                    Click or drag PDF file to this area to upload
                  </p>
                )}
              </Upload.Dragger>
            </Col>
          </Row>
          <Form.Item>
            <StyledRow gutter={12} justify="end">
              <Col>
                <Button
                  onClick={() => {
                    setVistible(false);
                    form.resetFields();
                    setSelectedFile(null);
                  }}
                  danger
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" loading={loading} htmlType="submit">
                  Save
                </Button>
              </Col>
            </StyledRow>
          </Form.Item>
        </Form>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default CreateConsentForm;
