import React from 'react';

export const tableColumns = () => [
  {
    width: '2vw',
  },
  {
    title: 'Sl. No',
    width: '3.5vw',
    dataIndex: 'Sl No',
  },
  {
    title: 'Date',
    width: '7vw',
    key: 'date',
    dataIndex: 'Date',
  },
  {
    width: '15vw',
    title: 'Drugs/ Generic code',
    dataIndex: 'Drugs/ Generic code',
  },
  {
    title: 'Dose',
    dataIndex: 'Dose',
    width: '5vw',
  },
  {
    title: 'Frequency',
    dataIndex: 'Frequency',
    width: '6vw',
  },
  {
    title: 'Duration',
    dataIndex: 'No of Days',
    width: '6vw',
  },
  {
    title: 'Dispense',
    dataIndex: 'Total Dispense',
    width: '6vw',
  },
  {
    // "width": "10vw",
    title: 'Notes',
    dataIndex: 'Notes',
    render: (item) => item?.slice(0, 45),
  },
];

export const tableColumnsPrint = () => [
  {
    title: 'Sl. No',
    width: '3vw',
    dataIndex: 'Sl No',
  },
  {
    title: 'Date',
    width: '6vw',
    key: 'date',
    dataIndex: 'Date',
  },
  {
    width: '15vw',
    title: 'Drugs/ Generic code',
    dataIndex: 'Drugs/ Generic code',
  },
  {
    title: 'Dose',
    dataIndex: 'Dose',
    width: '4vw',
  },
  {
    title: 'Frequency',
    dataIndex: 'Frequency',
    width: '4vw',
  },
  {
    title: 'Duration',
    dataIndex: 'No of Days',
    width: '4vw',
  },
  {
    title: 'Dispense',
    dataIndex: 'Total Dispense',
    width: '4vw',
  },
  {
    width: '10vw',
    title: 'Notes',
    dataIndex: 'Notes',
    render: (item) => item?.slice(0, 45),
  },
];
export const tablePrescriptionPrint = () => [
  {
    width: '15vw',
    title: 'Drugs/ Generic code',
    dataIndex: 'Drugs/ Generic code',
  },
  {
    title: 'Dose',
    dataIndex: 'Dose',
    width: '4vw',
  },
  {
    title: 'Frequency',
    dataIndex: 'Frequency',
    width: '4vw',
  },
  {
    title: 'Duration',
    dataIndex: 'No of Days',
    width: '4vw',
  },
  {
    title: 'InTake timing',
    dataIndex: 'Intake Timing',
    width: '10vw',
  },
  {
    title: 'Dispense',
    dataIndex: 'Total Dispense',
    width: '4vw',
  },

  {
    width: '10vw',
    title: 'Notes',
    dataIndex: 'Notes',
    render: (item) => item?.slice(0, 45),
  },
];
