import React, { useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import { Row, Col, Button, message } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";
import { deleteDepartment } from "../../../../apis/departments.js";
import { deleteField } from "../../../../apis/healthRecord.js";

const ModalBodyContainer = styled.div`
  width: 500px;
`;
const DeleteErrorModal = ({ visible, setVisible, message }) => {
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="warning"
              title="Unable to Delete"
              description={message}
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVisible(false);
              }}
              danger
            >
              Close
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default DeleteErrorModal;
