import React from "react";
import moment from "moment";
import { Row, Col } from "antd";
import styled from "styled-components";

export const activeRadiologyColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  inTimeModalVisible,
  setAppointmentId
) => [
    {
      title: "Actions",
      fixed: "left",
      dataIndex: ["patient"],
      render: (row, patient) => {
        return (
          <Row gutter={12}>
            {patient.inTime === null || patient.inTime === undefined ? (
              <Col>
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAppointmentId({
                      appointmentId: patient.id,
                      patientId: row.id,
                    });
                    inTimeModalVisible(true);
                  }}
                >
                  In Time
                </StyledButton>
              </Col>
            ) : (
              <Col>
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(
                      `/radiology-records/${row.id}/${row.firstName + row.lastName
                      }/${patient.id}`
                    );
                  }}
                >
                  Call
                </StyledButton>
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "P ID",
      dataIndex: ["patient", "id"],
      key: "id",
    },
    {
      title: "F Name",
      dataIndex: ["patient", "firstName"],
      key: "firstName",
    },
    {
      title: "L Name",
      dataIndex: ["patient", "lastName"],
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: ["patient", "gender"],
      key: "gender",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: ["patient", "phoneNumbers"],
      key: "phone",
      render: (value) =>
        value && (
          <div>
            {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
          </div>
        ),
    },
    {
      title: "Doctor Name",
      dataIndex: ["parentAppointment", "doctor"],
      key: "doctor",
      render: (doctor) => {
        if (doctor !== null) {
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        } else return <div>-</div>;
      },
    },
    {
      title: "Visit Booked Date",
      dataIndex: "date",
      key: "visitBookedDate",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Time",
      dataIndex: "startTime",
      key: "time",
      render: (startTime) => {
        if (startTime !== null)
          return <div>{moment(startTime).format("hh:mm A")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Payment Method",
      dataIndex: ["paymentType"],
      key: "paymentMethod",
      render: (paymentMethod) => {
        if (paymentMethod !== null) return <div>{paymentMethod}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      render: (status) => {
        if (status !== null) return <div>{status}</div>;
        else return <div>-</div>;
      },
    },
  ];

export const reportWaitingRadiologyColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme
) => [
    {
      title: "Actions",
      key: "id",
      fixed: "left",
      render: (row) => {
        return (
          <Row gutter={12}>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/radiology-records/${row.patient.id}/${row.patient.firstName + row.patient.lastName
                    }/${row.id}`
                  );
                }}
              >
                Update Report
              </StyledButton>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "P ID",
      dataIndex: ["patient", "id"],
      key: "id",
    },
    {
      title: "F Name",
      dataIndex: ["patient", "firstName"],
      key: "firstName",
    },
    {
      title: "L Name",
      dataIndex: ["patient", "lastName"],
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: ["patient", "gender"],
      key: "gender",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: ["patient", "phoneNumbers"],
      key: "phone",
      render: (value) =>
        value && (
          <div>
            {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
          </div>
        ),
    },
    {
      title: "Doctor Name",
      dataIndex: ["parentAppointment", "doctor"],
      key: "doctor",
      render: (doctor) => {
        if (doctor !== null) {
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        } else return <div>-</div>;
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      render: (status) => {
        if (status !== null) return <div>{status}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Time",
      dataIndex: "startTime",
      key: "time",
      render: (startTime) => {
        if (startTime !== null)
          return <div>{moment(startTime).format("hh:mm A")}</div>;
        else return <div>-</div>;
      },
    },
  ];

export const testHistoryRadiologyColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme
) => [
    {
      title: "Actions",
      key: "id",
      fixed: "left",
      render: (row) => {
        return (
          <Row gutter={12}>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname:
                      `/radiology-records/${row.patient.id}/${row.patient.firstName + row.patient.lastName
                      }/${row.id}`,
                    state: { viewHistory: true },
                  }
                  );
                }}
              >
                View
              </StyledButton>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "P ID",
      dataIndex: ["patient", "id"],
      key: "id",
    },
    {
      title: "F Name",
      dataIndex: ["patient", "firstName"],
      key: "firstName",
    },
    {
      title: "L Name",
      dataIndex: ["patient", "lastName"],
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: ["patient", "gender"],
      key: "gender",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: ["patient", "phoneNumbers"],
      key: "id",
      render: (value) =>
        value && (
          <div>
            {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
          </div>
        ),
    },
    {
      title: "Doctor Name",
      dataIndex: ["parentAppointment", "doctor"],
      key: "doctor",
      render: (doctor) => {
        if (doctor !== null) {
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        } else return <div>-</div>;
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      render: (status) => {
        if (status !== null) return <div>{status}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
  ];
