import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { message as AlertMessage, Button, Table } from 'antd';

import PatientsTable from './PatientsTable';
import { getSMSList } from '../../apis/Public';
import {
  createSMSToSinglePerson,
  getSMSListOfHospitalwithId,
  resendSMS,
} from '../../apis/Message';
import { SMSListColumns } from './column';
import { toContainHTML } from '@testing-library/jest-dom/dist/to-contain-html';
import { getHospitalId, getDoctorId } from '../utils/LocalStorage';

const StyledButton = styled(Button)`
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    background: ${(props) => props.color};
    color: white;
  }
`;

const SMSList = () => {
  const history = useHistory();
  const user = useSelector((state) => state.userReducer);
  const theme = useSelector((state) => state.themeReducer);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [SMSList, setSMSList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSMS, setTotalSMS] = useState(0);

  const getMessagesList = () => {
    setLoading(true);
    let searchQuery = `pageNo=${pageNo}&size=${pageSize}&hospitalId=${getHospitalId()}`;
    getSMSListOfHospitalwithId(searchQuery)
      .then((resp) => {
        console.log('response is', resp);
        if (resp.data.body) {
          setSMSList(resp.data.body);
          setTotalSMS(resp.data.total);
          setLoading(false);
        } else {
          AlertMessage.error('Could not fetch SMS List, Try again');
          setLoading(false);
        }
      })
      .catch((err) => {
        AlertMessage.error('Could not fetch SMS List, Try again');
        setLoading(false);
        console.log('error is', err);
      });
  };

  useEffect(() => {
    getMessagesList();
  }, [pageNo]);

  // const reSendFunction = (patientId, smsMessage, phoneNumber) => {
  //   setLoading(true);
  //   let searchQuery = `patientId=${patientId}&doctorId=${getDoctorId()}`;
  //   const payload = {
  //     phoneNumber,
  //     message: smsMessage,
  //     hospitalId: getHospitalId(),
  //   };
  //   createSMSToSinglePerson(payload, searchQuery)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         AlertMessage.success('Message send');
  //       } else {
  //         AlertMessage.error('Unable to send SMS');
  //       }
  //     })
  //     .catch((err) => {
  //       AlertMessage.error('Unable to send SMS!');
  //       console.log('error', err);
  //     });
  // };

  const reSendFunction = (messageId) => {
    resendSMS(messageId)
      .then((response) => {
        console.log('response SMS is', response);
        if (response.status == 200) {
          AlertMessage.success('Message send');
        } else {
          AlertMessage.error('Unable to send SMS');
        }
      })
      .catch((err) => {
        AlertMessage.error('Unable to send SMS!');
        console.log('error', err);
      });
  };

  return (
    <>
      <PatientsTable
        columns={SMSListColumns(
          history,
          pageNo,
          pageSize,
          StyledButton,
          theme,
          reSendFunction
        )}
        dataSource={SMSList}
        pageSize={pageSize}
        setPageNo={setPageNo}
        pageNo={pageNo}
        total={totalSMS}
      />
    </>
  );
};
export default SMSList;
