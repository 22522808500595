import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import styled from "styled-components";
import {
  Row,
  Col,
  Input,
  Button,
  Typography,
  message,
  Select,
  Form,
  notification,
} from "antd";
import { addNewDepartment } from "../../../../apis/departments.js";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Option } from "antd/lib/mentions";
import { StyledRow } from "../ConsentFormManagement/styledComponents.js";
import { StyledForm } from "./Styledcomponents";
import {
  addChiefcomplaint,
  updateChiefcomplaint,
} from "../../../../apis/Admin";
import {
  ModalBodyContainer,
  StyledTitle,
} from "../Sections/styledComponents.js";

const ComplaintModal = ({
  visible,
  departments,
  setVisible,
  edit,

  refetch,
  selectedItem,
  setSelectedItem,
}) => {
  const [complaint, setComplaint] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const theme = useSelector((state) => state.themeReducer);

  const submitHandler = (values) => {
    const payload = {
      name: values.complaint,
      departmentId: values.department,
    };
    if (edit) {
      payload["id"] = selectedItem.id;

      updateComplaint(payload);
    } else {
      addcomplaint(payload);
    }
    setVisible(false);
  };
  const addcomplaint = (data) => {
    // add
    addChiefcomplaint(data).then(({ data }) => {
      if (data.status === 1) {
        notification.success({
          message: data.body,
        });
      } else {
        notification.error({
          message: data.body,
        });
      }
      refetch();
      form.resetFields();
    });
  };
  const updateComplaint = (data) => {
    // edit
    updateChiefcomplaint(data).then(({ data }) => {
      if (data.status === 1) {
        notification.success({
          message: data.body,
        });
      }
      refetch();
      form.resetFields();
    });
  };
  const fetchFields = () => {
    form.setFieldsValue({
      complaint: selectedItem.name,
      department: selectedItem.department.id,
    });
  };
  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        complaint: selectedItem.name,
        department: selectedItem.department.id,
      });
    } else {
      form.resetFields();
    }
  }, [selectedItem, edit]);
  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBodyContainer>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            {" "}
            <StyledTitle level={3} color={theme["@primary-color"]}>
              {edit ? "Edit Complaint" : "Create New Complaint"}
            </StyledTitle>
          </Col>

          <StyledForm
            layout="vertical"
            onFinish={(values) => submitHandler(values)}
            form={form}
            scrollToFirstError={true}
          >
            <Row justify="center">
              <Col span={24}>
                <Form.Item
                  label="Complaint"
                  name="complaint"
                  rules={[
                    { required: true, message: "Complaint cannot be empty" },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" placeholder="Enter Complaint" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Form.Item
                label="Department"
                name="department"
                rules={[
                  { required: true, message: "Department cannot be empty" },
                ]}
                style={{ width: "100%" }}
              >
                <Select
                  size="large"
                  placeholder="Select Department"
                  //   mode="multiple"
                >
                  {departments &&
                    departments.map((department) => {
                      return (
                        <Option value={department.id} key={department.id}>
                          {department.departmentName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Row>

            <Form.Item>
              <StyledRow gutter={12} justify="end">
                <Col>
                  <Button
                    onClick={() => {
                      setVisible(false);
                      if (!edit) form.resetFields();
                    }}
                    danger
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" loading={loading} htmlType="submit">
                    Save
                  </Button>
                </Col>
              </StyledRow>
            </Form.Item>
          </StyledForm>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default ComplaintModal;
