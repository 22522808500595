import React from "react";
import { Button, Col, Divider, message, notification, Row, Upload } from "antd";
import { UploaderPlaceHolder } from "./styledComponents";
import { PictureOutlined, UploadOutlined } from "@ant-design/icons";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";

const ImageUpload = ({
  imageBlob,
  setImageBlob,
  innerNode,
  resolution,
  url,
}) => {
  const uploadProps = {
    beforeUpload: (file) => {
      var img = document.createElement("img");
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        var w = img.width;
        var h = img.height;

        if (resolution) {
          if (w / h < 3.1 && w / h > 2.7) {
            setImageBlob(file);
          } else {
            notification.error({
              message: "Unable to upload",
              description: "Logo resolution should be in the ratio of 1:3",
              // onClick: () => {
              //   console.log("Notification Clicked!");
              // },
            });
          }
        } else {
          setImageBlob(file);
        }
      };

      return false;
    },
    onRemove: () => {
      setImageBlob(undefined);
    },
    accept: ".jpg,.jpeg,.png",
    showUploadList: false,
  };

  return (
    <Row gutter={24} align="middle">
      <Col>
        <UploaderPlaceHolder
          bgImage={
            imageBlob
              ? URL.createObjectURL(imageBlob)
              : url
              ? PROFILE_IMAGE_PREFIX + url
              : ""
          }
        >
          {!(imageBlob || url) &&
            (innerNode || (
              <PictureOutlined
                style={{
                  fontSize: "35px",
                  margin: "auto",
                  opacity: 0.5,
                  color: "#cacaca",
                }}
              />
            ))}
        </UploaderPlaceHolder>
        {resolution && (
          <Divider
            style={{ fontSize: "12px", fontWeight: 600 }}
          >{`${resolution.height} x ${resolution.width}`}</Divider>
        )}
      </Col>
      <Col>
        <Upload {...uploadProps}>
          <Button
            type="primary"
            ghost
            style={{ marginBottom: "35px" }}
            size="large"
            icon={<UploadOutlined />}
          ></Button>
        </Upload>
      </Col>
    </Row>
  );
};

export default ImageUpload;
