import { Button, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  claimPendingInsuranceColumns,
  historyInsuranceColumns,
  preApprovalInsuranceColumns,
} from "./InsuranceColumns";
import {
  exportHistory,
  fetchClaimPendingInsurance,
  fetchHistoryInsurance,
  fetchInsuranceProviders,
  fetchPreApprovalInsurance,
} from "../../../apis/Insurance";
import * as Sentry from "@sentry/react";

import PatientSearch from "../PatientsList/PatientSearch";
import PatientsTable from "../PatientsList/PatientsTable";
import { getDoctors } from "../../../apis/patients";
import { getSettings } from "../../../apis/settings";
import { hasPrivilege } from "../../utils/privilegeHandlers/hasPrivilege.js";
import moment from "moment-timezone";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  &.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    /* padding: 16px 25px; */
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid #d6d6d6;
    width: 200px;
    /* text-align: center; */
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
  }
  & .ant-tabs-tab-btn {
    margin: auto;
  }
  &.tab_sec_portal .ant-tabs-tab div {
    font-size: 20px;
    color: #808080;
  }
  &.tab_sec_portal .ant-tabs-tab {
    width: auto !important;
    padding: 14px 18px;
  }
  &.tab_sec_portal .ant-tabs-tab.ant-tabs-tab-active div {
    color: ${(props) => props.color};
  }

  .table_col_style1 {
    padding-bottom: 60px;
  }
  @media (max-width: 1359px) {
    &.tab_sec_portal .ant-tabs-tab {
      width: auto !important;
      padding: 12px 18px;
    }
    &.tab_sec_portal .ant-tabs-tab div {
      font-size: 18px;
    }
  }

  .table_col_style1 .field-title {
    font-size: 16px;
    font-weight: 500;
  }
  .table_col_style1 .field-typed .ant-picker {
    height: 50px;
    border-radius: 5px;
  }
  .table_col_style1 .book_btn_bl {
    font-size: 17px;
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    padding: 0px 20px;
    border-radius: 5px;
  }
  .table_col_style1 table thead tr th {
    font-size: 16px;
    border-bottom: 0px;
  }
  .table_col_style1 table tbody tr td {
    border-top: 1px solid #cdcdcd;
    border-bottom: 0px;
  }
  .table_col_style1 table tbody tr td {
    font-size: 17px;
    padding: 12px 16px;
  }
  @media (max-width: 1359px) {
    .table_col_style1 table tbody tr td {
      font-size: 15px;
    }
  }

  .table_col_style1 table tbody tr td .ant-btn {
    padding: 5px 12px;
    height: auto;
    font-size: 16px;
    border-radius: 5px;
  }
  .table_col_style1 table tbody tr td .ant-btn-sm:hover {
    background-color: ${(props) => props.color} !important;

    color: #ffffff;
  }
  .table_col_style1
    table
    tbody
    tr
    td
    .ant-btn-background-ghost.ant-btn-primary:hover {
    background-color: ${(props) => props.color} !important;
    color: #ffffff;
  }
  @media (max-width: 1199px) {
    .table_col_style1 .field-typed .ant-picker,
    .table_col_style1 .book_btn_bl {
      height: 42px;
    }
    .table_col_style1 table tbody tr td .ant-btn {
      font-size: 14px !important;
    }
    .table_col_style1 table thead tr th {
      font-size: 15px;
    }
    .mt-input-search {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .mt-input-search-btn {
      flex: 0 0 13%;
    }
  }
  @media (max-width: 991px) {
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 640px;
      flex: 0 0 640px;
      max-width: 640px;
    }
    .table_col_style1 .btn_right_stripe {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 18px;
    }
    .mt-input-search {
      flex: 0 0 47%;
      max-width: 47%;
    }
    .mt-input-search-btn {
      flex: 0 0 13%;
    }
  }
  @media (max-width: 767px) {
    &.tab_sec_portal .ant-tabs-tab {
      padding: 9px 15px;
    }
    &.tab_sec_portal .ant-tabs-tab div {
      font-size: 16px;
    }
    .mt-input-search,
    .mt-input-search-btn {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 640px) {
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 480px;
      flex: 0 0 480px;
      max-width: 480px;
    }
    .data_field_picker .ant-row {
      margin: 0px -3px !important;
    }
    .data_field_picker .ant-col {
      padding: 0px 6px !important;
    }
    .data_field_picker .ant-col:last-child {
      padding-left: 0px !important;
    }
  }
  @media (max-width: 480px) {
    &.tab_sec_portal .ant-tabs-nav-wrap {
      width: 100%;
      overflow: inherit;
    }
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .data_field_picker .ant-row {
      margin: 0px 0px !important;
    }
    .data_field_picker .ant-col {
      padding: 0px 0px 5px !important;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .data_field_picker .ant-col.field-title {
      padding: 0px 0px !important;
    }
    .mx_clear_data {
      text-align: right;
      color: #dc2e18;
    }
  }
`;

const StyledButton = styled(Button)`
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    background: ${(props) => props.color};
    color: white;
  }
`;

const searchByItem = ["all", "name", "id", "mobileNo"];

const InsuranceDashboard = () => {
  const [pageNo, setPageNo] = useState(0);
  const [currentTab, setCurrentTab] = useState(1);
  const [searchParam, setSearchParam] = useState("all");
  const [doctors, setDoctors] = useState([]);
  const [insuranceProviders, setInsuranceProviders] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [docFilterParam, setDocFilterParam] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [dateRangeFilter, setDateRangeFilter] = useState({});
  const [defaultParams, setDefaultParams] = useState();
  const [downloadReport, setDownloadReport] = useState(false);

  const [preApprovalLoading, setPreApprovalLoading] = useState(false);
  const [claimPendingLoading, setClaimPendingLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [preApprovalData, setPreApprovalData] = useState([]);
  const [preApprovalTotal, setPreApprovalTotal] = useState(0);
  const [claimPendingData, setClaimPendingData] = useState([]);
  const [claimPendingTotal, setClaimPendingTotal] = useState(0);
  const [historyInsuranceData, setHistoryInsuranceData] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [currency, setCurrency] = useState();
  const pageSize = 15;
  const history = useHistory();
  const user = useSelector((state) => state.userReducer);
  const theme = useSelector((state) => state.themeReducer);

  const onTabChange = (activeTabKey) => {
    setCurrentTab(activeTabKey);
    if (
      searchString ||
      docFilterParam.length !== 0 ||
      filterParams.filterString ||
      (dateRangeFilter.startDate && dateRangeFilter.endDate)
    )
      onClear();
  };
  const onClear = () => {
    setPageNo(0);
    setDocFilterParam([]);
    setFilterParams({});
    setSearchParam("all");
    setSearchString("");
    setDateRangeFilter({});
    if (currentTab == 1) {
      setPreApprovalLoading(true);
      getPreApprovalInsurance(defaultParams);
    } else if (currentTab == 2) {
      setClaimPendingLoading(true);
      getClaimPendingInsurance(defaultParams);
    } else if (currentTab == 3) {
      setHistoryLoading(true);
      getHistoryInsurance(defaultParams);
    }
  };

  const onInputChange = (event) => {
    setSearchString(event.target.value);
  };
  const onSelectChange = (value) => {
    setSearchParam(value);
  };

  const onDocFilterSubmit = (filter) => {
    setDocFilterParam(filter);
  };

  const onFilterSubmit = (filter) => {
    let filterString;
    Object.entries(filter).forEach(([key, value]) => {
      if (value.length > 0) {
        filterString = `${filterString ? filterString : ""}${
          filterString ? "&" : ""
        }${key}=${value}`;
      } else
        filterString = `${filterString ? filterString : ""}${
          filterString ? "&" : ""
        }`;
    });
    setFilterParams({ filterString, filter });
  };

  const exportHistoryOnClick = (searchQuery) => {
    const today = new Date()
      .toLocaleDateString("en-GB", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");
    exportHistory(searchQuery)
      .then((res) => {
        setDownloadReport(false);
        var fileDownload = require("js-file-download");
        fileDownload(res.data, `History-${today}.xlsx`);
      })
      .catch();
  };

  const onSearch = (value) => {
    let searchQuery;
    if (downloadReport == false) {
      if (value == true) {
        searchQuery = `pageNo=0&size=${pageSize}&timeZone=${moment.tz.guess()}`;
        setPageNo(0);
      } else {
        searchQuery = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
      }
    } else searchQuery = defaultParams;
    if (searchString)
      searchQuery = `${searchQuery}&${searchParam}=${searchString}`;
    if (docFilterParam.length !== 0)
      searchQuery = `${searchQuery}&doctorName=${docFilterParam}`;
    if (filterParams.filterString)
      searchQuery = `${searchQuery}&${filterParams.filterString}`;
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${
        searchQuery ? searchQuery : ``
      }&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    if (downloadReport == false) {
      if (currentTab == 1) {
        setPreApprovalLoading(true);
        getPreApprovalInsurance(searchQuery);
      } else if (currentTab == 2) {
        setClaimPendingLoading(true);
        getClaimPendingInsurance(searchQuery);
      } else if (currentTab == 3) {
        setHistoryLoading(true);
        getHistoryInsurance(searchQuery);
      }
    } else exportHistoryOnClick(searchQuery ? searchQuery : ``);
  };

  const getPreApprovalInsurance = (queryString) => {
    fetchPreApprovalInsurance(queryString)
      .then((res) => {
        if (res.status == 200) {
          setPreApprovalData(res.data.body);
          setPreApprovalTotal(res.data.total);
        } else {
          message.error("Could not fetch insurance list, Try again");
        }
        setPreApprovalLoading(false);
      })
      .catch((err) => {
        setPreApprovalLoading(false);
        message.error("Could not fetch insurance list, Try again");
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const getClaimPendingInsurance = (queryString) => {
    fetchClaimPendingInsurance(queryString)
      .then((res) => {
        if (res.status === 200) {
          var tableData = res.data.body?.map((item) => {
            if (!item.appointment) {
              var appointment = item.pharmacyRequest?.appointment;
              appointment.insuranceData = item.insuranceData;
              item.appointment = appointment;
            }
            return item;
          });
          setClaimPendingData(tableData);
          setClaimPendingTotal(res.data.total);
        } else {
          message.error("Could not fetch insurance list, Try again");
        }
        setClaimPendingLoading(false);
      })
      .catch((err) => {
        setClaimPendingLoading(false);
        message.error("Could not fetch insurance list, Try again");
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const getHistoryInsurance = (queryString) => {
    fetchHistoryInsurance(queryString)
      .then((res) => {
        if (res.status === 200) {
          var tableData = res.data.body?.map((item) => {
            if (!item.appointment) {
              var appointment = item.pharmacyRequest?.appointment;
              appointment.insuranceData = item.insuranceData;
              item.appointment = appointment;
            }
            return item;
          });
          setHistoryInsuranceData(tableData);
          setHistoryTotal(res.data.total);
        } else {
          message.error("Could not fetch insurance list, Try again");
        }
        setHistoryLoading(false);
      })
      .catch((err) => {
        setHistoryLoading(false);
        message.error("Could not fetch insurance list, Try again");
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const fetchDoctors = () => {
    getDoctors()
      .then((res) => {
        if (res.data.status === 1) {
          const doctorData = res.data.body.map((eachDoc) => ({
            label: eachDoc.firstName + " " + eachDoc.lastName,
            value: eachDoc.firstName + " " + eachDoc.lastName,
          }));
          setDoctors(doctorData);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const fetchInsuranceProviderList = () => {
    fetchInsuranceProviders()
      .then((res) => {
        const providersData = res.data.body.map(
          (eachProvider) => eachProvider.name
        );
        setInsuranceProviders(providersData);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const onDateRangeFilter = (date, dateString) => {
    const startDate = moment(dateString[0], "DD-MM-YYYY");
    const endDate = moment(dateString[1], "DD-MM-YYYY");
    setDateRangeFilter({ startDate, endDate });
  };

  useEffect(() => {
    setDefaultParams(
      `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`
    );
    fetchDoctors();
    fetchInsuranceProviderList();
    getSettings().then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setCurrency(data.body.currency);
        }
      }
    });
  }, []);

  useEffect(() => {
    let insuranceParam = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
    if (
      searchString ||
      docFilterParam.length !== 0 ||
      filterParams.filterString ||
      (dateRangeFilter.startDate && dateRangeFilter.endDate)
    )
      onSearch();
    else {
      if (pageNo == 0) {
        if (hasPrivilege("VIEW_PENDING_INSURANCE")) {
          setPreApprovalLoading(true);
          getPreApprovalInsurance(insuranceParam);
        }
        if (hasPrivilege("VIEW_PENDING_INSURANCE")) {
          setClaimPendingLoading(true);
          getClaimPendingInsurance(insuranceParam);
        }
        if (hasPrivilege("VIEW_ALL_INSURANCE")) {
          setHistoryLoading(true);
          getHistoryInsurance(insuranceParam);
        }
      } else {
        if (currentTab == 1) {
          setPreApprovalLoading(true);
          getPreApprovalInsurance(insuranceParam);
        } else if (currentTab == 2) {
          setClaimPendingLoading(true);
          getClaimPendingInsurance(insuranceParam);
        } else if (currentTab == 3) {
          setHistoryLoading(true);
          getHistoryInsurance(insuranceParam);
        }
      }
    }
  }, [pageNo]);

  useEffect(() => {
    if (
      filterParams.filterString ||
      filterParams.filterString == "" ||
      docFilterParam.length !== 0
    )
      onSearch(true);
  }, [filterParams, docFilterParam]);

  useEffect(() => {
    if (downloadReport == true) onSearch();
  }, [downloadReport]);

  return (
    <StyledTabs
      className="tab_sec_portal"
      color={theme["@primary-color"]}
      activeKey={`${currentTab}`}
      onTabClick={(key) => setCurrentTab(key)}
      size="large"
      animated
      tabBarGutter={0}
      onChange={onTabChange}
      tabBarStyle={{ width: "100%" }}
    >
      {hasPrivilege("VIEW_PENDING_INSURANCE") && (
        <TabPane tab="Waiting for Pre-approval" key="1">
          <PatientSearch
            docFilterItems={doctors}
            searchSelect={searchParam}
            onSearch={onSearch}
            filters={["Insurance Providers", "Visit Type"]}
            tabType={"preApprovalInsurance"}
            {...{
              searchByItem,
              onInputChange,
              onSelectChange,
              onDocFilterSubmit,
              searchString,
              onClear,
              onFilterSubmit,
              onDateRangeFilter,
              dateRangeFilter,
              filterParams,
              docFilterParam,
              insuranceProviders,
            }}
          />
          <PatientsTable
            columns={preApprovalInsuranceColumns(
              history,
              pageNo,
              pageSize,
              StyledButton,
              theme
            )}
            loading={preApprovalLoading}
            dataSource={preApprovalData}
            pageSize={pageSize}
            setPageNo={setPageNo}
            pageNo={pageNo}
            total={preApprovalTotal}
          />
        </TabPane>
      )}
      {hasPrivilege("VIEW_PENDING_INSURANCE") && (
        <TabPane tab="Pending Claim" key="2">
          <PatientSearch
            docFilterItems={doctors}
            searchSelect={searchParam}
            onSearch={onSearch}
            filters={["Visit Type", "Insurance Status", "Insurance Providers"]}
            tabType={"claimPendingInsurance"}
            {...{
              searchByItem,
              onInputChange,
              onSelectChange,
              onDocFilterSubmit,
              searchString,
              onClear,
              onFilterSubmit,
              onDateRangeFilter,
              dateRangeFilter,
              filterParams,
              docFilterParam,
              insuranceProviders,
            }}
          />
          <PatientsTable
            columns={claimPendingInsuranceColumns(
              history,
              pageNo,
              pageSize,
              StyledButton,
              theme
            )}
            loading={claimPendingLoading}
            dataSource={claimPendingData}
            pageSize={pageSize}
            setPageNo={setPageNo}
            pageNo={pageNo}
            total={claimPendingTotal}
          />
        </TabPane>
      )}
      {hasPrivilege("VIEW_ALL_INSURANCE") && (
        <TabPane tab="History" key="3">
          <PatientSearch
            docFilterItems={doctors}
            searchSelect={searchParam}
            onSearch={onSearch}
            filters={["Visit Type", "Insurance Status", "Insurance Providers"]}
            tabType={"historyInsurance"}
            {...{
              searchByItem,
              onInputChange,
              onSelectChange,
              onDocFilterSubmit,
              searchString,
              onClear,
              onFilterSubmit,
              onDateRangeFilter,
              dateRangeFilter,
              filterParams,
              docFilterParam,
              insuranceProviders,
              setDownloadReport,
            }}
          />
          <PatientsTable
            columns={historyInsuranceColumns(
              history,
              pageNo,
              pageSize,
              StyledButton,
              theme,
              currency
            )}
            loading={historyLoading}
            dataSource={historyInsuranceData}
            pageSize={pageSize}
            setPageNo={setPageNo}
            pageNo={pageNo}
            total={historyTotal}
          />
        </TabPane>
      )}
    </StyledTabs>
  );
};

export default InsuranceDashboard;
