import React from "react";
import moment from "moment";
import { Row, Col } from "antd";
import styled from "styled-components";

const AppointmentStatus = styled.div`
  color: ${(props) => statusColour[props.status]};
`;
const statusColour = {
  "Pre-Booked": "orange",
  Confirmed: "green",
  Declined: "red",
};

export const myAppointmentsColumns = (
  history,
  user,
  cancelModalVisible,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  currency
) => [
    {
      title: "Actions",
      key: "id",
      render: (row) => {
        return (
          <Row gutter={12}>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                disabled={
                  row.status == "Cancelled" ||
                    row.status == "Expired" ||
                    row.status == "Declined"
                    ? true
                    : false
                }
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/appointment/${user.id}/${row.id}/${user.fullName}`
                  );
                }}
              >
                Reschedule
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                disabled={
                  row.status == "Cancelled" ||
                    row.status == "Expired" ||
                    row.status == "Declined"
                    ? true
                    : false
                }
                onClick={(e) => {
                  e.stopPropagation();
                  cancelModalVisible(row.id);
                }}
              >
                Cancel
              </StyledButton>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "Visit Type",
      dataIndex: ["visitType", "value"],
      key: "visitType",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor",
      key: "doctor",
      render: (doctor, record, index) => {
        if (doctor !== null)
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        else if (record.parentAppointment?.doctor)
          return (
            <div>
              Dr. {record.parentAppointment?.doctor.firstName} {record.parentAppointment?.doctor.lastName}
            </div>
          )
        else return <div>-</div>;
      },
    },
    {
      title: "Department",
      dataIndex: ["department", "departmentName"],
      key: "department",
      render: (value, record, index) => {
        if (value !== null) return <div>{value}</div>;
        else if (record.parentAppointment?.department?.departmentName)
          return (
            <div>
              {record.parentAppointment?.department?.departmentName}
            </div>
          )
        else return <div>-</div>
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Time",
      dataIndex: "startTime",
      key: "time",
      render: (time) => {
        if (time !== null) return <div>{moment(time).format("hh:mm A")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Total Cost",
      dataIndex: ["bill", "totalFee"],
      key: "cost",
      render: (cost) => {
        if (cost !== null) return `${currency ? currency : ""} ${cost}`;
        else return <div>-</div>;
      },
    },
    {
      title: "Payment Method",
      dataIndex: ["paymentType"],
      key: "paymentMethod",
      render: (paymentMethod) => {
        if (paymentMethod !== null) return <div>{paymentMethod}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        if (value !== null)
          return <AppointmentStatus status={value}>{value}</AppointmentStatus>;
        else return <div>-</div>;
      },
    },
    {
      width: 10,
    },
  ];

export const medicalHistoryColumns = (
  history,
  user,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  currency
) => [
    {
      title: "Actions",
      fixed: "left",
      render: (record) => {
        return (
          <StyledButton
            color={theme["@primary-color"]}
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              if (record?.visitType?.value == "Laboratory") history.push({
                pathname: `/laboratory-records/${user.id}/${user.fullName
                  .split(" ")
                  .join("-")}/${record.id}`,
                state: { viewHistory: true },
              });
              else if (record?.visitType?.value == "Radiology")
                history.push({
                  pathname: `/radiology-records/${user.id}/${user.fullName
                    .split(" ")
                    .join("-")}/${record.id}`,
                  state: { viewHistory: true },
                });
              else
                history.push({
                  pathname:
                    `/health-records/${user.id}/${user.fullName
                      .split(" ")
                      .join("-")}/${record.id}`,
                  state: { visitTypeForHistory: record?.visitType?.value }
                });
            }}
          >
            View Details
          </StyledButton>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "Visit Type",
      dataIndex: ["visitType", "value"],
      key: "visitType",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor",
      key: "doctor",
      render: (doctor, record, index) => {
        if (doctor !== null)
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        else if (record.parentAppointment?.doctor)
          return (
            <div>
              Dr. {record.parentAppointment?.doctor.firstName} {record.parentAppointment?.doctor.lastName}
            </div >
          )
        else return <div>-</div>;
      },
    },
    {
      title: "Department",
      dataIndex: ["department", "departmentName"],
      key: "department",
      render: (value, record, index) => {
        if (value !== null) return <div>{value}</div>;
        else if (record.parentAppointment?.department?.departmentName)
          return (
            <div>
              {record.parentAppointment?.department?.departmentName}
            </div>
          )
        else return <div>-</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Time",
      dataIndex: "startTime",
      key: "time",
      render: (time) => {
        if (time !== null) return <div>{moment(time).format("hh:mm A")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Total Cost",
      dataIndex: ["bill", "totalFee"],
      key: "cost",
      render: (cost) => {
        if (cost !== null) return `${currency ? currency : ""} ${cost}`;
        else return <div>-</div>;
      },
    },
    {
      title: "Payment Method",
      dataIndex: ["paymentType"],
      key: "paymentMethod",
      render: (paymentMethod) => {
        if (paymentMethod !== null) return <div>{paymentMethod}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        if (value !== null)
          return <AppointmentStatus status={value}>{value}</AppointmentStatus>;
        else return <div>-</div>;
      },
    },
    {
      width: 10,
    },
  ];
