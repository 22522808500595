import { Menu } from "antd";
import styled from "styled-components";

export const HealthRecordMenu = styled(Menu)`
  border-radius: 10px;
  &.sidepanel_menu_list {
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    height: auto;
  }
  &.sidepanel_menu_list li {
    height: auto;
    line-height: 70px;
    font-size: 16px;
  }
  &.sidepanel_menu_list li.ant-menu-item-selected {
    background-color: #e8f6ff;
  }
  &.sidepanel_menu_list li span.anticon {
    font-size: 35px;
  }
  &.sidepanel_menu_list li.ant-menu-item-selected span.anticon svg {
    stroke: #26b1ff;
  }
  @media (max-width: 1440px) {
    &.sidepanel_menu_list li {
      line-height: 70px;
    }
  }
  @media (max-width: 1360px) {
    &.sidepanel_menu_list li span.anticon {
      font-size: 26px;
    }
  }
  @media (max-width: 1199px) {
    &.sidepanel_menu_list {
      position: relative;
      top: 0px;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 100px;
      margin-top: 53px;
    }
    &.sidepanel_menu_list li {
      line-height: 62px;
    }
    &.sidepanel_menu_list {
      border-right: 0px !important;
    }
  }
  && {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 6px;
    // scrollbar-color: rebeccapurple green !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    // background-color: auto;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }
`;
export const HealthRecordMenuItem = styled(HealthRecordMenu.Item)`
  && {
    height: 70px;
    line-height: 70px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
  }
`;
export const ButtonContainer = styled.div`
  background: #fff;
  margin-top: -8px;
  padding: 20px;
  height: 70px;
  border-radius: 0 0 10px 10px;
`;
