import React, { useEffect, useState } from "react";
import { Row, Col, message, Button } from "antd";
import { SectionHeading, SectionHeadContainer } from "../styledComponents";
import { fetchRoles } from "../../../../apis/User";
import Spinner from "../../../utils/Spinner";
import styled from "styled-components";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

const StyledCol = styled(Col)``;

const StyledButton = styled(Button)`
  border-radius: 5px !important;
  &:hover {
    background: ${(props) => props.color};
    color: white;
  }
`;
const UserRolesList = ({
  setSelected,
  setSelectedId,
  trigger,
  setSelectedRole,
}) => {
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = useSelector((state) => state.themeReducer);

  useEffect(() => {
    fetchRoles()
      .then((resp) => {
        if (resp.data.status === 1) {
          setUserRoles(resp.data.body);
          setLoading(false);
        } else {
          setLoading(false);
          message.error("Could not fetch Userroles, Try again");
        }
      })
      .catch((err) => {
        message.error("Could not fetch Userroles, Try again");
        setLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  }, [trigger]);

  return loading ? (
    <Spinner />
  ) : (
    <Row gutter={[36, 24]} style={{ margin: "-18px -18px" }}>
      {userRoles.length > 0 &&
        userRoles.map((role) => (
          <StyledCol span={8} xs={24} md={8}>
            <StyledButton
              color={theme["@primary-color"]}
              size="large"
              onClick={() => {
                setSelected(true);
                setSelectedId(role.id);
                setSelectedRole(role);
              }}
              style={{ width: "100%" }}
              type={role.status === "INACTIVE" ? "dashed" : "default"}
              danger={role.status === "INACTIVE" ? true : false}
            >
              {role.name}
            </StyledButton>
          </StyledCol>
        ))}
    </Row>
  );
};

export default UserRolesList;
