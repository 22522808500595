import React, { useState, useEffect } from "react";
import {
  SectionHeadContainer,
  SectionHeading,
  StyledDiv,
} from "../styledComponents";
import { BodyContainer } from "./StyledComponent";
import * as Sentry from "@sentry/react";
import { Row, Col, Button, message, notification } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import {
  fetchLabTests,
  addLabTests,
  updateLabTests,
} from "../../../../apis/Admin";

import ConfirmationModal from "./ConfirmationModal";
import styled from "styled-components";
import { useSelector } from "react-redux";
import NewLabTypeFieldsModal from "./NewLabTypeFieldsModal";
import { fetchFieldsOfTest } from "../../../../apis/forms";
import SectionManagementTable from "./LabManagementTable";
import { newFieldColumns } from "./TableConfig";
import PreviewModal from "../Sections/PreviewModal";
import { changeSectionOrder } from "../../../../apis/healthRecord";

const TestFieldManagement = ({
  setTab,
  selectedForm,
  selectedSection,
  selectedField,
  totalForms,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.themeReducer);
  const [newModal, setNewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newFieldModal, setNewFieldModal] = useState(false);
  const [tests, setTests] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedType, setSelectedType] = useState({});
  const [total, setTotal] = useState();
  const [edit, setEdit] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [previewModal, setPreviewModal] = useState(false);
  const [changesOrderStatus, setChangeOrderStatus] = useState(false);

  const newTypeHandler = () => {
    setEdit(false);
    setNewModal(true);
    setSelectedItem();
  };

  const StyledButton = styled(Button)`
    border: 1px solid white !important;
    border-radius: 5px !important;
    min-width: 140px;
    height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
  `;

  const getTestType = () => {
    setLoading(true);
    // console.log(selectedField);
    fetchFieldsOfTest("LABORATORY", selectedField.id)
      .then(({ data, status }) => {
        if (data) {
          if (data.status === 1) {
            setTests(data.body);
            setTotal(data.body?.length);
            setLoading(false);
          } else {
            message.error(data.body);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getTestType();
  }, []);
  const addType = (type) => {
    // api to add lab
    // const payload = {
    //   name: type,
    //   sectionId: selectedForm.id,
    //   type: "LAB_TEST",
    // };
    // addLabTests(payload).then((data) => {
    //   // success mesage
    //   getTestType();
    //   if (data.data.status === 1) {
    //     notification.success({
    //       message: data.data.body,
    //     });
    //     getTestType();
    //   } else {
    //     notification.error({
    //       message: data.data.body,
    //     });
    //   }
    // });
    // setNewModal(false);
  };

  const update = (type) => {
    // api to add lab
    // const payload = {
    //   name: type,
    //   id: selectedItem.id,
    //   type: "LAB_TEST",
    // };
    // updateLabTests(payload).then((data) => {
    //   // success mesage
    //   getTestType();
    //   if (data.data.status === 1) {
    //     notification.success({
    //       message: data.data.body,
    //     });
    //     getTestType();
    //   } else {
    //     notification.error({
    //       message: data.data.body,
    //     });
    //   }
    // });
    // setNewModal(false);
  };

  const handleOrderChangeClick = () => {
    if (changesOrderStatus) {
      let newOrder = tests.map((item, index) => ({
        formId: item.id,
        formOrder: index + 1,
      }));
      changeSectionOrder({ formOrderChanges: newOrder })
        .then(({ data, status }) => {
          if (data.status == 1) {
            notification.success({
              message: "New Order saved!",
            });
            setChangeOrderStatus(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    } else {
      setChangeOrderStatus(true);
    }
  };

  const changeOrder = () => {
    setLoading(true);
    // console.log(selectedField);
    fetchFieldsOfTest("LABORATORY", selectedField.id)
      .then(({ data, status }) => {
        if (data) {
          if (data.status === 1) {
            setTests(data.body);
            setTotal(data.body?.length);
            setLoading(false);

            let newOrder = data.body.map((item, index) => ({
              formId: item.id,
              formOrder: index + 1,
            }));
            changeSectionOrder({ formOrderChanges: newOrder })
              .then(({ data, status }) => {
                if (data.status == 1) {
                  // setChangeOrderStatus(false);
                  // getTestType();
                }
              })
              .catch((err) => {
                console.log(err);
                Sentry.captureException(err);
              });
          } else {
            message.error(data.body);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row>
            <Col span={24}>
              <SectionHeading>
                <span style={{ color: "#999" }}>
                  Laboratory
                  <LeftCircleOutlined
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                  />
                  Laboratory Test Type
                  <LeftCircleOutlined
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                  />
                  Lab Test
                  <LeftCircleOutlined
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                    onClick={() => {
                      setTab("lab_test");
                    }}
                  />
                  Fields
                </span>
              </SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <Row gutter={[0, 18]}>
            <Col span={24} style={{ padding: "2.5px 0" }}>
              <Row justify="end">
                <Col>
                  <Row justify="end" align="right">
                    <Button
                      type="primary"
                      ghost
                      style={{ border: 0, boxShadow: "none" }}
                      size="large"
                      onClick={newTypeHandler}
                    >
                      Create New Field
                    </Button>
                  </Row>
                </Col>
                {/* <Col>
                  <Row justify="end" align="right">
                    <Button
                      type="primary"
                      ghost={true}
                      style={
                        changesOrderStatus
                          ? {}
                          : { border: 0, boxShadow: "none" }
                      }
                      size="large"
                      onClick={handleOrderChangeClick}
                    >
                      {changesOrderStatus ? "Save Order" : "Change Order"}
                    </Button>
                  </Row>
                </Col> */}
              </Row>
            </Col>
            <Col span={24} style={{ paddingTop: "0" }}>
              <StyledDiv bgcolor={theme["@primary-color"]}>
                <div>&nbsp;&nbsp;Tests</div>
                <div>&nbsp;&nbsp;</div>
                <Row justify="end">
                  <Col>
                    <Row justify="end" align="right">
                      <StyledButton
                        type="primary"
                        // ghost={true}
                        size="large"
                        onClick={() => {
                          if (tests?.length > 0) setPreviewModal(true);
                        }}
                      >
                        Preview
                      </StyledButton>
                    </Row>
                  </Col>
                  <Col>
                    <Row justify="end" align="right">
                      <StyledButton
                        type="primary"
                        // ghost={true}
                        style={{
                          marginLeft: "10px",
                        }}
                        size="large"
                        onClick={() => {
                          if (tests?.length > 0) handleOrderChangeClick();
                        }}
                      >
                        {changesOrderStatus ? "Save Order" : "Change Order"}
                      </StyledButton>
                    </Row>
                  </Col>
                </Row>
              </StyledDiv>
              <SectionManagementTable
                dataSource={tests}
                columns={newFieldColumns(
                  setSelectedItem,
                  setNewModal,
                  setDeleteModal,
                  setEdit
                )}
                loading={loading}
                setDataSource={setTests}
                draggable={changesOrderStatus}
              />
            </Col>
          </Row>
        </BodyContainer>
      </Col>
      <NewLabTypeFieldsModal
        visible={newModal}
        setVisible={setNewModal}
        addType={addType}
        edit={edit}
        update={update}
        setEdit={setEdit}
        selectedItem={edit ? selectedItem : undefined}
        selectedField={selectedField}
        total={total}
        refetch={getTestType}
        tests={tests}
      />

      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        refetch={getTestType}
        fields={true}
        changeOrder={changeOrder}
      />
      <PreviewModal
        visible={previewModal}
        setVisible={setPreviewModal}
        formName={selectedField.name}
        fields={tests}
      />
    </Row>
  );
};
export default TestFieldManagement;
