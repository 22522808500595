import React from 'react';
import { Row, Col, Button } from 'antd';
import Icon from '@ant-design/icons';
import moment from 'moment';
// import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';
// import { ReactComponent as DeleteIcon } from '../../assets/icons/DeleteIcon.svg';
import { loginAPI } from '../../../apis/Auth';

const loginFunction = (row) => {
  const email = row;
  console.log('email', email);
  // loginAPI()
  // .then((res) => {
  //   if (res.status === 200) {
  //     if (res.data.status === 1) {
  //       localStorage.setItem("user", JSON.stringify(res.data.body));
  //       localStorage.setItem("token", res.data.body.token);
  //       dispatch(addUser(res.data.body));
  //       history.push("/");
  //     } else {
  //       message.error(res.data.body);
  //       setLoading(false);
  //     }
  //   }
  // })
  // .catch((err) => {
  //   console.log(err);
  //   message.error("Invalid username or Password");
  //   setLoading(false);
  // });
};

const activeStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DELETED: 'Deleted',
};

const statusColour = {
  ACTIVE: 'green',
  INACTIVE: 'red',
};

export const columns = (
  setEditModal,
  setDeleteModal,
  setselectedItem,
  history
) => [
  { title: 'Hospital Name', dataIndex: 'name' },
  {
    title: 'Contact Person Name',
    dataIndex: 'contactPersonName',
  },
  { title: 'Mobile Number', dataIndex: ['mobileNumber', 'phoneNumber'] },
  // { title: "Email", dataIndex: "email" },
  {
    title: 'Active Status',
    dataIndex: 'status',
    render: (value) => (
      <div style={{ color: statusColour[value] }}>{activeStatuses[value]}</div>
    ),
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    render: (value) => <div>{moment(value).format('DD-MM-YYYY')}</div>,
  },
  // {
  //   title: 'View Details',
  //   render: (row) => (
  //     <Button
  //       ghost
  //       type="primary"
  //       onClick={(row) => {
  //         loginFunction(row);
  //       }}
  //     >
  //       View Details
  //     </Button>
  //   ),
  // },
  // {
  //   title: "Actions",
  //   width: "150px",
  //   render: (record) => (
  //     <Row gutter={12}>
  //       {/* <Col>
  //         {" "}
  //         <Icon
  //           style={{ fontSize: "20px" }}
  //           component={EditIcon}
  //           onClick={() => {
  //             setselectedItem(record);
  //             setEditModal(true);
  //           }}
  //         />
  //       </Col> */}
  //       <Col>
  //         <Icon
  //           style={{ fontSize: "22px" }}
  //           component={DeleteIcon}
  //           onClick={() => {
  //             setselectedItem(record);
  //             setDeleteModal(true);
  //           }}
  //         />
  //       </Col>
  //     </Row>
  //   ),
  // },
];
