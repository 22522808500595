import React from "react";
import { Col, Form, Input } from "antd";
const { TextArea } = Input;

const EmailFormFields = ({ type }) => {
  return (
    <>
      <Col span={12}>
        <Form.Item
          label="Subject"
          name={[type, "subject"]}
          rules={[{ required: true, message: "Subject is required!" }]}
        >
          <Input label="Header" size="large" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Header"
          name={[type, "header"]}
          rules={[{ required: true, message: "Header is required!" }]}
        >
          <Input label="Header" size="large" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Message"
          name={[type, "message"]}
          rules={[{ required: true, message: "Message is required!" }]}
        >
          <TextArea label="Message" size="large" />
        </Form.Item>
      </Col>
      {type !== "statusEmailTemplate" && (
        <Col span={12}>
          <Form.Item
            label="Button Name"
            name={[type, "buttonName"]}
            rules={[{ required: true, message: "Button value is required!" }]}
          >
            <Input label="Button Name" size="large" />
          </Form.Item>
        </Col>
      )}
      <Col span={24}>
        <Form.Item
          label="Footer"
          name={[type, "footer"]}
          rules={[{ required: true, message: "Footer is required!" }]}
        >
          <TextArea size="large" />
        </Form.Item>
      </Col>
    </>
  );
};
export default EmailFormFields;
