export const primaryLanguages = [
  {
    code: "AFR",
    text: "Afrikaans / Africans",
  },
  {
    code: "ARA",
    text: "Arabic",
  },
  {
    code: "BEN",
    text: "Bengali",
  },
  {
    code: "BRL",
    text: "Braille",
  },
  {
    code: "CHI",
    text: "Chinese",
  },
  {
    code: "HRV",
    text: "Croatian",
  },
  {
    code: "CZE",
    text: "Czech",
  },
  {
    code: "DAN",
    text: "Danish",
  },
  {
    code: "DUT",
    text: "Dutch; Flemish",
  },
  {
    code: "ENGL",
    text: "English",
  },
  {
    code: "FREN",
    text: "French",
  },
  {
    code: "GLA",
    text: "Gaelic; Scottish Gaelic",
  },
  {
    code: "GER",
    text: "German",
  },
  {
    code: "GRE",
    text: "Greek",
  },
  {
    code: "HEB",
    text: "Hebrew",
  },
  {
    code: "HIN",
    text: "Hindi",
  },
  {
    code: "ITA",
    text: "Italian",
  },
  {
    code: "JPN",
    text: "Japanese",
  },
  {
    code: "KOR",
    text: "Korean",
  },
  {
    code: "LAO",
    text: "Lao",
  },
  {
    code: "MAY",
    text: "Malay",
  },
  {
    code: "MAL",
    text: "Malayalam",
  },
  {
    code: "OTHR",
    text: "Other",
  },
  {
    code: "PER",
    text: "Persian / Farsi",
  },
  {
    code: "PHI",
    text: "Philippine languages",
  },
  {
    code: "POR",
    text: "Portuguese",
  },
  {
    code: "PUN",
    text: "Punjabi",
  },
  {
    code: "PUS",
    text: "Pushto; Pashto",
  },
  {
    code: "RUS",
    text: "Russian",
  },
  {
    code: "SEN",
    text: "Senegalese",
  },
  {
    code: "SOM",
    text: "Somali",
  },
  {
    code: "SPAN",
    text: "Spanish",
  },
  {
    code: "SWE",
    text: "Swedish",
  },
  {
    code: "TGL",
    text: "Tagalog",
  },
  {
    code: "TAI",
    text: "Tai languages",
  },
  {
    code: "TAM",
    text: "Tamil",
  },
  {
    code: "TEL",
    text: "Telugu",
  },
  {
    code: "TUR",
    text: "Turkish",
  },
  {
    code: "UKR",
    text: "Ukrainian",
  },
  {
    code: "UNK",
    text: "Unknown",
  },
  {
    code: "URD",
    text: "Urdu",
  },
  {
    code: "VIE",
    text: "Vietnamese",
  },
  {
    code: "YOR",
    text: "Yorub",
  },
];
