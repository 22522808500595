import React, { useEffect, useState } from 'react';
import {
  Select,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  TimePicker,
  Popover,
  Checkbox,
  Typography,
} from 'antd';
import styled from 'styled-components';
import './PatientList.css';
import { useSelector } from 'react-redux';
import Icon, {
  CaretDownOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { ReactComponent as DocIcon } from '../../../assets/icons/doc.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { ReactComponent as DateIcon } from '../../../assets/icons/date.svg';
import { ReactComponent as TimeIcon } from '../../../assets/icons/time.svg';

import moment from 'moment';
import { hasPrivilege } from '../../utils/privilegeHandlers/hasPrivilege.js';

const { Option } = Select;

const StyledSearchSelect = styled(Select)`
  background-color: '#fff';
  width: 250px;
  text-transform: capitalize;
  @media (max-width: 480px) {
    width: 100px;
  }
`;
const StyledSelect = styled(Select)`
  width: 100%;
  text-transform: capitalize;
`;

const PopoverItemContent = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 15px;
`;

const StyledInput = styled(Input)`
  & .ant-input-group-addon {
    background-color: white;
  }
  gap: 6px;
`;

const StyledDateRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  height: 40px;
  .ant-picker-input > input {
    font-size: 16px;
  }
`;

const StyledTimeRangePicker = styled(TimePicker.RangePicker)`
  width: 100%;
  height: 40px;
  .ant-picker-input > input {
    font-size: 16px;
  }
`;

const StyledButton = styled(Button)`
  width: 90%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
    margin-left: unset;
  }
`;

const IconWrapper = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

const IconTextWrapper = styled(Row)`
  align-items: center;
  gap: 10px;
`;

const StyledFilterButton = styled(Button)`
  width: 100%;
  color: rgb(125, 125, 125) !important;
  border: ${(props) => props.filter};
`;

const DownloadReportContainer = styled.div`
  height: 25px;
  display: flex;
  justify-content: flex-end;
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${(props) => props.color};
  font-size: 15px;
  transition: all 0.2s linear;
  :hover {
    cursor: pointer;
    color: ${(props) => props.color};
  }
`;

const ClearButtonContainer = styled.div`
  color: #b7b8b8;
  transition: all 0.2s linear;
  :hover {
    cursor: pointer;
    color: #7d7d7d;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px;
  margin-top: 10px;
`;

const capitalize = (camelCaseText) => {
  return (
    camelCaseText.charAt(0).toUpperCase() +
    camelCaseText
      .slice(1)
      .split(/(?=[A-Z])/)
      .join(' ')
  );
};

const getSelectBefore = (searchByItem, onSelectChange, searchSelect) => {
  return (
    <StyledSearchSelect
      value={searchSelect}
      suffixIcon={<CaretDownOutlined />}
      onChange={onSelectChange}
    >
      {searchByItem.map((item) => (
        <StyledSearchSelect.Option value={item}>
          {item}
        </StyledSearchSelect.Option>
      ))}
    </StyledSearchSelect>
  );
};
const PatientSearch = ({
  searchByItem,
  onSelectChange,
  docFilterItems,
  searchSelect,
  searchString,
  onDocFilterSubmit,
  onInputChange,
  onSearch,
  filters,
  onFilterSubmit,
  filterParams,
  docFilterParam,
  onTimeFilter,
  timeFilter,
  dateRangeFilter,
  onDateRangeFilter,
  tabType,
  onClear,
  setDownloadReport,
  insuranceProviders,
}) => {
  const user = useSelector((state) => state.userReducer);
  const theme = useSelector((state) => state.themeReducer);
  const FilterPopover = ({ onFilterSubmit }) => {
    const [filter, setFilter] = useState({});
    let popoverItems = [
      {
        label: 'Visit Type',
        items:
          tabType == 'preApprovalInsurance'
            ? ['Laboratory', 'Radiology']
            : ['Hospital', 'Laboratory', 'Radiology', 'Pharmacy'],
        paramString: 'visitType',
      },
      {
        label: 'Payment Method',
        items: ['Self', 'Insurance'],
        paramString: 'paymentStatus',
      },
      {
        label: 'Appointment Status',
        items: [
          'Pre-Booked',
          'Confirmed',
          'Declined',
          'Completed',
          'Report Waiting',
          'Expired',
          'Cancelled',
        ],
        paramString: 'appointmentStatus',
      },
      {
        label: 'Insurance Status',
        items:
          tabType == 'claimPendingInsurance'
            ? ['Claim Waiting', 'Pending', 'Waiting for Approval']
            : ['Paid', 'Partially Accepted', 'Declined'],
        paramString: 'insuranceStatus',
      },
      {
        label: 'Insurance Providers',
        items: insuranceProviders,
        paramString: 'insuranceProvider',
      },
      {
        label: 'Request Type',
        items: ['Emergency', 'Normal'],
        paramString: 'pharmacyRequestType',
      },
    ];
    useEffect(() => {
      if (filterParams.filter) setFilter(filterParams.filter);
    }, [filterParams.filter]);
    return (
      <Popover
        placement="bottom"
        trigger="click"
        getPopupContainer={(node) => node.parentNode}
        content={() => (
          <>
            <PopoverItemContent>
              {popoverItems.map((item) => {
                return filters.includes(item.label) ? (
                  <>
                    <Typography.Text type="secondary">
                      {item.label}
                    </Typography.Text>
                    <br />
                    <Checkbox.Group
                      options={item.items}
                      value={filter[item.paramString]}
                      onChange={(value) => {
                        setFilter({ ...filter, [item.paramString]: value });
                      }}
                    ></Checkbox.Group>
                    <br />
                  </>
                ) : (
                  ''
                );
              })}
            </PopoverItemContent>
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    onFilterSubmit ? onFilterSubmit(filter) : null
                  }
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </>
        )}
      >
        <StyledFilterButton
          filter={
            filterParams?.filterString?.length > 0
              ? `1px solid ${theme['@primary-color']} !important`
              : 'none'
          }
          color={theme['@primary-color']}
          size="large"
        >
          <IconWrapper>
            <IconTextWrapper>
              <Icon style={{ fontSize: '17px' }} component={FilterIcon} />
              Filters
            </IconTextWrapper>
            <CaretDownOutlined
              style={{
                fontSize: '12px',
              }}
            />
          </IconWrapper>
        </StyledFilterButton>
      </Popover>
    );
  };

  const DocFilterPopover = ({ docFilterItems, onDocFilterSubmit }) => {
    const [docFilter, setDocFilter] = useState([]);
    useEffect(() => {
      if (docFilterParam.length) setDocFilter(docFilterParam);
    }, [docFilterParam]);

    return (
      <Popover
        placement="bottom"
        trigger="click"
        getPopupContainer={(node) => node.parentNode}
        content={() => (
          <>
            <PopoverItemContent>
              <Checkbox.Group
                options={docFilterItems}
                value={docFilter}
                onChange={(value) => setDocFilter(value)}
              ></Checkbox.Group>
            </PopoverItemContent>
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    onDocFilterSubmit ? onDocFilterSubmit(docFilter) : null
                  }
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </>
        )}
      >
        <StyledFilterButton
          filter={
            docFilter.length !== 0
              ? `1px solid ${theme['@primary-color']} !important`
              : 'none'
          }
          size="large"
        >
          <IconWrapper>
            <IconTextWrapper>
              <Icon style={{ fontSize: '17px' }} component={DocIcon} />
              Doctors
            </IconTextWrapper>
            <CaretDownOutlined
              style={{
                fontSize: '12px',
              }}
            />
          </IconWrapper>
        </StyledFilterButton>
      </Popover>
    );
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  return (
    <>
      <DownloadReportContainer>
        {(user.userType == 'RECEPTIONIST' ||
          (user.userType == 'DOCTOR' &&
            (tabType == 'reports' || tabType == 'all')) ||
          tabType == 'historyInsurance') && (
          <>
            <DownloadButtonContainer
              color={theme['@primary-color']}
              onClick={() => setDownloadReport(true)}
            >
              <DownloadOutlined
                style={{
                  fontSize: '15px',
                }}
              />
              <div style={{ textDecoration: 'underline' }}>Download</div>
            </DownloadButtonContainer>
          </>
        )}
      </DownloadReportContainer>

      <StyledRow>
        {hasPrivilege(undefined, [
          'RECEPTIONIST',
          'DOCTOR',
          'NURSE',
          'INSURANCE',
          'LABORATORY',
          'RADIOLOGY',
          'PHARMACY',
        ]) && (
          <>
            {(tabType == 'active' ||
              tabType == 'pending' ||
              tabType == 'reports' ||
              hasPrivilege(undefined, [
                'NURSE',

                'INSURANCE',
                'LABORATORY',
                'RADIOLOGY',
              ])) && (
              <>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <StyledSelect
                    value={searchSelect}
                    size="large"
                    suffixIcon={<CaretDownOutlined />}
                    onChange={onSelectChange}
                  >
                    {searchByItem.map((item) => (
                      <Option
                        style={{ textTransform: 'capitalize' }}
                        value={item}
                      >
                        {item}
                      </Option>
                    ))}
                  </StyledSelect>
                </Col>
                <Col lg={6} md={18} sm={12} xs={12}>
                  <StyledInput
                    size="large"
                    placeholder="Search"
                    prefix={
                      <SearchOutlined style={{ color: ' rgb(5, 125, 125)' }} />
                    }
                    value={searchString}
                    onChange={onInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') onSearch(true);
                    }}
                  />
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <DocFilterPopover
                    docFilterItems={docFilterItems}
                    onDocFilterSubmit={onDocFilterSubmit}
                  />
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <FilterPopover onFilterSubmit={onFilterSubmit} />
                </Col>
                <Col lg={5} md={9} sm={24} xs={24}>
                  {(tabType == 'pending' ||
                    tabType == 'reports' ||
                    tabType == 'reportWaiting' ||
                    tabType == 'testHistory' ||
                    hasPrivilege(undefined, ['INSURANCE', 'PHARMACY'])) && (
                    <StyledDateRangePicker
                      placeholder={
                        user.userType == 'INSURANCE'
                          ? ['Visit Date', 'End Date']
                          : ['Start Date', 'End Date']
                      }
                      suffixIcon={
                        <Icon
                          style={{ fontSize: '17px' }}
                          component={DateIcon}
                        />
                      }
                      allowClear={false}
                      format="DD-MM-YYYY"
                      value={
                        dateRangeFilter.startDate && dateRangeFilter.endDate
                          ? [dateRangeFilter.startDate, dateRangeFilter.endDate]
                          : undefined
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                      disabledDate={
                        tabType == 'pending' ? disabledDate : undefined
                      }
                      onChange={onDateRangeFilter}
                    />
                  )}
                  {(tabType == 'active' || user.userType == 'NURSE') && (
                    <>
                      <StyledTimeRangePicker
                        format={'hh:mm a'}
                        value={
                          timeFilter?.startTime && timeFilter?.endTime
                            ? [timeFilter?.startTime, timeFilter?.endTime]
                            : undefined
                        }
                        placeholder={['Start Time', 'End Time']}
                        suffixIcon={
                          <Icon
                            style={{ fontSize: '17px' }}
                            component={TimeIcon}
                          />
                        }
                        allowClear={false}
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={onTimeFilter}
                      />
                      <StyledDateRangePicker
                        placeholder={['Start Date', 'End Date']}
                        suffixIcon={
                          <Icon
                            style={{ fontSize: '17px' }}
                            component={DateIcon}
                          />
                        }
                        allowClear={false}
                        format="DD-MM-YYYY"
                        value={
                          dateRangeFilter?.startDate && dateRangeFilter?.endDate
                            ? [
                                dateRangeFilter?.startDate,
                                dateRangeFilter?.endDate,
                              ]
                            : undefined
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={onDateRangeFilter}
                      />
                    </>
                  )}
                </Col>
              </>
            )}
            {tabType == 'all' && (
              <>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <StyledInput
                    //   enterButton="Search"
                    addonBefore={getSelectBefore(
                      searchByItem,
                      onSelectChange,
                      searchSelect
                    )}
                    size="large"
                    placeholder="Search"
                    prefix={
                      <SearchOutlined
                        style={{ color: ' rgb(125, 125, 125)' }}
                      />
                    }
                    value={searchString}
                    onChange={onInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') onSearch(true);
                    }}
                  />
                </Col>
                <Col lg={10} md={10} sm={24} xs={24}>
                  <StyledDateRangePicker
                    placeholder={['Registration Start Date', 'End Date']}
                    suffixIcon={
                      <Icon style={{ fontSize: '17px' }} component={DateIcon} />
                    }
                    allowClear={false}
                    format="DD-MM-YYYY"
                    value={
                      dateRangeFilter.startDate && dateRangeFilter.endDate
                        ? [dateRangeFilter.startDate, dateRangeFilter.endDate]
                        : undefined
                    }
                    getPopupContainer={(trigger) => trigger.parentElement}
                    onChange={onDateRangeFilter}
                  />
                </Col>
              </>
            )}

            {tabType == 'activePharmacy' && (
              <>
                <Col lg={4} md={9} sm={12} xs={12}>
                  <StyledSelect
                    value={searchSelect}
                    size="large"
                    suffixIcon={<CaretDownOutlined />}
                    onChange={onSelectChange}
                  >
                    {searchByItem.map((item) => (
                      <Option
                        style={{ textTransform: 'capitalize' }}
                        value={item}
                      >
                        {item}
                      </Option>
                    ))}
                  </StyledSelect>
                </Col>
                <Col lg={8} md={14} sm={12} xs={12}>
                  <StyledInput
                    size="large"
                    placeholder="Search"
                    prefix={
                      <SearchOutlined
                        style={{ color: ' rgb(125, 125, 125)' }}
                      />
                    }
                    value={searchString}
                    onChange={onInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') onSearch(true);
                    }}
                  />
                </Col>
                <Col lg={5} md={9} sm={12} xs={12}>
                  <DocFilterPopover
                    docFilterItems={docFilterItems}
                    onDocFilterSubmit={onDocFilterSubmit}
                  />
                </Col>
                <Col lg={5} md={11} sm={12} xs={12}>
                  <FilterPopover onFilterSubmit={onFilterSubmit} />
                </Col>
              </>
            )}
          </>
        )}
        {user.userType === 'DOCTOR' && (
          <>
            {(tabType == 'docActive' || tabType == 'docReportWaiting') && (
              <>
                <Col lg={4} md={4} sm={8} xs={8}>
                  <StyledSelect
                    value={searchSelect}
                    size="large"
                    suffixIcon={<CaretDownOutlined />}
                    onChange={onSelectChange}
                  >
                    {searchByItem.map((item) => (
                      <Option
                        style={{ textTransform: 'capitalize' }}
                        value={item}
                      >
                        {item}
                      </Option>
                    ))}
                  </StyledSelect>
                </Col>
                <Col lg={8} md={6} sm={16} xs={16}>
                  <StyledInput
                    size="large"
                    placeholder="Search"
                    prefix={
                      <SearchOutlined
                        style={{ color: ' rgb(125, 125, 125)' }}
                      />
                    }
                    value={searchString}
                    onChange={onInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') onSearch(true);
                    }}
                  />
                </Col>
                <Col lg={4} md={4} sm={8} xs={8}>
                  <FilterPopover onFilterSubmit={onFilterSubmit} />
                </Col>
                <Col lg={6} md={7} sm={16} xs={16}>
                  {tabType == 'docActive' && (
                    <>
                      <StyledTimeRangePicker
                        format={'hh:mm A'}
                        value={
                          timeFilter.startTime && timeFilter.endTime
                            ? [timeFilter.startTime, timeFilter.endTime]
                            : undefined
                        }
                        placeholder={['Start Time', 'End Time']}
                        suffixIcon={
                          <Icon
                            style={{ fontSize: '17px' }}
                            component={TimeIcon}
                          />
                        }
                        allowClear={false}
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={onTimeFilter}
                      />
                      <StyledDateRangePicker
                        placeholder={['Start Date', 'End Date']}
                        suffixIcon={
                          <Icon
                            style={{ fontSize: '17px' }}
                            component={DateIcon}
                          />
                        }
                        allowClear={false}
                        format="DD-MM-YYYY"
                        value={
                          dateRangeFilter.startDate && dateRangeFilter.endDate
                            ? [
                                dateRangeFilter.startDate,
                                dateRangeFilter.endDate,
                              ]
                            : undefined
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={onDateRangeFilter}
                      />
                    </>
                  )}
                  {tabType == 'docReportWaiting' && (
                    <StyledDateRangePicker
                      placeholder={['Start Date', 'End Date']}
                      suffixIcon={
                        <Icon
                          style={{ fontSize: '17px' }}
                          component={DateIcon}
                        />
                      }
                      allowClear={false}
                      format="DD-MM-YYYY"
                      value={
                        dateRangeFilter.startDate && dateRangeFilter.endDate
                          ? [dateRangeFilter.startDate, dateRangeFilter.endDate]
                          : undefined
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={onDateRangeFilter}
                    />
                  )}
                </Col>
              </>
            )}
            {/* {tabType == "all" && (
              <>
                <Col lg={22} md={21} sm={24} xs={24}>
                  <StyledInput
                    //   enterButton="Search"
                    addonBefore={getSelectBefore(
                      searchByItem,
                      onSelectChange,
                      searchSelect
                    )}
                    size="large"
                    placeholder="Search"
                    prefix={
                      <SearchOutlined
                        style={{ color: " rgb(125, 125, 125)" }}
                      />
                    }
                    value={searchString}
                    onChange={onInputChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") onSearch(true);
                    }}
                  />
                </Col>
              </>
            )} */}
          </>
        )}
        {user.userType == 'PATIENT' && (
          <>
            <Col lg={6} md={6} sm={12} xs={12}>
              <DocFilterPopover
                docFilterItems={docFilterItems}
                onDocFilterSubmit={onDocFilterSubmit}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <FilterPopover onFilterSubmit={onFilterSubmit} />
            </Col>
            <Col lg={10} md={9} sm={24} xs={24}>
              <StyledDateRangePicker
                placeholder={['Start Date', 'End Date']}
                suffixIcon={
                  <Icon style={{ fontSize: '17px' }} component={DateIcon} />
                }
                allowClear={false}
                format="DD-MM-YYYY"
                value={
                  dateRangeFilter.startDate && dateRangeFilter.endDate
                    ? [dateRangeFilter.startDate, dateRangeFilter.endDate]
                    : undefined
                }
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={onDateRangeFilter}
              />
            </Col>
          </>
        )}

        <Col className="mt-input-search-btn" lg={2} md={3} sm={24} xs={24}>
          <Row>
            <StyledButton
              type="primary"
              size="large"
              onClick={() => onSearch(true)}
            >
              Search
            </StyledButton>
          </Row>

          <Row style={{ justifyContent: 'flex-end', marginTop: '5px' }}>
            <ClearButtonContainer onClick={onClear}>
              <CloseCircleOutlined
                style={{ fontSize: '14px', marginRight: '6px ' }}
              />
              Clear All
            </ClearButtonContainer>
          </Row>
        </Col>
      </StyledRow>
    </>
  );
};

export default PatientSearch;
