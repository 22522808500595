import React from "react";
import { useState } from "react";
import FormManagement from "./FormManagement";
import FieldManamgement from "./FieldManagement";
const Encounters = () => {
  const [tab, setTab] = useState("forms");
  const [selectedSection, setSelectedSection] = useState();
  const [totalSections, setTotalSections] = useState(0);
  const [totalForms, setTotalForms] = useState(0);
  const [selectedForm, setSelectedForm] = useState();
  switch (tab) {
    case "forms":
      return (
        <FormManagement
          setTab={setTab}
          setSelectedForm={setSelectedForm}
          totalForms={totalForms}
          setTotalForms={setTotalForms}
        />
      );
    case "form_fields":
      return (
        <FieldManamgement
          setTab={setTab}
          selectedForm={selectedForm}
          selectedSection={selectedSection}
          totalForms={totalForms}
        />
      );
    default:
      return <FormManagement />;
  }
};
export default Encounters;
