import React from "react";
import { StyledButton } from "./styledComponents";

const insuranceStatusList = {
  Pending: "Pending",
  "Cash Paid": "Cash Paid",
  "Insurance Claimed": "Approved",
  "Insurance Claim Rejected": "Declined",
};

export const approvalColumns = (
  theme,
  settings,
  acceptedList,
  setAcceptedList,
  declinedList,
  setDeclinedList,
  isCompleted,
  isPharmacy
) => [
  { width: "1vw" },
  {
    title: "Actions",
    width: isCompleted ? "150px" : "250px",
    render: (value, row, index) => (
      <>
        {!isCompleted ? (
          <StyledButton
            color={theme["@primary-color"]}
            type="primary"
            ghost={!acceptedList.includes(row.testDataId)}
            value="cash"
            onClick={() => {
              if (!isCompleted) {
                let list = acceptedList;
                if (!acceptedList.includes(row.testDataId)) {
                  list = list.concat(row.testDataId);
                  setAcceptedList([...list]);
                  var index = declinedList.indexOf(row.testDataId);
                  if (index > -1) {
                    declinedList.splice(index, 1);
                  }
                  setDeclinedList([...declinedList]);
                } else {
                  var index = acceptedList.indexOf(row.testDataId);
                  if (index > -1) {
                    acceptedList.splice(index, 1);
                  }
                  setAcceptedList([...acceptedList]);
                }
              }
            }}
          >
            {!acceptedList.includes(row.testDataId) ? "Approve" : "Approved"}
          </StyledButton>
        ) : (
          <></>
        )}
        {isCompleted && (
          <StyledButton
            style={{ color: theme["@primary-color"], boxShadow: "none" }}
            color={"white"}
          >
            {isPharmacy
              ? insuranceStatusList[row.additionalInfo?.paymentStatus]
              : insuranceStatusList[row.paymentStatus]}
          </StyledButton>
        )}
        {!isCompleted ? (
          <StyledButton
            danger
            type="primary"
            ghost={!declinedList.includes(row.testDataId)}
            value="insurance"
            onClick={() => {
              if (!isCompleted) {
                let list = declinedList;
                if (!declinedList.includes(row.testDataId)) {
                  list = list.concat(row.testDataId);
                  setDeclinedList([...list]);
                  var index = acceptedList.indexOf(row.testDataId);
                  if (index > -1) {
                    acceptedList.splice(index, 1);
                  }
                  setAcceptedList([...acceptedList]);
                } else {
                  var index = declinedList.indexOf(row.testDataId);
                  if (index > -1) {
                    declinedList.splice(index, 1);
                  }
                  setDeclinedList([...declinedList]);
                }
              }
            }}
          >
            {!declinedList.includes(row.testDataId) ? "Decline" : "Declined"}
          </StyledButton>
        ) : (
          <></>
        )}
      </>
    ),
  },
  isPharmacy
    ? {
        dataIndex: "Sl No",
        title: "Sl. No",
      }
    : {
        title: "Sl. No",
        dataIndex: "Sl No.",
      },
  isPharmacy
    ? { title: "Drugs/ Generic code", dataIndex: "Drugs/ Generic code" }
    : { title: "Orders", dataIndex: "Test Name" },
  isPharmacy
    ? { title: "Dispense", dataIndex: ["additionalInfo", "dispenseQuantity"] }
    : { title: "Department", dataIndex: "Department" },
  {
    title: "Fee",
    dataIndex: "fee",
    render: (data) => (
      <p
        style={{
          fontWeight: "600",
          fontSize: "14px",
          color: "#666666",
        }}
      >
        {settings?.currency + " " + data}
      </p>
    ),
  },
  // { width: "1vw" },
];
