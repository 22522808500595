import axiosInstance from "../config/axiosInstance";
const AUTH_PREFIX = "/auth";
export const loginAPI = (data) => {
  return axiosInstance.post(AUTH_PREFIX + "/signIn", data);
};
export const generatePasswordResetLink = (data) => {
  return axiosInstance.post(AUTH_PREFIX + "/generate-reset-link", data);
};

export const resetPassword = (data) => {
  return axiosInstance.patch(AUTH_PREFIX + "/reset-password", data);
};

export const patientRegistration = (data) => {
  return axiosInstance.post(AUTH_PREFIX + "/patient-registration", data);
};

export const fetchBloodGroup = (type, sectionId) => {
  return axiosInstance.get(
    `${AUTH_PREFIX}/fetch-blood-group-drop-downs?type=${type}${
      sectionId ? "&section_id=" + sectionId : ""
    }`
  );
};
