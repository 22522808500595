import React from "react";
import { Table, Button, Empty, Menu, Dropdown, Row, Col } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Spinner from "../../../utils/Spinner";
import { CaretDownOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { BlueBgTable } from "../styledComponents";

export const StyledTable = styled(Table)`
  margin-top: 20px;
  & .ant-table-thead > tr > th {
    color: ${(props) => props.headColor};
    font-weight: 600;
    text-align: left;
    background: #ffffff;
    border-bottom: 2px solid #dedede;
  }

  & .ant-table-tbody > tr > td {
    border-bottom: 2px solid #f0f0f0;
  }
  & .ant-table-cell {
    text-transform: capitalize;
  }
  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    /* position: relative; */
    padding: 8px;
    overflow-wrap: break-word;
    margin: 0px;
  }
`;

const EmptyMessageContainer = styled.div`
  /* height: calc(100vh - 450px); */
  min-height: 250px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
  @media (max-width: 480px) {
    min-height: 250px;
  } ;
`;

const StyledDropdownWrapper = styled.div`
  height: 35px;
  width: 150px;
  padding: 0px 10px;
  border: 1px solid rgb(199, 199, 199) !important;
  border-radius: 3px;
  display: flex;
  margin-left: 20px;
`;

const StyledButtonContent = styled.a`
  color: rgb(125, 125, 125) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ClearButtonContainer = styled.div`
  color: #b7b8b8;
  font-size: 15px;
  transition: all 0.2s linear;
  :hover {
    cursor: pointer;
    color: #7d7d7d;
  }
`;

const HL7Table = ({
  columns,
  loading,
  dataSource,
  pageSize,
  setPageNo,
  pageNo,
  total,
  setStatusFilter,
  onClear,
}) => {
  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <Button>Previous</Button>;
    }
    if (type === "next") {
      return <Button>Next</Button>;
    }
    return originalElement;
  };

  const theme = useSelector((state) => state.themeReducer);
  const [pageCount, setPageCount] = useState(pageNo + 1);

  const menu = () => {
    return (
      <Menu
        onClick={(props) => {
          props.domEvent.stopPropagation();
          setPageNo(0);
          setStatusFilter(props.key);
        }}
      >
        <Menu.ItemGroup title="HL7 Status">
          <Menu.Item key="success" style={{ color: "green" }}>
            Success
          </Menu.Item>
          <Menu.Item key="failed" style={{ color: "red" }}>
            Failed
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  useEffect(() => {
    setPageCount(pageNo + 1);
  }, [pageNo]);
  return (
    <>
      <Row style={{ alignItems: "center", gap: "10px" }}>
        <Col>
          <StyledDropdownWrapper>
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              overlayStyle={{ width: "150px" }}
              trigger={["click"]}
            >
              <StyledButtonContent onClick={(e) => e.preventDefault()}>
                Filter
                <CaretDownOutlined
                  style={{
                    fontSize: "15px",
                  }}
                />
              </StyledButtonContent>
            </Dropdown>
          </StyledDropdownWrapper>
        </Col>
        <Col>
          <ClearButtonContainer onClick={onClear}>
            <CloseCircleOutlined
              style={{ fontSize: "15px", marginRight: "8px " }}
            />
            Clear
          </ClearButtonContainer>
        </Col>
      </Row>
      {!loading ? (
        <>
          {dataSource.length > 0 ? (
            <>
              <StyledTable
                headColor={theme["@primary-color"]}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                scroll={{ x: "max-content" }}
                pagination={{
                  onChange: (cur) => {
                    setPageNo(cur - 1);
                    setPageCount(cur);
                  },
                  pageSize: pageSize,
                  total: total,
                  current: pageCount,
                  showSizeChanger: false,
                  itemRender: itemRender,
                }}
                locale={{
                  emptyText: (
                    <EmptyMessageContainer>
                      <Empty />
                    </EmptyMessageContainer>
                  ),
                }}
              />
            </>
          ) : (
            <EmptyMessageContainer>
              <Empty />
            </EmptyMessageContainer>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default HL7Table;
