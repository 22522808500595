import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Row, Col } from "antd";
import ResetForm from "./ResetForm";
import HospitalLogo from "../Login/HospitalLogo";
import { ReactComponent as DoctorIcon } from "../../assets/icons/304919.svg";
import { useSelector } from "react-redux";
import { PROFILE_IMAGE_PREFIX } from "../../constants/StringConstants";

const ResetContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${(props) => props.bgColor};

  .popup-form-data .ant-col label{
    color: #8e8c8c;
    font-size: 15px;
  }
  .popup-form-data .ant-col label:before{
    display:none;
  }
  .popup-form-data .ant-col label:after{
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight:700;
    content: '*';
  }
  .popup-form-data .ant-col .ant-input-affix-wrapper{
    border-radius:5px;
    border:1px solid #cccccc;
  }
  .popup-form-data .ant-col textarea{
    resize:none;
  }
  button.submit-btn{
    height: 44px;
    font-weight: 600;
    letter-spacing: 0.020em;
    background-color:#0f70bc !important;
    border:1px solid transparent;
    width:100%;
    font-size:18px;
    width:100px;
    border-radius:5px;
    margin-top:20px;
  }
  button.submit-btn.w-100{
    width:100%;
  }
  .logo-reset-fm img{
    height:inherit;
    width:100%;
    margin-bottom:20px;
  }
  @media (max-width:600px){
    .reset-popup-fm{
      width:90%;
      padding: 20px 15px;
    }
  }

`;
const FullHeightRow = styled(Row)`
  height: 100%;
`;
const FormContainer = styled.div`
  background-color: #fff;
  width: 80%;
  border-radius: 8px;
  text-align: center;
  padding: 20px 40px;
  margin: auto;
`;
const StyledH2 = styled.h2`
  color: #6d6d6d;
`;
const ResetPassword = (props) => {
  const { token } = useParams();
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);
  return (
    <ResetContainer bgColor={theme["@primary-color"]}>
      <FullHeightRow justify="space-around" align="middle">
        <Col xl={10} lg={10} xxl={8}>
          <Row >
            <Col span={24}>
              <Row justify="space-around" >
                <Col span={10} className="logo-reset-fm">
                  <HospitalLogo
                    title="Hospital Logo"
                    icon={<DoctorIcon style={{ width: "100%" }} />}
                    imageUrl={
                      images.logo
                        ? PROFILE_IMAGE_PREFIX + images.logo
                        : undefined
                    }
                    
                  />
                </Col>
              </Row>
            </Col>
            <FormContainer  className="reset-popup-fm">
              <Col span={24}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <StyledH2>Reset your password</StyledH2>
                  </Col>
                  <Col span={24}>
                    <ResetForm token={token} />
                  </Col>
                </Row>
              </Col>
            </FormContainer>
          </Row>
        </Col>
      </FullHeightRow>
    </ResetContainer>
  );
};
export default ResetPassword;
