import React from 'react';
import { Row, Col, Select, Button, Input, message } from 'antd';
import {
  CaretDownOutlined,
  UserAddOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import {
  UserTypeSelect,
  NewUserButton,
  BorderLessInput,
  BorderLessSelect,
  ClearButtonContainer,
  TableCotainer,
} from './styledComponents';
import { useState } from 'react';
import UserTable from './UserTable';
import {
  fetchAllUsers,
  fetchActiveRoles as fetchRoles,
  deleteUser,
} from '../../../../apis/User';
import { useEffect } from 'react';
import { getDepartments as fetchDepartments } from '../../../../apis/Appointment';
import CreateUser from './CreateUser';
import ConfirmationModal from './ConfirmationModal';
import { getHospitalId } from '../../../utils/LocalStorage';

const statuses = [
  { name: 'Active', value: 'ACTIVE' },
  { name: 'Inactive', value: 'INACTIVE' },
];
const pageSize = 10;

const UserManagement = () => {
  const [filterOptions, setfilterOptions] = useState({});
  const [pageNo, setPageNo] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState([]);

  const [createModal, setCreateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onFilterOptionChange = (value, optionKey) => {
    if (Array.isArray(value)) {
      value = value.length > 0 ? value : undefined;
    }
    setfilterOptions({ ...filterOptions, [optionKey]: value });
  };
  const onClearFilterOptions = () => {
    setfilterOptions({});
  };

  const getAllusers = () => {
    setLoading(true);
    fetchAllUsers(filterOptions, pageNo, pageSize)
      .then(({ data, status }) => {
        if (status === 200) {
          setDataSource(data.body);
          setTotalCount(data.total);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const hospitalId = getHospitalId();
  const getDepartments = (hospitalId) => {
    fetchDepartments(getHospitalId())
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) setDepartments(data.body);
        } else {
          message.error('Unable to fetch departments');
        }
      })
      .catch();
  };
  const getRoles = () => {
    fetchRoles()
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) setUserTypes(data.body);
        } else {
          message.error('Unable to fetch departments');
        }
      })
      .catch();
  };

  useEffect(() => {
    getAllusers();
  }, [pageNo]);

  useEffect(() => {
    setPageNo(0);
    if (pageNo == 0) getAllusers();
  }, [filterOptions]);

  useEffect(() => {
    getDepartments();
    getRoles();
  }, []);

  const deleteUserHandler = () => {
    setDeleteLoading(true);
    deleteUser(selectedItem.id)
      .then((resp) => {
        if (resp.data.status === 1) {
          setDeleteLoading(false);
          message.success('Successfully Deleted User');
          setDeleteModal(false);
          getAllusers();
        } else {
          message.error('Could not delete User, Try Again');
          setDeleteLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error('Could not delete User, Try Again');
        setDeleteLoading(false);
      });
  };

  return (
    <>
      {!createModal ? (
        <Row>
          <Col span={24}>
            <Row justify="space-between" style={{ height: '80px' }}>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                xxl={6}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '12px',
                }}
              >
                <UserTypeSelect
                  // defaultValue={userTypes[0]}
                  suffixIcon={
                    <CaretDownOutlined style={{ color: '#929292' }} />
                  }
                  size="large"
                  value={filterOptions.userType || 'All'}
                  onChange={(value) => {
                    if (value !== 'All')
                      onFilterOptionChange(value, 'userType');
                    else {
                      onFilterOptionChange(undefined, 'userType');
                    }
                  }}
                >
                  <Select.Option value="All">All</Select.Option>
                  {userTypes.map((type) => (
                    <Select.Option value={type.name}>{type.name}</Select.Option>
                  ))}
                </UserTypeSelect>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '12px',
                }}
              >
                <NewUserButton
                  type="primary"
                  // size="large"
                  icon={<UserAddOutlined />}
                  onClick={() => {
                    setCreateModal(true);
                    setSelectedItem({});
                  }}
                >
                  Create User
                </NewUserButton>
              </Col>
            </Row>
          </Col>
          <TableCotainer>
            <Col span={24}>
              <Row gutter={12} style={{ marginBottom: '10px' }}>
                <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5}>
                  <Row gutter={[0, 6]}>
                    <Col span={24}>Name</Col>
                    <Col span={24}>
                      <BorderLessInput
                        placeholder="Name"
                        size="large"
                        value={filterOptions.name}
                        onChange={(event) => {
                          onFilterOptionChange(event.target.value, 'name');
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                {['Doctor', 'Nurse'].includes(filterOptions.userType) && (
                  <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5}>
                    <Row gutter={[0, 6]}>
                      <Col span={24}>Department</Col>
                      <Col span={24}>
                        <BorderLessSelect
                          placeholder="Department"
                          size="large"
                          value={filterOptions.departmentId}
                          showSearch
                          suffixIcon={
                            <CaretDownOutlined style={{ color: '#929292' }} />
                          }
                          mode="multiple"
                          onChange={(value) => {
                            onFilterOptionChange(value, 'departmentId');
                          }}
                        >
                          {departments.map((item) => (
                            <BorderLessSelect.Option
                              value={item.id}
                              key={item.id}
                            >
                              {item.departmentName}
                            </BorderLessSelect.Option>
                          ))}
                        </BorderLessSelect>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5}>
                  <Row gutter={[0, 6]}>
                    <Col span={24}>Email</Col>
                    <Col span={24}>
                      <BorderLessInput
                        placeholder="Email"
                        size="large"
                        value={filterOptions.email}
                        onChange={(event) => {
                          onFilterOptionChange(event.target.value, 'email');
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5}>
                  <Row gutter={[0, 6]}>
                    <Col span={24}>Status</Col>
                    <Col span={24}>
                      <BorderLessSelect
                        placeholder="Status"
                        size="large"
                        value={filterOptions.status}
                        suffixIcon={
                          <CaretDownOutlined style={{ color: '#929292' }} />
                        }
                        onChange={(value) => {
                          onFilterOptionChange(value, 'status');
                        }}
                      >
                        {statuses.map((item) => (
                          <BorderLessSelect.Option
                            value={item.value}
                            key={item.value}
                          >
                            {item.name}
                          </BorderLessSelect.Option>
                        ))}
                      </BorderLessSelect>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4}>
                  {Object.keys(filterOptions).length !== 0 && (
                    <ClearButtonContainer onClick={onClearFilterOptions}>
                      <div>
                        <CloseCircleOutlined
                          style={{ fontSize: '15px', marginRight: '7px ' }}
                        />
                        Clear All
                      </div>
                    </ClearButtonContainer>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <UserTable
                dataSource={dataSource}
                pageSize={pageSize}
                pageNo={pageNo}
                setPageNo={setPageNo}
                total={totalCount}
                loading={loading}
                setSelectedItem={setSelectedItem}
                setEditModal={setEditModal}
                setCreateModal={setCreateModal}
                setDeleteModal={setDeleteModal}
              />
            </Col>
          </TableCotainer>
        </Row>
      ) : (
        <CreateUser
          departments={departments}
          setVisible={setCreateModal}
          roles={userTypes}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          edit={editModal}
          setEdit={setEditModal}
          getUsers={getAllusers}
        />
      )}
      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        deleteForm={deleteUserHandler}
        loading={deleteLoading}
      />
    </>
  );
};

export default UserManagement;
