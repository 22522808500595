import React from "react";
import { useSelector } from "react-redux";
import { EmptyMessageContainer } from "../Departments/styledComponents";
import { columns } from "./tableConfig";
import { Empty } from "antd";
import Spinner from "../../../utils/Spinner";
import { BlueBgTable } from "../styledComponents";

const ConsentFormTable = ({
  forms,
  loading,
  setEditModal,
  setSelectedItem,
  setDeleteModal,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  return loading ? (
    <Spinner height="100px" />
  ) : forms.length > 0 ? (
    <BlueBgTable
      // headBg={theme["@primary-color"]}
      columns={columns(setEditModal, setDeleteModal, setSelectedItem)}
      dataSource={forms}
      loading={loading}
      pagination={false}
    />
  ) : (
    <EmptyMessageContainer>
      <Empty />
    </EmptyMessageContainer>
  );
};
export default ConsentFormTable;
