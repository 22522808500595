export const appointmentFormConfig = [
  {
    inputType: "heading",
    label: "Appointment Details",
  },
  {
    label: "Type",
    inputType: "select",
    name: "appointmentType",
    rules: [
      {
        required: true,
        message: "Type is Required!",
      },
    ],
    layout: {
      lg: 12,
      md: 24,
      xs: 24,
    },
    meta: [],
    disabled: true,
  },
  {
    inputType: "heading",
    label: "Appointment Identity",
  },
  {
    label: "Type",
    inputType: "select",
    name: "identity",
    rules: [
      {
        required: true,
        message: "Type is Required!",
      },
    ],
    layout: {
      lg: 12,
      md: 24,
      xs: 24,
    },
    meta: [],
    disabled: true,
    // meta: ["New", "Follow Up"],
  },
  {
    label: "Reason",
    inputType: "text-area",
    name: "reason",
    rules: [
      {
        required: true,
        message: "Reason is Required!",
      },
    ],
    layout: {
      span: 24,
    },
    meta: [],
    disabled: true,
  },
];
