import CustomModal from "../../utils/CustomModal.jsx/index";
import Icon, {
  CloudUploadOutlined,
  UserOutlined,
  FileTextOutlined,
  CaretDownOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  saveMedicalDocument,
  searchAppointmentUnderGivenDate,
} from "../../../apis/patients";
import styled from "styled-components";
import {
  Upload,
  Row,
  Col,
  Form,
  Menu,
  Button,
  message,
  Typography,
  Dropdown,
  Select,
  Tooltip,
  Table,
} from "antd";
import moment from "moment";
import StatusModal from "./confirmModal";
import {
  deleteUploadedFile,
  uploadPdfOrImage,
} from "../../../apis/uploadPdfOrImage";
import { createPatientOCR, pingOCR } from "../../../apis/createPatientOCR";
import Spinner from "../../utils/Spinner";
import { RightButton } from "../Appoinment/styledComponents";
import * as Sentry from "@sentry/react";

const { Option } = Select;

const { Text } = Typography;

const BodyContainer = styled.div`
  text-align: left;
  min-width: 40vw;
  margin: 0px 25px;
  padding: 10px;

  @media (max-width: 991px) {
    && {
      width: 90%;
      margin: auto;
    }
  }
`;

const StyledSelect = styled(Select)`
  background-color: "#fff";
  width: 100%;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  /* font-weight: 700; */
  height: max-content;
  border-radius: 5px;
  margin-left: 15px;
`;

const StyledB = styled.b`
  width: 100px;
  font-size: 30px;
`;

const StyledH2 = styled.h2`
  margin: 0 0 30px 0;
  font-weight: bold;
  /* color: black; */
`;

const CreateNewPatientManual = ({
  createPatientVisible,
  setCreatePatientVisible,
  setOcrData,
  setPreviewOCRFilesVisible,
}) => {
  const [form] = Form.useForm();
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);
  const [columnName, setColumnName] = useState([]);
  const [files, setFiles] = useState([]);
  const [type, setType] = useState("");
  const [appointmentFetch, setAppointmentFetch] = useState({});
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ocrLoading, setOcrLoading] = useState(false);

  const handleDocumentUpload = (file, fileList) => {
    var index = 0;
    uploadPdfOrImage(null, file).then((doc) => {
      if (doc.status === 200) {
        if (doc.data.status === 1) {
          var tempData = {
            documentUrl: doc?.data?.body?.documentUrl,
            fileName: doc?.data?.body?.fileName,
          };
          var temp = [...files, tempData];
          setUploading(false);
          setFiles(temp);
          // onChange(temp);
        }
        setUploading(false);
      }
      setUploading(false);
      index = index + 1;
    });
    console.log(files);
    console.log("");
  };

  const closeModal = (value) => {
    setCreatePatientVisible(false);
    files.map((item) => {
      deleteUploadedFile(item?.documentUrl)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
              console.log(res.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    });
  };
  const prop = {
    name: "files",
    multiple: true,
    showUploadList: false,
    beforeUpload: (file, fileList) => {
      setUploading(true);
      console.log("entered into prop in doc", fileList);
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "application/jpg" ||
        file.type === "application/pdf";
      if (!isJpgOrPng) {
        setUploading(false);
        message.error("You can only upload JPG/PNG/PDF file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        setUploading(false);
        message.error("File must smaller than 5MB!");
      }
      if (isJpgOrPng && isLt2M) {
        handleDocumentUpload(file, fileList);
        // var temp = [...files]
        // temp = temp.concat(fileList)
        // setFiles(temp)
      }
    },
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const clearDoc = (row) => {
    var temp = [];
    if (row) {
      files.map((item) => {
        if (item.documentUrl !== row.documentUrl) {
          temp = temp.concat(item);
        } else {
          deleteUploadedFile(item?.documentUrl)
            .then((res) => {
              if (res.status === 200) {
                if (res.data.status === 1) {
                  console.log(res.data);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              Sentry.captureException(err);
            });
        }
      });
    }
    setFiles(temp);
  };

  useEffect(() => {
    if (createPatientVisible) {
      setLoading(true);
      pingOCR().then((res) => {
        if (res.data.status === 1) {
          setLoading(false);
        } else {
          message.error("Please check after sometimes");
          setCreatePatientVisible(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    console.log("doc type:", type);
    setColumnName([
      {
        render: (files) => {
          return (
            <Tooltip title="Document">
              <Button
                shape="circle"
                type="link"
                danger
                icon={
                  <FileTextOutlined
                    style={{ color: theme["@primary-color"], fontSize: "25px" }}
                  />
                }
              // onClick={() => clearDoc(row, files)}
              />
            </Tooltip>
          );
        },
        width: "4vw",
      },
      {
        dataIndex: "fileName",
        render: (value) =>
          value?.length > 35 ? value?.slice(0, 35) + "..." : value,
        width: "30vw",
      },
      {
        render: (files) => {
          return (
            <Tooltip title="Delete document">
              <Button
                shape="circle"
                type="link"
                danger
                icon={<CloseCircleOutlined style={{ color: "grey" }} />}
                onClick={() => clearDoc(files)}
              />
            </Tooltip>
          );
        },
        width: "4vw",
      },
    ]);
    // onChange(files)
    // setFiles(files)
  }, [files]);

  const submitHandler = (values) => {
    setOcrLoading(true);
    console.log(values);
    values = form.getFieldValue();
    var documentUrls = files.map((item) => {
      return { documentUrl: item.documentUrl };
    });
    var data = {
      id_type: values.type,
      documentUrls: files,
    };
    // data.document = files
    console.log(data);
    pingOCR()
      .then((res) => {
        if (res.data.status === 1) {
          createPatientOCR(data)
            .then((res) => {
              if (res.status == 200) {
                var check = false;
                Object.keys(
                  typeof res?.data === "object" ? res?.data : { 1: "" }
                ).map((item) => {
                  if (item !== "id_type")
                    if (!Array.isArray(res.data[item]))
                      if (res.data[item] != "") check = true;
                });
                if (check) {
                  setOcrData(res.data);
                  setPreviewOCRFilesVisible(true);
                } else {
                  message.error("Please add a correct document");
                  setPreviewOCRFilesVisible(false);
                }
                setStatusModalVisible(false);
                setCreatePatientVisible(false);
                setOcrLoading(false);
              } else {
                message.error(res.body);
                setStatusModalVisible(false);
                setCreatePatientVisible(false);
                setOcrLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              Sentry.captureException(err);
              message.error("Internal Server Error");
              setStatusModalVisible(false);
              setCreatePatientVisible(false);
              setOcrLoading(false);
            });
        } else {
          console.log("OCR Server down");
          message.error("Please check after sometimes");
          setStatusModalVisible(false);
          setCreatePatientVisible(false);
          setOcrLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error("Please check after sometimes");
        setStatusModalVisible(false);
        setCreatePatientVisible(false);
        setOcrLoading(false);
      });
  };

  return (
    <CustomModal
      visible={createPatientVisible}
      antdMOdalProps={{ centered: true }}
      isClosable={true}
      setVisible={closeModal}
    >
      <BodyContainer className="popup-box-content">
        {loading ? <Spinner />
          :
          <>
            <Row>
              <Col span={100}>
                <StyledH2 style={{ color: "black" }}>Create New Patient</StyledH2>
              </Col>
            </Row>
            <Col>
              <Form
                layout="vertical"
                // onFinish={(values) => submitHandler(values)}
                form={form}
                className="form-m2-style"
                style={{ width: "100%" }}
                // onValuesChange={valuesChangeHandler}
                scrollToFirstError={true}
              >
                <Row>
                  <Col>
                    <Form.Item
                      label="Document Type"
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Document type is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Document Type"
                        onChange={setType}
                        style={{ minWidth: "20vw" }}
                        dropdownClassName={CaretDownOutlined}
                      >
                        <Option value="Emirates ID">Emirates ID</Option>
                        <Option value="Iqama ID">Iqama ID</Option>
                        <Option value="Patient Receipt">Patient Receipt</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                  <Col style={{ minWidth: "40vw" }}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Documents required",
                        },
                      ]}
                    >
                      <Upload.Dragger
                        {...prop}
                        action="/upload.do"
                        customRequest={dummyRequest}
                        disabled={uploading}
                      >
                        {uploading ? (
                          <Spinner height={"100px"} />
                        ) : (
                          <>
                            <p
                              className="ant-upload-hint"
                              style={{
                                fontSize: "50px",
                                color: "rgba(0, 0, 0, 0.25)",
                              }}
                            >
                              <CloudUploadOutlined />
                            </p>
                            <Text
                              className="ant-upload-text"
                              style={{
                                color: "rgba(0, 0, 0, 0.25)",
                                fontWeight: "bold",
                              }}
                            >
                              Drag and Drop or
                              <Text
                                style={{
                                  color: theme["@primary-color"],
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Browse{" "}
                              </Text>
                              files
                            </Text>
                            <br />
                            <Text
                              className="ant-upload-text"
                              style={{
                                color: theme["@primary-color"],
                                fontWeight: "600",
                              }}
                            >
                              Supporting file formats: .jpg, .jpeg, .png, .pdf
                              with max file size of 5MB each
                            </Text>
                          </>
                        )}
                      </Upload.Dragger>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  {Array.isArray(files) && files.length ? (
                    <>
                      <Row>
                        <StyledH2>Uploaded Documents</StyledH2>
                      </Row>
                      <Row>
                        <Table
                          headBg={theme["@primary-color"]}
                          showHeader={false}
                          columns={columnName}
                          dataSource={files}
                          pagination={false}
                        />
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Form.Item>
                <Form.Item style={{ width: "100%" }}>
                  <Row style={{ justifyContent: "flex-end", marginTop: "15px" }}>
                    <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                      <RightButton
                        type="primary"
                        ghost
                        size="large"
                        style={{ margin: "0 0 15px 15px", width: "150px" }}
                        className="btn-save"
                        onClick={() => {
                          closeModal();
                        }}
                        disabled={uploading}
                      >
                        Cancel Upload
                      </RightButton>
                    </Col>
                    <Col>
                      <RightButton
                        htmlType="submit"
                        size="large"
                        type="primary"
                        style={{ margin: "0 0 15px 15px", width: "150px" }}
                        className="btn-save"
                        onClick={() => setStatusModalVisible(true)}
                        disabled={
                          uploading ||
                          !Array.isArray(files) ||
                          !files?.length > 0 ||
                          !type
                        }
                      >
                        Proceed
                      </RightButton>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </>}
      </BodyContainer>
      <StatusModal
        cancel={true}
        cancelAppointment={submitHandler}
        visible={statusModalVisible}
        closeModal={() => setStatusModalVisible(false)}
        loading={ocrLoading}
        patientRegistration={true}
      />
    </CustomModal>
  );
};

export default CreateNewPatientManual;
