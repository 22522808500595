import React from "react";
import CustomModal from "../../utils/CustomModal.jsx";
import { Col, Row, Divider } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
const BodyContainer = styled.div`
  width: 600px;
`;
const Heading = styled.h3`
  font-weight: 600;
  color: ${(props) => props.color};
`;
const ViewDetailsModal = (props) => {
  const capitalize = (camelCaseText) => {
    return (
      camelCaseText.charAt(0).toUpperCase() +
      camelCaseText
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(" ")
    );
  };
  const theme = useSelector((state) => state.themeReducer);

  const ModalBody = ({ data }) => {
    // return JSON.stringify(data);

    if (data && typeof data === "object" && !Array.isArray(data))
      return Object.keys(data).map((key) => {
        if (
          data[key] &&
          typeof data[key] === "object" &&
          !Array.isArray(data[key])
        ) {
          return (
            <>
              <Col span={24}>
                {" "}
                <Heading color={theme["@primary-color"]}>{key}</Heading>
              </Col>
              <ModalBody data={data[key]} />
              <Divider />
            </>
          );
        } else
          return (
            <Col span={24}>
              <Row>
                <Col span={5}> {key}</Col>

                <Col span={1}>:</Col>
                <Col span={18} style={{ textTransform: "capitalize" }}>
                  {data[key]}
                </Col>
              </Row>
            </Col>
          );
      });
  };

  return (
    <CustomModal {...props} isClosable={true}>
      <BodyContainer>
        <Row gutter={[24, 16]}>
          <Col span={24}>
            {" "}
            <Heading color={theme["@primary-color"]}>
              {props.titleText && props.titleText}{" "}
            </Heading>
          </Col>
          <ModalBody data={props.dataSource} />
        </Row>
      </BodyContainer>
    </CustomModal>
  );
};
export default ViewDetailsModal;
