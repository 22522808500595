import React, { useState, useEffect } from "react";
import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from "../styledComponents";
import Icon, { UserAddOutlined } from "@ant-design/icons";
import { NewButton, BodyContainer } from "./styledComponents";
import { Row, Col, message, Button, notification } from "antd";
import SectionManagementTable from "./SectionManageMentTable";
import { sectionColumns } from "./tableConfig";
import * as Sentry from "@sentry/react";
import {
  changeSectionOrder,
  fetchMenuSections,
} from "../../../../apis/healthRecord";
import NewSectionModal from "./NewSectionModal";
import SessionDeleteConfirmationModal from "./SessionDeleteConfirmationModal";
import { useSelector } from "react-redux";
import { ReactComponent as changeOrder } from "../../../../assets/icons/changeOrder.svg";
import styled from "styled-components";

const SectionManagement = ({
  setTab,
  setSelectedSection,
  setTotalSections,
  totalSections,
  selectedSection,
}) => {
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [newSectionModal, setNewSectionModal] = useState(false);
  const [changesOrderStatus, setChangeOrderStatus] = useState(false);
  const theme = useSelector((state) => state.themeReducer);

  const StyledIcon = styled(Icon)`
    & .cls-1 {
      fill: ${changesOrderStatus ? "white" : theme["@primary-color"]};
      /* stroke: ${changesOrderStatus ? "white" : theme["@primary-color"]}; */
    }
  `;

  const handleOrderChangeClick = () => {
    if (changesOrderStatus) {
      let newOrder = sections.map((item, index) => ({
        formId: item.id,
        formOrder: index + 1,
      }));
      changeSectionOrder({ formOrderChanges: newOrder })
        .then(({ data, status }) => {
          if (data.status == 1) {
            notification.success({
              message: "New Order saved!",
            });
            setChangeOrderStatus(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    } else {
      setChangeOrderStatus(true);
    }
  };
  const getMenuItem = () => {
    setLoading(true);
    fetchMenuSections()
      .then(({ data, status }) => {
        console.log("data in admin", data);
        if (status === 200) {
          if (data.status === 1) {
            setSections(data.body);
            setTotalSections(data.body.length);
          } else message.error(data.body);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const newSectionHandler = () => {
    setNewSectionModal(true);
  };

  useEffect(() => {
    getMenuItem();
  }, []);
  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading>Manage Sections</SectionHeading>
            </Col>
            <Col style={{ display: "flex", alignItems: "flex-end" }}>
              <NewButton
                type="primary"
                // ghost
                size="large"
                icon={<UserAddOutlined />}
                onClick={newSectionHandler}
              >
                New Section
              </NewButton>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <Row justify="end" style={{ marginBottom: "10px" }}>
            <Col>
              <NewButton
                type={"primary"}
                ghost={!changesOrderStatus}
                onClick={handleOrderChangeClick}
                icon={
                  <StyledIcon
                    style={{ fontSize: "25px" }}
                    component={changeOrder}
                  />
                }
              >
                {changesOrderStatus ? "Save Order" : "Change Order"}
              </NewButton>
            </Col>
          </Row>
          <StyledDiv bgcolor={theme["@primary-color"]}>
            <div>&nbsp;&nbsp;Sections</div>
            <div>&nbsp;&nbsp;</div>
          </StyledDiv>
          <SectionManagementTable
            dataSource={sections}
            columns={sectionColumns(
              setTab,
              setSelectedSection,
              setConfirmationModal
            )}
            loading={loading}
            setDataSource={setSections}
            draggable={changesOrderStatus}
            preventLast={true}
          />
        </BodyContainer>
      </Col>
      <NewSectionModal
        visible={newSectionModal}
        setVisible={setNewSectionModal}
        totalSections={totalSections}
        refetch={() => getMenuItem()}
      />
      <SessionDeleteConfirmationModal
        visible={confirmationModal}
        setVisible={setConfirmationModal}
        selectedItem={selectedSection}
        refetch={() => getMenuItem()}
        // setDeleteErrorModal={setDeleteErrorModal}
        // setErrorMessage={setErrorMessage}
      />
    </Row>
  );
};
export default SectionManagement;
