import moment from 'moment';
export const CopySelectedTimesToAll = (
  index,
  timings,
  setTimings,
  form,
  setAvailability
) => {
  const arr = [...timings]; //in order to change to object  {...timing}
  const arrLength = arr.length;
  // const keys=Object.keys(arr)
  for (let i = 0; i < arrLength; i++) {
    //i<keys.length
    arr[i].startTime = arr[index].startTime; //arr[keys[i]].startTime=arr[day].startTime
    arr[i].endTime = arr[index].endTime;
    arr[i].breakStartTime = arr[index].breakStartTime; //arr[keys[i]].startTime=arr[day].startTime
    arr[i].breakEndTime = arr[index].breakEndTime;
    arr[i].isAvailable = arr[index].isAvailable;
  }
  console.log('arr is', arr);
  form.setFieldsValue({ days: [...arr] });
  setTimings([...arr]);
  let arrCopy = [];
  let weekDay = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];

  for (let i = 0; i < arr.length; i++) {
    arrCopy.push({
      ...arr[i],
      dayOfWeek: weekDay[i],
    });
  }

  const convertedArray = arrCopy.map((item) => ({
    ...item,
    startTime: item.startTime ? moment(item.startTime).format('HH:mm') : '',
    endTime: item.endTime ? moment(item.endTime).format('HH:mm') : '',
    breakStartTime: item.breakStartTime
      ? moment(item.breakStartTime).format('HH:mm')
      : '',
    breakEndTime: item.breakEndTime
      ? moment(item.breakEndTime).format('HH:mm')
      : '',
  }));
  setAvailability(convertedArray);
};

export const handleUpdateTime = (
  index,
  field,
  value,
  selectedDays,
  setSelectedDays
) => {
  const days = [...selectedDays];
  console.log('field is', field);

  switch (field) {
    case 'startTime':
      days[index].startTime = value;
      break;
    case 'endTime':
      days[index].endTime = value;
      break;
    case 'breakStartTime':
      days[index].breakStartTime = value;
      break;
    case 'breakEndTime':
      days[index].breakEndTime = value;
      break;
  }

  console.log('days are ', days);
  setSelectedDays(days);
};

export const handleChangeAvailDays = (
  event,
  index,
  // instead of index pass day for object
  selectedDays,
  setSelectedDays,
  availability,
  setAvailability
) => {
  console.log('avail', availability);
  const days = [...selectedDays]; //change this to object
  console.log('selected days', selectedDays);
  days[index].isAvailable = event.target.checked;

  if (!days[index].isAvailable) {
    days[index].startTime = '';
    days[index].endTime = '';
    days[index].breakStartTime = '';
    days[index].breakEndTime = '';
    let tempAvailability = [];
    selectedDays.forEach((item) => {
      if (!item.isAvailable) {
        tempAvailability.push({
          ...item,
          startTime: '',
          endTime: '',
          breakStartTime: '',
          breakEndTime: '',
        });
      } else {
        tempAvailability.push({
          ...item,
          startTime: item.startTime
            ? moment(item.startTime).format('HH:mm')
            : '',
          endTime: item.endTime ? moment(item.endTime).format('HH:mm') : '',
          breakStartTime: item.breakStartTime
            ? moment(item.breakStartTime).format('HH:mm')
            : '',
          breakEndTime: item.breakEndTime
            ? moment(item.breakEndTime).format('HH:mm')
            : '',
        });
      }
    });

    setAvailability([...tempAvailability]);
  }

  setSelectedDays([...days]);
};
