import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import PatientsList from '../PatientsList';
import VitalInformation from './VitalInformation';
import ThreeTierFormSection from './ThreeTierFormSection';
import PatientDetails from './PatientDetails';
import Laboratory from './Laboratory';
import ConcentForm from './ConcentForm';
import Examinations from './Examinations';
import CarePlan from './CarePlan';
import MedicalDetails from './MedicalDetails';
import Radiology from './Radiology';
import BillingSection from './Billing';
import ConsultationReport from './ConsultationReport';
import Patient from './Patient';
import CustomSection from './CustomSection';
import ConsultationHistory from './ConsultationHistory';

const HealthRecordContentRouter = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${url}/:sectionId/Medical History`}
        component={ThreeTierFormSection}
        key="Medical History"
      />
      <Route
        path={`${url}/:sectionId/Allergies`}
        component={ThreeTierFormSection}
        key="Allergy"
      />

      <Route
        path={`${url}/:sectionId/Prescriptions`}
        component={MedicalDetails}
        key="Prescriptions"
      />
      <Route
        path={`${url}/:sectionId/Vitals`}
        component={VitalInformation}
        key="Vitals"
      />
      <Route
        path={`${url}/:sectionId/Vital Information`}
        component={VitalInformation}
        key="Vital Information"
      />
      <Route
        path={`${url}/:sectionId/Patient Details`}
        component={PatientDetails}
        key="Patient Details"
      />
      <Route
        path={`${url}/:sectionId/Laboratory`}
        component={Laboratory}
        key="Laboratory"
      />
      <Route
        path={`${url}/:sectionId/Radiology`}
        component={Radiology}
        key="Radiology"
      />
      <Route
        path={`${url}/:sectionId/Consent Form`}
        component={ConcentForm}
        key="Consent Form"
      />
      <Route
        path={`${url}/:sectionId/Examination`}
        component={Examinations}
        key="Examination"
      />
      <Route
        path={`${url}/:sectionId/Care Plan`}
        component={CarePlan}
        key="Care Plan"
      />
      <Route
        path={`${url}/:sectionId/Billing`}
        component={BillingSection}
        key="Billing"
      />

      <Route
        path={`${url}/:sectionId/Consultation Report`}
        component={ConsultationReport}
        key="Consultation"
      />
      <Route
        path={`${url}/:sectionId/Patient`}
        component={Patient}
        key="Consultation"
      />
      <Route
        path={`${url}/:sectionId/Consultation History`}
        component={ConsultationHistory}
        key="Consultation History"
      />
      <Route path={`${url}/:sectionId/*`} component={CustomSection} />
    </Switch>
  );
};

export default HealthRecordContentRouter;
