import { Button, Col, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from '../styledComponents';
import { BodyContainer } from '../Sections/styledComponents';
import { fetchChiefComplaints } from '../../../../apis/Admin';
import ChiefComplaintTable from './ChiefComplaintTable';
import column from './TableConfig';
import { columns } from './TableConfig';
import ComplaintModal from './ComplaintModal';
import { getDepartments } from '../../../../apis/Appointment';
import ConfirmationModal from './ConfirmationModal';
import { NewButton } from './Styledcomponents';
import { useSelector } from 'react-redux';
import { getHospitalId } from '../../../utils/LocalStorage';

const ChiefComplaints = () => {
  const [complaints, setComplaints] = useState([]);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [departments, setDepartments] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.themeReducer);

  const fetchComplaints = () => {
    fetchChiefComplaints(pageNo, pageSize).then(({ data }) => {
      setComplaints(data.body);
      setLoading(false);
      setTotal(data.total);
    });
  };

  useEffect(() => {
    getDepartments(getHospitalId())
      .then((response) => {
        if (response.data.status === 1) {
          setDepartments(response.data.body);
        } else {
          message.error('Could not fetch departments, Try Again');
        }
      })
      .catch((err) => {
        message.error('Could not fetch departments, Try Again');
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchComplaints();
  }, [pageNo]);

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button>Previous</Button>;
    }
    if (type === 'next') {
      return <Button>Next</Button>;
    }
    return originalElement;
  };

  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading>Chief Complaints</SectionHeading>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'end' }}>
              <NewButton
                type="primary"
                // ghost
                // size="large"
                onClick={() => {
                  setModal(true);
                  setEdit(false);
                }}
              >
                Add New
              </NewButton>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>

      <Col span={24}>
        <BodyContainer>
          <Row gutter={[0, 18]}>
            <Col span={24}>
              <StyledDiv bgcolor={theme['@primary-color']}>
                <div>&nbsp;&nbsp;Chief Complaints</div>
                <div>&nbsp;&nbsp;</div>
              </StyledDiv>
              <ChiefComplaintTable
                dataSource={complaints}
                loading={loading}
                columns={columns(
                  setModal,
                  setEdit,
                  setSelectedItem,
                  setDeleteModal,
                  pageNo
                )}
                pagination={{
                  onChange: (cur) => {
                    setPageNo(cur - 1);
                  },
                  pageSize: pageSize,
                  total: total,
                  current: pageNo + 1,
                  itemRender: itemRender,
                }}
              />
            </Col>
          </Row>
        </BodyContainer>
      </Col>
      <ComplaintModal
        visible={modal}
        setVisible={setModal}
        edit={edit}
        departments={departments}
        selectedItem={selectedItem}
        refetch={fetchComplaints}
      />
      <ConfirmationModal
        selectedItem={selectedItem}
        visible={deleteModal}
        setVisible={setDeleteModal}
        refetch={fetchComplaints}
      />
    </Row>
  );
};
export default ChiefComplaints;
