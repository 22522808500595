import axiosInstance from '../config/axiosInstance';
const USER_PREFIX = '/user';

export const getProfile = (id) => {
  return axiosInstance.get(`${USER_PREFIX}/profile?id=${id}`);
};

export const editProfile = (payload) => {
  return axiosInstance.put(`${USER_PREFIX}/update-profile`, payload);
};

export const changePassword = (payload) => {
  return axiosInstance.put(`${USER_PREFIX}/change-password`, payload);
};

export const fetchAllUsers = (data, pageNo, size) => {
  let searchQuery = '';
  Object.entries(data).forEach(([key, value]) => {
    if (value || value === false)
      searchQuery = `${searchQuery}&${key}=${value}`;
  });
  return axiosInstance.get(
    `${USER_PREFIX}?pageNo=${pageNo}&size=${size}${searchQuery}`
  );
};
export const fetchRoles = () => {
  return axiosInstance.get(`${USER_PREFIX}/roles`);
};

export const fetchActiveRoles = () => {
  return axiosInstance.get(`${USER_PREFIX}/active-roles`);
};

export const addUser = (data) => {
  return axiosInstance.post(`${USER_PREFIX}`, data);
};

export const editUser = (id, data) => {
  return axiosInstance.put(`${USER_PREFIX}?userId=${id}`, data);
};

export const deleteUser = (id) => {
  return axiosInstance.delete(`${USER_PREFIX}/${id}`);
};

export const getNewAvailableTimes = (id) => {
  console.log("id in here",id)
  return axiosInstance.get(`${USER_PREFIX}/getListOfDoctorByHospital/${id}`);
};
