export const relationship = () => [
  { code: "ASC", text: "Associate" },
  { code: "AUN", text: "Aunty" },
  { code: "BRO", text: "Brother" },
  { code: "CGV", text: "Caregiver" },
  { code: "CHD", text: "Child" },
  { code: "DON", text: "Donor" },
  { code: "EMC", text: "Emergency Contact" },
  { code: "EME", text: "Employee" },
  { code: "EMR", text: "Employer" },
  { code: "EXF", text: "Extended Family" },
  { code: "FTH", text: "Father" },
  { code: "FCH", text: "Foster Child" },
  { code: "FND", text: "Friend" },
  { code: "GCH", text: "Grandchild" },
  { code: "GRP", text: "Grandparent" },
  { code: "GRD", text: "Guardian" },
  { code: "DEP", text: "Handicapped Dependent" },
  { code: "DOM", text: "Life Partner" },
  { code: "MGR", text: "Manager" },
  { code: "MTH", text: "Mother" },
  { code: "NCH", text: "Natural Child" },
  { code: "NON", text: "None" },
  { code: "NA", text: "Not Available" },
  { code: "NRS", text: "Nurse" },
  { code: "OTH", text: "Other" },
  { code: "OAD", text: "Other Adult" },
  { code: "OWN", text: "Owner" },
  { code: "POR", text: "Porter" },
  { code: "REC", text: "Recipient" },
  { code: "SEL", text: "Self" },
  { code: "SIB", text: "Sibling" },
  { code: "SIS", text: "Sister" },
  { code: "SPS", text: "Sponsor" },
  { code: "SPO", text: "Spouse" },
  { code: "SCH", text: "Stepchild" },
  { code: "TRA", text: "Trainer" },
  { code: "UNC", text: "Uncle" },
  { code: "UNK", text: "Unknown" },
  { code: "PAR", text: "Parent" },
  { code: "WRD", text: "Ward of court" },
];
