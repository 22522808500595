import React, { useEffect, useRef } from 'react';
import { Row, Col, message, Button, Tooltip } from 'antd';
import { SectionHeading, SectionHeadContainer } from '../styledComponents';

import { useState } from 'react';
import { LeftCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';

import {
  getHospitalId,
  getDoctorId,
  getHospitalName,
} from '../../../utils/LocalStorage/index';
import QRcode from 'react-qr-code';
import moment from 'moment';
import {
  BodyContainerSection,
  StyledDivParent,
  StyledDivQrCode,
  StyledDivPrint,
} from './styledComponents';
import DasboardCard from './DashboardCard';
import Analytics from './Analytics';

const Dashboard = () => {
  const componentRef = useRef();

  const [selected, setSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [trigger, setTrigger] = useState(true);
  const [qrcode, setqrCode] = useState(
    `https://app.meditagg.com/${getHospitalId()}/new-patient-form`
  );
  console.log('qrcode is', qrcode);

  useEffect(() => {}, [trigger]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data',
    // onAfterPrint: () => alert('print success'),
  });

  const history = useHistory();

  return (
    <>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between">
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <SectionHeading>
                  {selected && (
                    <LeftCircleOutlined
                      onClick={() => {
                        setSelected(false);
                        setSelectedId(null);
                      }}
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  Dashboard
                </SectionHeading>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'flex-end' }}></Col>
            </Row>
          </SectionHeadContainer>
        </Col>
        <BodyContainerSection span={24}>
          <>
            <Row gutter={10} justify="space">
              <Col xs={24} sm={24} md={12} style={{ marginBottom: '10px' }}>
                {' '}
                <Analytics />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <StyledDivParent>
                  <StyledDivQrCode>
                    <QRcode
                      size={350}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      value={qrcode}
                      viewBox={`0 0 350 350`}
                    />
                  </StyledDivQrCode>
                </StyledDivParent>
                <StyledDivParent>
                  <Tooltip title="Print QRCode" placement="bottom">
                    <Button
                      type="primary"
                      onClick={handlePrint}
                      style={{ width: '100px' }}
                    >
                      {' '}
                      Print
                    </Button>
                  </Tooltip>
                </StyledDivParent>
              </Col>
            </Row>

            <Row></Row>
            {/* <StyledDivParent>
              <DasboardCard
                icon={<UserAddOutlined />}
                title={'Patient'}
                value={1235}
              />
            </StyledDivParent> */}
          </>
        </BodyContainerSection>
        <div style={{ display: 'none' }}>
          <div ref={componentRef}>
            <StyledDivPrint>
              <h1 style={{ marginTop: '30px' }}>{getHospitalName()}</h1>
              <h4>Scan this QRCode to book an Appointment</h4>

              <div
                style={{
                  height: 'auto',
                  margin: '15% 0 0 0',
                }}
              >
                <QRcode value={qrcode} />
              </div>
            </StyledDivPrint>
          </div>
          <div></div>
        </div>
      </Row>
    </>
  );
};

export default Dashboard;
