import React from "react";
import { Button, Table, Typography } from "antd";
import styled from "styled-components";
import Form from "antd/lib/form/Form";
import Icon from "@ant-design/icons";

const { Title } = Typography;
export const NewButton = styled(Button)`
  height: 50px;
  width: 250px;
  font-size: 20px;
`;
export const ModalBodyContainer = styled.div`
  width: 450px;
  padding: 10px 30px;
`;

export const BlueBgTable = styled(Table)`
  & .ant-table-thead > tr > th {
    color: #fff;
    background: ${(props) => props.headBg};
    font-size: 14px;
    font-weight: 600;
  }

  & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  }
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #9e9292;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #9e9292;
  }

  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    position: relative;
    padding: 8px 16px;
    overflow-wrap: break-word;
  }
`;

export const StyledTitle = styled(Title)`
  &.ant-typography,
  &.ant-typography h3 {
    margin-bottom: 0;
    color: ${(props) => props.color};
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
  }
`;

export const StyledBodyTitle = styled(Title)`
  &.ant-typography,
  &.ant-typography h3 {
    /* margin-bottom: 0; */
    color: ${(props) => props.color};
    font-weight: 600;
  }
`;
export const StyledForm = styled(Form)`
  text-align: left;
  &.ant-form-vertical .ant-form-item-label > label {
    height: auto;
  }
  & .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }

  &
    .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    background: #b1b1b1;
    border: none;

    & .cls-1 {
      fill: #fff;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  && {
    font-size: 25px;
    margin-top: 5px;
  }
  /* ${(props) =>
    props.isactive &&
    `.cls-1 {
      fill: #26b1ff !important; 
  }`} */
`;

export const BodyContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  min-height: calc(100vh - 200px);
`;

export const NewFieldScrollContainer = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  /* border: 1px solid gray; */
  background: #f8f8f8;
  padding: 10px;
`;
