import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import UserManagement from './UserManagement';
import UserRoles from './UserRoles';
import Departments from './Departments';
import Dashboard from './Dashboard';
import Sections from './Sections';
import Encounters from './Encounters';
import ConsentFormManagement from './ConsentFormManagement';
import InsuranceManagement from './InsuranceManagement';
import GeneralSettings from './GeneralSettings';
import Hl7 from './Hl7';
import LabTests from './LabTests';
import RadiologyTests from './Radiology';
import Drugs from './Drugs';
import Dictionary from './Dictionary';
import ChiefComplaints from './ChiefComplaints';
import EmailTemplate from './EmailTemplate';
import HL7Status from './HL7Status';
import WardCategory from './WardCategory';

const DashboardRouter = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          path={`${url}/dashboard`}
          component={Dashboard}
          key="dashboard"
        />
        <Route
          path={`${url}/user-management`}
          component={UserManagement}
          key="user-management"
        />
        <Route
          path={`${url}/user-roles`}
          component={UserRoles}
          key="user-roles"
        />
        <Route
          path={`${url}/departments`}
          component={Departments}
          key="departments"
        />
        <Route
          path={`${url}/ward-category`}
          component={WardCategory}
          key="ward-category"
        />
        <Route
          path={`${url}/manage-sections`}
          component={Sections}
          key="manage-sections"
        />
        <Route path={`${url}/lab-tests`} component={LabTests} key="lab-tests" />
        <Route
          path={`${url}/radiology`}
          component={RadiologyTests}
          key="radiology"
        />
        <Route
          path={`${url}/chief-complaints`}
          component={ChiefComplaints}
          key="radiology"
        />
        <Route
          path={`${url}/observations`}
          component={Encounters}
          key="observations"
        />
        <Route
          path={`${url}/manage-consent-forms`}
          component={ConsentFormManagement}
          key="manage-consent-forms"
        />

        <Route
          path={`${url}/manage-insurance`}
          component={InsuranceManagement}
          key="manage-insurance"
        />
        <Route
          path={`${url}/email-template`}
          component={EmailTemplate}
          key="email-template"
        />
        <Route
          path={`${url}/general-settings`}
          component={GeneralSettings}
          key="general-settings"
        />
        <Route
          path={`${url}/hl7-status`}
          component={HL7Status}
          key="general-settings"
        />
        <Route
          path={`${url}/hl7-listeners`}
          component={Hl7}
          key="general-settings"
        />

        <Route path={`${url}/drug`} component={Drugs} key="drug" />
        <Route
          path={`${url}/dictionary`}
          component={Dictionary}
          key="dictionary"
        />
      </Switch>
    </>
  );
};

export default DashboardRouter;
