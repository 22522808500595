import React, { useState, useEffect } from 'react';
import { Col, Form, Button, message, Modal, Input, Row } from 'antd';
import FormBuilder from '../utils/FormBuilder';
import { getCountries } from '../../apis/publicApis';
import { LeftCircleOutlined } from '@ant-design/icons';
// import { fetchFormByType, fetchFormFieldMeta } from "../../../../apis/forms";

import { StyledRow } from './syledComponentForForm';
import moment from 'moment';

import {
  config as personalDetailsConfig,
  insuranceFields as insuranceTypes,
  phoneNumberType,
} from './formConfig';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addPatient } from '../../redux/actions/newPatient';
import { createPatient } from '../../apis/Public';

const NewPatientCreateForm = ({ hId, id }) => {
  const dispatch = useDispatch();

  const [formConfig, setFormConfig] = useState(personalDetailsConfig);

  const [forms, setForm] = useState();
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const [formInitialValues, setFormInitialValues] = useState({});
  const history = useHistory();
  const [countries, setCountries] = useState();
  const theme = useSelector((state) => state.themeReducer);
  const [loading, setloading] = useState(false);
  const [phone, setPhone] = useState({});
  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res.data);
        setFormConfig(formConfig.map((item) => ({ ...item })));

        // phoneNumberType.find((item) => item.name === 'phoneNumber').meta =
        //   res.data.map((country) => ({
        //     code: country.callingCodes[0],
        //     name: country.name,
        //   }));

        // let indices = [];
        // let fields = formConfig
        //   .filter((item, index) => {
        //     if (item.inputType === 'phoneNumber') {
        //       indices.push(index);
        //       return true;
        //     }
        //     return false;
        //   })
        //   .map((item) => ({
        //     ...item,
        //     meta: res.data.map((country) => ({
        //       code: country.callingCodes[0],
        //       name: country.name,
        //     })),
        //   }));

        // indices.forEach((item, index) => {
        //   formConfig[item] = fields[index];
        // });

        // setFormConfig(formConfig.map((item) => ({ ...item })));
      })
      .catch(console.err);
  }, []);

  useEffect(() => {
    // onFormValueChange(formInitialValues, formInitialValues);
  }, [formInitialValues]);

  const success = () => {
    const timer = setTimeout(() => {
      Modal.success({
        content: 'Profile created ',
      });
    }, 1000);
    return () => clearTimeout(timer);
  };

  const onFormValueChange = (changed, all) => {
    if (changed.dob) {
      form.setFieldsValue({ age: moment().diff(changed.dob, 'years', false) });
    }
    // if (!all.dob) {
    //   form.setFieldsValue({ age: undefined });
    // }

    setDisabled(false);
  };

  const onFinish = async (values) => {
    //   //   submitHandler(values);
    //   //   setDisabled(!disabled);

    values.password = 'abcd';
    values.gender = values.gender.toUpperCase();
    values.userType = 'PATIENT';
    values.roleType = 'ROLE_PATIENT';

    const payload = {
      phoneNumbers: [
        {
          phoneType: values.phoneType,
          countryCode: JSON.parse(localStorage?.getItem('phone')).countryCode,
          phoneNumber: JSON.parse(localStorage?.getItem('phone')).phoneNumber,
        },
      ],

      // identityDocuments: values.identityDocuments,
      hospitalId: hId,
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      gender: values.gender,
    };

    setloading(true);

    createPatient(payload)
      .then((response) => {
        if (response.status == 200) {
          console.log('response is', response.data);
          if (response.data.status !== 0) {
            setloading(false);
            // message.success('Patient created!');
            dispatch(addPatient(response.data));
            history.push(
              `/book-visit-new-patient/${response.data.body.id}/${
                response.data.body.firstName + ' ' + response.data.body.lastName
              }?hId=${hId}`
            );
          } else {
            message.error(response.data.body);
            setloading(false);
          }
        } else {
          message.error('Unable to created Patient!');
        }
      })
      .catch((err) => {
        message.error('Unable to create Patient!');
      });
  };
  return (
    <>
      <Col span={24}>
        <h2 style={{ color: '#3BB8FF', marginBottom: '15px' }}>
          <LeftCircleOutlined
            onClick={() => {
              history.goBack();
            }}
          />{' '}
          Patient Registration :
        </h2>
        <Form
          layout="vertical"
          onValuesChange={onFormValueChange}
          onFinish={onFinish}
          // initialValues={JSON.parse(localStorage?.getItem('phone'))}
          // key={Object.keys(formInitialValues).length !== 0}
          form={form}
          className="form-m2-style"
          scrollToFirstError={true}
        >
          <FormBuilder formConfig={formConfig} />

          <Row gutter={[36, 0]}>
            <Col span="24" xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Input
                  size="large"
                  disabled={disabled}
                  defaultValue={
                    JSON.parse(localStorage?.getItem('phone')).phoneNumber
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="flex-end-btn">
            <StyledRow gutter={12} justify="end">
              <Col>
                <Button
                  onClick={() => {
                    // form.resetFields();
                    // setSelectedItem({});

                    //   setVisible(false);
                    form.resetFields();
                    history.push(`/${hId}/new-patient-form`);
                  }}
                  className="btn-cancel"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  // loading={submitLoading}
                  htmlType="submit"
                  className="btn-save"
                  disabled={disabled}
                  onClick={success}
                >
                  {loading ? (
                    <>Creating Patient...Please wait </>
                  ) : (
                    <>Continue</>
                  )}
                </Button>
              </Col>
            </StyledRow>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};
export default NewPatientCreateForm;
