import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import AppointmentForm from "./AppointmentForm";
import AppointmentRequestDetails from "./AppointmentRequestDetails";
import { LeftCircleOutlined } from "@ant-design/icons";
import { UserProfileHead } from "./userProfileHead";
import styled from "styled-components";
import { useSelector } from "react-redux";

const AppoinmentFormContainer = styled.div`
  background-color: #fff;
  margin: 40px 0px;
  padding: 30px 60px;
  border-radius: 10px;

  @media (max-width: 1520px) {
    &.px-6 {
      padding: 45px 60px;
    }
  }
  @media (max-width: 1439px) {
    &.px-6 {
      padding: 45px 60px;
    }
  }
  @media (max-width: 1359px) {
    &.px-6 {
      padding: 45px 40px;
    }
  }
  @media (max-width: 1199px) {
    &.px-6 {
      padding: 35px 30px;
    }
  }
  @media (max-width: 600px) {
    &.px-6 {
      padding: 25px 15px;
      margin-top: 20px;
    }
  }
`;
const PatientInfo = styled.h3`
  font-weight: 600;
  font-size: 22px;
  color: #5d5d5d;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    margin: 0;
  }
`;

export const PatientInfoContainer = styled.div`
  .appoint_pat_info {
    margin: 20px 0;
  }
  @media (max-width: 1200px) {
    .appoint_pat_info {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
  .form-m2-style h3 {
    margin: 15px 0 25px;
    font-size: 20px;
  }
  .form-m2-style .ant-form-item-label label {
    font-size: 16px;
    color: #818181;
  }
  .form-m2-style .ant-form-item-label label:before {
    display: none;
  }
  .form-m2-style .ant-form-item-label label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff0003;
    font-size: 18px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    font-weight: 700;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-input,
  .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
  .form-m2-style .ant-form-item-control-input-content .ant-picker {
    height: 60px;
    border: 1px solid #d6d6d6;
    border-radius: 5px !important;
    font-size: 18px;
    color: #333333;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .appointment_form_style1.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-select + .ant-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select:hover
    .ant-select-selector {
    border: 1px solid #d6d6d6;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-search-input,
  .form-m2-style .ant-form-item-control-input-content .ant-input-number-input {
    height: 60px !important;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-placeholder,
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-item {
    line-height: 56px !important;
  }
  .form-m2-style .ant-form-item-explain div {
    font-size: 12px;
    text-transform: capitalize;
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }
  .form-m2-style
    .ant-form-item-has-error
    .ant-select:not(.ant-select-borderless)
    .ant-select-selector {
    border-color: #d6d6d6 !important;
  }
  .form-m2-style .ant-btn-background-ghost.ant-btn-danger {
    padding: 3px 10px;
    height: auto;
    font-weight: 600;
  }
  .form-m2-style .add-btn {
    padding: 9px 10px;
    height: auto;
    font-weight: 600;
    margin: 12px 0 0;
  }
  .form-check-tag {
    margin: 5px 0 25px;
  }
  .form-check-tag span {
    font-size: 18px;
  }
  .form-check-tag .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .btn-save,
  .btn-cancel {
    min-width: 120px;
    font-size: 18px;
    height: 50px;
    border: 2px solid #26b1ff;
    padding: 0px 10px;
    font-weight: 500;
    border-radius: 6px;
  }
  .form-m2-style .forminput_timepick {
    height: 60px;
    border-radius: 5px;
  }
  .form-m2-style .forminput_timepick span {
    color: #444444;
  }
  .form-m2-style .ant-form-item-control-input-content textarea.ant-input {
    height: 140px;
    resize: none;
  }
  .available_time_field label {
    visibility: hidden;
  }
  .available_time_field .ant-form-item-control-input {
    height: 60px;
  }
  .available_time_field p {
    color: #dc4c4c;
  }
  .available_time_field button {
    min-width: 100px;
    height: 42px;
    font-weight: 600;
  }
  .btn-save,
  .btn-cancel {
    min-width: 120px;
    font-size: 18px;
    height: 50px;
    border: 2px solid #26b1ff;
    padding: 0px 10px;
    font-weight: 500;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  .appointment_form_style1 {
    padding-top: 25px;
  }
  .payment-type-radio .ant-radio-wrapper {
    font-size: 16px;
    color: #818181;
  }
  .payment-type-radio .ant-radio-wrapper .ant-radio-inner {
    margin-bottom: 1px;
  }
  .payment-type-radio .ant-radio-wrapper.ant-radio-wrapper-checked {
    color: #333333;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-input-number {
    border-radius: 5px;
  }
  @media (max-width: 1100px) {
    .form-m2-style .ant-form-item-label label {
      font-size: 14px;
    }
    .form-m2-style .ant-form-item-control-input-content .ant-input,
    .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
    .form-m2-style .ant-form-item-control-input-content .ant-picker {
      height: 50px;
      font-size: 15px;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-search-input {
      height: 50px !important;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-placeholder,
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-item {
      line-height: 46px !important;
    }
    .form-check-tag span {
      font-size: 15px;
    }
    .form-m2-style h3 {
      margin: 12px 0 24px;
      font-size: 18px;
    }
    h2.h2-base-600 {
      font-size: 20px;
    }
    .form-m2-style .forminput_timepick {
      height: 50px;
      border-radius: 5px;
    }
    .available_time_field .ant-form-item-control-input,
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-input-number-input {
      height: 50px !important;
      border-radius: 5px;
    }
  }
  @media (max-width: 991px) {
    .forminput_timepick p,
    .available_time_field .ant-form-item-control-input-content p {
      font-size: 15px;
      line-height: 1.3;
      padding-right: 10px;
    }
  }
  @media (max-width: 767px) {
    .form-m2-style h3 {
      margin: 5px 0 15px;
      font-size: 16px;
    }
    .form-m2-style {
      max-width: 420px;
      margin: 0 auto;
    }
    .form-m2-style > .ant-row {
      display: block;
    }
    .form-m2-style .ant-col {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .form-m2-style .flex-end-btn .ant-row {
      display: flex;
    }
    .flex-end-btn .ant-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      align-items: center;
    }
    .flex-end-btn .ant-form-item-control {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .btn-save,
    .btn-cancel {
      min-width: 110px;
      font-size: 16px;
      height: 42px;
    }
    .form-m2-style .ant-form-item-explain div {
      font-size: 11px;
    }
    .appointment_form_style1 {
      padding-top: 15px;
    }
    .available_time_field .ant-form-item-label {
      display: none;
    }
    &
      .form-m2-style
      .tag-select
      .ant-select-selector
      .ant-select-selection-item {
      line-height: 26px !important;
    }
  }

  @media (max-width: 480px) {
    h2.h2-base-600 {
      font-size: 17px;
    }
    .available_time_field .ant-form-item-control-input-content p {
      font-size: 13px;
    }
  }
`;
const Appointments = () => {
  const user = useSelector((state) => state.userReducer);
  const history = useHistory();
  const {
    patientId: patientId,
    appointmentId: appointmentId,
    patientName: patientName,
  } = useParams();
  const x = useParams();

  return (
    <Fragment>
      <h2 className="h2-base-600">
        <LeftCircleOutlined
          onClick={() => {
            history.goBack();
          }}
        />
        {"  "}Appointment
      </h2>
      <AppoinmentFormContainer className="px-6">
        <UserProfileHead
          user={user}
          patientId={patientId}
          patientName={patientName}
        />
      </AppoinmentFormContainer>
      {history.location.state?.visitType == "Laboratory" ||
      history.location.state?.visitType == "Radiology" ? (
        <div className="px-6">
          <PatientInfoContainer>
            <AppointmentRequestDetails
              appointmentId={appointmentId}
              visitType={history.location.state?.visitType}
            />
          </PatientInfoContainer>
        </div>
      ) : (
        <AppoinmentFormContainer className="px-6">
          <PatientInfoContainer>
            <AppointmentForm
              patientId={patientId}
              appointmentId={appointmentId}
              user={user}
            />
          </PatientInfoContainer>
        </AppoinmentFormContainer>
      )}
    </Fragment>
  );
};
export default Appointments;
