import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OtpInput from 'react-otp-input';
import { Row, Col } from 'antd';
import Hospital from '../../assets/icons/Hospital.svg';
import { useQuery } from '../utils/UseQuery';
import md5 from 'md5';
import { getHospitalDetailWithHospitalId } from '../../apis/Public';
import { MdOutlineSendToMobile } from 'react-icons/md';
import { GrPrevious } from 'react-icons/gr';
import { validateOtp } from '../../apis/Otp';
import './otpform.css';

const StyledForm = styled(Form)`
  text-align: left;
  &.ant-form-vertical .ant-form-item-label > label {
    height: auto;
  }
  & .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }
`;

const VerifyOTPForm = ({
  hId,
  setOtpResponse,
  otpResponse,
  setStatus,
  phoneNumber,
}) => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onFinish = (values) => {
    // history.push(`/book-visit-new-patient/:patientId/:patientName`);
    const payload = {
      ...phoneNumber,
      otp,
    };
    validateOtp(payload)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === 'OTP verification successful') {
            if (otpResponse.data.status === false) {
              history.push(`/${hId}/patient-form`);
            } else {
              history.push(
                `/book-visit-new-patient/${otpResponse.data.patient?.id}/${
                  otpResponse.data.patient?.firstName +
                  ' ' +
                  otpResponse.data.patient?.lastName
                }?hId=${hId}`
              );
            }
          } else {
            message.error('Unable to process');

            // history.push(`/verify-otp-form?hospitalId=${hId}`);
            // history.push(`/${hId}/new-patient-form`);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('otp verification failed');
      });
  };

  const goBackFunc = () => {
    setStatus('otp-page');
  };
  // const resendOtp = () => {
  //   resendOtp(values)
  //     .then((res) => {
  //       console.log('res ', res);

  //       if (res.status === 200) {
  //         if (res.data) {
  //           // history.push(`/${hId}/new-patient-form`);
  //           alert('hi');
  //         } else {
  //           // history.push(`/verify-otp-form?hospitalId=${hId}`);
  //           // history.push(`/${hId}/new-patient-form`);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       message.error('otp verification failed');
  //     });
  // };

  return (
    <>
      <StyledForm
        name="phone-login-form"
        onFinish={onFinish}
        //   onFinishFailed={onFinishFailed}
        layout="vertical"
        size="large"
        scrollToFirstError={true}
        wrapperCol={{ gutter: 36 }}
      >
        <Form.Item
          label="Enter the OTP Number :"
          name="otpnumber"
          Style={{
            fontSize: '28px',
            fontWeight: '600px',
            color: 'black',
          }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        >
          <div
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              width: '100%',
            }}
          >
            <OtpInput
              inputStyle={{
                fontSize: '30px',
                borderRadius: '5px',
                height: '40px',
              }}
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={loading}
            size="large"
            type="primary"
            className="btn-send"
          >
            Verify OTP
          </Button>
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Form.Item>
            <Button
              size="medium"
              type="link"
              style={{
                float: 'left',
                marginTop: '-30px',
                color: '#000',
                marginLeft: '-20px',
              }}
              // onClick={onForgotPassword}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <MdOutlineSendToMobile />
                Resend OTP
              </span>
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              size="medium"
              type="link"
              style={{
                float: 'right',
                marginTop: '-30px',
                color: '#000',
                // marginLeft: '-20px',
              }}
              onClick={goBackFunc}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <GrPrevious />
                Go Back
              </span>
            </Button>
          </Form.Item>
        </div>
      </StyledForm>
    </>
  );
};

export default VerifyOTPForm;
