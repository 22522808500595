import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery } from '../utils/UseQuery';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getHospitalName } from '../utils/LocalStorage/index';
import styled from 'styled-components';
import { Row, Col, Form, Button, Divider } from 'antd';
import {
  Hospitalname,
  StyledRow,
  ExaminationsPrintContainer,
  StyledP,
  ScratchPadDiv,
} from './styledComponents';
import Moment from 'react-moment';
import { GithubPicker } from 'react-color';
import { useClickAway } from './useClickAway';
import Icon, {
  PrinterTwoTone,
  DeleteFilled,
  ClearOutlined,
  LeftCircleOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { createRef } from 'react';
import { Fragment } from 'react';

const colors = [
  '#B80000',
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#000000',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
  '#CCCCCC',
];

const width = `${Math.ceil(colors.length / 2) * 32}px`;

function DrawingCanvas() {
  const componentRef = useRef();
  const history = useHistory();

  const theme = useSelector((state) => state.themeReducer);
  const patient = useSelector((state) => state.patientReducer);

  let query = useQuery();
  const docName = query.get('docName');

  const [brushColor, setBrushColor] = useState('#000000');
  const [showColor, setShowColor] = useState(null);

  const [imageData, setImageData] = useState(null);
  const [scratchpad, setScratchPad] = useState(
    JSON.parse(localStorage.getItem(`${patient.pId}-${patient.pName}`))
      ?.length > 0
      ? JSON.parse(localStorage.getItem(`${patient.pId}-${patient.pName}`)).map(
          () => createRef()
        )
      : [createRef()]
  );
  console.log('scratchpad', scratchpad);

  useEffect(() => {
    const items = JSON.parse(
      localStorage.getItem(`${patient.pId}-${patient.pName}`)
    );
    if (Array.isArray(items) && items.length > 0) {
      setTimeout(() => {
        items.forEach((item, index) => {
          scratchpad[index].current.loadPaths(item);
        });
      }, 2000);
    } else {
    }
  }, []);

  // const handleSaveImage = async () => {
  //   const data = await scratchpad[0].current.exportImage('png');
  //   // const data = scratchpad[0]?.current?.getBase64('png', 1);
  //   console.log('img data is', data);
  //   setImageData(data);
  //   handlePrint();
  // };

  const handleGoBack = () => {
    let data = [];
    scratchpad.forEach(async (item, index) => {
      data.push(await item.current.exportPaths());
      if (index === scratchpad.length - 1) {
        localStorage.setItem(
          `${patient.pId}-${patient.pName}`,
          JSON.stringify(data)
        );
      }
    });
    // const data = await scratchpad[0].current.exportPaths();

    console.log('data is ', data);
    history.goBack();
  };

  const styles = {
    border: '0.0625rem solid #9c9c9c',
    borderRadius: '0.25rem',
  };

  // const paletteRef = useClickAway(() => {
  //   setShowColor(null);
  // });

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: 'emp-data',
  // });
  function clearAll(index) {
    setShowColor(null);
    scratchpad[index].current.clearCanvas();
  }

  function undofunc(index) {
    setShowColor(null);
    scratchpad[index].current.undo();
  }

  function eraseAll(index) {
    console.log('index s', index);
    setShowColor(null);
    scratchpad.forEach((item, i) => {
      item.current.eraseMode(i === index ? true : false);
    });
  }

  function handleAddScratch() {
    setScratchPad((scratchpad) => [...scratchpad, createRef()]);
  }
  function removeCanvas(i) {
    let temp = [];
    scratchpad.forEach((item, index) => {
      if (index !== i) {
        temp.push(item);
      }
    });
    setScratchPad(temp);
  }
  // const urlFunction = () => {
  //   let image = scratchpad[0]?.current?.getDataURL('png', null, 'white');
  //   console.log('url', image);
  //   setPriscriptionImage(image);
  // };

  // function saveFunc() {
  //   // const canvas = scratchpad[0].current.getCanvas();
  //   const dataURL = scratchpad[0].toDataURL();
  //   setSaveData(dataURL);
  //   console.log('save funct ', dataURL);
  // }
  // console.log('save funct in state ', saveData);

  return (
    <>
      <Row display="flex" justifyContent="center" align="center">
        {/* <img src={HospitalLogo} style={{ width: '20%' }} /> */}
        <Hospitalname>{getHospitalName()} </Hospitalname>
      </Row>

      <h3
        style={{
          color: theme['@primary-color'],
          textDecoration: 'underline',
          fontSize: '22px',
          fontWeight: 'bold',
        }}
      >
        <LeftCircleOutlined
          style={{
            marginLeft: '20px',
            color: 'black',
            marginBottom: '10px',
            marginRight: '10px',
          }}
          onClick={() => {
            handleGoBack();
          }}
        />
        Patient Detail
      </h3>

      <Row>
        <Col lg={1} md={1} sm={1} xs={1}></Col>
        <Col lg={8} md={8} sm={8} xs={23}>
          <StyledRow>
            Name:&nbsp;&nbsp;
            <StyledP style={{ textTransform: 'capitalize' }}>
              {patient.pName}
            </StyledP>
          </StyledRow>
          <StyledRow>
            Patient ID:&nbsp;&nbsp;
            <StyledP>
              {patient.pId}
              &nbsp;&nbsp;
            </StyledP>
          </StyledRow>
        </Col>
        <Col lg={0} md={0} sm={0} xs={1}></Col>
        <Col lg={8} md={8} sm={8} xs={23}>
          <StyledRow>
            DOB:&nbsp;&nbsp;
            <StyledP>
              {' '}
              <Moment format="DD-MM-YYYY">{patient.dob}</Moment>
            </StyledP>
          </StyledRow>
          <StyledRow>
            Gender:&nbsp;&nbsp;
            <StyledP>{patient.gender}</StyledP>
          </StyledRow>
        </Col>
        <Col lg={0} md={0} sm={0} xs={1}></Col>
        <Col lg={7} md={7} sm={7} xs={23}>
          <StyledRow>
            Provided By:&nbsp;&nbsp;
            <StyledP>{docName}</StyledP>
          </StyledRow>
          <Row></Row>
        </Col>
      </Row>
      {scratchpad.map((item, index) => (
        <div
          style={{
            display: 'flex',
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ScratchPadDiv>
              <ReactSketchCanvas
                style={styles}
                width="800px"
                height="900px"
                strokeWidth={5}
                strokeColor={brushColor}
                ref={item}
                eraserWidth={18}
              />
            </ScratchPadDiv>

            {index > 0 ? (
              <Divider>
                <Button
                  onClick={() => removeCanvas(index)}
                  type="danger"
                  ghost
                  size="small"
                >
                  Remove
                </Button>
              </Divider>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '15px',

              // width: '700px',
            }}
          >
            <div className="picker-container">
              <button
                className="btn-save"
                onClick={() => {
                  setShowColor((showColor) =>
                    showColor === index ? null : index
                  );
                  item.current.eraseMode(false);
                }}
                style={{ marginBottom: '10px' }}
              >
                <span role="img" aria-label="">
                  🎨
                </span>{' '}
                COLOR
              </button>
              {showColor === index && (
                <div className="picker-popper">
                  <GithubPicker
                    triangle={'hide'}
                    color={brushColor}
                    colors={colors}
                    width={width}
                    onChangeComplete={(c) => {
                      setBrushColor(c.hex);
                      setShowColor(null);
                    }}
                  />
                </div>
              )}
            </div>

            <button
              className="btn-save"
              // onClick={clearCanvas}
              type="reset"
              style={{ marginBottom: '10px', width: '100px' }}
              onClick={() => undofunc(index)}
            >
              <span role="img" aria-label="">
                ↩️
              </span>{' '}
              UNDO
            </button>

            <button
              className="btn-save"
              onClick={() => clearAll(index)}
              type="reset"
              style={{ marginBottom: '10px', width: '100px' }}
            >
              <span role="img" aria-label="">
                <DeleteFilled />
              </span>{' '}
              CLEAR
            </button>

            <button
              className="btn-save"
              onClick={() => eraseAll(index)}
              type="button"
              style={{ marginBottom: '10px', width: '100px' }}
            >
              <span role="img" aria-label="">
                <ClearOutlined />
              </span>{' '}
              ERASE &nbsp;
            </button>
            {/* 
            <button
              className="btn-save"
              onClick={() => {
                handleSaveImage();

                // urlFunction();
              }}
              type="button"
              style={{ width: '100px' }}
            >
              <span role="img" aria-label="">
                <PrinterTwoTone />
              </span>{' '}
              PRINT &nbsp;
            </button> */}
            <button
              className="btn-save"
              onClick={() => {
                // handleSaveImage();
                // urlFunction();
                handleAddScratch();
              }}
              type="button"
              style={{
                width: '100px',
                backgroundColor: '#42bff5',
                color: 'white',
              }}
            >
              <span role="img" aria-label="">
                <FileAddOutlined />
              </span>
              &nbsp;NewPad
            </button>
          </div>
        </div>
      ))}
      <Row></Row>

      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <Row display="flex" justifyContent="center" align="center">
            {/* <img src={HospitalLogo} style={{ width: '20%' }} /> */}
            <Hospitalname>{getHospitalName()} </Hospitalname>
          </Row>
          <h3
            style={{
              color: theme['@primary-color'],
              textDecoration: 'underline',
              fontSize: '22px',
              marginLeft: '25px',
            }}
          >
            Patient Detail
          </h3>

          <Row>
            <Col lg={1} md={1} sm={1} xs={1}></Col>
            <Col lg={8} md={8} sm={8} xs={23}>
              <StyledRow>
                Name:&nbsp;&nbsp;
                <StyledP style={{ textTransform: 'capitalize' }}>
                  {patient.pName}
                </StyledP>
              </StyledRow>
              <StyledRow>
                Patient ID:&nbsp;&nbsp;
                <StyledP>
                  {patient.pId}
                  &nbsp;&nbsp;
                </StyledP>
              </StyledRow>
            </Col>
            <Col lg={0} md={0} sm={0} xs={1}></Col>
            <Col lg={8} md={8} sm={8} xs={23}>
              <StyledRow>
                DOB:&nbsp;&nbsp;
                <StyledP>
                  {' '}
                  <Moment format="DD-MM-YYYY">{patient.dob}</Moment>
                </StyledP>
              </StyledRow>
              <StyledRow>
                Gender:&nbsp;&nbsp;
                <StyledP>{patient.gender}</StyledP>
              </StyledRow>
            </Col>
            <Col lg={0} md={0} sm={0} xs={1}></Col>
            <Col lg={7} md={7} sm={7} xs={23}>
              <StyledRow>
                Provided By:&nbsp;&nbsp;
                <StyledP>{docName}</StyledP>
              </StyledRow>
              <Row></Row>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px',
              marginTop: '10px',
            }}
          >
            {imageData && (
              <img
                src={imageData}
                style={{ width: '600px', height: '500px' }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DrawingCanvas;
