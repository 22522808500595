import React, { useEffect, useRef } from "react";
import { Modal, Row, Col, Button, Avatar, Image, Card, Tooltip } from "antd";
import styled from "styled-components";
import {
  CloseCircleOutlined,
  FileTextOutlined,
  UpCircleFilled,
  DownCircleFilled,
  FileImageOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { UserProfileHead } from "../../../Content/Appoinment/userProfileHead";
import { Slide, Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const StyledModal = styled(Modal)`
  min-width: 70vw;
  & .ant-modal-content {
    border-radius: 8px;
  }
  &.pop-modal {
    max-width: 100% !important;
  }
  &.pop-modal .ant-modal-body {
    padding: 15px 15px;
  }
  &.ant-modal {
    top: 20px;
    padding: 22px 15px;
  }
  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    font-size: 12px;
    text-transform: capitalize;
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }

  .popup-form-data .ant-col label {
    color: #8e8c8c;
    font-size: 15px;
  }
  .popup-form-data .ant-col label:before {
    display: none;
  }
  .popup-form-data .ant-col label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight: 700;
    content: "*";
  }
  .popup-form-data .ant-col .ant-input {
    border-radius: 5px;
    border: 1px solid #cccccc;
  }
  .popup-form-data .ant-col textarea {
    resize: none;
  }
  button.submit-btn {
    height: 44px;
    font-weight: 600;
    letter-spacing: 0.02em;
    background-color: #0f70bc !important;
    border: 1px solid transparent;
    width: 100%;
    font-size: 18px;
    width: 100px;
    border-radius: 5px;
  }
  button.submit-btn.w-100 {
    width: 100%;
  }
  .btn-send-bx {
    margin: 0px;
  }
  .cover-width {
    margin: 20px 20px;
  }
  .cover-width h2 {
    text-align: center;
  }

  @media (max-width: 767px) {
    .popup-box-content {
      width: 100% !important;
    }
  }
  @media (max-width: 600px) {
    .cover-width {
      margin: 20px 0px;
    }
  }
`;

const StyledImage = styled(Image)`
  cursor: pointer;
  & .ant-image-img {
    border-radius: 10px;
    object-fit: cover;
    border: ${(props) =>
    props.selected
      ? `3px solid ${props.color}`
      : "1px solid rgba(0,0,0,0.25)"};
  }
`;
const StyledDiv = styled.div`
  cursor: pointer;
  border-radius: 10px;
  border: ${(props) =>
    props.selected ? `3px solid ${props.color}` : "1px solid rgba(0,0,0,0.25)"};
`;
const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

const StyledColumn = styled(Col)`
  height: fit-content;
  height: -moz-fit-content;
`;

// const StyledSlide = styled(Slide)`
//     /* height: 50vh; */
//   & .react-slideshow-wrapper.slide{
//       /* width: 50vw; */
//   }
//   & .react-slideshow-container{
//       /* transform: rotate(90deg); */
//       /* height: 10vw;
//       width: 15vw; */
//   }
//   & .images-wrap > div {
//       /* width: 100px !important;
//       margin-left: 100px !important; */
//   }
//   & .images-wrap {
//       /* width: 1000px !important; */
//   }
// `

const CustomModal = ({
  visible,
  setVisible,
  antdMOdalProps,
  children,
  isClosable,
  data,
  patientId,
  patientName,
  downloadFile,
  historyDate,
}) => {
  const [filePosition, setFilePosition] = useState(0);
  const theme = useSelector((state) => state.themeReducer);
  const [selectedImage, setSelectedImage] = useState();
  const [consultationDate, setConsultationDate] = useState();
  const [uploadedDate, setUploadedDate] = useState();
  const [isPdf, setIsPdf] = useState(false);
  const [fileName, setFileName] = useState("");
  const componentRef = useRef();
  const [printImage, setPrintImage] = useState();
  const [printLoading, setPrintLoading] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    fetch(selectedImage, {
      method: "GET",
      headers: { "Content-Type": "application/octet-stream" },
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        setPrintImage(url);
        setPrintLoading(false);
      });
  }, [selectedImage]);

  useEffect(() => {
    for (let index = 0; index < data?.documents.length; index++) {
      const item = data?.documents[index];
      if (
        item.extension == "jpg" ||
        item.extension == "png" ||
        item.extension == "jpeg"
      ) {
        setSelectedImage(PROFILE_IMAGE_PREFIX + item.documentUrl);
        // setPrintLoading(true)
        setUploadedDate(item.uploadedDate);
        setConsultationDate(item.consultationDate);
        setIsPdf(false);
        setFileName(item.fileName);
        break;
      }
    }
  }, [visible]);

  useEffect(() => {
    const item = data?.documents[0];
    if (item) {
      setSelectedImage(PROFILE_IMAGE_PREFIX + item.documentUrl);
      setPrintLoading(true);
      setUploadedDate(item.uploadedDate);
      setConsultationDate(item.consultationDate);
      setFileName(item.fileName);
      if (
        item.extension == "jpg" ||
        item.extension == "png" ||
        item.extension == "jpeg"
      )
        setIsPdf(false);
      else setIsPdf(true);
    }
  }, [visible]);

  return (
    <StyledModal
      visible={visible}
      {...antdMOdalProps}
      closable={false}
      footer={null}
      width={"max-content"}
      destroyOnClose={true}
      forceRender={true}
      className="pop-modal"
    >
      <Row justify="end" style={{ height: "25px" }}>
        <Col>
          <CloseCircleOutlined
            style={{
              fontSize: "20px",
              color: "red",
              position: "absolute",
              right: "-7px",
              top: "-7px",
            }}
            onClick={() => {
              setVisible(false);
              setSelectedImage();
              setPrintImage();
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={0} lg={24} xl={24} xxl={24}>
          <UserProfileHead patientId={patientId} patientName={patientName} />
        </Col>
      </Row>
      <Row gutter={24} justify="center" style={{ margin: "20px 0 30px 0" }}>
        <Col xs={0} sm={0} md={0} lg={17} xl={17} xxl={17}>
          <Row>
            <Col span={2}></Col>
            <Col span={6}>
              {historyDate ? (
                ""
              ) : (
                <b style={{ fontSize: "16px" }}>
                  {data ? data["Common Test"] : ""}
                </b>
              )}
            </Col>
            <Col span={8} style={{ fontSize: "13px", fontWeight: "600" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  display: "contents",
                }}
              >
                Ordered Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              {moment(
                historyDate ? data?.consultationDate : consultationDate
              ).format("DD-MM-YYYY")}
            </Col>
            <Col span={8} style={{ fontSize: "13px", fontWeight: "600" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  display: "contents",
                }}
              >
                Result Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              {moment(historyDate ? data?.uploadedDate : uploadedDate).format(
                "DD-MM-YYYY"
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <div style={{ color: theme["@primary-color"], cursor: "pointer" }}>
            <a href={selectedImage}>
              <DownloadOutlined /> Download{" "}
            </a>
          </div>
        </Col>
      </Row>
      <div style={{ display: "none" }}>
        <Col ref={componentRef}>
          {!isPdf ? (
            <img
              src={printImage}
              style={{ margin: "5px 0", maxWidth: "95vw", maxHeight: "95vh" }}
            />
          ) : (
            <></>
          )}
        </Col>
      </div>
      <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
        <Row gutter={24}>
          <Col span={2}></Col>
          <Col>
            {!isPdf ? (
              <StyledImage
                width={"42vw"}
                height={"30.28vw"}
                preview={true}
                src={
                  selectedImage || (
                    <FileImageOutlined
                      style={{
                        width: "42vw",
                        height: "30.28vw",
                        color: "rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "50px",
                      }}
                    />
                  )
                }
                style={{ margin: "5px 0", objectFit: "cover" }}
                placeholder={
                  <FileImageOutlined
                    style={{
                      width: "42vw",
                      height: "30.28vw",
                      color: "rgba(0, 0, 0, 0.25)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "75px",
                    }}
                  />
                }
              />
            ) : (
              <StyledDiv
                style={{
                  width: "42vw",
                  height: "30.28vw",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "5px 0",
                }}
              >
                <FileTextOutlined
                  style={{ fontSize: "30px", marginRight: "10px" }}
                />
                {fileName ? fileName : "File"}
              </StyledDiv>
            )}
          </Col>
          <StyledColumn>
            <UpCircleFilled
              onClick={() => {
                filePosition == 0 || filePosition < 0
                  ? setFilePosition(filePosition)
                  : setFilePosition(filePosition - 1);
              }}
              style={{
                fontSize: "20px",
                color:
                  filePosition == 0
                    ? "rgb(222,219,219)"
                    : theme["@primary-color"],
                cursor: filePosition == 0 ? "not-allowed" : "pointer",
                position: "absolute",
                left: "5.25vw",
                zIndex: "1",
                top: "-10px",
              }}
            />
            {data?.documents?.map((item, i) => (
              <Row
                justify="center"
              // style={{ margin: "5px 0" }}
              >
                {i == filePosition ||
                  (i < filePosition + 4 && i > filePosition) ? (
                  item?.extension == "pdf" ? (
                    <Tooltip title={item.fileName} placement="right">
                      <StyledDiv
                        onClick={() => {
                          setSelectedImage(
                            PROFILE_IMAGE_PREFIX + item?.documentUrl
                          );
                          setPrintLoading(true);
                          setUploadedDate(item?.uploadedDate);
                          setConsultationDate(item?.consultationDate);
                          setIsPdf(true);
                          setFileName(item?.fileName);
                        }}
                        selected={
                          selectedImage ==
                            PROFILE_IMAGE_PREFIX + item?.documentUrl
                            ? true
                            : false
                        }
                        color={theme["@primary-color"]}
                        style={{
                          width: "10.5vw",
                          height: "7.07vw",
                          margin: "0.25vw 0",
                          fontSize: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          objectFit: "cover",
                        }}
                      >
                        <FileTextOutlined
                          style={{ fontSize: "15px", marginRight: "5px" }}
                        />
                        {item?.fileName
                          ? item.fileName.slice(0, 5) + "..."
                          : "File"}
                      </StyledDiv>
                    </Tooltip>
                  ) : (
                    <StyledImage
                      width={"10.5vw"}
                      height={"7.07vw"}
                      preview={false}
                      onClick={() => {
                        setSelectedImage(
                          PROFILE_IMAGE_PREFIX + item?.documentUrl
                        );
                        setPrintLoading(true);
                        setUploadedDate(item?.uploadedDate);
                        setConsultationDate(item?.consultationDate);
                        setFileName(item?.fileName);
                        setIsPdf(false);
                      }}
                      src={PROFILE_IMAGE_PREFIX + item?.documentUrl}
                      style={{ margin: "0.25vw 0", objectFit: "cover" }}
                      selected={
                        selectedImage == PROFILE_IMAGE_PREFIX + item?.documentUrl
                          ? true
                          : false
                      }
                      color={theme["@primary-color"]}
                      placeholder={
                        <FileImageOutlined
                          style={{
                            width: "10.5vw",
                            height: "7.07vw",
                            color: "rgba(0, 0, 0, 0.25)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "62px",
                          }}
                        />
                      }
                    />
                  )
                ) : (
                  <></>
                )}
              </Row>
            ))}
            <Row>
              <DownCircleFilled
                onClick={() => {
                  filePosition == data?.documents?.length - 4 ||
                    data?.documents?.length < 4
                    ? setFilePosition(filePosition)
                    : setFilePosition(filePosition + 1);
                }}
                style={{
                  fontSize: "20px",
                  color:
                    filePosition == data?.documents?.length - 4 ||
                      data?.documents?.length < 4
                      ? "rgb(222,219,219)"
                      : theme["@primary-color"],
                  cursor:
                    filePosition == data?.documents?.length - 4 ||
                      data?.documents?.length < 4
                      ? "not-allowed"
                      : "pointer",
                  position: "absolute",
                  left: "5.25vw",
                  zIndex: "1",
                  bottom: "-10px",
                }}
              />
            </Row>
            {/* <StyledSlide {...properties}>
                        {data?.documents?.map((item) => (
                            <div
                                // style={{ width: "10vh" }}
                            >
                                <Image
                                    width={100}
                                    // height={150}
                                    src={PROFILE_IMAGE_PREFIX + item?.documentUrl}
                                    // style={{ transform: " rotate(270deg)" }}
                                    placeholder={
                                        <Image
                                            width={100}
                                            // height={150}
                                            src={PROFILE_IMAGE_PREFIX + item?.documentUrl}
                                        // style={{ transform: " rotate(270deg)" }}
                                        />
                                    }
                                />
                            </div>
                        ))}
                    </StyledSlide> */}
          </StyledColumn>
          <Col span={1}></Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
        <StyledColumn>
          <UpCircleFilled
            onClick={() => {
              filePosition == 0 || filePosition < 0
                ? setFilePosition(filePosition)
                : setFilePosition(filePosition - 1);
            }}
            style={{
              fontSize: "20px",
              color:
                filePosition == 0
                  ? "rgb(222,219,219)"
                  : theme["@primary-color"],
              cursor: filePosition == 0 ? "not-allowed" : "pointer",
              position: "absolute",
              left: "calc(50% - 10px)",
              zIndex: "1",
              top: "-10px",
            }}
          />
          {data?.documents?.map((item, i) => (
            <Row
              justify="center"
            // style={{ margin: "5px 0" }}
            >
              {i == filePosition ||
                (i < filePosition + 4 && i > filePosition) ? (
                item?.extension == "pdf" ? (
                  <Tooltip title={item.fileName} placement="right">
                    <StyledDiv
                      onClick={() => {
                        setSelectedImage(
                          PROFILE_IMAGE_PREFIX + item?.documentUrl
                        );
                        setPrintLoading(true);
                        setUploadedDate(item?.uploadedDate);
                        setConsultationDate(item?.consultationDate);
                        setIsPdf(true);
                        setFileName(item?.fileName);
                      }}
                      selected={
                        selectedImage ==
                          PROFILE_IMAGE_PREFIX + item?.documentUrl
                          ? true
                          : false
                      }
                      color={theme["@primary-color"]}
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "0.25vw 0",
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        objectFit: "cover",
                      }}
                    >
                      <FileTextOutlined
                        style={{ fontSize: "15px", marginRight: "5px" }}
                      />
                      {item?.fileName
                        ? item.fileName.slice(0, 5) + "..."
                        : "File"}
                    </StyledDiv>
                  </Tooltip>
                ) : (
                  <StyledImage
                    width={"100%"}
                    height={"100%"}
                    preview={false}
                    onClick={() => {
                      setSelectedImage(
                        PROFILE_IMAGE_PREFIX + item?.documentUrl
                      );
                      setPrintLoading(true);
                      setUploadedDate(item?.uploadedDate);
                      setConsultationDate(item?.consultationDate);
                      setFileName(item?.fileName);
                      setIsPdf(false);
                    }}
                    src={PROFILE_IMAGE_PREFIX + item?.documentUrl}
                    style={{ margin: "0.25vw 0", objectFit: "cover" }}
                    selected={
                      selectedImage == PROFILE_IMAGE_PREFIX + item?.documentUrl
                        ? true
                        : false
                    }
                    color={theme["@primary-color"]}
                    placeholder={
                      <FileImageOutlined
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "rgba(0, 0, 0, 0.25)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "62px",
                        }}
                      />
                    }
                  />
                )
              ) : (
                <></>
              )}
            </Row>
          ))}
          <Row>
            <DownCircleFilled
              onClick={() => {
                filePosition == data?.documents?.length - 4 ||
                  data?.documents?.length < 4
                  ? setFilePosition(filePosition)
                  : setFilePosition(filePosition + 1);
              }}
              style={{
                fontSize: "20px",
                color:
                  filePosition == data?.documents?.length - 4 ||
                    data?.documents?.length < 4
                    ? "rgb(222,219,219)"
                    : theme["@primary-color"],
                cursor:
                  filePosition == data?.documents?.length - 4 ||
                    data?.documents?.length < 4
                    ? "not-allowed"
                    : "pointer",
                position: "absolute",
                left: "calc(50% - 10px)",
                zIndex: "1",
                bottom: "-10px",
              }}
            />
          </Row>
        </StyledColumn>
      </Col>
      <Row gutter={24} justify="end" style={{ margin: "20px 0" }}>
        <Button
          style={{ padding: "0 30px", borderRadius: "5px", height: "45px", marginBottom: "10px" }}
          // disabled={true}
          type="primary"
          size="large"
          ghost
          onClick={() => downloadFile(data)}
        >
          Download Result
        </Button>
        <RightButton
          className="btn-save"
          style={{ borderRadius: "5px", height: "45px" }}
          type="primary"
          size="large"
          onClick={() => handlePrint()}
          disabled={printLoading || isPdf}
        >
          Print
        </RightButton>
        <Col span={2}></Col>
      </Row>
    </StyledModal>
  );
};

export default CustomModal;
