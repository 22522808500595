import axiosInstance from '../config/axiosInstance';
import axios from 'axios';

const OTP_PRFIX = '/sms';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;
// export const baseUrl = process.env.REACT_APP_STAGING_API_BASE_URL;
export const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return baseUrl;
    default:
      return process.env.REACT_APP_STAGING_API_BASE_URL;
  }
};

export const sendOtp = (data, id) => {
  console.log('otp api', data, id);
  return axiosInstance.post(`${OTP_PRFIX}/sendOtp/${id}`, data);
};

export const validateOtp = (data) => {
  return axiosInstance.post(`${OTP_PRFIX}/validate-otp`, data);
};

export const resendOtp = (data) => {
  return axiosInstance.post(`${OTP_PRFIX}/resentOtp`);
};
