import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Wrapper,
  BodyContainer,
  StyledContent,
  LogoContainer,
} from './styledComponents';

import NewPatientCreateForm from './NewPatientCreateForm';
import { useParams } from 'react-router-dom';
import { getHospitalName } from '../utils/LocalStorage';
import { getHospitalDetail } from '../../apis/Public';
import { getHospitalDetailWithHospitalId } from '../../apis/Public';
import md5 from 'md5';
import moment from 'moment';
import Hospital from '../../assets/icons/Hospital.svg';
import { Divider } from 'antd';

const NewPatientForm = () => {
  const history = useHistory();
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);

  //to get id from url using useParams
  const { hId, id } = useParams();

  const [hospitalDetail, sethospitalDetail] = useState({});
  const hospitalId = md5(hId);
  const doctorid = id ? md5(id) : '';

  useEffect(() => {
    getHospitalDetailWithHospitalId(hospitalId, doctorid)
      .then((res) => {
        if (res.status == 200) {
          sethospitalDetail(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Wrapper justify="center">
      <StyledContent className="container">
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 15px',
          }}
        >
          <h3 className="h3-base-600">Opening Time :</h3>
          <h3 style={{ color: '#3BB8FF' }}>
            {moment(hospitalDetail?.doctor?.startTime).format("hh:mm A")}
          </h3>
        </div> */}

        <BodyContainer className="px-6">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {Object.keys(hospitalDetail).length !== 0 ? (
              <>
                <span>
                  <img
                    src={Hospital}
                    alt="hospital"
                    height={'130px'}
                    width={'130px'}
                  />
                </span>
                <h2 style={{ marginTop: '-25px' }}>
                  {hospitalDetail?.hospital?.name ?? ''}{' '}
                </h2>
                <h4>{hospitalDetail?.hospital?.address ?? ''}</h4>
                <h4>
                  Phone Number :{' '}
                  {hospitalDetail?.hospital?.phonenumber?.phoneNumber ?? ''}
                </h4>
              </>
            ) : (
              'No hospital data'
            )}
          </div>
          <Divider />
          <NewPatientCreateForm hId={hId} id={id} />
        </BodyContainer>
      </StyledContent>
    </Wrapper>
  );
};

export default NewPatientForm;
