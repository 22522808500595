import React, { useState } from 'react';
import {
  StyledRow,
  UploadTitle,
  SectionTtile,
  StyledCheckbox,
  StyledCol,
} from './styledComponents';
import * as Sentry from '@sentry/react';
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  message,
  Select,
  Radio,
  Collapse,
} from 'antd';
import { useEffect } from 'react';
import {
  findAllPrivilegesByRole,
  fetchRolesById,
} from '../../../../apis/Admin';
import {
  StyledDatePicker,
  StyledInputNumber,
  StyledTimePicker,
} from './styledComponents';
import moment from 'moment';
import { getCountries } from '../../../../apis/publicApis';
import { CaretDownOutlined } from '@ant-design/icons';
import { genderList } from '../../../Datasets/basicDetails';
import { ServiceCreateForm } from './ServiceCreateForm';

const UserDetailsForm = ({
  department,
  submitHandler,
  setVisible,
  loading,
  reception,
  selectedId,
  edit,
  selectedItem,
  role,
  submitLoading,
  disabled,
  setDisabled,
  setAvailability,
  availability,
}) => {
  const [form] = Form.useForm();
  const [privileges, setPrivileges] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState([]);
  const [countries, setCountries] = useState([]);
  const { Panel } = Collapse;
  const [activeKey, setActiveKey] = useState([]);
  const [initialValues, setInitialValues] = useState([]);

  const togglePanel = () => {
    setActiveKey(activeKey.length === 0 ? ['1'] : []);
  };

  const capitalize = (camelCaseText) => {
    return (
      camelCaseText.charAt(0).toUpperCase() +
      camelCaseText
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(' ')
    );
  };

  useEffect(() => {
    getCountries().then((resp) => {
      setCountries(
        resp.data.map((country) => ({
          code: country.callingCodes[0],
          name: country.name,
        }))
      );
    });
  }, []);

  // Define a custom sorting order for days starting from Monday
  const dayOrder = {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6,
  };

  useEffect(() => {
    if (edit) {
      // setFormInitialValues(selectedItem);
      console.log('selected iten', selectedItem);
      fetchRolesById(selectedItem.id)
        .then((resp) => {
          console.log('resp in fetch is', resp);
          if (resp.data.status === 1) {
            const data = resp.data.body;
            setPrivileges(data);
            const filteredData = data.filter((item) => item.active === true);
            if (selectedItem.userType === 'DOCTOR') {
              let availabilityFields = selectedItem.availabilities.map(
                (availability) => ({
                  id: availability.id,
                  isAvailable: availability.isAvailable,
                  startTime:
                    availability.isAvailable && availability.startTime
                      ? moment(availability.startTime, 'HH:mm:ss')
                      : '',
                  endTime:
                    availability.isAvailable && availability.endTime
                      ? moment(availability.endTime, 'HH:mm:ss')
                      : '',
                  breakStartTime:
                    availability.isAvailable && availability.breakStartTime
                      ? moment(availability.breakStartTime, 'HH:mm:ss')
                      : '',
                  breakEndTime:
                    availability.isAvailable && availability.breakEndTime
                      ? moment(availability.breakEndTime, 'HH:mm:ss')
                      : '',
                  dayOfWeek: availability.dayOfWeek,
                })
              );

              const sortedAvailabilityFields = [];
              availabilityFields.forEach((item) => {
                sortedAvailabilityFields[dayOrder[item.dayOfWeek]] = item;
              });

              setInitialValues([...sortedAvailabilityFields]);
              let formFields = { ...selectedItem };
              delete formFields.availabilities;
              setFormInitialValues({
                ...formFields,
                dob: selectedItem.dob
                  ? moment(selectedItem.dob)
                  : moment(new Date()),
                startTime: selectedItem.startTime
                  ? moment(selectedItem.startTime)
                  : null,
                endTime: selectedItem.endTime
                  ? moment(selectedItem.endTime)
                  : null,
                privilegesId: filteredData && filteredData.map((i) => i.id),
                // days: availabilityFields,
              });
            }
            setFormInitialValues({
              ...selectedItem,
              dob: selectedItem.dob
                ? moment(selectedItem.dob)
                : moment(new Date()),
              startTime: selectedItem.startTime
                ? moment(selectedItem.startTime)
                : null,
              endTime: selectedItem.endTime
                ? moment(selectedItem.endTime)
                : null,
              privilegesId: filteredData && filteredData.map((i) => i.id),
              // days: availabilityFields,
            });
            // setLoading(false);
          } else {
            // setLoading(false);
            message.error('Could not fetch User Privileges admin, Try again');
          }
        })
        .catch((err) => {
          message.error('Could not fetch User Privileges adm, Try again');
          // setLoading(false);
          console.log(err);
          Sentry.captureException(err);
        });
    } else {
      findAllPrivilegesByRole(selectedId)
        .then((resp) => {
          if (resp.status === 200) {
            const data = resp.data.body;
            setPrivileges(data.privileges);
            const filteredData = data.privileges.filter(
              (item) => item.active === true
            );
            setFormInitialValues(filteredData && filteredData.map((i) => i.id));
            // setLoading(false);
          } else {
            // setLoading(false);
            message.error('Could not fetch User Privileges, Try again');
          }
        })
        .catch((err) => {
          message.error('Could not fetch User Privileges, Try again');
          // setLoading(false);
          console.log(err);
          Sentry.captureException(err);
        });
    }
  }, [selectedId]);

  useEffect(() => {
    if (edit) {
      console.log('forminitialvalues&&&', formInitialValues);
      var genderField = '';

      if (formInitialValues) {
        console.log('form intial values', formInitialValues);
        form.setFieldsValue({
          ...formInitialValues,
          gender: formInitialValues.gender,
          privilegesId: formInitialValues.privilegesId,
          departmentIds: formInitialValues.departments,
          receptionistAddress:
            role === 'RECEPTIONIST' && formInitialValues.address,
        });
      }
    } else {
      form.setFieldsValue({ privilegesId: formInitialValues });
    }
  }, [formInitialValues]);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };
  const getEndTime = (time) => {
    console.log('end time is', time._d);
    form.setFieldsValue({
      endTime: time,
    });
    setDisabled(false);
  };

  const getstartTime = (time) => {
    console.log('st time is', time._d);
    form.setFieldsValue({
      startTime: time,
    });
    setDisabled(false);
  };

  const valuesChangeHandler = (changed, all) => {
    setDisabled(false);
  };

  return (
    <Col span={24}>
      <Form
        layout="vertical"
        onFinish={(values) => {
          submitHandler(values);
          setDisabled(!disabled);
        }}
        form={form}
        className="form-m2-style"
        style={{ width: '100%' }}
        onValuesChange={valuesChangeHandler}
        scrollToFirstError={true}
      >
        <Row justify="left" gutter={[36, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'First name cannot be empty',
                },
                {
                  pattern: new RegExp(
                    /^(([A-Za-z]{1,})([\.,] |[-']| )*)+[A-Za-z]+\.?\s*$/
                  ),
                  message: 'Please enter alphabets only.',
                },
              ]}
              style={{ width: '100%' }}
            >
              <Input size="large" placeholder="Enter First Name" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Last name cannot be empty',
                },
                {
                  pattern: new RegExp(
                    /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
                  ),
                  message: 'Please enter alphabets only.',
                },
              ]}
              style={{ width: '100%' }}
            >
              <Input size="large" placeholder="Enter Last Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: 'Gender cannot be empty' }]}
              style={{ width: '100%' }}
            >
              <Select size="large" placeholder="Select a Gender">
                {genderList().map((gender) => {
                  return (
                    <Select.Option value={gender.code} key={gender.code}>
                      {gender.text}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Enter valid email !',
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="abc@example.com" size="large" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Date of Birth"
              name="dob"
              rules={[
                {
                  required: true,
                  message: 'Date of Birth cannot be empty.',
                },
              ]}
            >
              <StyledDatePicker
                size="large"
                disabledDate={disabledDate}
                format="DD-MM-YYYY"
                allowClear={false}
              />
            </Form.Item>
          </Col>

          {/* <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            label="Age"
            name="age"
            rules={[
              {
                required: true,
                message: "Age cannot be empty.",
              },
            ]}
          >
            <Input size="large" disabled={true} />
          </Form.Item>
        </Col> */}

          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Primary Contact No." required>
              <Input.Group compact size="large">
                <Form.Item
                  name={['mobileNo', 'countryCode']}
                  rules={[
                    {
                      required: true,
                      message: 'Country Code is required',
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size="large"
                    suffixIcon={<CaretDownOutlined showSearch />}
                    placeholder="Select Country code"
                    style={{ width: '20%' }}
                    showSearch
                    // optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    //   || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {countries.map((item) => (
                      <Select.Option
                        value={'+' + item.code + ' ' + item.name}
                        key={item.name}
                      >
                        {'+' + item.code + ' ' + capitalize(item.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={['mobileNo', 'phoneNumber']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Enter valid Phone number ',
                      pattern: new RegExp(/^[0-9]{10}$/),
                    },
                  ]}
                >
                  <Input
                    style={{ width: '80%' }}
                    placeholder="Primary Contact Number"
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Secondary Contact Number">
              <Input.Group compact size="large">
                <Form.Item
                  name={['alternateContactNo', 'countryCode']}
                  rules={[
                    { required: true, message: 'Country Code is required' },
                  ]}
                  noStyle
                >
                  <Select
                    size="large"
                    suffixIcon={<CaretDownOutlined showSearch />}
                    placeholder="Select Country code"
                    style={{ width: '20%' }}
                    showSearch
                    // optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    //   || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {countries.map((item) => (
                      <Select.Option
                        value={'+' + item.code + ' ' + item.name}
                        key={item.name}
                      >
                        {'+' + item.code + ' ' + capitalize(item.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={['alternateContactNo', 'phoneNumber']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Enter valid Phone number ',
                      pattern: new RegExp(/^[0-9]{10}$/),
                    },
                  ]}
                >
                  <Input
                    style={{ width: '80%' }}
                    placeholder="Secondary Contact Number"
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          {(role === 'DOCTOR' ||
            role === 'RADIOLOGY' ||
            role === 'LABORATORY') && (
            <>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="consultationTime"
                  label="Consultation Time (Minutes)"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <StyledInputNumber />
                </Form.Item>
              </Col>
              {/* <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item
                  name="startTime"
                  label="Start Time"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <StyledTimePicker
                    size="large"
                    format="HH:mm"
                    onSelect={getstartTime}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="endTime"
                  label="End Time"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <StyledTimePicker
                    size="large"
                    format="HH:mm"
                    onSelect={getEndTime}
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Collapse
                  // onChange={onChange}
                  style={{
                    marginBottom: '25px',
                    fontFamily:
                      '"Open Sans", "Helvetica Neue", sans-serif !important',
                  }}
                  activeKey={activeKey}
                  onChange={(keys) => setActiveKey(keys)}
                >
                  <Panel header="Consultation Time" key="1">
                    <ServiceCreateForm
                      togglePanel={togglePanel}
                      setAvailability={setAvailability}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      initialValues={initialValues}
                      availability={availability}
                    />
                  </Panel>
                </Collapse>
              </Col>
              {role === 'DOCTOR' && (
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="fee"
                    label="Consultation Fee"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <StyledInputNumber />
                  </Form.Item>
                </Col>
              )}
            </>
          )}

          {role === 'RECEPTIONIST' && (
            <>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="receptionistAddress"
                  label="Address"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </>
          )}

          <Col span={24}>
            <SectionTtile>Privileges</SectionTtile>
          </Col>

          <Col span={24}>
            <Form.Item name="privilegesId">
              <StyledCheckbox.Group size="large">
                <Row gutter={[36, 36]}>
                  {privileges.length > 0 &&
                    privileges.map((privilege) => (
                      <StyledCol span={6} key={`Privilege${privilege.id}`}>
                        <StyledCheckbox
                          value={privilege?.id}
                          defaultChecked={privilege?.active ? true : false}
                          size="large"
                        >
                          {privilege?.name}
                        </StyledCheckbox>
                      </StyledCol>
                    ))}
                </Row>
              </StyledCheckbox.Group>
            </Form.Item>
          </Col>

          <Col span={24}>
            <SectionTtile>Active Status</SectionTtile>
          </Col>

          <Col span={24}>
            <Form.Item
              name="status"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group size="large">
                <Radio value={'ACTIVE'}>Active</Radio>
                <Radio value={'INACTIVE'}>Inactive</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <StyledRow gutter={12} justify="end">
            <Col>
              <Button
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
                danger
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                loading={submitLoading}
                htmlType="submit"
                disabled={disabled}
                // onClick={() => setDisabled(!disabled)}
              >
                Save
              </Button>
            </Col>
          </StyledRow>
        </Form.Item>
      </Form>
    </Col>
  );
};

export default UserDetailsForm;
