import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tabs, Button, message } from "antd";
import PatientSearch from "../PatientsList/PatientSearch";
import PatientsTable from "../PatientsList/PatientsTable";
import { hasPrivilege } from "../../utils/privilegeHandlers/hasPrivilege.js";
import IntimeModal from "../InTimeModal";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import {
  getActiveRadiology,
  getDoctors,
  getReportWaitingRadiology,
  getTestHistoryRadiology,
} from "../../../apis/patients";
import {
  activeRadiologyColumns,
  reportWaitingRadiologyColumns,
  testHistoryRadiologyColumns,
} from "./radiologyColumns";

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  &.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    /* padding: 16px 25px; */
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid #d6d6d6;
    width: 200px;
    /* text-align: center; */
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
  }
  & .ant-tabs-tab-btn {
    margin: auto;
  }
  &.tab_sec_portal .ant-tabs-tab div {
    font-size: 20px;
    color: #808080;
  }
  &.tab_sec_portal .ant-tabs-tab {
    width: auto !important;
    padding: 14px 18px;
  }
  &.tab_sec_portal .ant-tabs-tab.ant-tabs-tab-active div {
    color: ${(props) => props.color};
  }

  .table_col_style1 {
    padding-bottom: 60px;
  }
  @media (max-width: 1359px) {
    &.tab_sec_portal .ant-tabs-tab {
      width: auto !important;
      padding: 12px 18px;
    }
    &.tab_sec_portal .ant-tabs-tab div {
      font-size: 18px;
    }
  }

  .table_col_style1 .field-title {
    font-size: 16px;
    font-weight: 500;
  }
  .table_col_style1 .field-typed .ant-picker {
    height: 50px;
    border-radius: 5px;
  }
  .table_col_style1 .book_btn_bl {
    font-size: 17px;
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    padding: 0px 20px;
    border-radius: 5px;
  }
  .table_col_style1 table thead tr th {
    font-size: 16px;
    border-bottom: 0px;
  }
  .table_col_style1 table tbody tr td {
    border-top: 1px solid #cdcdcd;
    border-bottom: 0px;
  }
  .table_col_style1 table tbody tr td {
    font-size: 17px;
    padding: 12px 16px;
  }
  @media (max-width: 1359px) {
    .table_col_style1 table tbody tr td {
      font-size: 15px;
    }
  }

  .table_col_style1 table tbody tr td .ant-btn {
    padding: 5px 12px;
    height: auto;
    font-size: 16px;
    border-radius: 5px;
  }
  .table_col_style1 table tbody tr td .ant-btn-sm:hover {
    background-color: ${(props) => props.color} !important;

    color: #ffffff;
  }
  .table_col_style1
    table
    tbody
    tr
    td
    .ant-btn-background-ghost.ant-btn-primary:hover {
    background-color: ${(props) => props.color} !important;
    color: #ffffff;
  }
  @media (max-width: 1199px) {
    .table_col_style1 .field-typed .ant-picker,
    .table_col_style1 .book_btn_bl {
      height: 42px;
    }
    .table_col_style1 table tbody tr td .ant-btn {
      font-size: 14px !important;
    }
    .table_col_style1 table thead tr th {
      font-size: 15px;
    }
    .mt-input-search {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .mt-input-search-btn {
      flex: 0 0 13%;
    }
  }
  @media (max-width: 991px) {
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 640px;
      flex: 0 0 640px;
      max-width: 640px;
    }
    .table_col_style1 .btn_right_stripe {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 18px;
    }
    .mt-input-search {
      flex: 0 0 47%;
      max-width: 47%;
    }
    .mt-input-search-btn {
      flex: 0 0 13%;
    }
  }
  @media (max-width: 767px) {
    &.tab_sec_portal .ant-tabs-tab {
      padding: 9px 15px;
    }
    &.tab_sec_portal .ant-tabs-tab div {
      font-size: 16px;
    }
    .mt-input-search,
    .mt-input-search-btn {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 640px) {
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 480px;
      flex: 0 0 480px;
      max-width: 480px;
    }
    .data_field_picker .ant-row {
      margin: 0px -3px !important;
    }
    .data_field_picker .ant-col {
      padding: 0px 6px !important;
    }
    .data_field_picker .ant-col:last-child {
      padding-left: 0px !important;
    }
  }
  @media (max-width: 480px) {
    &.tab_sec_portal .ant-tabs-nav-wrap {
      width: 100%;
      overflow: inherit;
    }
    .table_col_style1 .data_field_picker {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .data_field_picker .ant-row {
      margin: 0px 0px !important;
    }
    .data_field_picker .ant-col {
      padding: 0px 0px 5px !important;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .data_field_picker .ant-col.field-title {
      padding: 0px 0px !important;
    }
    .mx_clear_data {
      text-align: right;
      color: #dc2e18;
    }
  }
`;

const StyledButton = styled(Button)`
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    background: ${(props) => props.color};
    color: white;
  }
`;

const searchByItem = ["all", "name", "id", "mobileNo"];

const RadiologyDashboard = () => {
  const [pageNo, setPageNo] = useState(0);
  const [currentTab, setCurrentTab] = useState(1);
  const [searchParam, setSearchParam] = useState("all");
  const [doctors, setDoctors] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [docFilterParam, setDocFilterParam] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [timeFilter, setTimeFilter] = useState({});
  const [dateRangeFilter, setDateRangeFilter] = useState({});
  const [defaultParams, setDefaultParams] = useState();
  const [inTimeModalVisible, setIntimeModalVisible] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState();

  const [activeLoading, setActiveLoading] = useState(false);
  const [activeData, setActiveData] = useState([]);
  const [activeTotal, setActiveTotal] = useState(0);
  const [reportWaitingLoading, setReportWaitingLoading] = useState(false);
  const [reportWaitingData, setReportWaitingData] = useState([]);
  const [reportWaitingTotal, setReportWaitingTotal] = useState(0);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const pageSize = 15;
  const history = useHistory();
  const user = useSelector((state) => state.userReducer);
  const theme = useSelector((state) => state.themeReducer);

  const onTabChange = (activeTabKey) => {
    setCurrentTab(activeTabKey);
    if (
      searchString ||
      docFilterParam.length !== 0 ||
      filterParams.filterString ||
      (dateRangeFilter.startDate && dateRangeFilter.endDate) ||
      (timeFilter.startTime && timeFilter.endTime)
    )
      onClear();
  };
  const onClear = () => {
    setPageNo(0);
    setDocFilterParam([]);
    setFilterParams({});
    setSearchParam("all");
    setSearchString("");
    setDateRangeFilter({});
    setTimeFilter({});
    if (currentTab == 1) {
      setActiveLoading(true);
      fetchActiveRadiology(defaultParams);
    } else if (currentTab == 2) {
      setReportWaitingLoading(true);
      fetchReportWaitingRadiology(defaultParams);
    } else if (currentTab == 3) {
      setHistoryLoading(true);
      fetchTestHistoryRadiology(defaultParams);
    }
  };

  const onInputChange = (event) => {
    setSearchString(event.target.value);
  };
  const onSelectChange = (value) => {
    setSearchParam(value);
  };

  const onDocFilterSubmit = (filter) => {
    setDocFilterParam(filter);
  };

  const onFilterSubmit = (filter) => {
    let filterString;
    Object.entries(filter).forEach(([key, value]) => {
      if (value.length > 0) {
        filterString = `${filterString ? filterString : ""}${
          filterString ? "&" : ""
        }${key}=${value}`;
      } else
        filterString = `${filterString ? filterString : ""}${
          filterString ? "&" : ""
        }`;
    });
    setFilterParams({ filterString, filter });
  };

  const onSearch = (value) => {
    let searchQuery;
    if (value == true) {
      searchQuery = `pageNo=0&size=${pageSize}&timeZone=${moment.tz.guess()}`;
      setPageNo(0);
    } else
      searchQuery = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
    if (searchString)
      searchQuery = `${searchQuery}&${searchParam}=${searchString}`;
    if (docFilterParam.length !== 0)
      searchQuery = `${searchQuery}&doctorName=${docFilterParam}`;
    if (filterParams.filterString)
      searchQuery = `${searchQuery}&${filterParams.filterString}`;
    if (timeFilter.startTime && timeFilter.endTime) {
      searchQuery = `${searchQuery}&startTime=${timeFilter.startTime.format(
        "x"
      )}&endTime=${timeFilter.endTime.format("x")}`;
    }
    if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
      searchQuery = `${
        searchQuery ? searchQuery : ``
      }&startDate=${dateRangeFilter.startDate.format(
        "x"
      )}&endDate=${dateRangeFilter.endDate.format("x")}`;
    }
    if (currentTab == 1) {
      setActiveLoading(true);
      fetchActiveRadiology(searchQuery);
    } else if (currentTab == 2) {
      setReportWaitingLoading(true);
      fetchReportWaitingRadiology(searchQuery);
    } else if (currentTab == 3) {
      setHistoryLoading(true);
      fetchTestHistoryRadiology(searchQuery);
    }
  };

  const fetchActiveRadiology = (searchQuery) => {
    getActiveRadiology(searchQuery)
      .then((res) => {
        if (res.status == 200) {
          setActiveData(res.data.body);
          setActiveTotal(res.data.total);
        }
        setActiveLoading(false);
      })
      .catch((err) => {
        setActiveLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const fetchReportWaitingRadiology = (searchQuery) => {
    getReportWaitingRadiology(searchQuery)
      .then((res) => {
        if (res.status == 200) {
          setReportWaitingData(res.data.body);
          setReportWaitingTotal(res.data.total);
        }
        setReportWaitingLoading(false);
      })
      .catch((err) => {
        setReportWaitingLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const fetchTestHistoryRadiology = (queryString) => {
    getTestHistoryRadiology(queryString)
      .then((res) => {
        if (res.status === 200) {
          setHistoryData(res.data.body);
          setHistoryTotal(res.data.total);
        } else {
          message.error("Could not fetch insurance list, Try again");
        }
        setHistoryLoading(false);
      })
      .catch((err) => {
        setHistoryLoading(false);
        message.error("Could not fetch insurance list, Try again");
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const fetchDoctors = () => {
    getDoctors()
      .then((res) => {
        if (res.data.status === 1) {
          const doctorData = res.data.body.map((eachDoc) => ({
            label: eachDoc.firstName + " " + eachDoc.lastName,
            value: eachDoc.firstName + " " + eachDoc.lastName,
          }));
          setDoctors(doctorData);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const onTimeFilter = (time, timeString) => {
    const startTime = moment(timeString[0], "hh:mm a");
    const endTime = moment(timeString[1], "hh:mm a");
    setTimeFilter({ startTime, endTime });
  };

  const onDateRangeFilter = (date, dateString) => {
    const startDate = moment(dateString[0], "DD-MM-YYYY");
    const endDate = moment(dateString[1], "DD-MM-YYYY");
    setDateRangeFilter({ startDate, endDate });
  };

  useEffect(() => {
    setDefaultParams(
      `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`
    );
    fetchDoctors();
  }, []);

  useEffect(() => {
    let radiologyParam = `pageNo=${pageNo}&size=${pageSize}&timeZone=${moment.tz.guess()}`;
    if (
      searchString ||
      docFilterParam.length !== 0 ||
      filterParams.filterString ||
      (dateRangeFilter.startDate && dateRangeFilter.endDate) ||
      (timeFilter.startTime && timeFilter.endTime)
    )
      onSearch();
    else {
      if (pageNo == 0) {
        if (hasPrivilege("ACTIVE_PATIENT")) {
          setActiveLoading(true);
          fetchActiveRadiology(radiologyParam);
        }
        if (hasPrivilege("ACTIVE_PATIENT")) {
          setReportWaitingLoading(true);
          fetchReportWaitingRadiology(radiologyParam);
        }
        if (hasPrivilege("VIEW_PATIENT")) {
          setHistoryLoading(true);
          fetchTestHistoryRadiology(radiologyParam);
        }
      } else {
        if (currentTab == 1) {
          setActiveLoading(true);
          fetchActiveRadiology(radiologyParam);
        } else if (currentTab == 2) {
          setReportWaitingLoading(true);
          fetchReportWaitingRadiology(radiologyParam);
        } else if (currentTab == 3) {
          setHistoryLoading(true);
          fetchTestHistoryRadiology(radiologyParam);
        }
      }
    }
  }, [pageNo]);

  useEffect(() => {
    if (
      filterParams.filterString ||
      filterParams.filterString == "" ||
      docFilterParam.length !== 0
    )
      onSearch(true);
  }, [filterParams, docFilterParam]);

  return (
    <>
      <StyledTabs
        className="tab_sec_portal"
        color={theme["@primary-color"]}
        activeKey={`${currentTab}`}
        onTabClick={(key) => setCurrentTab(key)}
        size="large"
        animated
        tabBarGutter={0}
        onChange={onTabChange}
        tabBarStyle={{ width: "100%" }}
      >
        {hasPrivilege("ACTIVE_PATIENT") && (
          <TabPane tab="Active Patients" key="1">
            <PatientSearch
              docFilterItems={doctors}
              searchSelect={searchParam}
              onSearch={onSearch}
              filters={["Payment Method"]}
              tabType={"active"}
              {...{
                searchByItem,
                onInputChange,
                onSelectChange,
                onDocFilterSubmit,
                searchString,
                onClear,
                onFilterSubmit,
                onTimeFilter,
                timeFilter,
                filterParams,
                docFilterParam,
              }}
            />
            <PatientsTable
              columns={activeRadiologyColumns(
                history,
                pageNo,
                pageSize,
                StyledButton,
                theme,
                setIntimeModalVisible,
                setSelectedAppointmentId
              )}
              loading={activeLoading}
              dataSource={activeData}
              pageSize={pageSize}
              setPageNo={setPageNo}
              pageNo={pageNo}
              total={activeTotal}
            />
          </TabPane>
        )}
        {hasPrivilege("ACTIVE_PATIENT") && (
          <TabPane tab="Report Waiting" key="2">
            <PatientSearch
              docFilterItems={doctors}
              searchSelect={searchParam}
              onSearch={onSearch}
              filters={["Appointment Status"]}
              tabType={"reportWaiting"}
              {...{
                searchByItem,
                onInputChange,
                onSelectChange,
                onDocFilterSubmit,
                searchString,
                onClear,
                onFilterSubmit,
                onDateRangeFilter,
                dateRangeFilter,
                filterParams,
                docFilterParam,
              }}
            />
            <PatientsTable
              columns={reportWaitingRadiologyColumns(
                history,
                pageNo,
                pageSize,
                StyledButton,
                theme
              )}
              loading={reportWaitingLoading}
              dataSource={reportWaitingData}
              pageSize={pageSize}
              setPageNo={setPageNo}
              pageNo={pageNo}
              total={reportWaitingTotal}
            />
          </TabPane>
        )}
        {hasPrivilege("VIEW_PATIENT") && (
          <TabPane tab="Test History" key="3">
            <PatientSearch
              docFilterItems={doctors}
              searchSelect={searchParam}
              onSearch={onSearch}
              filters={["Appointment Status"]}
              tabType={"testHistory"}
              {...{
                searchByItem,
                onInputChange,
                onSelectChange,
                onDocFilterSubmit,
                searchString,
                onClear,
                onFilterSubmit,
                onDateRangeFilter,
                dateRangeFilter,
                filterParams,
                docFilterParam,
              }}
            />
            <PatientsTable
              columns={testHistoryRadiologyColumns(
                history,
                pageNo,
                pageSize,
                StyledButton,
                theme
              )}
              loading={historyLoading}
              dataSource={historyData}
              pageSize={pageSize}
              setPageNo={setPageNo}
              pageNo={pageNo}
              total={historyTotal}
            />
          </TabPane>
        )}
      </StyledTabs>
      <IntimeModal
        visible={inTimeModalVisible}
        setVisible={setIntimeModalVisible}
        id={selectedAppointmentId}
        refetch={() => {
          fetchActiveRadiology(defaultParams);
        }}
      />
    </>
  );
};

export default RadiologyDashboard;
