import axiosInstance from "../config/axiosInstance";
const HL7_PREFIX = "/hl7";
const HL7_STATUS_PREFIX = "/hl7-status";

export const fetchHL7Listeners = () => {
  return axiosInstance.get(`${HL7_PREFIX}/listeners`);
};
export const createListener = (data) => {
  return axiosInstance.post(`${HL7_PREFIX}/add-listener`, data);
};
export const deleteListener = (id) => {
  console.log("called the delete api", id);
  return axiosInstance.delete(`${HL7_PREFIX}/delete?listenerId=${id}`);
};
export const updateListener = (data) => {
  return axiosInstance.put(`${HL7_PREFIX}/update-listener`, data);
};
export const fetchHL7ManageStatus = (data) => {
  return axiosInstance.get(`${HL7_STATUS_PREFIX}/manage-status?${data}`);
};
export const HL7Resend = (id) => {
  return axiosInstance.get(`${HL7_STATUS_PREFIX}/resend?ids=${id}`);
};
