import React, { useEffect } from 'react';
import { Row, Col, message, Button } from 'antd';
import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from '../styledComponents';
import { BodyContainer } from '../Sections/styledComponents';
import { useState } from 'react';
import { LeftCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CreateConsentForm from './CreateConsentForm';
import ConsentFormTable from './ConsentFormTable';
import { getHospitalId } from '../../../utils/LocalStorage';
import moment from 'moment';
import {
  addConsentForm,
  deleteConsentForm,
  editConsentForm,
  viewConsentForms,
} from '../../../../apis/ConsentForms';
import { getDepartments } from '../../../../apis/Appointment';
import ConfirmationModal from './ConfirmationModal';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

export const NewUserButton = styled(Button)`
  height: 40px;
  min-width: 150px;
  font-size: 17px;
  border-radius: 5px !important;
`;

const ConsentFormManagement = () => {
  const [selected, setSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [createConsentFormModal, setCreateConsentFormModal] = useState(false);
  const [trigger, setTrigger] = useState(true);

  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [forms, setForms] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const theme = useSelector((state) => state.themeReducer);

  useEffect(() => {
    viewConsentForms('')
      .then(({ data }) => {
        console.log('consentform', data);
        if (data.status === 1) {
          let forms = data.body;
          if (forms.length > 0) {
            forms.forEach((form) => {
              form.date = moment(form.date).format('DD-MM-YYYY');
            });
          }
          setForms(forms);
          setLoading(false);
        } else {
          setLoading(false);
          message.error('Could not fetch forms, Try again');
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, [trigger]);

  useEffect(() => {
    getDepartments(getHospitalId())
      .then((response) => {
        if (response.data.status === 1) {
          console.log('response is', response.data.body);
          setDepartments(response.data.body);
        } else {
          message.error('Could not fetch departments, Try Again');
        }
      })
      .catch((err) => {
        message.error('Could not fetch departments, Try Again');
      });
  }, []);

  const addNewConsentForm = (values) => {
    setSubmitLoading(true);
    addConsentForm(values.departmentId, values.name, values.file)
      .then((resp) => {
        if (resp.data.status === 1) {
          setTrigger(!trigger);
          message.success('Successfully added new Consent Form');
          setSubmitLoading(false);
          setCreateConsentFormModal(false);
        } else {
          message.error('Could not add new Consent Form, Try Again');
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        message.error('Could not add new Consent Form, Try Again');
        setSubmitLoading(false);
      });
  };

  const updateConsentForm = (values) => {
    setSubmitLoading(true);
    editConsentForm(
      selectedItem.id,
      values.departmentId,
      values.name,
      values.file
    )
      .then((resp) => {
        if (resp.data.status === 1) {
          setTrigger(!trigger);
          setSelectedItem({});
          message.success('Successfully Updated Consent Form');
          setSubmitLoading(false);
          setEditModal(false);
          setCreateConsentFormModal(false);
        } else {
          message.error('Could not Update Consent Form, Try Again');
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        message.error('Could not Update Consent Form, Try Again');
        setSubmitLoading(false);
      });
    setCreateConsentFormModal(false);
  };

  const removeConsentForm = () => {
    setDeleteLoading(true);
    deleteConsentForm(selectedItem.id)
      .then((resp) => {
        if (resp.data.status === 1) {
          setTrigger(!trigger);
          setDeleteLoading(false);
          message.success('Successfully Deleted Consent Form');
          setDeleteModal(false);
        } else {
          message.error('Could not deleteform, Try Again');
          setDeleteLoading(false);
        }
      })
      .catch((err) => {
        message.error('Could not deleteform, Try Again');
        setDeleteLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between">
              {/* <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}> */}
              <Col>
                <SectionHeading>
                  {selected && (
                    <LeftCircleOutlined
                      onClick={() => {
                        setSelected(false);
                        setSelectedId(null);
                      }}
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  Manage Consent Form
                </SectionHeading>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'end' }}>
                <NewUserButton
                  type="primary"
                  // ghost
                  // size="large"
                  onClick={() => setCreateConsentFormModal(true)}
                >
                  Create Consent Form
                </NewUserButton>
              </Col>
            </Row>
          </SectionHeadContainer>
        </Col>
        <Col span={24}>
          <BodyContainer>
            <StyledDiv bgcolor={theme['@primary-color']}>
              <div>&nbsp;&nbsp;Consent Form</div>
              <div>&nbsp;&nbsp;</div>
            </StyledDiv>
            <ConsentFormTable
              loading={loading}
              setEditModal={setEditModal}
              setDeleteModal={setDeleteModal}
              setSelectedItem={setSelectedItem}
              forms={forms}
            />
          </BodyContainer>
        </Col>
      </Row>
      <CreateConsentForm
        visible={editModal ? editModal : createConsentFormModal}
        setVistible={editModal ? setEditModal : setCreateConsentFormModal}
        trigger={trigger}
        setTrigger={setTrigger}
        departments={departments}
        addConsentForm={addNewConsentForm}
        updateConsentForm={updateConsentForm}
        loading={submitLoading}
        setEditModal={setEditModal}
        edit={editModal}
        selectedItem={selectedItem}
      />

      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        deleteForm={removeConsentForm}
        loading={deleteLoading}
      />
    </>
  );
};

export default ConsentFormManagement;
