import React from "react";
import { useState } from "react";
import SectionManagement from "./SectionManagement";
import FormManagement from "./FormManagement";
import FieldManamgement from "./FieldManagement";
const Sections = () => {
  const [tab, setTab] = useState("sections");
  const [selectedSection, setSelectedSection] = useState();
  const [totalSections, setTotalSections] = useState(0);
  const [totalForms, setTotalForms] = useState(0);
  const [selectedForm, setSelectedForm] = useState();
  switch (tab) {
    case "sections":
      return (
        <SectionManagement
          setTab={setTab}
          setSelectedSection={setSelectedSection}
          setTotalSections={setTotalSections}
          totalSections={totalSections}
          selectedSection={selectedSection}
        />
      );
    case "forms":
      return (
        <FormManagement
          setTab={setTab}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          setSelectedForm={setSelectedForm}
          totalForms={totalForms}
          setTotalForms={setTotalForms}
          selectedForm={selectedForm}
        />
      );
    case "form_fields":
      return (
        <FieldManamgement
          setTab={setTab}
          selectedForm={selectedForm}
          selectedSection={selectedSection}
          totalForms={totalForms}
        />
      );
    default:
      return <SectionManagement />;
  }
};
export default Sections;
