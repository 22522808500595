import React, { useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Table,
  Image,
  Card,
  Tooltip,
  Empty,
  Spin,
} from "antd";
import styled from "styled-components";
import {
  CloseCircleOutlined,
  FileTextOutlined,
  UpCircleFilled,
  DownCircleFilled,
  FileImageOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { UserProfileHead } from "../../../Content/Appoinment/userProfileHead";
import { Slide, Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../../../utils/Spinner";
// import { CustomTableForRetest } from "./customTable"
// import { columns } from "./columns";

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
    /* min-width: 50vw; */
  }
  &.pop-modal {
    max-width: 100% !important;
  }
  &.pop-modal .ant-modal-body .ant-table .top-right-header {
    border-radius: 0 10px 0 0 !important;
  }
  &.pop-modal .ant-modal-body .ant-table .top-left-header {
    border-radius: 10px 0 0 0 !important;
  }
  &.pop-modal .ant-modal-body {
    padding: 30px 30px 30px 45px;
    /* width: 40vw; */
  }
  & .ant-table {
    line-height: 1;
  }
  & .ant-table-small .ant-table-thead > tr > th {
    background: ${(props) => props.color};
    font-weight: bold;
    color: white;
    padding: 13px 0;
  }
  /* & .ant-table-small .ant-table-thead .ant-table-row:hover {
      background: red !important;
  } */
  /* &.pop-modal .ant-modal-body .ant-table .table-row-retest:hover{
    background: ${(props) => props.color} !important;
    opacity: 0.25;
    border-radius: 0 0 0 0 !important;
  } */
  & .ant-table-small .ant-table-thead > tr {
    background: ${(props) => props.color};
    height: 30px;
    border-radius: 10px 10px 0 0;
  }
  & .ant-table > thead > tr:hover {
    background: red !important;
  }
  & .ant-table-small .ant-table-thead {
    border-radius: 10px 10px 0 0;
    height: 30px;
  }
  &.ant-modal {
    top: 20px;
    width: 50vw !important;
    padding: 22px 15px;
  }
  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    font-size: 12px;
    text-transform: capitalize;
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }

  .popup-form-data .ant-col label {
    color: #8e8c8c;
    font-size: 15px;
  }
  .popup-form-data .ant-col label:before {
    display: none;
  }
  .popup-form-data .ant-col label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight: 700;
    content: "*";
  }
  .popup-form-data .ant-col .ant-input {
    border-radius: 5px;
    border: 1px solid #cccccc;
  }
  .popup-form-data .ant-col textarea {
    resize: none;
  }
  button.submit-btn {
    height: 44px;
    font-weight: 600;
    letter-spacing: 0.02em;
    background-color: #0f70bc !important;
    border: 1px solid transparent;
    width: 100%;
    font-size: 18px;
    width: 100px;
    border-radius: 5px;
  }
  button.submit-btn.w-100 {
    width: 100%;
  }
  .btn-send-bx {
    margin: 0px;
  }
  .cover-width {
    margin: 20px 20px;
  }
  .cover-width h2 {
    text-align: center;
  }

  @media (max-width: 767px) {
    .popup-box-content {
      width: 100% !important;
    }
  }
  @media (max-width: 600px) {
    .cover-width {
      margin: 20px 0px;
    }
  }
`;

const EmptyMessageContainer = styled.div`
  height: calc(100vh - 450px);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
`;

const StyledDiv = styled.div`
  cursor: pointer;
  border-radius: 10px;
  border: ${(props) =>
    props.selected ? `3px solid ${props.color}` : "1px solid rgba(0,0,0,0.25)"};
`;
const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

const CustomModalForRetest = ({
  visible,
  setVisible,
  data,
  patientId,
  patientName,
  dataSource,
  setDataSource,
  retestSelected,
  isLoading,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  const [columnsRetest, setColumnsRetest] = useState();

  useEffect(() => {
    setColumnsRetest([
      {
        width: "5vw",
        align: "center",
        className: "top-left-header",
        title: "Sl. No",
        render: (value, record, index) => {
          return index + 1;
        },
      },
      {
        title: "Type",
        dataIndex: "Type",
        key: "type",
        width: "5vw",
        align: "center",
      },
      {
        title: "Common Test",
        dataIndex: "Common Test",
        key: "commonTest",
        width: "10vw",
        align: "center",
      },
      {
        title: "Test Name",
        dataIndex: "Test Name",
        key: "testName",
        width: "8vw",
        align: "center",
      },
      {
        render: (value, record, index) => {
          return (
            <Row justify="start">
              <p
                style={{
                  display: "contents",
                  color: theme["@primary-color"],
                  marginRight: "3vw",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDataSource(dataSource.filter((item, i) => i !== index));
                }}
              >
                Remove
              </p>
            </Row>
          );
        },
        width: "8vw",
        align: "center",
        className: "top-right-header",
      },
    ]);
  }, [dataSource]);

  useEffect(() => {}, [visible]);

  return (
    <StyledModal
      visible={visible}
      closable={false}
      footer={null}
      color={theme["@primary-color"]}
      destroyOnClose={true}
      className="pop-modal"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Row justify="space-between" style={{ height: "25px" }}>
            <h3>
              <b>Retest</b>
            </h3>
            <Col>
              <CloseCircleOutlined
                style={{
                  fontSize: "20px",
                  color: theme["@primary-color"],
                  position: "absolute",
                  right: "-7px",
                  top: "-7px",
                }}
                onClick={() => {
                  setVisible(false);
                }}
              />
            </Col>
          </Row>
          <Row>&nbsp;</Row>
          <Row>Do you want to Redo these tests?</Row>
          <Row>&nbsp;</Row>
          <Row justify="center">
            <Col>
              {dataSource?.length > 0 ? (
                <Table
                  // ref={componentRef}
                  columns={columnsRetest}
                  dataSource={dataSource}
                  pagination={false}
                  size={"small"}
                  tableLayout={"fixed"}
                  rowClassName="table-row-retest"
                />
              ) : (
                <EmptyMessageContainer>
                  <Empty />
                </EmptyMessageContainer>
              )}
            </Col>
          </Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row gutter={24} justify="end" style={{ margin: "20px 0" }}>
            <Button
              style={{ padding: "0 10px", borderRadius: "5px", height: "45px" }}
              // className="btn-cancel"
              type="primary"
              size="large"
              ghost
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </Button>
            <RightButton
              className="btn-save"
              style={{ borderRadius: "5px", height: "45px" }}
              type="primary"
              size="large"
              disabled={dataSource?.length > 0 ? false : true}
              // htmlType="submit"
              onClick={() => retestSelected()}
            >
              Retest
            </RightButton>
            <Col span={2}></Col>
          </Row>
        </>
      )}
    </StyledModal>
  );
};

export default CustomModalForRetest;
