module.exports = {
  ADD_PATIENT: "ADD_PATIENT",
  ADD_DOCTOR: "ADD_DOCTOR",
  MODIFY_FORM: "MODIFY_FORM",
  ADD_FORM: "ADD_FORM",
  UPDATE_HEALTH_RECORD: "UPDATE_HEALTH_RECORD",
  SCHEDULE_APPOINTMENT: "SCHEDULE_APPOINTMENT",
  VIEW_PATIENT: "VIEW_PATIENT",
  UPDATE_PATIENT: "UPDATE_PATIENT",
  VIEW_FORM: "VIEW_FORM",
};
