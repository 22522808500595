import axiosInstance from "../config/axiosInstance";
const USER_PREFIX = "/user";
export const uploadImage = async (id = null, fileBlob) => {
  const formData = new FormData();
  formData.append("imageFile", fileBlob, fileBlob.name);
  return axiosInstance.post(
    USER_PREFIX + `/upload-image${id ? "/" + id : ""}`,
    formData,
    { "content-type": "multipart/form-data" }
  );
};