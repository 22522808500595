import axiosInstance from "../config/axiosInstance";
const USER_PREFIX = "/patient";
export const uploadPdfOrImage = async (id = null, fileBlob) => {
  const formData = new FormData();
  formData.append("files", fileBlob, fileBlob.name);
  return axiosInstance.post(
    USER_PREFIX + `/upload-medical-document${id ? "/" + id : ""}`,
    formData,
    { "content-type": "multipart/form-data" }
  );
};

export const deleteUploadedFile = async (url) => {
  return axiosInstance.delete(
    USER_PREFIX + `/delete-uploaded-document?url=${url}`
  );
};
