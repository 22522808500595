import { Col, Row } from "antd";
import moment from "moment";
import React from "react";

export const billHospitalDataColumns = () => [
  { width: "1vw" },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "3vw",
  },
  {
    title: "Appointment Type",
    dataIndex: ["appointment", "appointmentType", "name"],
  },
  {
    title: "Time",
    dataIndex: ["appointment", "inTimeDoc"],
  },
  { title: "Visit Type", dataIndex: "Visit Type" },
  {
    title: "Bill Amount",
    width: "30vw",
    render: (item) => (
      <div style={{ fontSize: "12px" }}>
        <Row>
          <Col span={14}>Fee </Col>
          <Col>
            : {item.Currency} {item?.appointment?.bill.fee}
          </Col>
        </Row>
        <Row>
          <Col span={14}>Insurance Claim </Col>
          <Col>
            : {item.Currency} {item?.appointment?.bill.insuranceClaim}
          </Col>
        </Row>
        <Row>
          <Col span={14}>VAT({item?.appointment?.bill.vatPercentage}%) </Col>
          <Col>
            : {item.Currency}{" "}
            {(item?.appointment?.bill.fee -
              item?.appointment?.bill.insuranceClaim) *
              (item?.appointment?.bill.vatPercentage / 100)}
          </Col>
        </Row>
        <Row>
          <Col span={14} className="total">
            Total{" "}
          </Col>
          <Col className="total">
            : {item.Currency} {item?.appointment?.bill.totalFee}
          </Col>
        </Row>
      </div>
    ),
  },
];

export const billPharmacyDataColumns = (settings) => [
  { width: "1vw" },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "3vw",
  },
  {
    dataIndex: "Date",
    title: "Date",
    // render: (value) => moment(value).format("DD-MM-YYYY")
  },
  {
    dataIndex: "No of Days",
    title: "No of Days",
  },
  {
    dataIndex: "Dose",
    title: "Dose",
  },
  {
    dataIndex: "Drugs/ Generic code",
    title: "Drugs/ Generic code",
  },
  {
    dataIndex: "Frequency",
    title: "Frequency",
  },
  {
    dataIndex: "Notes",
    title: "Notes",
  },
  {
    title: "Dispense",
    dataIndex: ["additionalInfo", "dispenseQuantity"],
  },
  {
    title: "Fee",
    dataIndex: ["drugInfo", "sellingPrice"],
    render: (value) => (
      <p style={{ width: "max-content" }}>
        {settings?.currency + " " + parseFloat(value ? value : 0).toFixed(2)}
      </p>
    ),
  },
];

export const billLabOrRadiologyDataColumns = (settings) => [
  { width: "1vw" },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "3vw",
  },
  {
    dataIndex: "department",
    title: "Department",
  },
  {
    title: "Order",
    dataIndex: "orders",
    render: (value) => <p style={{ textTransform: "capitalize" }}>{value}</p>,
  },
  {
    title: "Fee",
    dataIndex: "fee",
    render: (value) => (
      <p style={{ width: "max-content" }}>
        {settings?.currency + " " + parseFloat(value ? value : 0).toFixed(2)}
      </p>
    ),
  },
];
