import axiosInstance from '../config/axiosInstance'
import axios from 'axios'

const APPOINTMENT_PREFIX = '/appointment'

export const baseUrl = process.env.REACT_APP_API_BASE_URL
// export const baseUrl = process.env.REACT_APP_STAGING_API_BASE_URL;
export const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return baseUrl
    default:
      return process.env.REACT_APP_STAGING_API_BASE_URL
  }
}
export const getRevenueCount = (hospitaId, startDate, endDate) => {
  return axiosInstance.get(
    `${APPOINTMENT_PREFIX}/countRevenueofConsultation?&startDate=${startDate}&endDate=${endDate}&hospitalId=${hospitaId}`,
  )
}
