import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Hospitalname = styled.h2`
  color: black;
  text-transform: capitalize;
  font-size: 32px;
`;

export const ExaminationsPrintContainer = styled.div`
  background: #fff;
  padding: 10px 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 1500px;

  @media (max-width: 600px) {
    padding-top: 15px !important;
  }
`;

export const StyledRow = styled(Row)`
  font-weight: 600;
  font-size: 20px;
  color: rgb(93, 93, 93);
  margin: 0;
`;

export const StyledP = styled.p`
  display: contents;
  font-size: 20px;
  font-weight: normal;
`;

export const ScratchPadDiv = styled.div`
  margin-top: 15px;
  margin-right: 10px;
`;
