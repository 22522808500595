import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";

const ModalBodyContainer = styled.div`
  width: 500px;
`;
const ConfirmationModal = ({ visible, setVistible, onConfirm,loading }) => {
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="success"
              title="Save new privileges?"
              description="By clicking save, The user privileges will be saved."
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVistible(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                onConfirm();
                setVistible(false);
              }}
              loading={loading}
            >
              Save
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default ConfirmationModal;