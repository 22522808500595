import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EmptyMessageContainer } from "../Sections/styledComponents";
import { tableColumns } from "./tableColumns";
import { Button, Empty, Table } from "antd";
import CustomeTableForRquests from "../../HealthRecords/Laboratory/customTable";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { BlueBgTable, StyledDiv } from "../styledComponents";
import { getSettings } from "../../../../apis/settings";

const StyledTable = styled(Table)`
  & .ant-table-tbody > tr > td {
    font-size: 13px !important;
    /* padding: 10px 14px !important; */
    overflow-wrap: normal;
    color: #6d6d6d !important;
    border-color: rgba(0, 0, 0, 0);
  }
  & .ant-table-thead > tr > th {
    font-size: 15px !important;
    /* padding-left: 30px !important; */
    /* padding: 0 0 !important; */
    overflow-wrap: normal;
    font-weight: 600;
    color: #5d5d5d !important;
  }
`;

const DrugsTable = ({
  dataSource,
  pageSize,
  setPageNo,
  total,
  setEditModal,
  setDeleteModal,
  setSelectedItem,
  pageNo,
  loading,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  const [settings, setSettings] = useState(false);

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <Button>Previous</Button>;
    }
    if (type === "next") {
      return <Button>Next</Button>;
    }
    return originalElement;
  };

  useEffect(() => {
    getSettings().then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setSettings(data.body);
        }
      }
    });
  }, []);

  return dataSource.length > 0 ? (
    <div>
      <StyledDiv bgcolor={theme["@primary-color"]}>
        <div>&nbsp;&nbsp;All Drugs</div>
        <div>&nbsp;&nbsp;</div>
      </StyledDiv>
      <BlueBgTable
        loading={loading}
        columns={tableColumns(
          setEditModal,
          setDeleteModal,
          setSelectedItem,
          pageNo,
          settings
        )}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
        // headBg={theme["@primary-color"]}
        pagination={{
          onChange: (cur) => {
            setPageNo(cur - 1);
          },
          pageSize: pageSize,
          total: total,
          showSizeChanger: false,
          current: pageNo + 1,
          itemRender: itemRender,
        }}
      />
    </div>
  ) : (
    <EmptyMessageContainer>
      <Empty />
    </EmptyMessageContainer>
  );
};

export default DrugsTable;
