import React from "react";
import { Button, Table } from "antd";
import styled from "styled-components";

export const NewButton = styled(Button)`
  height: 40px;
  /* width: 200px; */
  font-size: 17px;
  border-radius: 5px !important;
`;

export const StyledDiv = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.bgcolor};
  font-size: 16px !important;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  /* margin-top: 20px; */
  font-weight: 600;
  height: 40px;
  border-radius: 10px 10px 0 0;
`;

export const BlueBgTable = styled(Table)`
  /* & .ant-table-thead > tr > th, */
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    /* position: relative; */
    padding: 8px 0;
    overflow-wrap: break-word;
    margin: 0px;
  }
  & .ant-table-thead > tr > th {
    padding: 8px 0 !important;
    font-weight: 600;
  }
`;

export const EmptyMessageContainer = styled.div`
height: calc(100vh - 450px);
display: flex;
align-content: center;
align-items: center;
justify-content: center;
justify-items: center;
background: white;
`;