import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import {
  getHealthRecordForms,
  getPreviousRecords,
  addHealthRecord,
  getSectionData,
} from '../../../../apis/healthRecord';
import { Row, Col, Button, message, Form } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import TableContainer from '../TableContainer';
import {
  generateFormFields,
  deserialize,
} from '../../../utils/FormBuilder/valuesSerialisation';
import FormBuilder from '../../../utils/FormBuilder';
import ViewDetailsModal from '../ViewDetailsModal';
import { fetchFormFieldMeta } from '../../../../apis/forms';
import { useCallback } from 'react';
import Spinner from '../../../utils/Spinner';
import { bmiCalculator, softValidate } from '../helper';

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: 767px) {
    &.px-6-form {
      padding: 20px 14px;
    }
  }
`;

export const DiscardSubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledForm = styled(Form)`
  &
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #6d6d6d;
    background: #fff;
    cursor: not-allowed;
  }

  & .ant-input[disabled] {
    color: #6d6d6d;
    cursor: not-allowed;
    opacity: 1;
  }

  &.form-m2-style h3 {
    margin: 15px 0 25px;
    font-size: 20px;
  }
  &.form-m2-style .ant-form-item-label label {
    font-size: 16px;
    color: #818181;
  }
  &.form-m2-style .ant-form-item-label label:before {
    display: none;
  }
  &.form-m2-style .ant-form-item-label label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff0003;
    font-size: 18px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    font-weight: 700;
  }
  &.form-m2-style .ant-form-item-control-input-content .ant-input,
  &.form-m2-style .ant-form-item-control-input-content .ant-select-selector,
  &.form-m2-style .ant-form-item-control-input-content .ant-picker {
    height: 60px;
    border: 1px solid #d6d6d6;
    border-radius: 5px !important;
    font-size: 18px;
    color: #333333;
  }
  &.form-m2-style .ant-form-item-control-input-content .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  &.appointment_form_style1.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select
    + .ant-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select:hover
    .ant-select-selector {
    border: 1px solid #d6d6d6;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-search-input,
  &.form-m2-style .ant-form-item-control-input-content .ant-input-number-input {
    height: 60px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-placeholder,
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-item {
    line-height: 56px !important;
  }
  &.form-m2-style .ant-form-item-explain div {
    font-size: 12px;
    /* text-transform: capitalize; */
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }
  &.form-m2-style
    .ant-form-item-has-error
    .ant-select:not(.ant-select-borderless)
    .ant-select-selector {
    border-color: #d6d6d6 !important;
  }
  &.form-m2-style .ant-btn-background-ghost.ant-btn-danger {
    padding: 3px 10px;
    height: auto;
    font-weight: 600;
  }
  &.form-m2-style .add-btn {
    padding: 9px 10px;
    height: auto;
    font-weight: 600;
    margin: 12px 0 0;
  }
  &.form-check-tag {
    margin: 5px 0 25px;
  }
  &.form-check-tag span {
    font-size: 18px;
  }
  &.form-check-tag .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .btn-save,
  .btn-cancel {
    min-width: 120px;
    font-size: 18px;
    height: 50px;
    border: 2px solid #26b1ff;
    padding: 0px 10px;
    font-weight: 500;
    border-radius: 6px;
  }
  @media (max-width: 1100px) {
    &.form-m2-style .ant-form-item-label label {
      font-size: 14px;
    }
    &.form-m2-style .ant-form-item-control-input-content .ant-input,
    &.form-m2-style .ant-form-item-control-input-content .ant-select-selector,
    &.form-m2-style .ant-form-item-control-input-content .ant-picker,
    &.form-m2-style .ant-form-item-control-input-content .ant-input-number {
      height: 50px;
      font-size: 15px;
    }
    &.form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-search-input {
      height: 50px !important;
    }
    &.form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-placeholder,
    &.form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-item {
      line-height: 46px !important;
    }
    .form-check-tag span {
      font-size: 15px;
    }
    &.form-m2-style h3 {
      margin: 12px 0 24px;
      font-size: 18px;
    }
    h2.h2-base-600 {
      font-size: 20px;
    }
    &.form-m2-style .forminput_timepick {
      height: 50px;
      border-radius: 5px;
    }
    .available_time_field .ant-form-item-control-input,
    &.form-m2-style
      .ant-form-item-control-input-content
      .ant-input-number-input {
      height: 50px !important;
      border-radius: 5px;
    }
  }
  @media (max-width: 600px) {
    &.form-m2-style h3 {
      margin: 0px 0 16px;
    }
  }
`;

const columns = (setSelected, setVisible) => [
  {
    width: 15,
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'id',
    width: '150px',
    render: (value) => moment(value).format('DD-MM-YYYY'),
  },
  {
    title: 'Vital Informations',
    dataIndex: 'vitalsInformation',
    key: 'vital',
    render: (values) => (
      <Button
        size="small"
        type="primary"
        ghost
        onClick={() => {
          setSelected(values);
          setVisible(true);
        }}
      >
        View Details
      </Button>
    ),
  },
];

const VitalInformation = () => {
  const { sectionId } = useParams();
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedVitalInformation, setSelectedVitalInformation] = useState({});
  const patient = useSelector((state) => state.patientReducer);
  const user = useSelector((state) => state.userReducer);
  const section = useSelector((state) => state.sectionReducer);
  const [visible, setVisible] = useState(false);
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [codeTypeValues, setCodeTypeValues] = useState([]);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [tableFetchLoading, setTableFetchLoading] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  console.log('codeeType is', codeTypeValues);
  const [form] = Form.useForm();
  const getPreviousData = () => {
    getSectionData(
      section.sectionId,
      user.useType == 'PATIENT' ? user.id : patient.pId,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status == 200) {
          if (data.status == 1) {
            console.log('patient data', data);
            let initialValues = {};
            data.body.forEach((item) => {
              item.fields.forEach((item) => {
                initialValues[item.fieldId] = item.value;
              });
            });
            setFormInitialValues(initialValues);
          }
        }
      })
      .catch(console.err);
  };
  const getForms = useCallback(() => {
    var isData = false;
    getHealthRecordForms(sectionId, patient.pId)
      .then(({ data, status }) => {
        console.log('gethealthrecord', data);
        if (status === 200) {
          if (data.status === 1) {
            const tempFormIds = {};
            var tempMetaDataIds = [];
            var tempParentIds = [];
            var validationList = [];
            var codeTypeList = [];
            isData = data.body[0].date ? true : false;
            data.body.forEach((item) => {
              //getting formIds and corresponding field IDs for submit request Generation
              tempFormIds[item.id] = item.fields.map((field) => field.id);

              //finding fields which needs to call external API for fetching values eg:dropdown
              tempMetaDataIds = [
                ...tempMetaDataIds,
                ...item.fields.filter(
                  (field) =>
                    field.dropDownType !== null &&
                    field.dropDownType !== undefined &&
                    Object.keys(field.dropDownType).length !== 0
                ),
              ];
              //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
              tempParentIds = [
                ...tempParentIds,
                ...item.fields.filter(
                  (field) =>
                    field.parentField !== null &&
                    field.parentField !== undefined
                ),
              ];
              //COLLECTING FIELDS HAVING SOFT VALIDATORS
              validationList = [
                ...validationList,
                ...item.fields
                  .filter(
                    (field) =>
                      field.softValidator !== null &&
                      field.softValidator !== undefined &&
                      field.softValidator.length !== 0
                  )
                  .map((item) => {
                    return {
                      fieldId: item.id,
                      validationCrieteria: item.softValidator.map((val) => ({
                        type: val.validator,
                        value: val.value,
                      })),
                    };
                  }),
              ];
              //Collecting fields having Code Types
              codeTypeList = [
                ...codeTypeList,
                ...item.fields.filter(
                  (field) =>
                    field.codeType !== null && field.codeType !== undefined
                ),
              ];
            });

            //converting forms into formBuilder readable format
            let FormsTemp = data.body.map((item) => {
              return {
                formName: item.formName,
                fields: generateFormFields(item.fields).map((field) => ({
                  ...field,
                  disabled:
                    user.userType === 'PATIENT' ||
                    field.codetype == 'BMI' ||
                    field.codetype == 'BMI_STATUS'
                      ? true
                      : false,
                })),
              };
            });
            setFormIds(tempFormIds);
            setMetaDataFieldIds(tempMetaDataIds);
            setForms(FormsTemp);
            setValidationCriterias(validationList);
            setCodeTypeValues(codeTypeList);
            setFormFetchLoading(false);
            setDropDownParentIds(tempParentIds);
          } else {
            message.error(data.body);
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  }, [sectionId]);
  const getTableData = () => {
    getPreviousRecords(sectionId, patient.pId, patient.appointmentId)
      .then(({ data, status }) => {
        if (status === 200) {
          console.log('table datas', data);
          if (data.status === 1) {
            setTableData(data.body);
            setTableFetchLoading(false);
          } else {
            // message.error("No Record");
            setTableFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setTableFetchLoading(false);
      });
  };

  const submitFormData = (dynamicFormData) => {
    console.log('data', dynamicFormData);

    setSubmitLoading(true);
    addHealthRecord(
      sectionId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            message.success('Health Record added');
            getTableData();
            setFormInitialValues(form.getFieldsValue());
            // form.resetFields();
          } else {
            message.error("couldn't add Vital Informations!");
          }
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const formSubmitHandler = (values) => {
    let data = Object.keys(formIds).map((key) => {
      const filtered = {};
      formIds[key].forEach((k) => {
        filtered[k] = values[k];
      });
      return { formId: key, fields: deserialize(filtered) };
    });
    submitFormData(data);
  };

  const onClear = () => {
    // setFormInitialValues({});
    form.resetFields();
    setFormFetchLoading(true);
    setValidationStatus({});
    getForms();
    getTableData();
    getPreviousData();
  };

  useEffect(() => {
    setTableFetchLoading(true);
    setFormFetchLoading(true);
    getForms();
    getTableData();
    getPreviousData();
  }, []);
  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        return fetchFormFieldMeta(
          item.dropDownType.dropDownType,
          item.sectionId
        );
      });
      let tempDropDownValues = [...dropDownValues];

      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status == 200) {
              if (data.status == 1)
                tempDropDownValues = [...tempDropDownValues, ...data.body];
              //Injecting meta data for corresponding  formfields
              forms.forEach((formItem) => {
                let metaField = formItem.fields.find(
                  (field) => field.name === metaDataFieldIds[index].id
                );
                if (metaField) {
                  // setDropDownValues([...dropDownValues, ...data.body]);
                  metaField.meta = data.body.map((item) => item.value);
                } else console.log('Meta undefined');
                if (form.getFieldValue(metaField?.name) == undefined) {
                  if (metaField?.label == 'Posture') {
                    form.setFieldsValue({
                      [metaField?.name]: 'Sitting',
                    });
                  }
                }
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setMetaDataFieldIds([]);
          setDropDownValues(tempDropDownValues);
          setForms([...forms]);
        });
    }
  }, [metaDataFieldIds, forms]);

  const onFormChangeHandler = (changed, allValues) => {
    console.log('allvalues', allValues);
    //Calculation of BMI
    let fieldId = Object.keys(changed)[0];
    console.log('field id', fieldId);
    if (fieldId) {
      let height, weight, bmi, bmiStatus;
      height = codeTypeValues.find((item) => item.codeType == 'HEIGHT');
      weight = codeTypeValues.find((item) => item.codeType == 'WEIGHT');
      bmi = codeTypeValues.find((item) => item.codeType == 'BMI');
      bmiStatus = codeTypeValues.find((item) => item.codeType == 'BMI_STATUS');
      if (fieldId == height?.id) {
        let weightValue = allValues[weight.id];
        let heightValue = allValues[height.id];
        if (weightValue) {
          let bmiValue = bmiCalculator(changed[height.id], weightValue);
          form.setFieldsValue({ [bmi.id]: bmiValue[0] });
          form.setFieldsValue({ [bmiStatus.id]: bmiValue[1] });
        }
        if (!heightValue) {
          form.resetFields([bmi.id]);
          form.resetFields([bmiStatus.id]);
        }
      } else if (fieldId == weight?.id) {
        let heightValue = allValues[height.id];
        let weightValue = allValues[weight.id];
        if (heightValue) {
          let bmiValue = bmiCalculator(heightValue, changed[weight.id]);
          form.setFieldsValue({ [bmi.id]: bmiValue[0] });
          form.setFieldsValue({ [bmiStatus.id]: bmiValue[1] });
        }
        if (!weightValue) {
          form.resetFields([bmi.id]);
          form.resetFields([bmiStatus.id]);
        }
      }
    }
    Object.entries(changed).map(([key, value]) => {
      let changedField = dropDownParentIds.find(
        (item) => item.parentField == key
      );
      if (changedField) {
        let dropDownItem = dropDownValues.filter((item) => item.value == value);

        if (dropDownItem[dropDownItem.length - 1]) {
          changedField.sectionId = dropDownItem[dropDownItem.length - 1].id;

          setMetaDataFieldIds([changedField]);
        }
      }
    });
    Object.keys(changed).forEach((chngd) => {
      let validator = validationCriterias.find((item) => {
        return item.fieldId == chngd;
      });

      if (validator) {
        validationStatus[chngd] = softValidate(validator, changed[chngd]);
      }
    });

    setValidationStatus({ ...validationStatus });
  };

  useEffect(() => {
    onFormChangeHandler(formInitialValues);
    // if (Object.keys(formInitialValues).length === 0) form.resetFields();
  }, [formInitialValues]);

  return !formFetchLoading && !tableFetchLoading ? (
    <Row gutter={[24, 12]}>
      {tableData.length > 0 && user.userType !== 'PATIENT' && (
        <Col span={24}>
          <TableContainer
            title="Previous Vital Informations"
            tableProps={{
              columns: columns(setSelectedVitalInformation, setVisible),
              dataSource: tableData,
            }}
            tableTitle="Result"
          />
        </Col>
      )}
      <Col span={24}>
        <FormContainer className="px-6-form">
          <StyledForm
            className="form-m2-style"
            layout="vertical"
            onFinish={formSubmitHandler}
            form={form}
            initialValues={formInitialValues}
            key={Object.keys(formInitialValues).length}
            onValuesChange={onFormChangeHandler}
            scrollToFirstError={true}
          >
            {forms.map((subForm) => (
              <FormBuilder
                formInstance={form}
                formConfig={[
                  {
                    inputType: 'heading',
                    label: subForm.formName,
                  },
                  ...subForm.fields,
                ]}
                softValidationFields={validationStatus}
              />
            ))}
            {user.userType !== 'PATIENT' && (
              <Form.Item>
                {forms.length !== 0 && (
                  <DiscardSubmitWrapper>
                    <Button
                      type="primary"
                      ghost
                      htmlType="submit"
                      className="btn-save"
                      onClick={onClear}
                    >
                      Discard
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}
                      className="btn-save"
                    >
                      Submit
                    </Button>
                  </DiscardSubmitWrapper>
                )}
              </Form.Item>
            )}
          </StyledForm>
          <ViewDetailsModal
            visible={visible}
            setVisible={setVisible}
            dataSource={selectedVitalInformation}
          />
        </FormContainer>
      </Col>
    </Row>
  ) : (
    <Spinner />
  );
};
export default VitalInformation;
