import React from "react";
import CustomModal from "../utils/CustomModal.jsx";
import CustomAlerts from "../utils/CustomAlerts/index.jsx";
import styled from "styled-components";
import { Col, Row } from "antd";
import FullWidthButton from "../utils/FullWidthButton/index.jsx";
const ModalBody = styled.div`
  width: 450px;
`;
const PasswordResetAlert = ({ visible, setVisible }) => {
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBody>
        <CustomAlerts
          type="success"
          title="Successfully Password Reset!"
          description="You can now use your new password to login in to your account"
        />
        <Row justify="space-around">
          <Col span={18}>
            <FullWidthButton
              size="large"
              onClick={() => {
                setVisible(false);
              }}
            >
              Login{" "}
            </FullWidthButton>
          </Col>
        </Row>
      </ModalBody>
    </CustomModal>
  );
};
export default PasswordResetAlert;
