import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, message } from 'antd';
import { loginAPI } from '../../apis/Auth';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BsTelephoneFill } from 'react-icons/bs';
import { sendOtp } from '../../apis/Otp';
import './otpform.css';

const StyledForm = styled(Form)`
  text-align: left;
  &.ant-form-vertical .ant-form-item-label > label {
    height: auto;
  }
  & .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 5px;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
`;

const SendOtpForm = ({ hId, setStatus, setOtpResponse, setPhoneNumber }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const payload = {
      phoneNumber: `+91${values.phoneNumber.trim()}`,
    };
    localStorage.setItem(
      'phone',
      JSON.stringify({
        countryCode: '+91',
        phoneNumber: values.phoneNumber.trim(),
      })
    );

    sendOtp(payload, hId)
      .then((res) => {
        setPhoneNumber(payload);
        setOtpResponse(res);
        setStatus('verify-otp');
      })
      .catch((err) => {
        message.error('Cannot send otp');
      });
  };
  //   const onFinishFailed = (errorInfo) => {
  //     console.log("Failed:", errorInfo);
  //   };

  return (
    <StyledForm
      name="phone-login-form"
      onFinish={onFinish}
      //   onFinishFailed={onFinishFailed}
      layout="vertical"
      size="large"
      scrollToFirstError={true}
      wrapperCol={{ gutter: 36 }}
    >
      <Form.Item
        label="Enter your Mobile No: to proceed further"
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: 'Enter valid phone number!',
            pattern: new RegExp(/^[0-9]{10}$/),
          },
        ]}
        style={{ marginBottom: '10px', fontWeight: '200px', color: 'black' }}
      >
        <StyledInput
          size="large"
          prefix={<BsTelephoneFill />}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          loading={loading}
          size="large"
          type="primary"
          className="btn-send"
        >
          Send OTP
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

export default SendOtpForm;
