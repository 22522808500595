import React, { useState, useEffect } from 'react';
import {
  Col,
  Form,
  Button,
  Divider,
  message,
  Radio,
  Checkbox,
  Row,
} from 'antd';
import FormBuilder from '../../../utils/FormBuilder';
import { getCountries } from '../../../../apis/publicApis';
// import { fetchFormByType, fetchFormFieldMeta } from "../../../../apis/forms";

import {
  DocumentImage,
  DocumentImages,
  RightButton,
  SectionTtile,
  StyledRow,
} from './styledComponents';
import moment from 'moment';
import {
  generateFormFields,
  deserialize,
  serialize,
  serializeInsurance,
} from '../../../utils/FormBuilder/valuesSerialisation';
import { fetchPatientForms } from '../../../../apis/patients';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Sentry from '@sentry/react';
import {
  CAPTCHA_SERCRET_KEY,
  PROFILE_IMAGE_PREFIX,
} from '../../../../constants/StringConstants';
import {
  config as personalDetailsConfig,
  documenType,
  paymentType,
  insuranceFields as insuranceTypes,
  copayOrSelf,
  addresses,
  nextOfKinType,
  staticDocumentType,
  phoneNumberType,
  deathStatus,
} from '../../PatientForm/formConfig';
import { DocumentUpload } from '../../PatientForm/DocumentUpload';
import { useSelector } from 'react-redux';
import { ImageContainer } from '../../PatientForm/styledComponents';
import { useHistory } from 'react-router-dom';
import { fetchBloodGroup } from '../../../../apis/Auth';
const PatientDetailsForm = ({
  submitLoading,
  selectedItem,
  submitHandler,
  setVisible,
  setSelectedItem,
  register,
}) => {
  const recaptchaRef = React.createRef();
  const [formConfig, setFormConfig] = useState(personalDetailsConfig);
  const [documents, setDocuments] = useState(documenType);
  const [documentUrl, setDocumentUrl] = useState();
  const [forms, setForm] = useState();
  const [dynamicDocumentTypes, setDynamicDocumentTypes] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const [formInitialValues, setFormInitialValues] = useState({});
  const history = useHistory();
  const [countries, setCountries] = useState();
  const theme = useSelector((state) => state.themeReducer);
  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res.data);
        // formConfig.find(
        //   (item) => Array.isArray(item.name) && item.name[1] === "country"
        // ).meta = res.data.map((country) => country.name);
        setFormConfig(formConfig.map((item) => ({ ...item })));
        // formConfig.find(
        //   (item) => item.name === "nationality"
        // ).meta = res.data.map((country) => country.name);
        // nextOfKinType.find(
        //   (item) => item.name === "alternateContactNo"
        // ).meta = res.data.map((country) => ({
        //   code: country.callingCodes[0],
        //   name: country.name,
        // }));
        nextOfKinType.find((item) => item.name === 'phoneNumber').meta =
          res.data.map((country) => ({
            code: country.callingCodes[0],
            name: country.name,
          }));
        phoneNumberType.find((item) => item.name === 'phoneNumber').meta =
          res.data.map((country) => ({
            code: country.callingCodes[0],
            name: country.name,
          }));

        let indices = [];
        let fields = formConfig
          .filter((item, index) => {
            if (item.inputType === 'phoneNumber') {
              indices.push(index);
              return true;
            }
            return false;
          })
          .map((item) => ({
            ...item,
            meta: res.data.map((country) => ({
              code: country.callingCodes[0],
              name: country.name,
            })),
          }));

        indices.forEach((item, index) => {
          formConfig[item] = fields[index];
        });

        setFormConfig(formConfig.map((item) => ({ ...item })));
      })
      .catch(console.err);
    //fetching blood group
    fetchBloodGroup('BLOOD_GROUP')
      .then((res) => {
        if (res.data.status === 1) {
          const bloodGrpData = res.data.body.map((eachItem) => ({
            code: eachItem.id,
            text: eachItem.value,
          }));
          formConfig.find((item) => item.name == 'bloodGroup').meta =
            bloodGrpData;
          setFormConfig([...formConfig]);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
    fetchPatient();
  }, []);

  //setting documents and insurance types
  useEffect(() => {
    if (forms) {
      documents[1].meta = forms.security.map((item) => item.formName);
      setDocuments(documents);
    }
  }, [forms]);

  useEffect(() => {
    // onFormValueChange(formInitialValues, formInitialValues);
  }, [formInitialValues]);

  //regenerating dynamic form fields in case of update
  useEffect(() => {
    //documents fields
    if (
      Object.keys(formInitialValues).length > 0 &&
      formInitialValues.documents &&
      formInitialValues.documents.length
    ) {
      setDynamicDocumentTypes(
        formInitialValues.documents.map((doc) => {
          return generateFormFields(
            forms.security.find((type) => type.formName === doc.type).fields,
            undefined
          );
        })
      );
    } else {
      // form.setFieldsValue({
      //   addresses: [{}],
      //   identityDocuments: [{}],
      //   alternateContactDetails: [{}],
      //   phoneNumbers: [{}],
      // });
    }
  }, [formInitialValues]);

  const onFormValueChange = (changed, all) => {
    if (changed.dob) {
      form.setFieldsValue({ age: moment().diff(changed.dob, 'years', false) });
    }
    // if (!all.dob) {
    //   form.setFieldsValue({ age: undefined });
    // }
    if (changed.nationality) {
      setFormConfig(formConfig.map((item) => ({ ...item })));
    }
    setDisabled(false);

    if (changed.documents) {
      const index = changed.documents.findIndex((item) => item !== undefined);

      if (
        index !== -1 &&
        changed.documents[index] &&
        changed.documents[index].type
      ) {
        let temp = generateFormFields(
          forms.security.find(
            (type) =>
              type.formName ===
              (changed.documents[index] && changed.documents[index].type)
          ).fields,
          undefined
        );
        dynamicDocumentTypes[index] = temp;
        setDynamicDocumentTypes([...dynamicDocumentTypes]);
      }
    }
  };
  useEffect(() => {
    if (
      register &&
      dynamicDocumentTypes.length > 0 &&
      form.getFieldValue('documents').length === 0
    ) {
      form.setFieldsValue({ documents: [{}] });
    }
  }, [dynamicDocumentTypes]);
  const onFinish = async (values) => {
    if (values.documents)
      values.documents = values.documents.map((item) => {
        return {
          formId: forms.security.find((form) => form.formName === item.type).id,
          fields: deserialize(item),
        };
      });

    submitHandler(values);
    setDisabled(!disabled);
  };

  const fetchPatient = () => {
    let body = selectedItem;

    !register && selectedItem.id !== undefined
      ? form.setFieldsValue({
          firstName: body.firstName,
          lastName: body.lastName,
          gender: body.gender,
          dob: moment(body.dob),
          age: moment().diff(body.dob, 'years', false),
          bloodGroup: body.bloodGroup?.id,
          maritalStatus: body.additionalInfo?.maritalStatus,
          race: body.additionalInfo?.race,
          religion: body.additionalInfo?.religion,
          nationality: body.additionalInfo?.nationality,
          citizenship: body.additionalInfo?.citizenship,
          primaryLanguage: body.additionalInfo?.primaryLanguage,
          emailType: body.additionalInfo?.emailType,
          mobileNo: body.mobileNo,
          email: body.email,
          phoneNumbers: body.phoneNumbers.map((number) => {
            return {
              phoneType: number.phoneType,
              phoneNumber: {
                countryCode: number.countryCode,
                phoneNumber: number.phoneNumber,
              },
            };
          }),
          alternateContactDetails: body.additionalInfo?.alternateContactDetails,
          addresses: body.addresses,
          status: body.status,
          deathStatus: body.additionalInfo?.deathStatus === true ? 'Yes' : 'No',
          status: body.status && body.status,
          deathDate: body.additionalInfo?.deathDate
            ? moment(body.additionalInfo?.deathDate)
            : undefined,
          identityDocuments: body.identityDocuments.map((doc) => {
            return {
              documentType: doc.documentType,
              number: doc.number,
              expiryDate: moment(doc.expiryDate),
              documentUrl: doc.documentUrl,
            };
          }),
        })
      : form.setFieldsValue({
          identityDocuments: [{}],
          addresses: [{}],
          phoneNumbers: [{}],
          alternateContactDetails: [{}],
        });
  };

  useEffect(() => {
    if (register && forms) {
      form.setFieldsValue({ documents: [{}] });
    }
  }, [forms]);

  return (
    <Col span={24}>
      <Form
        layout="vertical"
        onValuesChange={onFormValueChange}
        onFinish={onFinish}
        // initialValues={formInitialValues}
        // key={Object.keys(formInitialValues).length !== 0}
        form={form}
        className="form-m2-style"
        scrollToFirstError={true}
      >
        <FormBuilder formConfig={formConfig} />
        <Form.List name="phoneNumbers">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <FormBuilder
                    formConfig={phoneNumberType}
                    index={index}
                    // formInstance={form}
                  />
                  {fields.length > 1 && (
                    <Form.Item k>
                      <Divider>
                        <Button
                          onClick={() => {
                            remove(field.name);
                          }}
                          type="danger"
                          ghost
                          size="small"
                        >
                          Remove
                        </Button>
                      </Divider>
                    </Form.Item>
                  )}
                </div>
              ))}
              <Form.Item>
                <RightButton
                  onClick={() => {
                    add();
                  }}
                  type="primary"
                  ghost
                  size="small"
                >
                  Add New Phone Number
                </RightButton>
              </Form.Item>
            </div>
          )}
        </Form.List>
        {/* <Form.List name="addresses">
          {(fields, { add, remove }) => (
            <div>
              <Form.Item>
                <RightButton
                  onClick={() => {
                    add();
                    console.log("Clicked add");
                  }}
                  type="primary"
                  ghost
                  size="small"
                >
                  Add New Address
                </RightButton>
              </Form.Item>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <FormBuilder
                    formConfig={addresses}
                    index={index}
                    // formInstance={form}
                  />
                  {fields.length > 1 && (
                    <Form.Item k>
                      <Divider>
                        <Button
                          onClick={() => {
                            remove(field.name);
                          }}
                          type="danger"
                          ghost
                          size="small"
                        >
                          Remove
                        </Button>
                      </Divider>
                    </Form.Item>
                  )}
                </div>
              ))}
            </div>
          )}
        </Form.List>
        <Form.List name="alternateContactDetails">
          {(fields, { add, remove }) => (
            <div>
              <Form.Item>
                <RightButton
                  onClick={() => {
                    add();
                    console.log("Clicked add");
                  }}
                  type="primary"
                  ghost
                  size="small"
                >
                  Add New Relationship
                </RightButton>
              </Form.Item>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <FormBuilder
                    formConfig={nextOfKinType}
                    index={index}
                    // formInstance={form}
                  />
                  {fields.length > 1 && (
                    <Form.Item k>
                      <Divider>
                        <Button
                          onClick={() => {
                            remove(field.name);
                          }}
                          type="danger"
                          ghost
                          size="small"
                        >
                          Remove
                        </Button>
                      </Divider>
                    </Form.Item>
                  )}
                </div>
              ))}
            </div>
          )}
        </Form.List>
        <Form.List name="identityDocuments">
          {(fields, { add, remove }) => (
            <div>
              <Form.Item>
                <RightButton
                  onClick={() => {
                    add();
                    console.log("Clicked add");
                  }}
                  type="primary"
                  ghost
                  size="small"
                >
                  Add New Document
                </RightButton>
              </Form.Item>

              {fields.map((field, index) => (
                <div key={field.key}>
                  <FormBuilder
                    formConfig={staticDocumentType}
                    index={index}
                    // formInstance={form}
                  />

                  {form.getFieldValue("identityDocuments")[index] &&
                    form.getFieldValue("identityDocuments")[index]
                      .documentUrl && (
                      <Form.Item
                        name={[index, "documentUrl"]}
                        label="Upload Document"
                      >
                        <DocumentImages gutter={[12, 12]}>
                          <DocumentImage>
                            <img
                              src={
                                PROFILE_IMAGE_PREFIX +
                                form.getFieldValue("identityDocuments")[index]
                                  .documentUrl
                              }
                              height={"100%"}
                            />
                          </DocumentImage>
                        </DocumentImages>
                      </Form.Item>
                    )}

                  {fields.length > 1 && (
                    <Form.Item k>
                      <Divider>
                        <Button
                          onClick={() => {
                            remove(field.name);
                            form.validateFields();
                          }}
                          type="danger"
                          ghost
                          size="small"
                        >
                          Remove
                        </Button>
                      </Divider>
                    </Form.Item>
                  )}
                </div>
              ))}
            </div>
          )}
        </Form.List> */}

        {!register && selectedItem.id && (
          <FormBuilder formConfig={deathStatus} />
        )}
        {register ? (
          <Col span={24}>
            <Form.Item
              name="tandc"
              rules={[
                {
                  required: true,
                  message:
                    'Accept Terms and Conditions to complete registration.',
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox className="form-check-tag">
                I agree to the Terms of Service and Privacy Policy.
              </Checkbox>
            </Form.Item>
          </Col>
        ) : (
          <>
            <Col span={24}>
              <SectionTtile>Active Status</SectionTtile>
            </Col>

            <Col span={24}>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group size="large">
                  <Radio value={'ACTIVE'}>Active</Radio>
                  <Radio value={'INACTIVE'}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </>
        )}
        {register && (
          <Form.Item
            name="captcha"
            rules={[
              {
                required: true,
                message: 'Please verify captcha!',
              },
            ]}
          >
            <ReCAPTCHA ref={recaptchaRef} sitekey={CAPTCHA_SERCRET_KEY} />
          </Form.Item>
        )}
        <Form.Item className="flex-end-btn">
          <StyledRow gutter={12} justify="end">
            <Col>
              <Button
                onClick={() => {
                  // form.resetFields();
                  // setSelectedItem({});
                  // history.push("/login");
                  setVisible(false);
                  form.resetFields();
                }}
                className="btn-cancel"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                loading={submitLoading}
                htmlType="submit"
                className="btn-save"
                disabled={disabled}
                // onClick={() => setDisabled(!disabled)}
              >
                Save
              </Button>
            </Col>
          </StyledRow>
        </Form.Item>
      </Form>
    </Col>
  );
};

export default PatientDetailsForm;
