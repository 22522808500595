import moment from 'moment';

//convert api data into form readable
export const serialize = (unserialised) => {
  return {
    [unserialised.formField.id]:
      unserialised.formField.fieldType === 'DATE'
        ? moment(unserialised.fieldValue)
        : unserialised.fieldValue,
  };
};

export const serializeInsurance = (unserialised) => {
  return {
    [unserialised.formField.id]:
      unserialised.formField.fieldType === 'DATE'
        ? moment(unserialised.value)
        : unserialised.value,
  };
};

//convert form data into api format
export const deserialize = (serialised) => {
  console.log('deserialize', serialised);
  return Object.keys(serialised)
    .map((key) => ({
      fieldValue: Array.isArray(serialised[key])
        ? serialised[key].join(', ')
        : typeof serialised[key] === 'object'
        ? serialised[key].code
        : serialised[key],
      formFieldId: key,
    }))
    .filter((item) => item.formFieldId !== 'type');
};

export const generateFormFields = (apiFiels, objectName) => {
  return apiFiels.map((item) => {
    let data = {
      label: item.formName,
      placeholder: item.placeHolder,
      inputType: migrateType(item.fieldType),
      name: objectName ? [objectName, item.id] : item.id,
      codetype: item.codeType,
      rules:
        item.fieldType === 'CHECKBOX'
          ? []
          : [
              {
                required: item.required,
                message: `${item.formName} is Required!`,
              },
            ],
      layout: item.width
        ? item.width === 'FULL'
          ? { span: 24 }
          : item.width == 'HALF'
          ? { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 }
          : { span: 6 }
        : { span: 12 },
      meta: [],
      order: item.fieldOrder,
    };
    if (item.codeType === 'NO_OF_DAYS' || item.codeType === 'TOTAL_DISPENSE') {
      const numberRegex = /^[1-9]\d*$/;
      data.rules.push({
        pattern: numberRegex,
        message: 'Please enter a valid number',
      });
    }
    if (item.hardValidator) {
      data.rules[data.rules.length] = {
        pattern: new RegExp(item.hardValidator.regex),
        message: item.hardValidator.message,
      };
    }
    return data;
  });
};

export const migrateType = (type) => {
  switch (type) {
    case 'TEXT':
      return 'input';
    case 'DATE':
      return 'date';
    case 'DROPDOWN':
      return 'select';
    case 'SEARCHABLE_DROPDOWN':
      return 'select-scroll';
    case 'MULTI_SELECT_DROP_DOWN':
      return 'select-multi';
    case 'CHECKBOX':
      return 'check-box';
    case 'TEXT_AREA':
      return 'text-area';
    case 'BOOLEAN_RADIO_BUTTON':
      return 'radio';
    case 'RADIO':
      return 'radio';
    case 'MULTI_SELECT_BUTTON':
      return 'button-check-box';
    case 'NUMBER':
      return 'number';
    default:
      return null;
  }
};
