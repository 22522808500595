import React, { useState, useEffect } from "react";
import CustomModal from "../utils/CustomModal.jsx";
import styled from "styled-components";
import { Row, Col, Button, message } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { updateInTime } from "../../apis/Appointment.js";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

const BodyContainer = styled.div`
  width: 400px;
  @media (max-width: 480px) {
    width: unset;
    .in-time-section {
      flex-direction: column;
      align-items: unset;
    }
  }
`;

const Styledh2 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
`;

const IntimeModal = ({ visible, setVisible, id, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");

  const theme = useSelector((state) => state.themeReducer);

  const onCancelClick = () => {
    setVisible(false);
  };

  const onSave = () => {
    setLoading(true);

    updateInTime(id.appointmentId, time)
      .then(({ status, data }) => {
        if (status === 200) {
          if (data.status == 1) {
            refetch();
            message.success("In Time recorded!");
            setLoading(false);
            setVisible(false);
          } else {
            message.error(data.body);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };
  return (
    <CustomModal visible={visible}>
      <BodyContainer>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Styledh2 color={theme["@primary-color"]}>{`Patient ID :  ${
              id ? id.patientId : ""
            }`}</Styledh2>
          </Col>
          <Col span={24}>
            <Row align="middle" className="in-time-section">
              <Col span={12}>Patient Arrival Time : </Col>
              <Col span={12}>
                <TimePicker time={time} setTime={setTime} />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={12} justify="end">
              <Col>
                <Button type="primary" ghost onClick={onCancelClick}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" loading={loading} onClick={onSave}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </BodyContainer>
    </CustomModal>
  );
};

const TimePicker = ({ time, setTime }) => {
  const [hours, setHours] = useState(
    new Date().getHours() > 12
      ? new Date().getHours() - 12
      : new Date().getHours()
  );
  const [minute, setMinute] = useState(new Date().getMinutes());
  const [mediterian, setMeditarian] = useState(
    new Date().getHours() > 12 ? "PM" : "AM"
  );
  const [currentHour, setCurrentHour] = useState(
    new Date().getHours() > 12
      ? new Date().getHours() - 12
      : new Date().getHours()
  );

  const [currentMediterian, setCurrentMediterian] = useState(
    new Date().getHours() > 12 ? "PM" : "AM"
  );
  const onHourIncrement = () => {
    if (hours == 12) {
      setHours(1);
    } else if (hours + 1 >= currentHour) {
      setHours(hours + 1);
    } else if (hours + 1 < currentHour) {
      if (mediterian !== currentMediterian);
      setHours(hours + 1);
    }
  };
  const onHourDecrement = () => {
    if (hours == 1) {
      setHours(12);
    } else if (hours - 1 >= currentHour) {
      setHours(hours - 1);
    } else {
      if (currentMediterian !== mediterian) {
        setHours(hours - 1);
      }
    }
  };
  const onMinuteIncrement = () => {
    if (minute == 59) {
      setMinute(0);
    } else setMinute(minute + 1);
  };
  const onMinuteDecrement = () => {
    if (minute == 0) {
      setMinute(59);
    } else setMinute(minute - 1);
  };

  const onMeditarianChange = () => {
    if (mediterian == "AM") setMeditarian("PM");
    else setMeditarian("AM");
  };

  useEffect(() => {
    setTime(`${hours}:${minute} ${mediterian}`);
  }, [hours, minute, mediterian]);
  return (
    <Row gutter={6} align="middle">
      <Col span={8}>
        <TimeBox
          value={hours}
          onIncrement={onHourIncrement}
          onDecrement={onHourDecrement}
        />
      </Col>
      <Col span={8}>
        <TimeBox
          value={minute}
          onIncrement={onMinuteIncrement}
          onDecrement={onMinuteDecrement}
        />
      </Col>
      <Col span={8}>
        <TimeBox
          type="primary"
          value={mediterian}
          onIncrement={onMeditarianChange}
          onDecrement={onMeditarianChange}
        />
      </Col>
    </Row>
  );
};

const TimeBox = ({ type, value, onIncrement, onDecrement }) => {
  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <div style={{ textAlign: "center", width: "50px" }}>
          <CaretUpOutlined onClick={onIncrement} style={{ fontSize: "10px" }} />
        </div>
      </Col>
      <Col span={24}>
        <Button type={type} style={{ width: "50px", height: "50px" }}>
          {value == 0 ? "00" : value}
        </Button>
      </Col>
      <Col span={24}>
        {" "}
        <div style={{ textAlign: "center", width: "50px" }}>
          <CaretDownOutlined
            onClick={onDecrement}
            style={{ fontSize: "10px" }}
          />
        </div>
      </Col>
    </Row>
  );
};
export default IntimeModal;
