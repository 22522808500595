import axiosInstance from "../config/axiosInstance";
const SETTINGS_PRFIX = "/settings";

export const uploadImage = async (fileBlob) => {
  const formData = new FormData();
  formData.append("imageFile", fileBlob,fileBlob.name);
  return axiosInstance.post(SETTINGS_PRFIX + `/upload-image`, formData, {
    "content-type": "multipart/form-data",
  });
};

export const saveSettings = (data) => {
  return axiosInstance.post(SETTINGS_PRFIX, data);
};

export const getSettings = () => {
  return axiosInstance.get(SETTINGS_PRFIX);
};
