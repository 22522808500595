import React, { useState } from "react";
import { Form, Input, message } from "antd";
import FullWidthButton from "../utils/FullWidthButton";
import styled from "styled-components";
import { resetPassword } from "../../apis/Auth";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

const StyledForm = styled(Form)`
  & .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }
`;
const ResetForm = ({ token }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    resetPassword({ password: values.password, resetToken: token })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status === 1) {
            if (!res.data.body.backend)
              history.push({
                pathname: "/login",
                state: { showAlert: true },
              });
            else
              history.push({
                pathname: "/admin-login",
                state: { showAlert: true },
              });
          } else {
            message.error(res.data.body);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        if (
          typeof err.response.data.body !== "object" &&
          !Array.isArray(err.response.data.body)
        )
          message.error(err.response.data.body);
        else message.error("Service temparly unavailable");
        console.log(err);
Sentry.captureException(err);
        setLoading(false);
      });
  };
  return (
    <StyledForm
      layout="vertical"
      onFinish={onFinish}
      className="popup-form-data"
      scrollToFirstError={true}
    >
      <Form.Item
        label="New password"
        name="password"
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Enter a new password" size="large" />
      </Form.Item>
      <Form.Item
        label="Confirm new password"
        name="confirmPassword"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "The two passwords that you entered do not match!"
              );
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm your new password" size="large" />
      </Form.Item>
      <Form.Item>
        <FullWidthButton
          size="large"
          htmlType="submit"
          loading={loading}
          className="submit-btn w-100"
        >
          Reset my password
        </FullWidthButton>
      </Form.Item>
    </StyledForm>
  );
};

export default ResetForm;
