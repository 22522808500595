import { Button, Col, DatePicker, InputNumber } from 'antd';

import Icon from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

export const BodyContainerSection = styled(Col)`
  background: #fff;
  padding: 20px;
  min-height: calc(100vh - 200px);
  margin-bottom: 20px;
  border-radius: 10px;
`;

export const StyledDivParent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledDivQrCode = styled.div`
  height: auto;
  width: 100px;
`;

export const StyledDivPrint = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
