import React from "react";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/DeleteIcon.svg";
import moment from "moment";

const activeStatus = {
  true: "Active",
  false: "Inactive",
};

export const columns = (
  setEditModal,
  setSelectedItem,
  setCreateModal,
  setDeleteModal,
  pageNo,
  pageSize
) => [
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: "Name",
    // dataIndex: ["user","userType"],
    render: (record) =>
      record.user && [record.user.firstName, record.user.lastName].join(" "),
  },
  {
    title: "User Type",
    dataIndex: ["user", "role", "name"],
    render: (value) => (
      <div>{value && value.charAt(0) + value.slice(1).toLowerCase()}</div>
    ),
  },
  {
    title: "Department",
    dataIndex: ["user", "departments"],
    render: (value) => (
      <div style={{ textAlign: value === null ? "center" : "left" }}>
        {value && value.length > 0
          ? value.map((item) => item.departmentName).join(", ")
          : "-"}
      </div>
    ),
  },
  { title: "Email", dataIndex: ["user", "email"] },
  {
    title: "Mobile Number",
    dataIndex: ["user"],
    render: (value) => {
      // console.log("value of mobile number", value);
      return value.userType === "PATIENT" ? (
        <>
          {value.additionalInfo &&
            value.additionalInfo.alternateContactDetails &&
            value.additionalInfo.alternateContactDetails[0]?.phoneNumber?.countryCode?.split(
              " "
            )[0]}
          -
          {value.additionalInfo &&
            value.additionalInfo.alternateContactDetails &&
            value.additionalInfo.alternateContactDetails[0]?.phoneNumber
              ?.phoneNumber}
        </>
      ) : (
        <>
          {value && value.mobileNo && value.mobileNo?.countryCode.split(" ")[0]}
          -{value && value.mobileNo && value.mobileNo?.phoneNumber}{" "}
        </>
      );
    },
  },

  {
    title: "Status",
    dataIndex: ["user", "status"],
    render: (value) => (
      <div style={{ color: value === "ACTIVE" ? "green" : "red" }}>
        {value && value.charAt(0) + value.toLowerCase().slice(1)}
      </div>
    ),
  },
  ,
  {
    title: "Updated By",
    dataIndex: ["user", "updatedBy"],
    render: (value) => (value ? value : "-"),
  },
  {
    title: "Last Updated",
    dataIndex: ["user", "lastUpdatedDate"],
    render: (value) => (value ? moment(value).format("DD-MM-YYYY") : "-"),
  },
  {
    title: "Actions",
    render: (record) =>
      record &&
      record.user && (
        <Row gutter={12}>
          {record.editable && (
            <Col>
              <Icon
                style={{ fontSize: "20px" }}
                component={EditIcon}
                onClick={() => {
                  setSelectedItem(record.user);
                  setEditModal(true);
                  setCreateModal(true);
                }}
              />
            </Col>
          )}
          {record.user.status === "ACTIVE" && record.editable && (
            <Col>
              <Icon
                style={{ fontSize: "22px" }}
                component={DeleteIcon}
                onClick={() => {
                  setSelectedItem(record.user);
                  setDeleteModal(true);
                }}
              />
            </Col>
          )}
        </Row>
      ),
  },
];
