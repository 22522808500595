import axiosInstance from "../config/axiosInstance";
const ADMIN_PREFIX = "/admin";

export const findAllPrivilegesByRole = (id) => {
  return axiosInstance.get(
    `${ADMIN_PREFIX}/role-based-privileges?roleId=${id}`
  );
};

export const updatePrivileges = (data) => {
  return axiosInstance.put(`${ADMIN_PREFIX}/role-privilege`, data);
};

export const addRole = (data) => {
  return axiosInstance.post(`${ADMIN_PREFIX}/add-role`, data);
};
export const deleteChiefComplaint = (id) => {
  return axiosInstance.delete(`${ADMIN_PREFIX}/chief-complaint?id=${id}`);
};
export const addChiefcomplaint = (data) => {
  return axiosInstance.post(`${ADMIN_PREFIX}/chief-complaint`, data);
};
export const updateChiefcomplaint = (data) => {
  return axiosInstance.patch(`${ADMIN_PREFIX}/chief-complaint`, data);
};
export const fetchChiefComplaints = (pageNo, pageSize) => {
  return axiosInstance.get(
    `${ADMIN_PREFIX}/chief-complaints?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const fetchRolesById = (id) => {
  return axiosInstance.get(
    `${ADMIN_PREFIX}/user-based-privileges?userId=${id}`
  );
};
export const fetchLabTests = (searchQuery) => {
  return axiosInstance.get(`${ADMIN_PREFIX}/lab-tests?${searchQuery}`);
};
export const deleteLabTest = (id) => {
  return axiosInstance.delete(`${ADMIN_PREFIX}/delete-lab-test?id=${id}`);
};
export const addLabTests = (data) => {
  return axiosInstance.post(`${ADMIN_PREFIX}/lab-test`, data);
};
export const updateLabTests = (data) => {
  return axiosInstance.patch(`${ADMIN_PREFIX}/lab-test`, data);
};
export const fetchRadiologyTests = (searchQuery) => {
  return axiosInstance.get(`${ADMIN_PREFIX}/radiology-tests?${searchQuery}`);
};
export const deleteRadiologyTest = (id) => {
  return axiosInstance.delete(`${ADMIN_PREFIX}/delete-radiology-test?id=${id}`);
};
export const addRadiologyTests = (data) => {
  return axiosInstance.post(`${ADMIN_PREFIX}/radiology-test`, data);
};
export const updateRadiologyTests = (data) => {
  return axiosInstance.patch(`${ADMIN_PREFIX}/radiology-test`, data);
};
export const importDrugs = (data) => {
  const formData = new FormData();
  formData.append("drugs", data);
  return axiosInstance.post(`${ADMIN_PREFIX}/import-drugs`, formData, {
    "content-type": "multipart/form-data",
  });
};
export const importDictionary = (data) => {
  const formData = new FormData();
  formData.append("dictionary", data);
  return axiosInstance.post(`${ADMIN_PREFIX}/import-dictionary`, formData, {
    "content-type": "multipart/form-data",
  });
};
export const getDictionaryList = (page, size, name) => {
  return axiosInstance.get(
    `${ADMIN_PREFIX}/dictionary?page=${page}&size=${size}${
      name ? `&type=${name}` : ""
    }`
  );
};
export const exportDictionary = () => {
  return axiosInstance.get(`${ADMIN_PREFIX}/export-dictionary`, {
    headers: { "Content-Type": "application/octet-stream" },
    responseType: "blob",
  });
};
export const removeAllergy = (id) => {
  return axiosInstance.delete(`${ADMIN_PREFIX}/dictionary?id=${id}`);
};

export const addOrModifyDictionary = (data) => {
  return axiosInstance.post(`${ADMIN_PREFIX}/dictionary`, data);
};
export const getDrugsList = (page, size, name) => {
  return axiosInstance.get(
    `${ADMIN_PREFIX}/drugs?page=${page}&size=${size}&name=${name}`
  );
};

export const removeDrug = (id) => {
  return axiosInstance.delete(`${ADMIN_PREFIX}/drug?id=${id}`);
};

export const addOrModifyDrug = (data) => {
  return axiosInstance.post(`${ADMIN_PREFIX}/modify-drugs`, data);
};

export const exportDrugs = () => {
  return axiosInstance.get(`${ADMIN_PREFIX}/export-drugs`, {
    headers: { "Content-Type": "application/octet-stream" },
    responseType: "blob",
  });
};

export const fetchEmailTemplate = () => {
  return axiosInstance.get(`${ADMIN_PREFIX}/email-template`);
};
export const saveEmailTemplate = (data) => {
  return axiosInstance.post(`${ADMIN_PREFIX}/email-template`, data);
};
