export const ServiceSelectDays = [
  {
    dayOfWeek: 'MONDAY',
    startTime: '',
    endTime: '',
    breakStartTime: '',
    breakEndTime: '',
    isAvailable: false,
  },
  {
    dayOfWeek: 'TUESDAY',
    startTime: '',
    endTime: '',
    breakStartTime: '',
    breakEndTime: '',
    isAvailable: false,
  },
  {
    dayOfWeek: 'WEDNESDAY',
    startTime: '',
    endTime: '',
    breakStartTime: '',
    breakEndTime: '',
    isAvailable: false,
  },
  {
    dayOfWeek: 'THURSDAY',
    startTime: '',
    endTime: '',
    breakStartTime: '',
    breakEndTime: '',
    isAvailable: false,
  },
  {
    dayOfWeek: 'FRIDAY',
    startTime: '',
    endTime: '',
    breakStartTime: '',
    breakEndTime: '',
    isAvailable: false,
  },
  {
    dayOfWeek: 'SATURDAY',
    startTime: '',
    endTime: '',
    breakStartTime: '',
    breakEndTime: '',
    isAvailable: false,
  },
  {
    dayOfWeek: 'SUNDAY',
    startTime: '',
    endTime: '',
    breakStartTime: '',
    breakEndTime: '',
    isAvailable: false,
  },
];

//incase of object
// export const ServiceSelectDays = {
//   Monday: {
//     startTime: '',
//     endTime: '',
//     isAvailable: false,
//   },
//   Tuesday: {
//     startTime: '',
//     endTime: '',
//     isAvailable: false,
//   },
//   Wednesday: {
//     startTime: '',
//     endTime: '',
//     isAvailable: false,
//   },
//   Thursday: {
//     startTime: '',
//     endTime: '',
//     isAvailable: false,
//   },
//   Friday: {
//     startTime: '',
//     endTime: '',
//     isAvailable: false,
//   },
//   Saturday: {
//     startTime: '',
//     endTime: '',
//     isAvailable: false,
//   },
//   Sunday: {
//     startTime: '',
//     endTime: '',
//     isAvailable: false,
//   },
// };
