import axiosInstance from "../config/axiosInstance";
const RADIOLOGY_PREFIX = "/radiology";

export const getAppointmentDetailsAndTechnologyNotesRadiology = (appointmentId) => {
  return axiosInstance.get(`${RADIOLOGY_PREFIX}/appointment-orders?appointmentId=${appointmentId}`);
};

export const submitTestReportRadiology = (appointmentId) => {
  return axiosInstance.post(`${RADIOLOGY_PREFIX}/submit-test-report?appointmentId=${appointmentId}`);
};

export const updateLabOrderDetailsRadiology = (data) => {
  return axiosInstance.post(`${RADIOLOGY_PREFIX}/appointment-order`, data);
};

export const fetchFieldsOfTestBasedOnTestTypeRadiology = (testId) => {
  return axiosInstance.get(`${RADIOLOGY_PREFIX}/test-fields/${testId}`);
};

export const fetchTableDataForTestRadiology = (appointmentId, testDataId) => {
  return axiosInstance.get(`${RADIOLOGY_PREFIX}/test-field-table-data?appointmentId=${appointmentId}&testDataId=${testDataId}`);
};

export const addTestFieldDataRadiology = (data) => {
  return axiosInstance.post(`${RADIOLOGY_PREFIX}/save-test-field-data`, data);
};

export const deleteTestFieldDataRadiology = (fieldDataId) => {
  return axiosInstance.delete(`${RADIOLOGY_PREFIX}/delete-test-field-data/${fieldDataId}`);
};