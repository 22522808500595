import { Button, Col, Row } from "antd";

import React from "react";
import moment from "moment";
import styled from "styled-components";

const InsuranceStatus = styled.div`
  color: ${(props) => statusColour[props.status]};
`;
const statusColour = {
  Paid: "green",
  "Claim Waiting": "orange",
  Pending: "orange",
  "Partially Accepted": "orange",
  "Waiting for Approval": "orange",
  Declined: "red",
};

export const preApprovalInsuranceColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme
) => [
    {
      title: "Actions",
      fixed: "left",
      render: (row) => {
        return (
          <Row gutter={12}>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();

                  history.push({
                    pathname: `/health-records/${row.appointment.patient.id}/${row.appointment.patient.firstName +
                      row.appointment.patient.lastName
                      }/${row.appointment.id}`,
                    state: {
                      preApproval: true, insuranceRequestId: row.id, waitingForPreApproval: true
                    },
                  });
                  localStorage.setItem("insuranceRequestId", row.id);
                }}
              >
                View Details
              </StyledButton>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "P ID",
      dataIndex: ["appointment", "patient", "id"],
      key: "id",
    },
    {
      title: "F Name",
      dataIndex: ["appointment", "patient", "firstName"],
      key: "firstName",
    },
    {
      title: "L Name",
      dataIndex: ["appointment", "patient", "lastName"],
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: ["appointment", "patient", "gender"],
      key: "gender",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: ["appointment", "patient", "phoneNumbers"],
      key: "phone",
      render: (value) =>
        value && (
          <div>
            {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
          </div>
        ),
    },
    {
      title: "Visit Type",
      dataIndex: ["appointment", "visitType", "value"],
      key: "visitType",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Doctor Name",
      dataIndex: ["appointment", "parentAppointment", "doctor"],
      key: "doctor",
      render: (doctor) => {
        if (doctor !== null) {
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        } else return <div>-</div>;
      },
    },
    // {
    //   title: "Doctor Ordered Date",
    //   dataIndex: "",
    //   key: "doctorOrderedDate",
    //   render: (date) => {
    //     if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
    //     else return <div>-</div>;
    //   },
    // },
    {
      title: "Visit Booked By",
      dataIndex: ["appointment", "visitBookedBy"],
      key: "visitBookedBy",
      render: (value) => {
        if (value !== null) {
          return <div> {value} </div>;
        } else return <div>-</div>;
      },
    },
    {
      title: "Visit Booked Date",
      dataIndex: "date",
      key: "visitBookedDate",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Insurance Provider",
      dataIndex: ["appointment", "insuranceData", "insuranceProvider", "name"],
      key: "insuranceProvider",
      render: (insuranceProvider) => {
        if (insuranceProvider !== null) return <div>{insuranceProvider}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Insurance Number",
      dataIndex: ["appointment", "insuranceData", "number"],
      key: "insuranceNumber",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
  ];

export const claimPendingInsuranceColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme
) => [
    {
      title: "Actions",
      key: "id",
      fixed: "left",
      render: (row) => {
        return (
          <Row gutter={12}>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname: `/health-records/${row.appointment.patient.id}/${row.appointment.patient.firstName +
                      row.appointment.patient.lastName
                      }/${row.appointment.id}`,
                    state:
                      row.appointment?.visitType?.value == "Laboratory" ||
                        row.appointment?.visitType?.value == "Radiology"
                        ? {
                          preApproval: true, visitType: true, insuranceRequestId: row.id
                        }
                        : row.pharmacyRequest ? {
                          preApproval: false, insuranceRequestId: row.id, pharmacy: true
                        } : row.appointment?.visitType?.value == "Hospital" && {
                          preApproval: false, insuranceRequestId: row.id, hospital: true
                        }
                  });
                  localStorage.setItem("insuranceRequestId", row.id);
                }}
              >
                Update
              </StyledButton>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "P ID",
      dataIndex: ["appointment", "patient", "id"],
      key: "id",
    },
    {
      title: "F Name",
      dataIndex: ["appointment", "patient", "firstName"],
      key: "firstName",
    },
    {
      title: "L Name",
      dataIndex: ["appointment", "patient", "lastName"],
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: ["appointment", "patient", "gender"],
      key: "gender",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: ["appointment", "patient", "phoneNumbers"],
      key: "phone",
      render: (value) =>
        value && (
          <div>
            {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
          </div>
        ),
    },
    {
      title: "Visit Type",
      dataIndex: ["appointment", "visitType", "value"],
      key: "visitType",
      render: (value, record, index) => {
        if (record.pharmacyRequest)
          return "Pharmacy"
        else if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Doctor Name",
      dataIndex: ["appointment", "doctor"],
      key: "doctor",
      render: (doctor, record, index) => {
        if (doctor !== null) {
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        } else if (record.appointment?.parentAppointment?.doctor)
          return (
            <div>
              Dr. {record.appointment?.parentAppointment?.doctor.firstName} {record.appointment?.parentAppointment?.doctor.lastName}
            </div>
          )
        else return <div>-</div>;
      },
    },
    {
      title: "Visit Booked By",
      dataIndex: ["appointment", "visitBookedBy"],
      key: "visitBookedBy",
      render: (value) => {
        if (value !== null) {
          return <div> {value} </div>;
        } else return <div>-</div>;
      },
    },
    {
      title: "Visit Booked Date",
      dataIndex: "date",
      key: "visitBookedDate",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Insurance Provider",
      dataIndex: ["appointment", "insuranceData", "insuranceProvider", "name"],
      key: "insuranceProvider",
      render: (insuranceProvider) => {
        if (insuranceProvider !== null) return <div>{insuranceProvider}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Insurance Number",
      dataIndex: ["appointment", "insuranceData", "number"],
      key: "insuranceNumber",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        if (value !== null)
          return <InsuranceStatus status={value}>{value}</InsuranceStatus>;
        else return <div>-</div>;
      },
    },
  ];

export const historyInsuranceColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  currency
) => [
    {
      title: "Actions",
      key: "id",
      fixed: "left",
      render: (row) => {
        return (
          <Row gutter={12}>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname:
                      `/health-records/${row.appointment.patient.id}/${row.appointment.patient.firstName +
                      row.appointment.patient.lastName
                      }/${row.appointment.id}`, state: {
                        preApproval: row.pharmacyRequest ? false : true,
                        insuranceRequestId: row.id,
                        visitType: row.appointment?.visitType?.value == "Laboratory" ||
                          row.appointment?.visitType?.value == "Radiology",
                        pharmacy: row.pharmacyRequest ? true : false,
                        hospital: row.appointment?.visitType?.value == "Hospital"
                      }
                  });
                  localStorage.setItem("insuranceRequestId", row.id);
                }}
              >
                View Details
              </StyledButton>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "P ID",
      dataIndex: ["appointment", "patient", "id"],
      key: "id",
    },
    {
      title: "F Name",
      dataIndex: ["appointment", "patient", "firstName"],
      key: "firstName",
    },
    {
      title: "L Name",
      dataIndex: ["appointment", "patient", "lastName"],
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: ["appointment", "patient", "gender"],
      key: "gender",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: ["appointment", "patient", "phoneNumbers"],
      key: "id",
      render: (value) =>
        value && (
          <div>
            {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
          </div>
        ),
    },
    {
      title: "Visit Type",
      dataIndex: ["appointment", "visitType", "value"],
      key: "visitType",
      render: (value, record, index) => {
        if (record.pharmacyRequest)
          return "Pharmacy"
        else if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Doctor Name",
      dataIndex: ["appointment", "doctor"],
      key: "doctor",
      render: (doctor, record, index) => {
        if (doctor !== null) {
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        } else if (record.appointment?.parentAppointment?.doctor)
          return (
            <div>
              Dr. {record.appointment?.parentAppointment?.doctor.firstName} {record.appointment?.parentAppointment?.doctor.lastName}
            </div>
          )
        else return <div>-</div>;
      },
    },
    {
      title: "Visit Booked By",
      dataIndex: ["appointment", "visitBookedBy"],
      key: "visitBookedBy",
      render: (value) => {
        if (value !== null) {
          return <div> {value} </div>;
        } else return <div>-</div>;
      },
    },
    {
      title: "Visit Booked Date",
      dataIndex: "date",
      key: "visitBookedDate",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Insurance Provider",
      dataIndex: ["appointment", "insuranceData", "insuranceProvider", "name"],
      key: "insuranceProvider",
      render: (insuranceProvider) => {
        if (insuranceProvider !== null) return <div>{insuranceProvider}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Insurance Number",
      dataIndex: ["appointment", "insuranceData", "number"],
      key: "insuranceNumber",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "id",
      render: (amount) => {
        if (amount !== null) return `${currency ? currency : ""} ${amount}`;
        else return <div>-</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        if (value !== null)
          return <InsuranceStatus status={value}>{value == "Paid" ? "Claimed" : value}</InsuranceStatus>;
        else return <div>-</div>;
      },
    },
  ];
